import { Modal, Spinner } from "react-bootstrap";
import { useAppSelector } from "../../../../hooks";
import { selectGame } from "../../../gameSlice";

export default function AnimalAttackModal() {
    const { animalAttack } = useAppSelector(selectGame);

    return (
        <Modal show={!!animalAttack && !animalAttack.result} backdrop="static">
            <Modal.Body className="text-center fs-2 pt-5 pb-5">
                { !!animalAttack &&
                <div>
                    <div>You're being attacked by wild animals!</div>
                    <div className="fs-4">Everybody grab your guns and start shooting!</div>
                    <div className="text-warning">&quot;{animalAttack.word}&quot;</div>
                </div>
                }
                <Spinner animation="grow" variant="danger" />
            </Modal.Body>
        </Modal>
    );
}