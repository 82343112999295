import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShirt } from "@fortawesome/free-solid-svg-icons";
import { Player } from "../../../types/Player";

export default function ClothesIndicator({ player }: { player: Player }) {
    if (player.naked) {
        return null;
    } else {
        return <FontAwesomeIcon icon={faShirt} className="ms-1 text-info" />
    }
}