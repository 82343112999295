export type EastereggName = 'Konami Code';

export interface Easteregg {
    name: EastereggName;
}

export interface KonamiCodeEasteregg extends Easteregg {
    name: 'Konami Code';
    count: number;
}

export interface Eastereggs {
    konamiCode: KonamiCodeEasteregg;
}

export function makeEastereggs(): Eastereggs {
    return {
        konamiCode: { name: 'Konami Code', count: 0 },
    };
}