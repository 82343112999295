import { Achievement } from "./Achievement";
import { HuntingHit, HuntingMiss } from "./Hunting";
import { Location } from "./location/Location";
import { Player } from "./Player";

export type GameEventType = 'Game Start'
    | 'Player Died'
    | 'Arrived At Hidden Location'
    | 'Arrived In Dickshooter'
    | 'Found Abandoned Wagon'
    | 'Hunting Failed'
    | 'Hunting Succeeded'
    | 'Unlocked Achievement';

let __nextEventID = 0;

export interface GameEvent {
    type: GameEventType;
    id: number;
}

export interface GameStartEvent extends GameEvent {
    type: 'Game Start';
}

export function makeGameStartEvent(): GameStartEvent {
    const id = ++__nextEventID;
    return { type: 'Game Start', id };
}

export interface PlayerDiedEvent extends GameEvent {
    type: 'Player Died';
    player: Player;
}

export function makePlayerDiedEvent(player: Player): PlayerDiedEvent {
    const id = ++__nextEventID;
    return { type: 'Player Died', id, player };
}

export interface ArrivedAtHiddenLocationEvent extends GameEvent {
    type: 'Arrived At Hidden Location';
    location: Location;
}

export function makeArrivedAtHiddenLocationEvent(location: Location): ArrivedAtHiddenLocationEvent {
    const id = ++__nextEventID;
    return { type: 'Arrived At Hidden Location', id, location };
}

export interface ArrivedInDickshooterEvent extends GameEvent {
    type: 'Arrived In Dickshooter';
}

export function makeArrivedInDickshooterEvent(): ArrivedInDickshooterEvent {
    const id = ++__nextEventID;
    return { type: 'Arrived In Dickshooter', id };
}

export interface FoundAbandonedWagonEvent extends GameEvent {
    type: 'Found Abandoned Wagon';
    oxen: number;
    wagonTongues: number;
    wagonWheels: number;
    wagonAxles: number;
    clothes: number;
    bullets: number;
    rifles: number;
    medicine: number;
    map: boolean;
}

export function makeFoundAbandonedWagonEvent(things: { oxen: number, wagonTongues: number, wagonWheels: number, wagonAxles: number, clothes: number, bullets: number, rifles: number, medicine: number, map: boolean }): FoundAbandonedWagonEvent {
    const id = ++__nextEventID;
    return { type: 'Found Abandoned Wagon', id, ...things };
}

export interface HuntingFailureEvent extends GameEvent {
    type: 'Hunting Failed';
    miss: HuntingMiss;
}

export function makeHuntingFailureEvent(miss: HuntingMiss): HuntingFailureEvent {
    const id = ++__nextEventID;
    return { type: 'Hunting Failed', id, miss };
}

export interface HuntingSucceededEvent extends GameEvent {
    type: 'Hunting Succeeded';
    hit: HuntingHit;
}

export function makeHuntingSucceededEvent(hit: HuntingHit): HuntingSucceededEvent {
    const id = ++__nextEventID;
    return { type: 'Hunting Succeeded', id, hit };
}

export interface UnlockedAchievementEvent extends GameEvent {
    type: 'Unlocked Achievement';
    achievement: Achievement;
}

export function makeUnlockedAchievementEvent(achievement: Achievement): UnlockedAchievementEvent {
    const id = ++__nextEventID;
    return { type: 'Unlocked Achievement', id, achievement };
}
