import { useState } from "react";
import { Card } from "react-bootstrap";
import { routingCurrentLocation } from "../../../../types/location/Routing";
import { Town } from "../../../../types/location/Town";
import { useAppSelector } from "../../../hooks";
import { selectGame } from "../../gameSlice";

import InnButton from "./inn/InnButton";
import InnModal from "./inn/InnModal";
import StoreButton from "./store/StoreButton";
import StoreModal from "./store/StoreModal";
import DoctorModal from "./doctor/DoctorModal";
import DoctorButton from "./doctor/DoctorButton";
import RationControl from "../navigation/RationControl";

export default function TownInteractions() {
    const { trail } = useAppSelector(selectGame);
    const location = routingCurrentLocation(trail.routing);
    const town = location?.type === 'Town' ? location as Town : null;

    const [isStoreModalOpen, setStoreModalOpen] = useState(false);
    const [isInnModalOpen, setInnModalOpen] = useState(false);
    const [isDoctorModalOpen, setDoctorModalOpen] = useState(false);

    if (town === null) {
        return null;
    }

    return (
        <>
        <StoreModal open={isStoreModalOpen} close={() => setStoreModalOpen(false)} />
        <InnModal open={isInnModalOpen} close={() => setInnModalOpen(false)} />
        <DoctorModal open={isDoctorModalOpen} close={() => setDoctorModalOpen(false)} />
        <Card>
            <Card.Header>Town</Card.Header>
            <Card.Body className="d-flex flex-column justify-content-start align-items-stretch">
                <StoreButton openStore={() => setStoreModalOpen(true)} />
                <InnButton openInn={() => setInnModalOpen(true)} />
                <DoctorButton openDoctor={() => setDoctorModalOpen(true)} />
            </Card.Body>
            <Card.Footer>
                <RationControl />
            </Card.Footer>
        </Card>
        </>
    );
}