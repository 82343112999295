import { ListGroup } from "react-bootstrap";
import { Message } from "../../../types/Message";

import StyledMessageView from "./StyledMessageView";

export default function MessagesView({ messages, bg, count }: { messages: Message[], bg?: 'dark', count?: number }) {
    let displayedMessages = [...messages];
    if (count) {
        displayedMessages = Array.from(displayedMessages.slice(displayedMessages.length - count));
    }

    return (
        <ListGroup>
            { displayedMessages.map((message, index) => <MessageView message={message} bg={bg} key={index} />) }
        </ListGroup>
    );
}

function MessageView({ message, bg }: { message: Message, bg?: 'dark' }) {
    return <StyledMessageView text={message.text} bg={bg} />;
}