import { Inventory, makeInventoryChanges } from "./inventory/Inventory";
import { InventoryItemName } from "./inventory/InventoryItem";

export type AmountChangeIndicationKind = InventoryItemName;

let lastID = 0;

export interface AmountChangeIndication {
    id: number;
    time: number;
    kind: AmountChangeIndicationKind;
    change: number;
}

function makeIndication(kind: AmountChangeIndicationKind, change: number): AmountChangeIndication {
    const id = ++lastID;
    const time = new Date().getTime();
    return { id, time, kind, change };
}

export function addInventoryChangeIndications(indications: Array<AmountChangeIndication>, inventoryBefore: Inventory, inventoryAfter: Inventory): Array<AmountChangeIndication> {
    return makeInventoryChanges(inventoryBefore, inventoryAfter)
        .reduce((previous, { name, change }) => [...previous, makeIndication(name, change)], indications);
}

export function displayed(indications: Array<AmountChangeIndication>, indication: AmountChangeIndication): Array<AmountChangeIndication> {
    return indications.filter(entry => entry.id !== indication.id);
}