import { HealthVariants } from "./health/Health";
import { toRomanNumeral } from "./Utilities";
import { randomElement, removeRandomElement } from "./Random";
import { Injury, makeInjury, isInjuryDamaging } from "./health/Injury";
import { Exhaustion, exhaustionMalus, makeExhaustion } from "./health/Exhaustion";
import { addDays, format } from "date-fns";

const rudds = ['Haul', 'Saul', 'Paul', 'Maul', 'Raúl'];

export interface Ox {
    name: string;
    age: number;
    hp: number;
    causeOfDeath?: string;
    injuries: Injury[];
    exhaustion: Exhaustion;
    ranOff?: {
        date: number;
        atMileage: number;
    };
}

export function formatOxen(oxen: Ox[] | number): string {
    if (typeof oxen === 'number') {
        if (oxen === 1) {
            return '1 ox';
        } else {
            return `${oxen} oxen`;
        }
    } else if (oxen.length === 1) {
        return '1 ox';
    } else {
        return `${oxen.length} oxen`;
    }
}

export function formatOxCreationDate(ox: Ox, date: Date | number): string {
    const creation = addDays(date, -ox.age);
    return format(creation, 'yyyy-MM-dd');
}

export function makeDefaultName(existingNames: string[], availableNames?: string[]): string {
    if (existingNames.length === 0) {
        return 'Haul Rudd';
    }

    let name: string;
    if (availableNames === undefined) {
        const firstName = randomElement(rudds)!;
        name = `${firstName} Rudd`;
    } else {
        const pickedName = removeRandomElement(availableNames);
        if (pickedName === undefined) {
            const firstName = randomElement(rudds)!
            name = `${firstName} Rudd`;
        } else {
            name = pickedName;
        }
    }

    const matches = existingNames.filter(n => n.startsWith(name)).length;
    if (matches === 0) {
        return name;
    } else {
        const n = toRomanNumeral(matches + 1);
        return `${name} ${n}.`;
    }
}

export function makeOx(name: string): Ox {
    const age = 0;
    const hp = HealthVariants.good.maximum;
    const injuries: Injury[] = [];
    const exhaustion = makeExhaustion();
    return { name, age, hp, injuries, exhaustion };
}

export function addOxen(oxen: Ox[], count: number = 1): Ox[] {
    const existingNames = oxen.map(ox => ox.name);
    const availableNames = [...rudds].map(f => f + ' Rudd');
    const modifiedOxen = [...oxen];
    for (let index = 0; index < count; ++index) {
        const name = makeDefaultName(existingNames, availableNames);
        existingNames.push(name);
        modifiedOxen.push(makeOx(name));
    }
    return modifiedOxen;
}

export function removeOxen(oxen: Ox[], count: number = 1): Ox[] {
    const modifiedOxen = [...oxen];
    modifiedOxen.splice(0, count);
    return modifiedOxen;
}

export function changeOxHP(ox: Ox, delta: number): Ox {
    const hp = Math.max(0, ox.hp + delta);
    if (delta !== 0) {
        return { ...ox, hp };
    }
    return { ...ox, hp };
}

export function capOxHP(ox: Ox): Ox {
    const hp = Math.min(HealthVariants.good.maximum, Math.max(0, ox.hp));
    return { ...ox, hp };
}

export function isOxDead(ox: Ox): boolean {
    return ox.hp === 0;
}

export function enableOxDeath(ox: Ox, causeOfDeath: string): Ox {
    const exhaustion = makeExhaustion();
    return { ...ox, hp: 0, causeOfDeath, injuries: [], exhaustion };
}

export function disableOxDeath(ox: Ox, hp: number, reason: string): Ox {
    return {
        ...ox,
        hp,
        causeOfDeath: undefined,
    };
}

export function isOxInjured(ox: Ox): boolean {
    return ox.injuries
        .filter(isInjuryDamaging)
        .length > 0;
}

export function enableOxInjury(ox: Ox, severity: 'mild' | 'severe' = 'severe'): Ox {
    if (isOxInjured(ox)) {
        return ox;
    }

    const injury = makeInjury('Broken Foot', severity);
    const injuries = [...ox.injuries, injury];
    return { ...ox, injuries };
}

export function disableOxInjury(ox: Ox): Ox {
    return { ...ox, injuries: [] };
}

export function isOxExhausted(ox: Ox): boolean {
    return ox.exhaustion.active === true;
}

export function enableOxExhaustion(ox: Ox): Ox {
    const exhaustion: Exhaustion = { ...ox.exhaustion, active: true };
    return { ...ox, exhaustion };
}

export function disableOxExhaustion(ox: Ox): Ox {
    const exhaustion: Exhaustion = { ...ox.exhaustion, active: false };
    return { ...ox, exhaustion };
}

export function increaseOxExhaustionDamageTaken(ox: Ox, damage: number): Ox {
    const damageTaken = ox.exhaustion.damageTaken + damage;
    const exhaustion: Exhaustion = { ...ox.exhaustion, damageTaken };
    return { ...ox, exhaustion };
}

export function resetOxExhaustionDamageTaken(ox: Ox): Ox {
    const exhaustion: Exhaustion = { ...ox.exhaustion, damageTaken: 0 };
    return { ...ox, exhaustion };
}

export function advanceOxAge(ox: Ox): Ox {
    if (isOxDead(ox) && !isOxRanOff(ox)) {
        return ox;
    } else {
        const age = ox.age + 1;
        return { ...ox, age };
    }
}

export function determineOxExhaustion(ox: Ox): number {
    const ageInWeeks = Math.floor(ox.age / 7);
    const damage = ageInWeeks;
    const defaultMalus = exhaustionMalus();
    
    return Math.max(damage, defaultMalus);
}

export function isOxRanOff(ox: Ox): boolean {
    return ox.ranOff !== undefined;
}

export function enableOxRanOff(ox: Ox, date: number, mileage: number): Ox {
    return {
        ...ox,
        ranOff: {
            date,
            atMileage: mileage,
        },
    };
}

export function disableOxRanOff(ox: Ox): Ox {
    return { ...ox, ranOff: undefined };
}
