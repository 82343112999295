import { useCallback, useEffect } from "react";
import { AmountChangeIndication } from "../../../types/AmountChangeIndication";
import { formatFood } from "../../../types/inventory/Food";
import { formatWater } from "../../../types/inventory/Water";
import { useAppDispatch } from "../../hooks";
import { displayedAmountChangeIndications } from "../gameSlice";

interface AmountChangeIndicatorProps {
    indication: AmountChangeIndication;
}

export default function AmountChangeIndicator({ indication }: AmountChangeIndicatorProps) {
    const dispatch = useAppDispatch();

    const markAsDisplayed = useCallback(() => {
        dispatch(displayedAmountChangeIndications(indication));
    }, [indication, dispatch]);

    useEffect(() => {
        setTimeout(() => markAsDisplayed(), 2000);
    }, [markAsDisplayed]);

    if (indication.change > 0) {
        return (
            <span className="text-move-up text-success text-sm ms-2">+{formatChange(indication)}</span>
        );
    }

    else if (indication.change < 0) {
        return (
            <span className="text-move-up text-danger text-sm ms-2">{formatChange(indication)}</span>
        );
    }

    else {
        return null;
    }
}

function formatChange(indication: AmountChangeIndication): string {
    if (indication.kind === 'Food') {
        const sign = indication.change < 0 ? '-' : '';
        return sign + formatFood(Math.abs(indication.change));
    } else if (indication.kind === 'Water') {
        const sign = indication.change < 0 ? '-' : '';
        return sign + formatWater(Math.abs(indication.change));
    } else {
        return indication.change.toFixed(2);
    }
}