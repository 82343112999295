import { Climate, ClimateName, getClimates } from "./Climate";
import { randomEvent, randomFloat, randomInt } from "./Random";

export type WeatherCondition =
      'Partly Sunny'
    | 'Scattered Thunderstorm'
    | 'Scattered Showers'
    | 'Overcast'
    | 'Light Snow'
    | 'Freezing Drizzle'
    | 'Chance of Rain'
    | 'Sunny'
    | 'Clear'
    | 'Mostly Sunny'
    | 'Rain'
    | 'Cloudy'
    | 'Storm'
    | 'Thunderstorm'
    | 'Chance of Thunderstorm'
    | 'Sleet'
    | 'Snow'
    | 'Icy'
    | 'Fog'
    | 'Haze'
    | 'Flurries'
    | 'Snow Showers'
    | 'Hail';

export type WeatherEventName = 'Hail Storm' | 'Severe Weather' | 'Heavy Fog';

interface WeatherEvent {
    probability: number;
    cooldownInDays: number;
    name?: WeatherEventName;
}

export interface Weather {
    climateName: ClimateName;
    condition: WeatherCondition;
    temperature: number;
    humidity: number;
    weatherEvent: WeatherEvent;
}

export function makeWeather(): Weather {
    const climate = getClimates('Moderate')[2];
    const temperature = climate.avgTemperature;
    const humidity = climate.avgHumidity;

    const weather: Weather = {
        climateName: climate.name,
        condition: 'Clear',
        temperature,
        humidity,
        weatherEvent: {
            probability: 0,
            cooldownInDays: 0,
        },
    };

    return niceDay(weather);
}

export function advanceWeather(weather: Weather, climate: Climate): Weather {
    const climateName = climate.name;
    let advancedWeather = { ...weather, climateName };
    let weatherEvent: WeatherEvent = { ...weather.weatherEvent, name: undefined };

    // Change the weather.
    const changing = randomEvent(weather.weatherEvent.probability);
    if (changing) {
        const temperature = randomFloat(climate.minTemperature, climate.maxTemperature);
        const humidity = climate.avgHumidity;
        advancedWeather = { ...advancedWeather, temperature, humidity };
    }

    // Adjust weather based on temperature.
    if (changing && advancedWeather.temperature > climate.avgTemperature) {
        advancedWeather = randomEvent(0.5) ? hotDay(advancedWeather) : niceDay(advancedWeather);
    } else if (changing && advancedWeather.temperature < climate.avgTemperature) {
        advancedWeather = randomEvent(0.5) ? rainyDay(advancedWeather) : coldDay(advancedWeather);
        advancedWeather = convertSnowToRain(advancedWeather);
    }

    // Decrease cooldown in days. If we are having a weather event now, reset the cooldown to 5 days.
    const cooldownInDays = Math.max(0, weatherEvent.cooldownInDays - 1);
    if (cooldownInDays > 0) {
        weatherEvent = { ...weatherEvent, cooldownInDays };
    } else if (advancedWeather.weatherEvent.name !== undefined) {
        weatherEvent = { ...weatherEvent, cooldownInDays: 5 };
    } else {
        weatherEvent = { ...weatherEvent, cooldownInDays: 0 };
    }

    return { ...advancedWeather, weatherEvent };
}

function convertSnowToRain(weather: Weather): Weather {
    const { temperature, condition } = weather;
    if (temperature <= 10) {
        return weather; // It's too cold to convert snow to rain
    }

    const allowedConditions = new Set<WeatherCondition>(['Hail', 'Light Snow', 'Flurries', 'Snow Showers', 'Icy', 'Snow', 'Sleet', 'Freezing Drizzle']);
    const allowed = allowedConditions.has(condition);
    if (!allowed) {
        return weather; // Current weather doesn't allow to convert snow to rain
    }

    interface Conversion {
        condition: WeatherCondition;
        weatherEventProbability: number;
    }

    const convert: (conversion: Conversion) => Weather = (conversion) => {
        const weatherEvent: WeatherEvent = {
            ...weather.weatherEvent,
            probability: conversion.weatherEventProbability,
        };

        return {
            ...weather,
            condition: conversion.condition,
            weatherEvent,
        };
    };

    switch (randomInt(0, 5)) {
        case 5: return convert({ condition: 'Chance of Rain', weatherEventProbability: 0.55 });
        case 4: return convert({ condition: 'Chance of Thunderstorm', weatherEventProbability: 0.45 });
        case 3: return convert({ condition: 'Sunny', weatherEventProbability: 0.33 });
        case 2: return convert({ condition: 'Partly Sunny', weatherEventProbability: 0.42 });
        case 1: return convert({ condition: 'Mostly Sunny', weatherEventProbability: 0.25 });
        default:
        case 0: return convert({ condition: 'Clear', weatherEventProbability: 0.30 });
    }
}

function coldDay(weather: Weather): Weather {

    interface Conversion {
        condition: WeatherCondition;
        weatherEventProbability: number;
        weatherEvent?: WeatherEventName;
    }

    const convert: (conversion: Conversion) => Weather = (conversion) => {
        const weatherEvent: WeatherEvent = {
            ...weather.weatherEvent,
            probability: conversion.weatherEventProbability,
            name: randomEvent(0.5) ? conversion.weatherEvent : undefined,
        };

        return {
            ...weather,
            condition: conversion.condition,
            weatherEvent,
        };
    };
    
    switch (randomInt(0, 5)) {
        case 5: return convert({ condition: 'Storm', weatherEventProbability: 0.85, weatherEvent: 'Severe Weather' });
        case 4: return convert({ condition: 'Hail', weatherEventProbability: 0.95, weatherEvent: 'Hail Storm' });
        case 3: return convert({ condition: 'Sleet', weatherEventProbability: 0.90 });
        case 2: return convert({ condition: 'Snow', weatherEventProbability: 0.75 });
        case 1: return convert({ condition: 'Snow Showers', weatherEventProbability: 0.85 });
        default:
        case 0: return convert({ condition: 'Flurries', weatherEventProbability: 0.80 });
    }
}

function rainyDay(weather: Weather): Weather {
    
    interface Conversion {
        condition: WeatherCondition;
        weatherEventProbability: number;
        weatherEvent?: WeatherEventName;
    }

    const convert: (conversion: Conversion) => Weather = (conversion) => {
        const weatherEvent: WeatherEvent = {
            ...weather.weatherEvent,
            probability: conversion.weatherEventProbability,
            name: randomEvent(0.5) ? conversion.weatherEvent : undefined,
        };

        return {
            ...weather,
            condition: conversion.condition,
            weatherEvent,
        };
    };
    
    switch (randomInt(0, 8)) {
        case 8: return convert({ condition: 'Cloudy', weatherEventProbability: 0.30 });
        case 7: return convert({ condition: 'Overcast', weatherEventProbability: 0.42 });
        case 6: return convert({ condition: 'Rain', weatherEventProbability: 0.56 });
        case 5: return convert({ condition: 'Fog', weatherEventProbability: 0.78, weatherEvent: 'Heavy Fog' });
        case 4: return convert({ condition: 'Haze', weatherEventProbability: 0.70 });
        case 3: return convert({ condition: 'Thunderstorm', weatherEventProbability: 0.90 });
        case 2: return convert({ condition: 'Mostly Sunny', weatherEventProbability: 0.50 });
        case 1: return convert({ condition: 'Scattered Showers', weatherEventProbability: 0.85 });
        default:
        case 0: return convert({ condition: 'Scattered Thunderstorm', weatherEventProbability: 0.90, weatherEvent: 'Severe Weather' });
    }
}

function niceDay(weather: Weather): Weather {
    
    interface Conversion {
        condition: WeatherCondition;
        weatherEventProbability: number;
    }

    const convert: (conversion: Conversion) => Weather = (conversion) => {
        const weatherEvent: WeatherEvent = {
            ...weather.weatherEvent,
            probability: conversion.weatherEventProbability,
        };

        return {
            ...weather,
            condition: conversion.condition,
            weatherEvent,
        };
    };
    
    switch (randomInt(0, 5)) {
        case 5: return convert({ condition: 'Chance of Rain', weatherEventProbability: 0.56 });
        case 4: return convert({ condition: 'Chance of Thunderstorm', weatherEventProbability: 0.60 });
        case 3: return convert({ condition: 'Sunny', weatherEventProbability: 0.24 });
        case 2: return convert({ condition: 'Partly Sunny', weatherEventProbability: 0.28 });
        case 1: return convert({ condition: 'Mostly Sunny', weatherEventProbability: 0.35 });
        default:
        case 0: return convert({ condition: 'Clear', weatherEventProbability: 0.28 });
    }
}

function hotDay(weather: Weather): Weather {
    
    interface Conversion {
        condition: WeatherCondition;
        weatherEventProbability: number;
    }

    const convert: (conversion: Conversion) => Weather = (conversion) => {
        const weatherEvent: WeatherEvent = {
            ...weather.weatherEvent,
            probability: conversion.weatherEventProbability,
        };

        return {
            ...weather,
            condition: conversion.condition,
            weatherEvent,
        };
    };
    
    switch (randomInt(0, 5)) {
        case 5: return convert({ condition: 'Chance of Rain', weatherEventProbability: 0.33 });
        case 4: return convert({ condition: 'Chance of Thunderstorm', weatherEventProbability: 0.30 });
        case 3: return convert({ condition: 'Sunny', weatherEventProbability: 0.60 });
        case 2: return convert({ condition: 'Partly Sunny', weatherEventProbability: 0.35 });
        case 1: return convert({ condition: 'Mostly Sunny', weatherEventProbability: 0.25 });
        default:
        case 0: return convert({ condition: 'Clear', weatherEventProbability: 0.10 });
    }
}

export function formatTemperature(weather: Weather | number, unit: 'celsius' | 'fahrenheit' = 'fahrenheit'): string {
    if (typeof weather === 'number') {
        if (unit === 'celsius') {
            return `${weather.toFixed(0)} °C`;
        } else if (unit === 'fahrenheit') {
            const value = weather * 9/5 + 32;
            return `${value.toFixed(0)} °F`;
        } else {
            return 'INVALID UNIT';
        }
    } else {
        if (unit === 'celsius') {
            return `${weather.temperature.toFixed(0)} °C`;
        } else if (unit === 'fahrenheit') {
            const value = weather.temperature * 9/5 + 32;
            return `${value.toFixed(0)} °F`;
        } else {
            return 'INVALID UNIT';
        }
    }
}