import { configureStore, combineReducers, Action, ThunkAction } from "@reduxjs/toolkit";
import { persistReducer, persistStore, PersistConfig, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import storage from "redux-persist/lib/storage";
import sessionStorage from "redux-persist/lib/storage/session";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

import gameReducer, { GameState } from "./game/gameSlice";
import settingsReducer, { SettingsState } from "./settings/settingsSlice";

const gamePersistConfig: PersistConfig<GameState, any, any, any> = {
    key: 'root',
    storage: sessionStorage,
    version: 1,
};

const settingsPersistConfig: PersistConfig<SettingsState, any, any, any> = {
    key: 'settings',
    storage: storage,
    version: 1,
    blacklist: ['game'],
    stateReconciler: autoMergeLevel2,
};

const rootReducer = combineReducers({
    game: persistReducer(gamePersistConfig, gameReducer),
    settings: persistReducer(settingsPersistConfig, settingsReducer),
});

export const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    }),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;