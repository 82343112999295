import { decodeStyledText, StyledText } from "../../../types/StyledText";

export default function StyledMessageView({ text, bg }: { text: string, bg?: 'dark' }) {
    const components = decodeStyledText(text)
        .map((component, index) => <StyledTextView styledText={component} bg={bg} key={index} />);
    
    return <div>{components}</div>;
}

function StyledTextView({ styledText, bg }: { styledText: StyledText, bg?: 'dark' }) {
    switch (styledText.style) {
        case 'green': return <GreenComponent text={styledText.text} />;
        case 'blue': return <BlueComponent text={styledText.text} />;
        case 'yellow': return <YellowComponent text={styledText.text} />;
        case 'red': return <RedComponent text={styledText.text} />;
        case 'dark': return <DarkComponent text={styledText.text} bg={bg} />;
        case 'light': return <LightComponent text={styledText.text} bg={bg} />;
        case 'plain': return <LightComponent text={styledText.text} />;
    }
}

function LightComponent({ text, bg }: { text: string, bg?: 'dark' }) {
    return <span className={bg === 'dark' ? "text-light" : "text-white"}>{text}</span>;
}

function DarkComponent({ text, bg }: { text: string, bg?: 'dark' }) {
    return <span className={bg === 'dark' ? "text-light" : "text-muted"}>{text}</span>;
}

function RedComponent({ text }: { text: string }) {
    return <span className="text-danger">{text}</span>;
}

function YellowComponent({ text }: { text: string }) {
    return <span className="text-warning">{text}</span>;
}

function BlueComponent({ text }: { text: string }) {
    return <span className="text-info">{text}</span>;
}

function GreenComponent({ text }: { text: string }) {
    return <span className="text-success">{text}</span>;
}