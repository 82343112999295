import { format } from "date-fns";
import { Col, Row } from "react-bootstrap";
import { Achievement } from "../../../types/Achievement";

import GameIcon from "../../misc/GameIcon";

export default function AchievementView({ achievement, size, prependTitle, missing }: { achievement: Achievement, size?: 'lg' | 'md', prependTitle?: string, missing?: boolean }) {
    const date = format(achievement.createdAt, 'd MMM y');
    const title = prependTitle ? `${prependTitle} ${achievement.name}` : achievement.name;

    return (
        <Row className={missing ? 'text-muted' : 'text-white'}>
            <Col sm={2} lg={size === 'lg' ? 1 : 2} className="text-center">
                <GameIcon icon={achievement.iconName ?? 'achievement'} size="3x" />
                { !missing && <div className="text-muted text-nowrap fw-lighter"><small>{date}</small></div> }
            </Col>
            <Col>
                <h3>{title}</h3>
                <div>{achievement.description}</div>
            </Col>
        </Row>
    );
}