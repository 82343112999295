import { Button } from "react-bootstrap";
import { isSick } from "../../../../../types/health/Disease";
import { isInjured } from "../../../../../types/health/Injury";
import { routingCurrentLocation } from "../../../../../types/location/Routing";
import { Town } from "../../../../../types/location/Town";
import { isDead } from "../../../../../types/Player";
import { useAppSelector } from "../../../../hooks";
import { selectGame } from "../../../gameSlice";

export default function DoctorButton({ openDoctor }: { openDoctor: () => void }) {
    const { players, status, trail } = useAppSelector(selectGame);
    const location = routingCurrentLocation(trail.routing);
    const town = location?.type === 'Town' ? location as Town : null;

    const patients = players.filter(player => !isDead(player) && (isSick(player) || isInjured(player)));
    
    if (town === null || patients.length === 0) {
        return null;
    } else {
        return <Button variant="primary" className="mb-1 ps-2 pe-2 text-truncate" onClick={openDoctor} disabled={status !== 'Playing'}>Doctor</Button>;
    }
}