import { ChestLock, tryUnlockingChest } from "../../types/ChestLock";
import { Chest } from "../../types/inventory/Chest";
import { QuestInventory } from "../../types/inventory/QuestInventory";
import { makeOpeningChestLockMessage, makeOpeningChestMessage } from "../../types/Message";
import { isDead } from "../../types/Player";
import { GameState } from "../game/gameSlice";

export default class ChestLockSystem {

    static startUnlocking(game: GameState): GameState {
        const { questInventory, date } = game;
        const { chest } = questInventory;
        const messages = [...game.messages];

        if (chest === undefined) {
            return game; // no chest found
        }

        else if (chest.lock === null || chest.lock.result === 'unlocked' || chest.lock.tries.length < chest.lock.maxTries) {
            messages.push(makeOpeningChestMessage(chest, date));
            return { ...game, messages, openingChestAttempt: true };
        }

        return game;
    }

    static stopUnlocking(game: GameState): GameState {
        game = { ...game, openingChestAttempt: false };

        if (game.questInventory.chest?.lock?.result === 'unlocked') {
            const questInventory: QuestInventory = { ...game.questInventory, chest: undefined };
            game = { ...game, questInventory };
        }

        return game;
    }

    static unlock(playerName: string, message: string, game: GameState): GameState {
        const { questInventory, date } = game;
        const { chest } = questInventory;
        const messages = [...game.messages];

        if (chest === undefined || chest.lock === null || chest.lock.result === 'unlocked') {
            return game; // no locked chest found
        }

        const player = game.players.find(p => p.name.toLowerCase() === playerName.toLowerCase());
        if (player === undefined || isDead(player)) {
            return game; // dead people don't get to vote. We're not in Murica! Kappa
        }

        const symbols = parseSymbols(chest.lock, message);
        if (symbols === null || symbols.length !== chest.lock.expectedSymbols.length) {
            return game; // no valid guess
        }

        if (chest.lock.tries.length >= chest.lock.maxTries) {
            return game; // no more tries
        }

        if (isGuessedAlready(chest.lock, symbols)) {
            return game; // already guessed these symbols
        }

        const lock = tryUnlockingChest(chest.lock, player.name, symbols);
        const updatedChest: Chest = { ...chest, lock };
        const updatedInventory: QuestInventory = { ...questInventory, chest: updatedChest };
        
        messages.push(makeOpeningChestLockMessage(updatedChest, date));

        return { ...game, messages, questInventory: updatedInventory };
    }

}

function parseSymbols(lock: ChestLock, message: string): string[] | null {
    const words = message
        .split(' ')
        .map(str => str ? str.trim() : '')
        .filter(str => str.length > 0);

    const symbols: string[] = [];

    for (let index = 0; index < words.length; ++index) {
        const word = words[index];
        if (!lock.availableSymbols.includes(word)) {
            return null; // not a symbol
        } else if (symbols.includes(word)) {
            return null; // duplicate symbol
        } else {
            symbols.push(word);
        }
    }

    return symbols;
}

function isSymbolsEqual(lhs: string[], rhs: string[]): boolean {
    if (lhs.length !== rhs.length || lhs.length === 0) {
        return false;
    }

    for (let index = 0; index < lhs.length; ++index) {
        if (lhs[index] !== rhs[index]) {
            return false;
        }
    }

    return true;
}

function isGuessedAlready(lock: ChestLock, symbols: string[]): boolean {
    const index = lock.tries.findIndex(guess => isSymbolsEqual(guess.symbols, symbols));
    return index !== -1;
}