export type HealthStatus = 'Good' | 'Fair' | 'Poor' | 'Very Poor' | 'Dead';

export interface HealthVariant {
    status: HealthStatus;
    maximum: number;
    matches: (value: number) => boolean;
}

export class HealthVariants {

    static readonly good: HealthVariant = {
        status: 'Good',
        maximum: 500,
        matches: value => value > 400,
    };

    static readonly fair: HealthVariant = {
        status: 'Fair',
        maximum: 400,
        matches: value => value <= 400 && value > 200,
    };

    static readonly poor: HealthVariant = {
        status: 'Poor',
        maximum: 200,
        matches: value => value <= 200 && value > 100,
    };

    static readonly veryPoor: HealthVariant = {
        status: 'Very Poor',
        maximum: 100,
        matches: value => value <= 100 && value > 0,
    };

    static readonly dead: HealthVariant = {
        status: 'Dead',
        maximum: 0,
        matches: value => value <= 0,
    };

    static readonly all: HealthVariant[] = [HealthVariants.good, HealthVariants.fair, HealthVariants.poor, HealthVariants.veryPoor, HealthVariants.dead];

    static ofStatus(status: HealthStatus): HealthVariant {
        switch (status) {
            case 'Good': return HealthVariants.good;
            case 'Fair': return HealthVariants.fair;
            case 'Poor': return HealthVariants.poor;
            case 'Very Poor': return HealthVariants.veryPoor;
            case 'Dead': return HealthVariants.dead;
        }
    }

    static ofValue(value: number): HealthVariant {
        return HealthVariants.all
            .find(variant => variant.matches(value))
            ?? HealthVariants.dead;
    }

}