import { calculateFoodRations, disableHunger } from "../../types/health/Hunger";
import { calculateWaterRations, disableThirst } from "../../types/health/Thirst";
import { Inventory } from "../../types/inventory/Inventory";
import { decreaseInventoryItem } from "../../types/inventory/InventoryItem";
import { determineRoomCount } from "../../types/location/Inn";
import { routingCurrentLocation } from "../../types/location/Routing";
import { Town } from "../../types/location/Town";
import { makeAteAtInnMessage } from "../../types/Message";
import { changeHP, isDead, Player } from "../../types/Player";
import { GameState } from "../game/gameSlice";
import HealthSystem from "./HealthSystem";

export default class TownSystem {

    static eatAtInn(game: GameState): GameState {
        const location = routingCurrentLocation(game.trail.routing);
        if (location?.type !== 'Town') {
            return game;
        }

        const town = location as Town;
        const price = town.inn?.foodPrice ?? 0;
        if (!price) {
            return game;
        }

        const peopleCount = game.players
            .filter(player => !isDead(player))
            .length;
        
        const totalPrice = price * peopleCount;
        if (totalPrice > game.inventory.cash.amount) {
            return game;
        }

        const foodRation = calculateFoodRations('Grueling', 'Filling', 1, Infinity);
        const waterRation = calculateWaterRations(20, 1, Infinity);

        const applyPlayer = (player: Player) => {
            if (!isDead(player)) {
                player = changeHP(player, 'food', foodRation.hpBonus);
                player = changeHP(player, 'water', waterRation.hpBonus);
                player = disableHunger(player);
                player = disableThirst(player);
            }
            return player;
        };

        const players = game.players.map(applyPlayer);
        const cash = decreaseInventoryItem(game.inventory.cash, totalPrice);
        const inventory: Inventory = { ...game.inventory, cash };
        const messages = [...game.messages, makeAteAtInnMessage(town.inn!, totalPrice, game.date)];
        
        return { ...game, inventory, players, messages };
    }

    static restAtInn(game: GameState): GameState {
        const location = routingCurrentLocation(game.trail.routing);
        if (location?.type !== 'Town') {
            return game;
        }

        const town = location as Town;
        const price = town.inn?.roomPrice ?? 0;
        if (!price) {
            return game;
        }
        
        const peopleCount = game.players
            .filter(player => !isDead(player))
            .length;
        const rooms = determineRoomCount(peopleCount);
        if (rooms === 0) {
            return game;
        }
        
        const totalPrice = price * rooms;
        if (totalPrice > game.inventory.cash.amount) {
            return game;
        }

        const cash = decreaseInventoryItem(game.inventory.cash, totalPrice);
        const inventory: Inventory = { ...game.inventory, cash };

        return { ...HealthSystem.healPlayerExhaustion(game, 'resting'), inventory };
    }

}