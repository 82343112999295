import { randomElement } from "../Random";
import { ShopParameters, Town } from "./Town"

export const INN_NAMES = ['The Filthy Chicken Pub', 'The Ethereal Crabs', 'The Creepy Bell', 'The Oceanic Hill Pub', 'The Marked Books Inn', 'The Ivory Sword Pub', 'The Giant Harp Bar', 'The Cowardly Crow Bar', 'The Fearless Spider Bar', 'The Colossal Jester Tavern', 'The Peaceful Coconut Pub', 'The Chilly Bell Tavern', 'The Molten Pigeon Inn', 'The Proud Bassoon Pub', 'The Abandoned Camel', 'The International Deer', 'The Short Ducks Pub', 'The Violent Jaguar Pub', 'The Mighty Fork Bar', 'The Wild Princess Inn', 'The Wrong Tree Tavern', 'The Violent Ox', 'The Quickest Cake Pub', 'The Deep Snowdrop Pub', 'The Slippery Prince Pub', 'The Measly Rabbit Tavern', 'The Ethereal Serpent', 'The Frozen Bed Bar', 'The Middle Coin Bar', 'The Gentle Stew'];

export interface Inn {
    type: 'Inn';
    name: string;
    foodPrice?: number;
    roomPrice?: number;
}

function determineInnName(): string {
    return randomElement(INN_NAMES)!;
}

function determineFoodPrice(parameters: ShopParameters): number | undefined {
    if (parameters.rarity < .1) {
        return undefined;
    } else {
        return parameters.priceFactor * 3.00;
    }
}

function determineRoomPrice(parameters: ShopParameters): number | undefined {
    if (parameters.rarity < .2) {
        return undefined;
    } else {
        return parameters.priceFactor * 5.00;
    }
}

export function makeInn(town: Town): Inn | null {
    const { shopParameters } = town;
    if (shopParameters === null) {
        return null;
    }

    const name = determineInnName();
    const foodPrice = determineFoodPrice(shopParameters);
    const roomPrice = determineRoomPrice(shopParameters);
    if (foodPrice === undefined && roomPrice === undefined) {
        return null;
    }

    return { type: 'Inn', name, foodPrice, roomPrice };
}

export function determineRoomCount(peopleCount: number): number {
    return Math.ceil(peopleCount / 3);
}

export function canAffordEatingAtInn(town: Town, peopleCount: number, cash: number): boolean {
    const price = town.inn?.foodPrice ?? 0;
    if (price === 0 || peopleCount === 0 || cash === 0) {
        return false;
    }

    const totalPrice = price * peopleCount;
    return totalPrice <= cash;
}

export function canAffordRestingAtInn(town: Town, peopleCount: number, cash: number): boolean {
    const price = town.inn?.roomPrice ?? 0;
    const rooms = determineRoomCount(peopleCount);
    if (price === 0 || rooms === 0 || cash === 0) {
        return false;
    }

    const totalPrice = price * rooms;
    return totalPrice <= cash;
}
