import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GameIcon from "../../../../misc/GameIcon";

interface StoreItemProps {
    productIcon?: IconDefinition;
    gameIcon?: string;
    productName: JSX.Element;
    productPrice: JSX.Element;
    productAmount: JSX.Element;
    tradingButtons: JSX.Element;
}

export default function StoreItem({ productIcon, gameIcon, productName, productPrice, productAmount, tradingButtons }: StoreItemProps) {
    return (
        <div className="d-flex justify-content-start align-items-center">
            { !!productIcon && <FontAwesomeIcon icon={productIcon} fixedWidth size="3x" className="text-lighter" /> }
            { !!gameIcon && <GameIcon icon={gameIcon} fixedWidth size="3x" className="text-lighter" /> }
            <div className="ms-3 flex-grow-1">
                {productName}
                {productPrice}
                {productAmount}
            </div>
            <div className="text-end ms-1">
                {tradingButtons}
            </div>
        </div>
    );
}