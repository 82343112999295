import { useState } from "react";
import { faMap } from "@fortawesome/free-solid-svg-icons";
import { ListGroup } from "react-bootstrap";

import { useAppDispatch, useAppSelector } from "../../hooks";
import { selectSettings } from "../../settings/settingsSlice";
import { changeQuestInventory, selectGame } from "../gameSlice";
import { routingCurrentMapRegionNames } from "../../../types/location/Routing";
import { makeChestLock } from "../../../types/ChestLock";
import { useTwitchManager } from "../../twitch/TwitchManager";
import { getMapItem } from "../../../types/inventory/QuestInventory";
import { addMap, formatMaps, removeMap } from "../../../types/inventory/Maps";
import { makeChest } from "../../../types/inventory/Chest";

import InventoryItemView from "./InventoryItemView";
import MapButton from "./MapButton";
import MapModal from "../trail/MapModal";
import ChestInteractions from "../interactions/extras/chest/ChestInteractions";
import DiaryInteractions from "../interactions/extras/diary/DiaryInteractions";

export default function QuestInventoryView({ expanded }: { expanded?: boolean }) {
    const dispatch = useAppDispatch();
    const twitch = useTwitchManager();

    const { questInventory, trail } = useAppSelector(selectGame);
    const { cheatMode, emoteNames } = useAppSelector(selectSettings);
    const [isMapModalOpen, setMapModalOpen] = useState(false);

    const { maps, chest } = questInventory;
    const regions = routingCurrentMapRegionNames(trail.routing);
    const region = regions.length === 0 ? '' : regions[0];
    const currentMapItem = getMapItem(questInventory, region);

    const increaseMaps = () => {
        if (currentMapItem === null) {
            dispatch(changeQuestInventory({ ...questInventory, maps: addMap(maps, region, 'Nothing') }));
        } else if (currentMapItem.levelOfDetail === 'Nothing') {
            dispatch(changeQuestInventory({ ...questInventory, maps: addMap(maps, region, 'Names') }));
        } else if (currentMapItem.levelOfDetail === 'Names') {
            dispatch(changeQuestInventory({ ...questInventory, maps: addMap(maps, region, 'Roads') }));
        } else if (currentMapItem.levelOfDetail === 'Roads') {
            dispatch(changeQuestInventory({ ...questInventory, maps: addMap(maps, region, 'Details') }));
        }
    };

    const decreaseMaps = () => {
        if (currentMapItem !== null) {
            dispatch(changeQuestInventory({ ...questInventory, maps: removeMap(maps, region) }));
        }
    };

    const increaseChests = () => {
        if (chest === undefined) {
            const lock = makeChestLock(3, 12, twitch.emotes, emoteNames);
            dispatch(changeQuestInventory({ ...questInventory, chest: makeChest('a fart', lock) }));
        }
    };

    const decreaseChests = () => {
        if (chest !== undefined) {
            dispatch(changeQuestInventory({ ...questInventory, chest: undefined }));
        }
    };

    return (
        <>
        <ListGroup>
            <ListGroup.Item>
                <InventoryItemView name="Diary" gameIcon="notebook">
                    <DiaryInteractions />
                </InventoryItemView>
            </ListGroup.Item>
            <ListGroup.Item>
                <InventoryItemView name="Maps" icon={faMap} showAmountChangeIndicator increase={!!expanded && cheatMode === 'on' ? increaseMaps : undefined} decrease={!!expanded && cheatMode === 'on' ? decreaseMaps : undefined}>
                    <div className="d-flex justify-content-end align-items-center">
                    <span className="me-2">{maps.items.length > 0 ? formatMaps(maps, true) : ''}</span>
                    <MapButton openModal={[isMapModalOpen, setMapModalOpen]} />
                    </div>
                </InventoryItemView>
            </ListGroup.Item>
            { (!!chest || (!!expanded && cheatMode === 'on')) &&
            <ListGroup.Item>
                <InventoryItemView name="Chest" gameIcon="locked-chest" increase={!!expanded && cheatMode === 'on' ? increaseChests : undefined} decrease={!!expanded && cheatMode === 'on' ? decreaseChests : undefined}>
                    <ChestInteractions />
                </InventoryItemView>
            </ListGroup.Item>
            }
        </ListGroup>
        <MapModal open={[isMapModalOpen, setMapModalOpen]} />
        </>
    );
}