import { ChestLock } from "../ChestLock";
import { QuestInventoryItem } from "./QuestInventoryItem";

export type ChestContentType = 'a fart';

export interface Chest extends QuestInventoryItem {
    name: 'Chest';
    contentType: ChestContentType;
    lock: ChestLock | null;
}

export function makeChest(contentType: ChestContentType, lock: ChestLock | null): Chest {
    return { name: 'Chest', contentType, lock };
}