import { useAppSelector } from "../../hooks";
import GameIcon from "../../misc/GameIcon";
import { selectGame } from "../gameSlice";

export default function RoadView({ fraction }: { fraction: number }) {
    const { weather } = useAppSelector(selectGame);

    const position = (fraction * 100) + '%';
    const ground = ['ground'];
    if (weather.temperature < 0 || weather.condition === 'Snow' || weather.condition === 'Light Snow' || weather.condition === 'Snow Showers' || weather.condition === 'Icy') {
        ground.push('snowy');
    }

    return (
        <div className="roadview d-flex flex-column justify-content-start align-items-top">
            <div className="flex-grow-1">{/* vertical filler so the next element appears on the bottom */}</div>
            <div className="d-flex flex-row justify-content-start align-items-bottom">
                <GameIcon icon="village" size="3x" />
                <div className="flex-grow-1 d-flex justify-content-start align-items-center">
                    <div className="wagon flex-grow-1">
                        <div style={{ left: position }}>
                            <GameIcon icon="old-wagon" size="3x" />
                        </div>
                    </div>
                </div>
                <GameIcon icon="village" size="3x" />
            </div>
            <div className={ground.join(' ')}></div>
        </div>
    );
}