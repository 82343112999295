import { has1836Colt, hasHuntingRifle, hasSniperRifle } from "../../types/inventory/Rifles";
import { makeFound1836ColtMessage } from "../../types/Message";
import { disableNaked, enableNaked, isDead, isNaked } from "../../types/Player";
import { randomElement } from "../../types/Random";
import { add1836ColtModifier, addHuntingRifleModifier, addSniperRifleModifier, addStatModifier, has1836ColtModifier, hasHuntingRifleModifier, hasSniperRifleModifier, makeStatModifier, remove1836ColtModifier, removeHuntingRifleModifier, removeSniperRifleModifier, removeStatModifier } from "../../types/Stat";
import { GameState } from "../game/gameSlice";

export default class InventorySystem {

    static distributeAvailableClothes(game: GameState): GameState {
        const players = [...game.players];
        const clothesCount = game.inventory.clothes.amount;
        let nakedPlayers = [...players].filter(player => !isDead(player) && isNaked(player));
        let availableClothesCount = Math.max(0, clothesCount - (players.length - nakedPlayers.length));

        while (availableClothesCount > 0 && nakedPlayers.length > 0) {
            const player = randomElement(nakedPlayers);
            const index = player ? players.findIndex(p => p.name === player.name) : -1;
            if (player && index !== -1) {
                nakedPlayers = nakedPlayers.filter(p => p.name !== player.name);
                players[index] = disableNaked(player);
                availableClothesCount -= 1;
            }
        }

        let dressedPlayers = [...players].filter(player => !isDead(player) && !isNaked(player));
        let missingClothesCount = Math.max(0, dressedPlayers.length - clothesCount);

        while (missingClothesCount > 0 && dressedPlayers.length > 0) {
            const player = randomElement(dressedPlayers);
            const index = player ? players.findIndex(p => p.name === player.name) : -1;
            if (player && index !== -1) {
                dressedPlayers = dressedPlayers.filter(p => p.name !== player.name);
                players[index] = enableNaked(player);
                missingClothesCount -= 1;
            }
        }

        return { ...game, players };
    }

    static applyInventoryStatModifiers(game: GameState): GameState {
        const { stats, inventory } = game;
        let { hunting, trading, wealth, strength, health } = stats;

        const huntingRifle = hasHuntingRifle(inventory);
        const huntingRifleMod = hasHuntingRifleModifier(hunting);
        if (huntingRifle && !huntingRifleMod) {
            hunting = addHuntingRifleModifier(hunting);
        } else if (!huntingRifle && huntingRifleMod) {
            hunting = removeHuntingRifleModifier(hunting);
        }

        const sniperRifle = hasSniperRifle(inventory);
        const sniperRifleMod = hasSniperRifleModifier(hunting);
        if (sniperRifle && !sniperRifleMod) {
            hunting = addSniperRifleModifier(hunting);
        } else if (!sniperRifle && sniperRifleMod) {
            hunting = removeSniperRifleModifier(hunting);
        }

        const colt = has1836Colt(inventory);
        const coltMod = has1836ColtModifier(stats);
        if (colt && !coltMod) {
            const modified = add1836ColtModifier({ ...stats, hunting });
            hunting = modified.hunting;
            trading = modified.trading;
            wealth = modified.wealth;
            strength = modified.strength;
            health = modified.health;
        } else if (!colt && coltMod) {
            const modified = remove1836ColtModifier({ ...stats, hunting });
            hunting = modified.hunting;
            trading = modified.trading;
            wealth = modified.wealth;
            strength = modified.strength;
            health = modified.health;
        }

        return { ...game, stats: { ...stats, hunting, trading, wealth, strength, health } };
    }

    static applyShoppingBoostForTrader(game: GameState, price: number, item: string): GameState {
        const { role, stats } = game;
        if (role !== 'Trader' || !price || !item) {
            return game;
        }

        let { wealth, strength } = stats;
        const name = 'Shopping Boost';

        wealth = removeStatModifier(name, wealth);
        wealth = addStatModifier(makeStatModifier(name, 1, 7), wealth);

        strength = removeStatModifier(name, strength);
        strength = addStatModifier(makeStatModifier(name, 1, 7), strength);

        return { ...game, stats: { ...stats, wealth, strength } };
    }

    static found1836Colt(game: GameState): GameState {
        const message = makeFound1836ColtMessage(game.date);
        const messages = [...game.messages, message];

        return { ...game, messages };
    }
    
}