import { ClimateName } from "../Climate";
import { AdjacencyMatrix } from "./Graph";
import { ResourceAbundances } from "./Resource";
import { TerrainName } from "./Terrain";

import matrixJson from "./matrix.json";
import { RiverCrossing } from "./RiverCrossing";

export type LocationName = string;
export type RegionName = string;
export type LocationStatus = 'Pending' | 'Arrived' | 'Departed';
export type LocationType = 'Town' | 'RiverCrossing' | 'TollStation' | 'ValleyLodge';
export type LocationVisibility = 'hidden' | 'revealed' | 'visible';

export interface Location {
    type: LocationType;
    id: string;
    name: LocationName;
    regions: RegionName[];
    status: LocationStatus;
    latitude: number;
    longitude: number;
    resources: ResourceAbundances;
    climate: ClimateName;
    terrain: TerrainName;
    visibility: LocationVisibility;
}

interface AdjacencyMatrixLike {
    nodes: string[];
    matrix: number[][];
}

function resolveAdjacencyMatrix(data: AdjacencyMatrixLike, locations: Location[]): AdjacencyMatrix<Location> {
    const equals: (a: Location, b: Location) => boolean = (a, b) => a.name === b.name;
    const relevantLocations = locations.filter(location => data.nodes.includes(location.id));
    const mappedLocations = new Map<string, Location>();
    relevantLocations.forEach(location => mappedLocations.set(location.id, location));

    const riverCrossings = locations
        .filter(location => location.type === 'RiverCrossing')
        .map(location => location as RiverCrossing);
    
    riverCrossings.forEach(riverCrossing => relevantLocations.push(riverCrossing));

    const matrix = new AdjacencyMatrix<Location>(relevantLocations);

    let printLocations = false;
    for (let i = 0; i < data.nodes.length; ++i) {
        for (let j = i + 1; j < data.nodes.length; ++j) {
            const value = data.matrix[i][j];
            if (value) {
                const a = mappedLocations.get(data.nodes[i]);
                const b = mappedLocations.get(data.nodes[j]);
                if (a === undefined) {
                    console.warn(`[Location] Could not find location ${data.nodes[i]} from matrix in i=${i}`);
                    printLocations = true;
                } else if (b === undefined) {
                    console.warn(`[Location] Could not find location ${data.nodes[j]} from matrix in j=${j}`);
                    printLocations = true;
                } else {
                    matrix.connect(a, b, equals);
                }
            }
        }
    }

    const disconnectRiverCrossingSides = (riverCrossing: RiverCrossing, side1: LocationName[], side2: LocationName[]) => {
        const locations1 = side1.map(name => locations.find(location => location.name === name)).filter(location => location !== undefined).map(location => location!);
        const locations2 = side2.map(name => locations.find(location => location.name === name)).filter(location => location !== undefined).map(location => location!);
        locations1.forEach(location => {
            locations2.forEach(opposite => {
                matrix.disconnect(location, opposite, equals);
            });
        });
    };

    const connectRiverCrossingSide = (riverCrossing: RiverCrossing, side: LocationName[]) => {
        side.forEach(name => {
            const location = locations.find(l => l.name === name);
            if (location === undefined) {
                console.warn(`[Location] Could not find location ${name} from river crossing ${riverCrossing.id}`);
                printLocations = true;
            } else {
                matrix.connect(riverCrossing, location, equals);
            }
        });
    };

    riverCrossings.forEach(riverCrossing => {
        disconnectRiverCrossingSides(riverCrossing, riverCrossing.side1, riverCrossing.side2);
        disconnectRiverCrossingSides(riverCrossing, riverCrossing.side2, riverCrossing.side1);
        connectRiverCrossingSide(riverCrossing, riverCrossing.side1);
        connectRiverCrossingSide(riverCrossing, riverCrossing.side2);
    });

    if (printLocations) {
        console.log('[Location] All known locations', locations.map(location => ({ id: location.id, name: location.name })));
    }

    return matrix;
}

export function loadAdjacencyMatrix(locations: Location[]): AdjacencyMatrix<Location> {
    const { nodes, matrix } = matrixJson;
    return resolveAdjacencyMatrix({ nodes, matrix }, locations);
}

export function interpolatedValueBetweenLocations(lhs: number, rhs: number, distanceSinceLastLocation: number, distanceToNextLocation: number): number {
    if (distanceToNextLocation === 0) {
        return rhs;
    } else if (distanceSinceLastLocation === 0) {
        return lhs;
    }

    const ratio = distanceSinceLastLocation / distanceToNextLocation;
    const delta = rhs - lhs;
    
    return lhs + delta * ratio;
}
