import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { formatCash } from "../../../../../types/inventory/Cash";
import { calculateRiflesCapacity } from "../../../../../types/inventory/Inventory";
import { formatRifles, RifleName } from "../../../../../types/inventory/Rifles";
import { buyRifle, determineSellingPrice, sellRifle, StoreItemName } from "../../../../../types/Store";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { boughtSomething, changeInventory, selectGame } from "../../../gameSlice";

import ProductAmount from "./ProductAmount";
import ProductName from "./ProductName";
import ProductPrice from "./ProductPrice";
import StoreItem from "./StoreItem";
import TradingButtons from "./TradingButtons";

interface RiflesStoreItemProps {
    grandpasRiflePrice: number;
    huntingRiflePrice: number;
    sniperRiflePrice: number;
    selection: [selection: StoreItemName | null, setSelection: (name: StoreItemName | null) => void];
}

export function RiflesStoreItem({ grandpasRiflePrice, huntingRiflePrice, sniperRiflePrice, selection }: RiflesStoreItemProps) {
    const dispatch = useAppDispatch();
    const { inventory, stats, wagons, players } = useAppSelector(selectGame);
    const { cash, rifles } = inventory;

    const [selectedItem, setSelectedItem] = selection;
    const [rifleName, setRifleName] = useState<RifleName | null>(null);
    const active = selectedItem === 'Rifles';
    const grandpasRifleCapacity = calculateRiflesCapacity("Grandpa's Rifle", inventory, wagons, players);
    const huntingRifleCapacity = calculateRiflesCapacity('Hunting Rifle', inventory, wagons, players);
    const sniperRifleCapacity = calculateRiflesCapacity('Sniper Rifle', inventory, wagons, players);

    const grandpasRifles = !!grandpasRiflePrice;
    const huntingRifles  = !!huntingRiflePrice;
    const sniperRifles   = !!sniperRiflePrice;
    const count = [grandpasRifles, huntingRifles, sniperRifles].filter(e => e === true).length;

    let price: number | undefined = undefined;
    if (rifleName === "Grandpa's Rifle") {
        price = grandpasRiflePrice;
    } else if (rifleName === 'Hunting Rifle') {
        price = huntingRiflePrice;
    } else if (rifleName === 'Sniper Rifle') {
        price = sniperRiflePrice;
    }

    let capacity = 0;
    if (rifleName === "Grandpa's Rifle") {
        capacity = grandpasRifleCapacity;
    } else if (rifleName === 'Hunting Rifle') {
        capacity = huntingRifleCapacity;
    } else if (rifleName === 'Sniper Rifle') {
        capacity = sniperRifleCapacity;
    }

    let buy: ((amount: number) => void) | undefined = undefined;
    if (rifleName !== null && !!price && cash.amount >= price && capacity > 0) {
        buy = (amount) => {
            const a = Math.min(amount, capacity);
            dispatch(changeInventory(buyRifle(inventory, rifleName, a, price ?? 0)));
            dispatch(boughtSomething({ price: (price ?? 0) * a, item: 'rifles' }));
            setSelectedItem(null);
        };
    }

    let sell: ((amount: number) => void) | undefined = undefined;
    if (rifleName !== null && !!price && rifles.names.includes(rifleName)) {
        sell = (amount) => {
            dispatch(changeInventory(sellRifle(inventory, rifleName, amount, determineSellingPrice(price ?? 0, stats.trading))));
            setSelectedItem(null);
        };
    }

    useEffect(() => {
        const grandpasRifles = !!grandpasRiflePrice;
        const huntingRifles  = !!huntingRiflePrice;
        const sniperRifles   = !!sniperRiflePrice;
        const count = [grandpasRifles, huntingRifles, sniperRifles].filter(e => e === true).length;

        if (grandpasRifles && !huntingRifles && !sniperRifles && rifleName !== "Grandpa's Rifle") {
            setRifleName("Grandpa's Rifle");
        } else if (!grandpasRifles && huntingRifles && !sniperRifles && rifleName !== 'Hunting Rifle') {
            setRifleName('Hunting Rifle');
        } else if (!grandpasRifles && !huntingRifles && sniperRifles && rifleName !== 'Sniper Rifle') {
            setRifleName('Sniper Rifle');
        } else if (count > 1 && rifleName === null) {
            if (grandpasRifles) {
                setRifleName("Grandpa's Rifle");
            } else if (huntingRifles) {
                setRifleName('Hunting Rifle');
            } else {
                setRifleName('Sniper Rifle');
            }
        } else if (count === 0 && rifleName !== null) {
            setRifleName(null);
        }
    }, [grandpasRiflePrice, huntingRiflePrice, sniperRiflePrice, rifleName, setRifleName]);

    const productName = (
        <ProductName>
            <div className="d-flex justify-content-start align-items-center">
                <div className="me-2">Rifles</div>
                { grandpasRifles && !huntingRifles && !sniperRifles && <div>Grandpa's Rifles</div> }
                { !grandpasRifles && huntingRifles && !sniperRifles && <div>Hunting Rifles</div> }
                { !grandpasRifles && !huntingRifles && sniperRifles && <div>Sniper Rifles</div> }
                { count > 1 &&
                <Form.Select value={rifleName ?? ''} onChange={({target}) => setRifleName(target.value as RifleName)}>
                    { grandpasRifles && <option value="Grandpa's Rifle">Grandpa's Rifles</option> }
                    { huntingRifles && <option value="Hunting Rifle">Hunting Rifles</option> }
                    { sniperRifles && <option value="Sniper Rifle">Sniper Rifles</option> }
                </Form.Select>
                }
            </div>
        </ProductName>
    );

    const productPrice = (
        <ProductPrice active={active}>
            <>
            { rifleName === "Grandpa's Rifle" && !!grandpasRiflePrice && <span>buy for {formatCash(grandpasRiflePrice)}, sell for {formatCash(determineSellingPrice(grandpasRiflePrice, stats.trading))}.</span> }
            { rifleName === 'Hunting Rifle' && !!huntingRiflePrice && <span>buy for {formatCash(huntingRiflePrice)}, sell for {formatCash(determineSellingPrice(huntingRiflePrice, stats.trading))}.</span> }
            { rifleName === 'Sniper Rifle' && !!sniperRiflePrice && <span>buy for {formatCash(sniperRiflePrice)}, sell for {formatCash(determineSellingPrice(sniperRiflePrice, stats.trading))}.</span> }
            </>
        </ProductPrice>
    );

    const productAmount = <ProductAmount>{formatRifles(rifles)}</ProductAmount>;

    const money = (amount: number, mode: 'buy' | 'sell') => {
        const factor = mode === 'buy' ? 1 : .5;
        if (rifleName === "Grandpa's Rifle") {
            return grandpasRiflePrice * factor * amount;
        } else if (rifleName === 'Hunting Rifle') {
            return huntingRiflePrice * factor * amount;
        } else if (rifleName === 'Sniper Rifle') {
            return sniperRiflePrice * factor * amount;
        } else {
            return 0
        }
    };

    const canAfford = (amount: number) => {
        if (rifleName === "Grandpa's Rifle") {
            return amount * grandpasRiflePrice <= cash.amount;
        } else if (rifleName === 'Hunting Rifle') {
            return amount * huntingRiflePrice <= cash.amount;
        } else if (rifleName === 'Sniper Rifle') {
            return amount * sniperRiflePrice <= cash.amount;
        } else {
            return false;
        }
    };

    const tradingButtons = (
        <TradingButtons
            amountInStock={rifles.names.filter(name => name === rifleName).length}
            amountPerUnit={1}
            canAfford={canAfford}
            money={money}
            formatUnits={formatRifles}
            minAmount={0}
            maxAmount={capacity}
            buy={buy}
            sell={sell}
        />
    );

    return (
        <StoreItem
            gameIcon="winchester-rifle"
            productName={productName}
            productPrice={productPrice}
            productAmount={productAmount}
            tradingButtons={tradingButtons}
        />
    );
}