import { DistanceMatrix } from "./Graph";
import { Location, LocationName, RegionName } from "./Location";
import { RiverCrossing } from "./RiverCrossing";
import { RouteNode } from "./RouteNode";

export interface Routing {
    root: RouteNode;
    destinationName: LocationName;
    locations: Location[];
    nodes: RouteNode[];
    pathTaken: LocationName[];
}

export function routingNodeForLocation(routing: Routing, locationName: LocationName): RouteNode | null {
    return routing.nodes.find(node => node.location === locationName) ?? null;
}

export function routingLocation(routing: Routing, locationName: LocationName): Location | null {
    return routing.locations.find(location => location.name === locationName) ?? null;
}

interface NodeAndLocation { node: RouteNode, location: Location, skipped?: Location };

function findNextNodesAndLocations(start: NodeAndLocation, lookupLocation: (name: LocationName) => Location | null, lookupNode: (name: LocationName) => RouteNode | null, skipHiddenLocations: boolean): NodeAndLocation[] {
    const nextNodesAndLocations: NodeAndLocation[] = [];

    start.node.next.forEach(next => {
        const node = lookupNode(next);
        const location = lookupLocation(next);
        if (node !== null && location !== null) {
            nextNodesAndLocations.push({ node, location });
        }
    });

    if (!skipHiddenLocations) {
        return nextNodesAndLocations;
    }

    const hiddenNodesAndLocations = nextNodesAndLocations.filter(n => n.location.visibility === 'hidden');
    const visibleNodesAndLocations = nextNodesAndLocations.filter(n => n.location.visibility !== 'hidden');

    hiddenNodesAndLocations.forEach(hidden => {
        findNextNodesAndLocations(hidden, lookupLocation, lookupNode, skipHiddenLocations).forEach(next => {
            visibleNodesAndLocations.push({ ...next, skipped: hidden.location });
        });
    });

    return visibleNodesAndLocations;
}

export function routingNextNodes(routing: Routing, node: RouteNode): RouteNode[] {
    return node.next
        .map(name => routing.nodes.find(next => next.location === name))
        .filter(next => next !== undefined)
        .map(next => next!);
}

export function routingCurrentLocation(routing: Routing): Location | null {
    return routing.locations.find(location => location.status === 'Arrived') ?? null;
}

export function routingLastLocation(routing: Routing): Location | null {
    const locations = routingPathTaken(routing);
    return locations.length === 0 ? null : locations[locations.length - 1];
}

export function routingPreviousLocation(routing: Routing): Location | null {
    const currentLocation = routingCurrentLocation(routing);
    if (currentLocation === null) {
        return routingLastLocation(routing);
    }

    const locations = routingPathTaken(routing);
    return locations.length < 2 ? null : locations[locations.length - 2];
}

export function routingForwardAndReverseDirections(routing: Routing): { forward: LocationName, reverse: LocationName } | null {
    const currentLocation = routingCurrentLocation(routing);
    if (currentLocation === null) {
        return null;
    }

    const previousLocation = routingPreviousLocation(routing);
    if (previousLocation === null) {
        return null;
    }

    const directions = routingNextVisibleLocations(routing);
    if (directions.length !== 2) {
        return null;
    }

    const index = directions.findIndex(next => next.name === previousLocation.name);
    if (index === -1) {
        return null;
    }

    const nextName = directions[index === 0 ? 1 : 0].name;
    return { forward: nextName, reverse: previousLocation.name };
}

export function routingRiverCrossingDirections(routing: Routing, direction: 'across the river' | 'backwards' = 'across the river'): LocationName[] {
    const currentLocation = routingCurrentLocation(routing);
    if (currentLocation === null || currentLocation.type !== 'RiverCrossing') {
        return [];
    }

    const riverCrossing = currentLocation as RiverCrossing;
    const previousLocation = routingPreviousLocation(routing);
    if (previousLocation === null) {
        return [];
    }

    const isSide1 = riverCrossing.side1.find(name => name === previousLocation.name) !== undefined;
    const isSide2 = riverCrossing.side2.find(name => name === previousLocation.name) !== undefined;
    let side: LocationName[] = [];

    if (isSide1) {
        side = direction === 'across the river' ? riverCrossing.side2 : riverCrossing.side1;
    } else if (isSide2) {
        side = direction === 'across the river' ? riverCrossing.side1 : riverCrossing.side2;
    }

    return routingNextVisibleLocations(routing)
        .filter(location => side.includes(location.name))
        .map(location => location.name);
}

export function routingNextVisibleLocations(routing: Routing): Location[] {
    const lastLocation = routingCurrentLocation(routing) || routingLastLocation(routing);
    if (lastLocation === null) {
        return [];
    }

    const lastLocationNode = routingNodeForLocation(routing, lastLocation.name);
    if (lastLocationNode === null) {
        return [];
    }

    const nextLocations = findNextNodesAndLocations({ node: lastLocationNode, location: lastLocation },
        (name) => routingLocation(routing, name),
        (name) => routingNodeForLocation(routing, name),
        true);
    
    return nextLocations
        .filter(next => lastLocation.name !== next.location.name)
        .map(next => next.location);
}

export function routingNextLocation(routing: Routing, chosenDirection: LocationName | null): Location | null {
    const lastLocation = routingCurrentLocation(routing) || routingLastLocation(routing);
    if (lastLocation === null) {
        if (process.env.NODE_ENV !== 'test') {
            console.warn('No current or last location available');
        }
        return null;
    }

    const lastLocationNode = routingNodeForLocation(routing, lastLocation.name);
    if (lastLocationNode === null) {
        console.warn(`Missing route node for location ${lastLocation.name}`);
        return null;
    }

    if (lastLocationNode.next.length === 0) {
        if (lastLocationNode.location !== routing.destinationName) {
            console.warn(`No next nodes available for ${lastLocationNode.location}`);
        }
        return null;
    }

    else if (lastLocationNode.next.length === 1) {
        const next = lastLocationNode.next[0];
        const location = routingLocation(routing, next);
        if (location === null) {
            console.warn(`Location ${next} not found`);
            return null;
        }
        return location;
    }

    else if (chosenDirection !== null) {
        const lookupLocation = (name: LocationName) => routing.locations.find(location => location.name === name) ?? null;
        const lookupNode = (name: LocationName) => routing.nodes.find(node => node.location === name) ?? null;

        const next = lastLocationNode.next.find(name => name === chosenDirection);
        if (next !== undefined) {
            return lookupLocation(next);
        }

        const start: NodeAndLocation = { node: lastLocationNode, location: lastLocation };
        const chosen = findNextNodesAndLocations(start, lookupLocation, lookupNode, true)
            .find(next => next.location.name === chosenDirection);
        
        if (chosen !== undefined) {
            return chosen.skipped ?? chosen.location;
        }
        
        console.warn(`Chosen direction ${chosenDirection} not found in ${lastLocation.name}`);
        return null;
    }

    else {
        // console.warn(`No direction chosen at ${node.location}, available are ${node.next}`);
        // try {
        //     throw new Error('foo');
        // } catch (e) {
        //     console.error(e);
        // }
        return null;
    }
}

export function routingNextVisibleLocation(routing: Routing, chosenDirection: LocationName | null): Location | null {
    const nextLocation = routingNextLocation(routing, chosenDirection);
    if (nextLocation === null) {
        return null;
    } else if (nextLocation.visibility !== 'hidden') {
        return nextLocation;
    }

    const nextNode = routingNodeForLocation(routing, nextLocation.name);
    if (nextNode === null) {
        return null;
    }

    const chosen = findNextNodesAndLocations({ node: nextNode, location: nextLocation }, name => routingLocation(routing, name), name => routingNodeForLocation(routing, name), true)
        .find(next => next.location.name === chosenDirection);
    
    if (chosen !== undefined) {
        return chosen.location;
    }

    return null;
}

export function routingPathTaken(routing: Routing): Location[] {
    return routing.pathTaken
        .map(name => routingLocation(routing, name))
        .filter(location => location !== null)
        .map(location => location!);
}

export function routingDistanceTravelled(routing: Routing): number {
    const locations = routingPathTaken(routing);
    if (locations.length === 0) {
        return 0;
    }

    const distanceMatrix = new DistanceMatrix(locations, (location) => ({
        lat: location.latitude,
        lng: location.longitude,
    }));

    let distance = 0;

    for (let index = 1; index < locations.length; ++index) {
        const predecessor = locations[index - 1];
        const location = locations[index];
        distance += distanceMatrix.distance(predecessor, location, (a, b) => a.name === b.name);
    }

    return distance;
}

export function routingDistanceToNextLocation(routing: Routing, chosenDirection: LocationName | null): number {
    const nextLocation = routingNextLocation(routing, chosenDirection);
    if (nextLocation === null) {
        return NaN;
    }

    const pathTaken = routingPathTaken(routing);
    const startLocation = pathTaken.length > 0 ? pathTaken[pathTaken.length - 1] : routingLocation(routing, routing.root.location);
    if (startLocation === null || startLocation.name === nextLocation.name) {
        return 0;
    }

    const distanceMatrix = new DistanceMatrix(routing.locations, (location) => ({
        lat: location.latitude,
        lng: location.longitude,
    }));

    return distanceMatrix.distance(startLocation, nextLocation, (a, b) => a.name === b.name);
}

export function routingCurrentMapRegionNames(routing: Routing): RegionName[] {
    const location = routingCurrentLocation(routing) ?? routingLastLocation(routing);
    if (location === null) {
        const name = routing.root.location;
        const location = routing.locations.find(l => l.name === name)
        if (location === undefined) {
            return [];
        }
        return location.regions;
    } else {
        return location.regions;
    }
}

export function routingLocationsForMapRegions(routing: Routing, regionNames: RegionName[]): Location[] {
    const matchesAnyRegionName = (name: RegionName) => regionNames.includes(name);
    return routing.locations.filter(location => location.regions.filter(matchesAnyRegionName).length > 0);
}

export function routingLocationConnections(routing: Routing, acceptLocation: (location: Location) => boolean = (location) => true, skipHiddenLocations: boolean = true): [Location, Location][] {
    const connections: [Location, Location][] = [];
    
    const acceptedLocations = new Map<LocationName, Location>();
    routing.locations
        .filter(location => acceptLocation(location))
        .forEach(location => acceptedLocations.set(location.name, location));
    
    const visitedNodes = new Set<LocationName>();
    const visitNode = (node: RouteNode) => {
        if (visitedNodes.has(node.location)) {
            return;
        } else {
            visitedNodes.add(node.location);
        }

        const location = acceptedLocations.get(node.location);
        if (location === undefined) {
            return;
        }

        const nextNodesAndLocations = findNextNodesAndLocations({ node, location },
            name => acceptedLocations.get(name) ?? null,
            name => routing.nodes.find(n => n.location === name) ?? null,
            skipHiddenLocations);

        nextNodesAndLocations.forEach(next => {
            connections.push([location, next.location]);
            visitNode(next.node);
        });
    };
    visitNode(routing.root);

    return connections;
}