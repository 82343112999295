import { AnimalAttack, makeFightingResult, registerShot } from "../../types/AnimalAttack";
import { makePlayerDiedEvent } from "../../types/GameEvent";
import { enableInjury, makeRandomInjury } from "../../types/health/Injury";
import { decreaseInventoryItem, increaseInventoryItem } from "../../types/inventory/InventoryItem";
import { makeFightingAnimalsEndedMessage, makeFightingAnimalsStartedMessage, makePlayerDiedMessage, makePlayerInjuredMessage } from "../../types/Message";
import { enableDeath, isDead } from "../../types/Player";
import { GameState } from "../game/gameSlice";

export default class FightingAnimalsSystem {

    static start(animalAttack: AnimalAttack, game: GameState): GameState {
        const message = makeFightingAnimalsStartedMessage(animalAttack, game.date);
        const messages = [...game.messages, message];
        return { ...game, animalAttack, messages };
    }

    static registerShot(playerName: string, word: string, game: GameState): GameState {
        let { animalAttack } = game;

        let finished = false;
        if (animalAttack !== null && animalAttack.result === undefined) {
            animalAttack = registerShot(animalAttack, playerName, word);
            if (animalAttack.hits.length === animalAttack.attackingPlayers.length) {
                finished = true;
            }
        }

        if (finished) {
            return this.stop({ ...game, animalAttack });
        } else {
            return { ...game, animalAttack };
        }
    }

    static stop(game: GameState): GameState {
        let { animalAttack, inventory, date, wagons } = game;
        const messages = [...game.messages];
        const events = [...game.events];
        const players = [...game.players];
        
        if (animalAttack) {
            const result = makeFightingResult(animalAttack, players, game.stats.hunting, inventory, wagons);
            animalAttack = { ...animalAttack, result };
            messages.push(makeFightingAnimalsEndedMessage(animalAttack, date));
            const bullets = decreaseInventoryItem(inventory.bullets, result.bulletsUsed);
            const food = increaseInventoryItem(inventory.food, result.food);
            inventory = { ...inventory, bullets, food };

            if (result.killedPlayerName) {
                const index = players.findIndex(player => player.name === result.killedPlayerName && !isDead(player));
                if (index !== -1) {
                    const killedPlayer = enableDeath(players[index], 'Killed by wild animals');
                    players[index] = killedPlayer;
                    messages.push(makePlayerDiedMessage(killedPlayer, date));
                    events.push(makePlayerDiedEvent(killedPlayer));
                }
            }

            if (result.injuredPlayerName) {
                const index = players.findIndex(player => player.name === result.injuredPlayerName && !isDead(player));
                if (index !== -1) {
                    const injury = makeRandomInjury(game.stats.health);
                    const injuredPlayer = enableInjury(players[index], injury);
                    players[index] = injuredPlayer;
                    messages.push(makePlayerInjuredMessage(injuredPlayer, injury, date));
                }
            }
        }

        return { ...game, animalAttack, inventory, messages, events, players };
    }

    static reset(game: GameState): GameState {
        return { ...game, animalAttack: null };
    }

}