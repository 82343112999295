import { Location } from "./Location";
import { Town } from "./Town";

export interface ValleyLodge extends Location {}

export function makeValleyLodge(town: Town): ValleyLodge {
    return {
        type: 'ValleyLodge',
        id: 'VALLEY_LODGE',
        name: 'Valley Lodge',
        regions: town.regions,
        status: 'Pending',
        latitude: town.latitude,
        longitude: town.longitude,
        resources: {
            food: 'poor',
            water: 'poor',
            other: 'poor',
        },
        climate: town.climate,
        terrain: town.terrain,
        visibility: 'hidden',
    };
}
