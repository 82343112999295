import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { openChest, selectGame } from "../../../gameSlice";

import OpenChestLockModal from "./OpenChestLockModal";

export default function ChestInteractions() {
    const dispatch = useAppDispatch();
    const { openingChestAttempt, questInventory } = useAppSelector(selectGame);
    const [isModalOpen, setModalOpen] = useState(false);

    const { chest } = questInventory;

    const open = () => {
        if (!isModalOpen && chest !== undefined && chest.lock !== null) {
            dispatch(openChest());
        }
    };

    useEffect(() => {
        if (chest !== undefined && openingChestAttempt && !isModalOpen) {
            setModalOpen(true);
        } else if ((chest === undefined || !openingChestAttempt) && isModalOpen) {
            setModalOpen(false);
        }
    }, [isModalOpen, setModalOpen, openingChestAttempt, chest]);

    if (chest === undefined || chest.lock === null) {
        return null;
    } else {
        return (
            <>
            <Button variant="secondary" size="sm" onClick={open}>open</Button>
            <OpenChestLockModal lock={chest.lock} open={[isModalOpen, setModalOpen]} />
            </>
        );
    }
}