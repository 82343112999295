import { Player } from "../Player";
import { rollDice } from "../Random";
import { HealthStat } from "../Stat";

export type InjuryName = 'Concussion' | 'Injured Torso' | 'Broken Back' | 'Broken Arm' | 'Broken Leg' | 'Broken Foot' | 'Broken Hand' | 'Broken Toe' | 'Broken Finger';

export interface Injury {
    name: InjuryName;
    remainingDays: number;
    damagePerDay: number;
    healingPerDay: number;
    damageTaken: number;
}

function makeSevereInjury(name: InjuryName): Injury {
    return {
        name,
        remainingDays: 10,
        damagePerDay: 30,
        healingPerDay: 10,
        damageTaken: 0,
    };
}

function makeMildInjury(name: InjuryName): Injury {
    return {
        name,
        remainingDays: 5,
        damagePerDay: 15,
        healingPerDay: 8,
        damageTaken: 0,
    };
}

export function makeInjury(name: InjuryName, severity: 'mild' | 'severe'): Injury {
    if (severity === 'mild') {
        return makeMildInjury(name);
    } else if (severity === 'severe') {
        return makeSevereInjury(name);
    } else {
        throw new Error(`Invalid severity: ${severity}`);
    }
}

export function makeRandomInjury(healthStat: HealthStat): Injury {
    const d = rollDice(10) + healthStat.value;
    if (d <= 1) {
        return makeInjury('Concussion', 'severe');
    } else if (d === 2) {
        return makeInjury('Injured Torso', 'severe');
    } else if (d === 3) {
        return makeInjury('Broken Back', 'severe');
    } else if (d === 4) {
        return makeInjury('Broken Arm', 'severe');
    } else if (d === 5) {
        return makeInjury('Broken Leg', 'severe');
    } else if (d === 6) {
        return makeInjury('Broken Leg', 'mild');
    } else if (d === 7) {
        return makeInjury('Broken Foot', 'mild');
    } else if (d === 8) {
        return makeInjury('Broken Hand', 'mild');
    } else if (d === 9) {
        return makeInjury('Broken Toe', 'mild');
    } else {
        return makeInjury('Broken Finger', 'mild');
    }
}

export function isInjuryDamaging(injury: Injury): boolean {
    return injury.remainingDays > 0;
}

export function isInjuryHealing(injury: Injury): boolean {
    return injury.remainingDays === 0 && injury.damageTaken > 0;
}

export function decreaseInjuryRemainingDays(injury: Injury, amount: number = 1): Injury {
    const remainingDays = Math.max(0, injury.remainingDays - amount);
    return { ...injury, remainingDays };
}

export function increaseInjuryDamageTaken(injury: Injury, damage: number): Injury {
    const damageTaken = injury.damageTaken + damage;
    return { ...injury, damageTaken };
}

export function decreaseInjuryDamageTaken(injury: Injury, damage: number): Injury {
    const damageTaken = Math.max(0, injury.damageTaken - damage);
    return { ...injury, damageTaken };
}

export function isInjured(player: Player): boolean {
    return player.injuries
        .filter(isInjuryDamaging)
        .length > 0;
}

export function enableInjury(player: Player, injury: Injury): Player {
    const index = player.injuries.findIndex(i => i.name === injury.name);
    if (index === -1) {
        const injuries: Injury[] = [...player.injuries, injury];
        return { ...player, injuries };
    } else {
        const injuries: Injury[] = [...player.injuries];
        injuries[index] = injury;
        return { ...player, injuries };
    }
}

export function disableInjury(player: Player, injury: Injury): Player {
    const injuries: Injury[] = player.injuries.filter(i => i.name !== injury.name);
    return { ...player, injuries };
}
