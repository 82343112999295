export const POUND_PER_KILOGRAM = 2.204623;
export const GALLON_PER_LITER = 0.2199692;
export const OUNCE_PER_GALLON = 128;

export function formatAnd(things: string[], nothing: string = 'Nothing'): string {
    if (things.length > 2) {
        const reversed = [...things];
        reversed.reverse();
        const [last, ...others] = reversed;
        others.reverse();
        return others.join(', ') + ' and ' + last;
    } else if (things.length === 2) {
        const [first, second] = things;
        return first + ' and ' + second;
    } else if (things.length === 1) {
        return things[0];
    } else {
        return nothing;
    }
}

const US = new Intl.Locale('en-US');

export function formatWeight(weight: number, unit: 'kilogram' | 'pound' | 'poundWithoutUnit' = 'pound'): string {
    if (unit === 'kilogram') {
        return Math.floor(weight).toLocaleString(US) + ' kg';
    } else if (unit === 'pound') {
        const pound = weight * POUND_PER_KILOGRAM;
        return Math.floor(pound).toLocaleString(US) + ' lbs';
    } else if (unit === 'poundWithoutUnit') {
        const pound = weight * POUND_PER_KILOGRAM;
        return Math.floor(pound).toLocaleString(US);
    } else {
        return 'invalid unit';
    }
}

export function repeat<T>(item: T, count: number): Array<T> {
    const array: T[] = [];
    for (let index = 0; index < count; ++index) {
        array.push(item);
    }
    return array;
}

export function toRomanNumeral(value: number): string {
    if (isNaN(value)) {
        return '';
    }

    const digits = `${value}`
        .split('')
        .map(s => parseInt(s));

    const keys = ['', 'C', 'CC', 'CCC', 'CD', 'D', 'DC', 'DCC', 'DCCC', 'CM',
        '', 'X', 'XX', 'XXX', 'XL', 'L', 'LX', 'LXX', 'LXXX', 'XC',
        '', 'I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX'];
    
    let roman = '';
    let index = 3;

    while (index--) {
        const digit = digits.pop();
        if (digit !== undefined) {
            const prefix = keys[digit + (index * 10)] ?? '';
            roman = prefix + roman;
        }
    }

    const remainder = +digits.join('') + 1;
    return Array(remainder).join('M') + roman;
}

export function uniqueArray<T>(array: T[], equals: (lhs: T, rhs: T) => boolean = (lhs, rhs) => lhs === rhs): T[] {
    if (array.length < 2) {
        return array;
    }

    const uniqueArray: T[] = [array[0]];
    
    for (let index = 1; index < array.length; ++index) {
        const value = array[index];
        const contained = uniqueArray.findIndex(other => equals(value, other)) !== -1;
        if (!contained) {
            uniqueArray.push(value);
        }
    }

    return uniqueArray;
}

export function countItemsInArray<T>(array: T[], equals: (lhs: T, rhs: T) => boolean = (lhs, rhs) => lhs === rhs): { item: T, count: number }[] {
    const countedItems: { item: T, count: number }[] = [];

    array.forEach(item => {
        const index = countedItems.findIndex(entry => equals(item, entry.item));
        if (index === -1) {
            countedItems.push({ item, count: 1 });
        } else {
            countedItems[index].count += 1;
        }
    });

    return countedItems;
}

export function zip<A, B, R>(aArray: A[], bArray: B[], combine: (a: A, b: B) => R): R[] {
    const rArray: R[] = [];

    aArray.forEach(a => {
        bArray.forEach(b => {
            rArray.push(combine(a, b));
        });
    });

    return rArray;
}