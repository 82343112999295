import { Player } from "../Player";

const FREEZING_LVL1_HP = 16; // HP per freezing person per day, level 1
const FREEZING_LVL2_HP = 24; // HP per freezing person per day, level 2

export function freezingMalus(temperature: number): number {
    if (temperature < 0) {
        return FREEZING_LVL2_HP;
    } else {
        return FREEZING_LVL1_HP;
    }
}

export function isFreezing(player: Player): boolean {
    return player.freezing === true;
}

export function enableFreezing(player: Player): Player {
    return { ...player, freezing: true };
}

export function disableFreezing(player: Player): Player {
    return { ...player, freezing: false };
}
