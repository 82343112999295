import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { Button, ButtonGroup } from "react-bootstrap";
import { formatCash } from "../../../../../types/inventory/Cash";

interface TradingButtonsProps {
    amountInStock: number;
    amountPerUnit: number;
    minAmount: number;
    maxAmount: number;
    canAfford: (amount: number) => boolean;
    money: (amount: number, mode: 'buy' | 'sell') => number;
    formatUnits: (units: number) => string;
    buy?: (amount: number) => void;
    sell?: (amount: number) => void;
}

export default function TradingButtons({ amountInStock, amountPerUnit, minAmount, maxAmount, canAfford, money, formatUnits, buy, sell }: TradingButtonsProps) {
    type Mode = 'buy' | 'sell' | 'none';

    const [mode, setMode] = useState<Mode>('none');
    const [units, setUnits] = useState(0);

    const buyingDisabled = !buy || (amountInStock + (units * amountPerUnit)) > maxAmount;
    const sellingDisabled = !sell || (amountInStock - (units * amountPerUnit)) < minAmount;

    let plusDisabled = true;
    let minusDisabled = true;

    if (mode === 'buy') {
        plusDisabled = (amountInStock + ((units + 1) * amountPerUnit)) > maxAmount || !canAfford((units + 1) * amountPerUnit);
        minusDisabled = (amountInStock + ((units - 1) * amountPerUnit)) < minAmount || units === 0;
    } else if (mode === 'sell') {
        plusDisabled = (amountInStock - ((units + 1) * amountPerUnit)) < minAmount;
        minusDisabled = (amountInStock - ((units - 1) * amountPerUnit)) > maxAmount || units === 0;
    }
    
    const onBuyButtonClicked = () => {
        if (mode === 'buy') {
            if (buy && !buyingDisabled) {
                const amount = units * amountPerUnit;
                buy(amount);
                setMode('none');
                setUnits(0);
            }
        } else {
            setMode('buy');
            setUnits(0);
        }
    };

    const onSellButtonClicked = () => {
        if (mode === 'sell') {
            if (sell && !sellingDisabled) {
                const amount = units * amountPerUnit;
                sell(amount);
                setMode('none');
                setUnits(0);
            }
        } else {
            setMode('sell');
            setUnits(0);
        }
    };

    const onCancelButtonClicked = () => {
        setMode('none');
        setUnits(0);
    };

    const onPlusButtonClicked = () => {
        if (mode === 'buy' && !buyingDisabled) {
            setUnits(units + 1);
        } else if (mode === 'sell' && !sellingDisabled) {
            setUnits(units + 1);
        }
    };

    const onMinusButtonClicked = () => {
        if (mode === 'buy' && !buyingDisabled) {
            setUnits(units - 1);
        } else if (mode === 'sell' && !sellingDisabled) {
            setUnits(units - 1);
        }
    };

    if (mode === 'buy' || mode === 'sell') {
        return (
            <div className="d-flex justify-content-between align-items-center">
                <div className="me-3">
                    <div>{formatUnits(units)}</div>
                    <div>{formatCash(money(units * amountPerUnit, mode))}</div>
                </div>
                <ButtonGroup className="ms-1">
                    <Button variant="secondary" size="sm" onClick={onMinusButtonClicked} disabled={minusDisabled}>
                        <FontAwesomeIcon icon={faMinus} fixedWidth />
                    </Button>
                    <Button variant="secondary" size="sm" onClick={onPlusButtonClicked} disabled={plusDisabled}>
                        <FontAwesomeIcon icon={faPlus} fixedWidth />
                    </Button>
                </ButtonGroup>
                { mode === 'buy' && <Button variant="primary" size="sm" onClick={onBuyButtonClicked} disabled={buyingDisabled || units === 0} className="ms-1">Buy</Button> }
                { mode === 'sell' && <Button variant="primary" size="sm" onClick={onSellButtonClicked} disabled={sellingDisabled || units === 0} className="ms-1">Sell</Button> }
                <Button variant="secondary" size="sm" onClick={onCancelButtonClicked} className="ms-1">Cancel</Button>
            </div>
        );
    } else if (!buy && !sell) {
        return null;
    } else {
        return (
            <div>
                <Button variant="secondary" onClick={onBuyButtonClicked} disabled={buyingDisabled} className="ms-1">Buy</Button>
                <Button variant="secondary" onClick={onSellButtonClicked} disabled={sellingDisabled} className="ms-1">Sell</Button>
            </div>
        );
    }
}