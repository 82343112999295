import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { isDead, Player } from "../../../types/Player";
import { isInjured } from "../../../types/health/Injury";
import { isSick } from "../../../types/health/Disease";
import { isFreezing } from "../../../types/health/Freezing";
import { useAppDispatch } from "../../hooks";
import { activatePlayer, deactivatePlayer, removePlayer } from "../gameSlice";
import { isExhausted } from "../../../types/health/Exhaustion";
import { formatAnd } from "../../../types/Utilities";
import { HealthVariants } from "../../../types/health/Health";

import AvatarImage from "./AvatarImage";
import ClothesIndicator from "./ClothesIndicator";
import GameIcon from "../../misc/GameIcon";

export default function PlayerAvatar({ player, avatarSize, clothesIndicator, inflictionIndicator, removeButton, large }: { player: Player, avatarSize?: number, clothesIndicator?: true, inflictionIndicator?: true, removeButton?: true, large?: boolean }) {
    if (!!large) {
        return <LargePlayerAvatar player={player} />;
    } else {
        return <DefaultPlayerAvatar player={player} avatarSize={avatarSize}
            clothesIndicator={clothesIndicator}
            inflictionIndicator={inflictionIndicator}
            removeButton={removeButton} />;
    }
}

function DefaultPlayerAvatar({ player, avatarSize, clothesIndicator, inflictionIndicator, removeButton }: { player: Player, avatarSize?: number, clothesIndicator?: true, inflictionIndicator?: true, removeButton?: true }) {
    const dispatch = useAppDispatch();

    const dead = isDead(player);
    const sick = !dead && isSick(player);
    const injured = !dead && isInjured(player);
    const freezing = !dead && isFreezing(player);

    const onNameClicked = () => {
        if (player.active) {
            dispatch(deactivatePlayer(player.name));
        } else if (!dead) {
            dispatch(activatePlayer(player.name));
        }
    };

    const onRemoveButtonClicked = () => dispatch(removePlayer(player.name));

    return (
        <div className="player-avatar d-flex flex-row justify-content-start align-items-center h-100">
            <AvatarImage player={player} size={avatarSize} />
            <div className={"player-name flex-grow-1 ms-2 fs-5 text-truncate text-nowrap d-none d-md-block" + ((player.active && !dead) ? '' : ' text-muted')} onClick={onNameClicked}>{player.name}</div>
            { (clothesIndicator || inflictionIndicator) &&
            <div className="ms-2 fs-5 text-muted d-none d-xl-block text-nowrap">
                { clothesIndicator && !dead && <ClothesIndicator player={player} /> }
                { inflictionIndicator && sick && <GameIcon icon="biohazard" className="ms-1 text-warning" title="sick" /> }
                { inflictionIndicator && injured && <GameIcon icon="arm-bandage" className="ms-1 text-warning" title="injured" /> }
                { inflictionIndicator && freezing && <GameIcon icon="thermometer-cold" className="ms-1 text-white" title="freezing" /> }
                { inflictionIndicator && dead && <GameIcon icon="death-skull" className="ms-1 text-secondary" title={player.causeOfDeath ?? "dead"} /> }
            </div>
            }
            { removeButton === true &&
            <div className="flex-grow-1 text-end">
                <Button variant="link" className="text-dark" onClick={onRemoveButtonClicked} title="Remove Player">
                    <FontAwesomeIcon icon={faXmark} />
                </Button>
            </div>
            }
        </div>
    );
}

function LargePlayerAvatar({ player }: { player: Player }) {
    const dead = isDead(player);
    const sick = !dead && isSick(player);
    const injured = !dead && isInjured(player);
    const freezing = !dead && isFreezing(player);
    const exhausted = !dead && isExhausted(player);
    const naked = !dead && !!player.naked;
    const wet = !dead && !!player.wet;
    const hungry = !dead && !!player.hungry;
    const thirsty = !dead && !!player.thirsty;

    const diseases = (sick ? player.diseases : [])
        .filter(disease => disease.remainingDays > 0)
        .map(disease => disease.name);
    
    const injuries = (injured ? player.injuries : [])
        .filter(injury => injury.remainingDays > 0)
        .map(injury => injury.name);

    const hpPercentage = (100 * player.hp / HealthVariants.good.maximum).toFixed(0) + '%';

    return (
        <div className="d-flex flex-column justify-content-center align-items-center">
            <AvatarImage player={player} size={92} />
            <h5>{player.name}</h5>
            { dead && <div className="text-muted">{player.causeOfDeath ?? 'dead'}</div> }
            { !dead &&
            <table>
                <tbody>
                    <tr>
                        <td><strong>Health:</strong></td>
                        <td>{hpPercentage}</td>
                    </tr>
                    <tr>
                        <td><strong>Disease:</strong></td>
                        <td>{formatAnd(diseases, 'none')}</td>
                    </tr>
                    <tr>
                        <td><strong>Injury:</strong></td>
                        <td>{formatAnd(injuries, 'none')}</td>
                    </tr>
                    <tr>
                        <td><strong>Freezing:</strong></td>
                        <td>{freezing ? "yes" : "no"}</td>
                    </tr>
                    <tr>
                        <td><strong>Dressed:</strong></td>
                        <td>{naked ? "no" : "yes"}</td>
                    </tr>
                    <tr>
                        <td><strong>Wet:</strong></td>
                        <td>{wet ? "yes" : "no"}</td>
                    </tr>
                    <tr>
                        <td><strong>Hungry:</strong></td>
                        <td>{hungry ? "yes" : "no"}</td>
                    </tr>
                    <tr>
                        <td><strong>Thirsty:</strong></td>
                        <td>{thirsty ? "yes" : "no"}</td>
                    </tr>
                    <tr>
                        <td><strong>Exhausted:</strong></td>
                        <td>{exhausted ? "yes" : "no"}</td>
                    </tr>
                </tbody>
            </table>
            }
        </div>
    );
}