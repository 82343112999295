import { formatAnd, POUND_PER_KILOGRAM } from "../Utilities";
import { InventoryItem } from "./InventoryItem";

export interface WagonTongues extends InventoryItem {
    name: 'Wagon Tongues';
}

export function makeWagonTongues(amount: number = 0): WagonTongues {
    return { amount, name: 'Wagon Tongues' };
}

export function formatWagonTongues(tongues: WagonTongues | number): string {
    if (typeof tongues === 'number') {
        if (tongues === 1) {
            return '1 tongue';
        } else {
            return `${tongues} tongues`;
        }
    } else if (tongues.amount === 1) {
        return '1 tongue';
    } else {
        return `${tongues.amount} tongues`;
    }
}

export interface WagonWheels extends InventoryItem {
    name: 'Wagon Wheels';
}

export function makeWagonWheels(amount: number = 0): WagonWheels {
    return { amount, name: 'Wagon Wheels' };
}

export function formatWagonWheels(wheels: WagonWheels | number): string {
    if (typeof wheels === 'number') {
        if (wheels === 1) {
            return '1 wheel';
        } else {
            return `${wheels} wheels`;
        }
    } else if (wheels.amount === 1) {
        return '1 wheel';
    } else {
        return `${wheels.amount} wheels`;
    }
}

export interface WagonAxles extends InventoryItem {
    name: 'Wagon Axles';
}

export function makeWagonAxles(amount: number = 0): WagonAxles {
    return { amount, name: 'Wagon Axles' };
}

export function formatWagonAxles(axles: WagonAxles | number): string {
    if (typeof axles === 'number') {
        if (axles === 1) {
            return '1 axle';
        } else {
            return `${axles} axles`;
        }
    } else if (axles.amount === 1) {
        return '1 axle';
    } else {
        return `${axles.amount} axles`;
    }
}

export function formatSpareParts(tongues: WagonTongues, wheels: WagonWheels, axles: WagonAxles): string {
    const things: string[] = [];
    if (tongues.amount > 0) {
        things.push(formatWagonTongues(tongues));
    }
    if (wheels.amount > 0) {
        things.push(formatWagonWheels(wheels));
    }
    if (axles.amount > 0) {
        things.push(formatWagonAxles(axles));
    }
    return formatAnd(things, 'no spare parts');
}

export function determineWagonTongueWeight(tongues: WagonTongues): number {
    return tongues.amount * 35 / POUND_PER_KILOGRAM;
}

export function determineWagonTonguesForWeight(weight: number): number {
    return Math.floor(weight / 35 * POUND_PER_KILOGRAM);
}

export function determineWagonWheelWeight(wheels: WagonWheels): number {
    return wheels.amount * 50 / POUND_PER_KILOGRAM;
}

export function determineWagonWheelsForWeight(weight: number): number {
    return Math.floor(weight / 50 * POUND_PER_KILOGRAM);
}

export function determineWagonAxleWheel(axles: WagonAxles): number {
    return axles.amount * 25 / POUND_PER_KILOGRAM;
}

export function determineWagonAxlesForWeight(weight: number): number {
    return Math.floor(weight / 25 * POUND_PER_KILOGRAM);
}
