import { Button, Modal } from "react-bootstrap";

import MapView from "./MapView";

interface MapModalProps {
    open: [boolean, (open: boolean) => void];
}

export default function MapModal({ open }: MapModalProps) {
    const [isOpen, setOpen] = open;

    const close = () => setOpen(false);

    return (
        <Modal show={isOpen} onHide={close} size="xl">
            <Modal.Header>
                <Modal.Title>Map</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{ height: '75vh' }}>
                    <MapView />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={close}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}