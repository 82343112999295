import { Button } from "react-bootstrap";
import { canAffordEatingAtInn, canAffordRestingAtInn } from "../../../../../types/location/Inn";
import { routingCurrentLocation } from "../../../../../types/location/Routing";
import { Town } from "../../../../../types/location/Town";
import { isDead } from "../../../../../types/Player";
import { useAppSelector } from "../../../../hooks";
import { selectGame } from "../../../gameSlice";

export default function InnButton({ openInn }: { openInn: () => void }) {
    const { trail, players, inventory } = useAppSelector(selectGame);
    const peopleCount = players.filter(player => !isDead(player)).length;
    const location = routingCurrentLocation(trail.routing);
    const town = location?.type === 'Town' ? location as Town : null;
    const inn = town?.inn ?? null;
    const canEat = town !== null && canAffordEatingAtInn(town, peopleCount, inventory.cash.amount);
    const canRest = town !== null && canAffordRestingAtInn(town, peopleCount, inventory.cash.amount);

    if (inn === null) {
        return null;
    } else {
        return <Button variant="primary" className="mb-1 ps-2 pe-2 text-truncate" onClick={openInn} disabled={!canEat && !canRest}>{inn.name}</Button>;
    }
}