export function randomIndex(array: any[]): number {
    if (array.length > 0) {
        const r = Math.random();
        return Math.floor(r * array.length);
    }
    return -1;
}

export function randomIndexWithCondition<T>(array: T[], condition: (element: T) => boolean): number {
    let triesLeft = array.length * 100;
    while (array.length > 0 && (triesLeft--) > 0) {
        const r = Math.random();
        const index = Math.floor(r * array.length);
        const element = array[index];
        if (condition(element)) {
            return index;
        }
    }
    return -1;
}

export function randomElement<T>(array: T[]): T | undefined {
    if (array.length === 0) {
        return undefined;
    } else if (array.length === 1) {
        return array[0];
    }

    const index = randomIndex(array);
    if (index !== -1) {
        return array[index];
    }

    return undefined;
}

export function removeRandomElement<T>(array: T[]): T | undefined {
    if (array.length === 0) {
        return undefined;
    }

    const index = randomIndex(array);
    if (index === -1) {
        return undefined;
    } else {
        const element = array[index];
        array.splice(index, 1);
        return element;
    }
}

export function randomFloat(minimum: number, maximum: number): number {
    if (maximum <= minimum) {
        return maximum;
    }
    
    const stride = maximum - minimum;
    const r = Math.random();
    return r * stride + minimum;
}

export function randomInt(minimum: number, maximum: number): number {
    const r = randomFloat(minimum, maximum);
    return Math.floor(r);
}

export function randomString(length: number, alphabet: string = 'abcdefghijklmnopqrstuvwxyz0123456789'): string {
    const characters = new Array<string>(length);
    for (let index = 0; index < length; ++index) {
        const r = randomInt(0, alphabet.length - 1);
        characters.push(alphabet[r]);
    }
    return characters.join('');
}

export function randomEvent(probability: number): boolean {
    return Math.random() <= probability;
}

export function rollDice(sides: number): number {
    return randomInt(1, sides);
}

export function rollDiceEvents<Event>(sides: number, events: { min: number, max: number, event: Event }[]): Event {
    const r = rollDice(sides);
    const match = events
        .map(({ min, max, event }) => {
            if (r >= min && r <= max) {
                return event;
            } else {
                return null;
            }
        })
        .find(event => event !== null);
    
    if (match === undefined || match === null) {
        const stringify: (min: number, max: number, event: Event) => string = (min, max, event) => `from ${min} to ${max} is ${event}`;
        const description = events.map(data => stringify(data.min, data.max, data.event)).join(', ');
        throw new Error(`Die roll ${r} produced no event! Available are ${description}.`);
    }

    return match;
}
