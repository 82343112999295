import { Button } from "react-bootstrap";
import { FerryRiverCrossingOption, GuideRiverCrossingOption, RiverCrossingOption } from "../../../../types/location/RiverCrossingOption";
import { useAppSelector } from "../../../hooks";
import { selectGame } from "../../gameSlice";

export default function ChooseRiverCrossingOptionButton({ chooseOption }: { chooseOption: () => void }) {
    const { travelOptions, status } = useAppSelector(selectGame);
    const payedFerry = isFerryPayed(travelOptions.chosenRiverCrossingOption);
    const payedGuide = isGuidePayed(travelOptions.chosenRiverCrossingOption);

    return <Button variant="primary" className="mb-1 ps-2 pe-2 text-truncate" onClick={chooseOption} disabled={payedFerry || payedGuide || status !== 'Playing'}>Cross River</Button>
}

function isFerryPayed(option: RiverCrossingOption | undefined | null): boolean {
    if (option?.name === 'Ferry') {
        const ferry = option as FerryRiverCrossingOption;
        return ferry.payed;
    }
    return false;
}

function isGuidePayed(option: RiverCrossingOption | undefined | null): boolean {
    if (option?.name === 'Guide') {
        const guide = option as GuideRiverCrossingOption;
        return guide.payed;
    }
    return false;
}