type TextStyle = 'plain' | 'light' | 'dark' | 'red' | 'yellow' | 'blue' | 'green';
const styles: TextStyle[] = ['light', 'dark', 'red', 'yellow', 'blue', 'green'];

interface StyledRange {
    style: TextStyle;
    start: number;
    length: number;
}

export interface StyledText {
    style: TextStyle;
    text: string;
}

function decodeNextStyle(text: string, offset: number): StyledRange {
    let start = text.substring(offset).indexOf(':');
    if (start === -1) {
        return {
            style: 'plain',
            start: offset,
            length: text.length - offset,
        };
    }

    start += offset;

    const substring = text.substring(start);
    const style = styles.find(s => substring.startsWith(`:${s}:{`));
    if (!style) {
        return decodeNextStyle(text, start + 1);
    }

    let length = text.substring(start + style.length + 3).indexOf('}');
    if (length === -1) {
        return decodeNextStyle(text, start + 1);
    }

    length += style.length + 4;

    return { style, start, length };
}

function decodeStyles(text: string): StyledRange[] {
    const ranges: StyledRange[] = [];
    let offset = 0;
    let endReached = false;

    while (!endReached) {
        const offsetBefore = offset;
        let range = decodeNextStyle(text, offset);

        if (range.start !== offset) {
            ranges.push({
                style: 'plain',
                start: offset,
                length: range.start - offset,
            });
        }

        ranges.push(range);
        offset = range.start + range.length;
        endReached = offset >= text.length || offset === offsetBefore;
    }

    return ranges;
}

function decodeStyledTextRange(text: string, range: StyledRange): StyledText {
    const { style, start, length } = range;

    if (style === 'plain') {
        const substring = text.substring(start, start + length);
        return { style, text: substring };
    } else {
        const substring = text.substring(start + style.length + 3, start + length - 1);
        return { style, text: substring };
    }
}

export function decodeStyledText(text: string): StyledText[] {
    return decodeStyles(text)
        .map(range => decodeStyledTextRange(text, range))
        .filter(styledText => styledText.text.length > 0);
}