import { POUND_PER_KILOGRAM } from "../Utilities";
import { InventoryItem } from "./InventoryItem";

export interface Food extends InventoryItem {
    name: 'Food';
}

export function makeFood(amount: number = 0): Food {
    return { amount, name: 'Food' };
}

export function formatFood(food: Food | number, unit: 'kilogram' | 'pound' = 'pound'): string {
    if (typeof food === 'number') {
        if (unit === 'kilogram') {
            return food.toFixed(0) + ' kg';
        } else if (unit === 'pound') {
            return (food * POUND_PER_KILOGRAM).toFixed(0) + ' lbs';
        }
    } else {
        if (unit === 'kilogram') {
            return food.amount.toFixed(0) + ' kg';
        } else if (unit === 'pound') {
            return (food.amount * POUND_PER_KILOGRAM).toFixed(0) + ' lbs';
        }
    }
    return 'invalid unit';
}

export function determineFoodWeight(food: Food): number {
    return food.amount;
}

export function determineFoodForWeight(weight: number): number {
    return weight;
}
