import { format } from "date-fns";
import { Button, ListGroup, Modal } from "react-bootstrap";
import { canMessageAppearInDiary, makeTextMessage, Message } from "../../../../../types/Message";
import { useAppSelector } from "../../../../hooks";
import { selectGame } from "../../../gameSlice";

import StyledMessageView from "../../../message/StyledMessageView";

interface DiaryModalOptions {
    messages: Message[];
    open: [boolean, (open: boolean) => void];
}

interface DiaryEntry {
    date: number;
    messages: Message[];
}

export default function DiaryModal({ messages, open }: DiaryModalOptions) {
    const { date } = useAppSelector(selectGame);
    const [isOpen, setOpen] = open;

    const firstMessageDate = messages.find(message => message.date !== 0)?.date ?? date
    const firstMessage = makeTextMessage(`Dear diary, today I am starting my journey to a place called :yellow:{Valley Lodge}. I have settled my things and invited companions to join me on my quest.`, firstMessageDate);
    const diaryMessages = [firstMessage, ...messages.filter(message => canMessageAppearInDiary(message))];
    const diaryEntries = makeDiaryEntries(diaryMessages);
    
    const close = () => setOpen(false);
    
    return (
        <Modal show={isOpen} size="lg" onHide={() => setOpen(false)}>
            <Modal.Header>
                <Modal.Title>Dear Diary…</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ListGroup>
                    {diaryEntries.map(((entry, index) => (
                        <ListGroup.Item key={index}>
                            <DiaryEntryView entry={entry} />
                        </ListGroup.Item>
                    )))}
                </ListGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={close}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

function makeDiaryEntries(messages: Message[]): DiaryEntry[] {
    const dates = Array.from(new Set(messages.map(message => message.date))).sort();
    return dates.map(date => ({
        date,
        messages: messages.filter(message => message.date === date),
    }));
}

function DiaryEntryView({ entry }: { entry: DiaryEntry }) {
    const date = format(entry.date, 'EEE, d MMMM yyyy');

    return (
        <div>
            <div className="text-muted text-nowrap">{date}</div>
            {entry.messages.map((message, index) => <StyledMessageView key={index} text={message.text} />)}
        </div>
    );
}