import { Button } from "react-bootstrap";
import { travelPreventions } from "../../../../../types/travel/Travel";
import { useAppSelector } from "../../../../hooks";
import { selectGame } from "../../../gameSlice";

export default function ChooseDirectionButton({ chooseDirection }: { chooseDirection: () => void }) {
    const game = useAppSelector(selectGame);
    const { status, travelOptions, traveling } = game;
    const chosenOption = travelOptions.chosenDestinationName;
    const preventions = travelPreventions(game);

    if (preventions.includes('cooldown')) {
        return null;
    } else {
        return <Button variant="primary" className="mb-1 ps-2 pe-2 text-truncate" onClick={chooseDirection} disabled={status !== 'Playing' || traveling}>{chosenOption ? "Change" : "Choose"} Direction</Button>;
    }
}