import { Inventory } from "./inventory/Inventory";
import { decreaseInventoryItem } from "./inventory/InventoryItem";
import { randomFloat } from "./Random";
import { POUND_PER_KILOGRAM, repeat } from "./Utilities";

export type WagonComponentStatus = 'working' | 'broken';
export type WagonComponentName = 'Wheels' | 'Axles' | 'Tongue';

export interface WagonComponent {
    name: WagonComponentName;
    items: WagonComponentStatus[];
}

interface Wheels extends WagonComponent {
    name: 'Wheels';
}

function makeWheels(count: number = 4): Wheels {
    return {
        name: 'Wheels',
        items: repeat('working', count),
    };
}

interface Axles extends WagonComponent {
    name: 'Axles';
}

function makeAxles(count: number = 2): Axles {
    return {
        name: 'Axles',
        items: repeat('working', count),
    };
}

interface Tongue extends WagonComponent {
    name: 'Tongue';
}

function makeTongue(count: number = 1): Tongue {
    return {
        name: 'Tongue',
        items: repeat('working', count),
    };
}

export type WagonName = "Pilgrim's Wagon" | 'Base Wagon' | 'Large Wagon' | 'Double Base Wagon' | 'Double Large Wagon';

export interface Wagon {
    id: number;
    name: WagonName;
    wheels: Wheels;
    axles: Axles;
    tongue: Tongue;
    repairStatus: number; // between 0 and 1
}

let __lastWagonID = 0;

function determineAxleCount(name: WagonName): number {
    if (name === 'Double Base Wagon' || name === 'Double Large Wagon') {
        return 4;
    } else {
        return 2;
    }
}

export function makeWagon(name: WagonName): Wagon {
    const id = ++__lastWagonID;
    return {
        id,
        name,
        wheels: makeWheels(),
        axles: makeAxles(determineAxleCount(name)),
        tongue: makeTongue(),
        repairStatus: 0,
    };
}

export function formatRepairStatus(wagon: Wagon): string {
    const percentage = wagon.repairStatus * 100;
    return percentage.toFixed(0) + ' %';
}

export function countBrokenWheels(wagon: Wagon): number {
    return wagon.wheels.items.filter(wheel => wheel === 'broken').length;
}

export function countBrokenAxles(wagon: Wagon): number {
    return wagon.axles.items.filter(axle => axle === 'broken').length;
}

export function hasBrokenTongue(wagon: Wagon): boolean {
    return wagon.tongue.items[0] === 'broken';
}

export function isWagonBroken(wagon: Wagon): boolean {
    return countBrokenWheels(wagon) > 0
        || countBrokenAxles(wagon) > 0
        || hasBrokenTongue(wagon);
}

export function isAnyWagonBroken(wagons: Wagon[]): boolean {
    return wagons.find(isWagonBroken) !== undefined;
}

function breakWheel(wagon: Wagon): Wagon {
    const index = wagon.wheels.items.findIndex(wheel => wheel === 'working');
    if (index === -1) {
        return wagon; // there is no wheel to break!
    }

    const items = [...wagon.wheels.items];
    items[index] = 'broken';

    const wheels: Wheels = { ...wagon.wheels, items };
    return { ...wagon, wheels };
}

function fixWheel(wagon: Wagon): Wagon {
    const index = wagon.wheels.items.findIndex(wheel => wheel === 'broken');
    if (index === -1) {
        return wagon;
    }

    const items = [...wagon.wheels.items];
    items[index] = 'working';

    const wheels: Wheels = { ...wagon.wheels, items };
    return { ...wagon, wheels };
}

function breakAxle(wagon: Wagon): Wagon {
    const index = wagon.axles.items.findIndex(axle => axle === 'working');
    if (index === -1) {
        return wagon; // there is no axle to break!
    }

    const items = [...wagon.axles.items];
    items[index] = 'broken';

    const axles: Axles = { ...wagon.axles, items };
    return { ...wagon, axles };
}

function fixAxle(wagon: Wagon): Wagon {
    const index = wagon.axles.items.findIndex(axle => axle === 'broken');
    if (index === -1) {
        return wagon;
    }

    const items = [...wagon.axles.items];
    items[index] = 'working';

    const axles: Axles = { ...wagon.axles, items };
    return { ...wagon, axles };
}

function breakTongue(wagon: Wagon): Wagon {
    if (wagon.tongue.items[0] === 'broken') {
        return wagon; // the tongue is already messed up!
    }

    const items: WagonComponentStatus[] = ['broken'];
    const tongue: Tongue = { ...wagon.tongue, items };
    return { ...wagon, tongue };
}

function fixTongue(wagon: Wagon): Wagon {
    if (wagon.tongue.items[0] === 'working') {
        return wagon;
    }

    const items: WagonComponentStatus[] = ['working'];
    const tongue: Tongue = { ...wagon.tongue, items };
    return { ...wagon, tongue };
}

export function breakWagonComponent(wagon: Wagon, name: WagonComponentName): Wagon {
    switch (name) {
        case 'Wheels': return breakWheel(wagon);
        case 'Axles': return breakAxle(wagon);
        case 'Tongue': return breakTongue(wagon);
    }
}

export function fixWagonComponent(wagon: Wagon, name: WagonComponentName): Wagon {
    switch (name) {
        case 'Wheels': return fixWheel(wagon);
        case 'Axles': return fixAxle(wagon);
        case 'Tongue': return fixTongue(wagon);
    }
}

export function applySparePart(wagon: Wagon, inventory: Inventory): { wagon: Wagon, inventory: Inventory } {
    if (inventory.wagonTongues.amount === 0 && inventory.wagonWheels.amount === 0 && inventory.wagonAxles.amount === 0) {
        return { wagon, inventory };
    }

    wagon = { ...wagon, repairStatus: 0 };

    if (countBrokenWheels(wagon) > 0) {
        const wagonWheels = decreaseInventoryItem(inventory.wagonWheels, 1);
        return {
            wagon: fixWheel(wagon),
            inventory: { ...inventory, wagonWheels },
        };
    }

    if (countBrokenAxles(wagon) > 0) {
        const wagonAxles = decreaseInventoryItem(inventory.wagonAxles, 1);
        return {
            wagon: fixAxle(wagon),
            inventory: { ...inventory, wagonAxles },
        };
    }

    if (hasBrokenTongue(wagon)) {
        const wagonTongues = decreaseInventoryItem(inventory.wagonTongues, 1);
        return {
            wagon: fixTongue(wagon),
            inventory: { ...inventory, wagonTongues },
        };
    }

    return { wagon, inventory };
}

export function repair(wagon: Wagon): Wagon {
    const brokenThings = countBrokenWheels(wagon)
        + countBrokenAxles(wagon)
        + (hasBrokenTongue(wagon) ? 1 : 0);

    if (brokenThings === 0) {
        return { ...wagon, repairStatus: 0 };
    }

    const progress = randomFloat(0.1, 0.5) / brokenThings;
    const repairStatus = Math.min(1, wagon.repairStatus + progress);

    if (repairStatus === 1) {
        return {
            ...wagon,
            wheels: makeWheels(),
            axles: makeAxles(),
            tongue: makeTongue(),
            repairStatus: 0,
        };
    }

    return { ...wagon, repairStatus };
}

function formatWheels(amount: number): string {
    if (amount === 1) {
        return '1 wheel';
    } else {
        return `${amount} wheels`;
    }
}

function formatAxles(amount: number): string {
    if (amount === 1) {
        return '1 axle';
    } else {
        return `${amount} axles`;
    }
}

function formatTongues(amount: number): string {
    if (amount === 1) {
        return '1 tongue';
    } else {
        return `${amount} tongues`;
    }
}

export function formatWagonComponent(name: WagonComponentName, amount: number): string {
    switch (name) {
        case 'Wheels': return formatWheels(amount);
        case 'Axles': return formatAxles(amount);
        case 'Tongue': return formatTongues(amount);
    }
}

export function applyWagon(wagons: Wagon[], wagon: Wagon): Wagon[] {
    const index = wagons.findIndex(w => w.id === wagon.id);
    if (index === -1) {
        return wagons;
    }

    wagons[index] = wagon;
    return [...wagons];
}

export function determineWagonWeight(wagon: Wagon): number {
    switch (wagon.name) {
        case "Pilgrim's Wagon":
            return 650 / POUND_PER_KILOGRAM;
        case 'Base Wagon':
            return 1300 / POUND_PER_KILOGRAM;
        case 'Large Wagon':
            return 1700 / POUND_PER_KILOGRAM;
        case 'Double Base Wagon':
            return 2600 / POUND_PER_KILOGRAM;
        case 'Double Large Wagon':
            return 3400 / POUND_PER_KILOGRAM;
    }
}

export function determineWagonWeightCapacity(wagon: Wagon): number {
    switch (wagon.name) {
        case "Pilgrim's Wagon":
            return 2000 / POUND_PER_KILOGRAM;
        case 'Base Wagon':
            return 4000 / POUND_PER_KILOGRAM;
        case 'Large Wagon':
            return 6000 / POUND_PER_KILOGRAM;
        case 'Double Base Wagon':
            return 8000 / POUND_PER_KILOGRAM;
        case 'Double Large Wagon':
            return 12000 / POUND_PER_KILOGRAM;
    }
}
