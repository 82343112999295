import Dexie, { Table } from "dexie";

import { Achievement } from "../types/Achievement";
import { makeWord, Word } from "../types/Word";

export class CustomizedDexie extends Dexie {

    achievements!: Table<Achievement>;
    wordlist!: Table<Word>;

    constructor() {
        super('valleylodge');

        this.version(1).stores({
            achievements: '++id, name, createdAt',
        });

        this.version(2).stores({
            achievements: '++id, name, createdAt',
            wordlist: 'word',
        });
    }

    deleteDatabase() {
        window.indexedDB?.deleteDatabase('valleylodge');
    }

    async createAchievement(achievement: Achievement): Promise<Achievement | null> {
        const existing = await this.achievements
            .where('name').equalsIgnoreCase(achievement.name)
            .limit(1)
            .first();
        
        if (existing) {
            return null;
        }

        const id = await this.achievements.add(achievement) as number;
        return { ...achievement, id };
    }

    async getRecentAchievements(): Promise<Achievement[]> {
        const threshold = new Date().getTime() - 10_000; // ten seconds ago

        return await this.achievements
            .where('createdAt')
            .aboveOrEqual(threshold)
            .toArray();
    }

    async importWords(words: string[]): Promise<void> {
        return new Promise((resolve, reject) => {
            this.wordlist.bulkAdd(words.map(word => makeWord(word)))
                .then(_ => resolve())
                .catch(reject);
        });
    }

    async getWords(): Promise<string[]> {
        return await this.wordlist
            .toArray()
            .then(words => words.map((word: Word) => word.word));
    }

}

export const db = new CustomizedDexie();
