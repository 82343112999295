import { createContext, useContext } from "react";
import { EastereggName } from "../../types/Easteregg";
import { KeyboardInputManager } from "./KeyboardInputManager";

export interface EastereggFoundHandler {
    (name: EastereggName): void;
}

interface NamedEastereggFoundHandler {
    name: string;
    handle: EastereggFoundHandler;
}

export class EastereggManager {

    private _konamiCodeSequence = '';
    private _handlers: NamedEastereggFoundHandler[] = [];

    push(name: string, handler: EastereggFoundHandler) {
        const index = this._handlers.findIndex(handler => name === handler.name);
        if (index === -1) {
            this._handlers.push({ name, handle: handler });
        }
    }

    pop(name: string) {
        const index = this._handlers.findIndex(handler => name === handler.name);
        if (index !== -1) {
            this._handlers.splice(index, 1);
        }
    }

    private found(name: EastereggName) {
        this._handlers.forEach(handler => handler.handle(name));
    }

    registerKeyboardEvents(manager: KeyboardInputManager) {
        manager.push('EastereggManager', (event: KeyboardEvent) => {
            this.checkKonamiCodeSequence(event);
            return false;
        });
    }

    unregisterKeyboardEvents(manager: KeyboardInputManager) {
        manager.pop('EastereggManager');
    }

    private checkKonamiCodeSequence(event: KeyboardEvent) {
        const abortKonamiCode = () => {
            this._konamiCodeSequence = '';
        };

        const appendToKonamiCodeSequence = (c: string) => {
            this._konamiCodeSequence += c;
        };

        const matchedKonamiCode = () => {
            this._konamiCodeSequence = '';
            this.found('Konami Code');
        };

        // Figure out which relevant key was pressed.
        const up = event.key === 'ArrowUp';
        const down = event.key === 'ArrowDown';
        const left = event.key === 'ArrowLeft';
        const right = event.key === 'ArrowRight';
        const a = event.key === 'a';
        const b = event.key === 'b';

        // Translate that into a character.
        let c: string;
        if (up) {
            c = '↑';
        } else if (down) {
            c = '↓';
        } else if (left) {
            c = '←';
        } else if (right) {
            c = '→';
        } else if (a) {
            c = 'a';
        } else if (b) {
            c = 'b';
        } else {
            abortKonamiCode(); // None of them was pressed
            return;
        }

        // Check Konami code sequence.
        const expectedSequence = '↑↑↓↓←→←→ba';
        const sequence = this._konamiCodeSequence + c;
        if (sequence === expectedSequence) {
            matchedKonamiCode();
        } else if (expectedSequence.startsWith(sequence)) {
            appendToKonamiCodeSequence(c);
        } else {
            abortKonamiCode();
        }
    }

}

export const defaultEastereggManager = new EastereggManager();
export const EastereggManagerContext = createContext(defaultEastereggManager);
EastereggManagerContext.displayName = 'EastereggManagerContext';

export const useEastereggManager = () => useContext(EastereggManagerContext);
