import { Location, LocationStatus, LocationType, LocationVisibility, RegionName } from "./Location";

export interface TollStation extends Location {
    price: number;
}

function resolveTollStation(json: TollStation | any): TollStation {
    const type: LocationType = 'TollStation';
    const regions: RegionName[] = ['all'];
    const status: LocationStatus = 'Pending';
    const visibility: LocationVisibility = 'visible';

    return { ...json, type, regions, status, visibility };
}

export function loadTollStations(): TollStation[] {
    return [].map(resolveTollStation);
}
