import { Card, ProgressBar } from "react-bootstrap";
import { countBrokenAxles, countBrokenWheels, hasBrokenTongue } from "../../../../types/Wagon";
import { useAppSelector } from "../../../hooks";
import { selectGame } from "../../gameSlice";
import FixWagonButton from "./FixWagonButton";

export default function RepairsInteractions() {
    const { wagons, inventory } = useAppSelector(selectGame);
    const { wagonTongues, wagonWheels, wagonAxles } = inventory;
    
    const isTonguesBroken = wagons.filter(hasBrokenTongue).length > 0;
    const canFixTongues = isTonguesBroken && wagonTongues.amount > 0;
    const isWheelsBroken = wagons.filter(wagon => countBrokenWheels(wagon) > 0).length > 0;
    const canFixWheels = isWheelsBroken && wagonWheels.amount > 0;
    const isAxlesBroken = wagons.filter(wagon => countBrokenAxles(wagon) > 0).length > 0;
    const canFixAxles = isAxlesBroken && wagonAxles.amount > 0;
    const totalRepairStatus = wagons.map(wagon => wagon.repairStatus).reduce((prev, curr) => prev + curr, 0);
    const avgRepairStatus = wagons.length === 0 ? 0 : totalRepairStatus / wagons.length;

    if (!isTonguesBroken && !isWheelsBroken && !isAxlesBroken) {
        return null;
    } else {
        return (
            <Card>
                <Card.Header>Repairs</Card.Header>
                <Card.Body className="d-flex flex-column justify-content-start align-items-stretch">
                    <FixWagonButton />
                    
                    { !(canFixTongues || canFixWheels || canFixAxles) &&
                    <Card.Text className="text-center text-muted" style={{ width: '10rem' }}>
                        No spare parts? Rest here and try to fix the wagon.
                    </Card.Text>
                    }

                </Card.Body>
                <Card.Footer>
                    <ProgressBar min={0} max={1} now={avgRepairStatus} className="repair-status" />
                </Card.Footer>
            </Card>
        );
    }
}