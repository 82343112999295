import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { formatCash } from "../../../../../types/inventory/Cash";
import { calculateWagonAxlesCapacity, calculateWagonTonguesCapacity, calculateWagonWheelsCapacity } from "../../../../../types/inventory/Inventory";
import { formatWagonAxles, formatWagonTongues, formatWagonWheels } from "../../../../../types/inventory/SpareParts";
import { buyWagonAxles, buyWagonTongues, buyWagonWheels, determineSellingPrice, sellWagonAxles, sellWagonTongues, sellWagonWheels, StoreItemName } from "../../../../../types/Store";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { boughtSomething, changeInventory, selectGame } from "../../../gameSlice";

import ProductAmount from "./ProductAmount";
import ProductName from "./ProductName";
import ProductPrice from "./ProductPrice";
import StoreItem from "./StoreItem";
import TradingButtons from "./TradingButtons";

interface SparePartsStoreItemProps {
    tonguePrice: number;
    wheelPrice: number;
    axlePrice: number;
    selection: [selection: StoreItemName | null, setSelection: (name: StoreItemName | null) => void];
}

type SparePartType = 'Tongue' | 'Wheel' | 'Axle';

export default function SparePartsStoreItem({ tonguePrice, wheelPrice, axlePrice, selection }: SparePartsStoreItemProps) {
    const dispatch = useAppDispatch();
    const { inventory, stats, wagons, players } = useAppSelector(selectGame);
    const { cash, wagonTongues, wagonWheels, wagonAxles } = inventory;

    const [selectedItem, setSelectedItem] = selection;
    const [sparePartType, setSparePartType] = useState<SparePartType | null>(null);
    const active = selectedItem === 'Wagon Tongues' || selectedItem === 'Wagon Wheels' || selectedItem === 'Wagon Axles';
    const tonguesCapacity = calculateWagonTonguesCapacity(inventory, wagons, players);
    const wheelsCapacity = calculateWagonWheelsCapacity(inventory, wagons, players);
    const axlesCapacity = calculateWagonAxlesCapacity(inventory, wagons, players);

    const tongues = !!tonguePrice;
    const wheels  = !!wheelPrice;
    const axles   = !!axlePrice;
    const count = [tongues, wheels, axles].filter(e => e === true).length;

    let price: number | undefined = undefined;
    if (sparePartType === 'Tongue') {
        price = tonguePrice;
    } else if (sparePartType === 'Wheel') {
        price = wheelPrice;
    } else if (sparePartType === 'Axle') {
        price = axlePrice;
    }

    let capacity = 0;
    if (sparePartType === 'Tongue') {
        capacity = tonguesCapacity;
    } else if (sparePartType === 'Wheel') {
        capacity = wheelsCapacity;
    } else if (sparePartType === 'Axle') {
        capacity = axlesCapacity;
    }

    let buy: ((amount: number) => void) | undefined = undefined;
    if (price !== undefined && cash.amount >= price && capacity > 0) {
        buy = (amount) => {
            const a = Math.min(amount, capacity);
            if (sparePartType === 'Tongue' && !!tonguePrice) {
                dispatch(changeInventory(buyWagonTongues(inventory, a, tonguePrice)));
                dispatch(boughtSomething({ price: (price ?? 0) * a, item: 'tongues' }));
                setSelectedItem(null);
            } else if (sparePartType === 'Wheel' && !!wheelPrice) {
                dispatch(changeInventory(buyWagonWheels(inventory, a, wheelPrice)));
                dispatch(boughtSomething({ price: (price ?? 0) * a, item: 'wheels' }));
                setSelectedItem(null);
            } else if (sparePartType === 'Axle' && !!axlePrice) {
                dispatch(changeInventory(buyWagonAxles(inventory, a, axlePrice)));
                dispatch(boughtSomething({ price: (price ?? 0) * a, item: 'axles' }));
                setSelectedItem(null);
            } else {
                console.warn(`Spare part type ${sparePartType} not implemented!`);
            }
        };
    }

    let sell: ((amount: number) => void) | undefined = undefined;
    if (price !== undefined && cash.amount >= price) {
        sell = (amount) => {
            if (sparePartType === 'Tongue' && !!tonguePrice) {
                dispatch(changeInventory(sellWagonTongues(inventory, amount, determineSellingPrice(tonguePrice, stats.trading))));
                setSelectedItem(null);
            } else if (sparePartType === 'Wheel' && !!wheelPrice) {
                dispatch(changeInventory(sellWagonWheels(inventory, amount, determineSellingPrice(wheelPrice, stats.trading))));
                setSelectedItem(null);
            } else if (sparePartType === 'Axle' && !!axlePrice) {
                dispatch(changeInventory(sellWagonAxles(inventory, amount, determineSellingPrice(axlePrice, stats.trading))));
                setSelectedItem(null);
            } else {
                console.warn(`Spare part type ${sparePartType} not implemented!`);
            }
        };
    }

    useEffect(() => {
        const tongues = !!tonguePrice;
        const wheels  = !!wheelPrice;
        const axles   = !!axlePrice;
        const count = [tongues, wheels, axles].filter(e => e === true).length;

        if (tongues && !wheels && !axles && sparePartType !== 'Tongue') {
            setSparePartType('Tongue');
        } else if (!tongues && wheels && !axles && sparePartType !== 'Wheel') {
            setSparePartType('Wheel');
        } else if (!tongues && !wheels && axles && sparePartType !== 'Axle') {
            setSparePartType('Axle');
        } else if (count > 1 && sparePartType === null) {
            if (wheels) {
                setSparePartType('Wheel');
            } else if (axles) {
                setSparePartType('Axle');
            } else {
                setSparePartType('Tongue');
            }
        } else if (count === 0 && sparePartType !== null) {
            setSparePartType(null);
        }
    }, [tonguePrice, wheelPrice, axlePrice, sparePartType, setSparePartType]);

    const productName = (
        <ProductName>
            <div className="d-flex justify-content-start align-items-center">
                <div className="me-2">Spare&nbsp;Parts</div>
                { !!tonguePrice && !wheelPrice && !axlePrice && <div>Tongues</div> }
                { !tonguePrice && !!wheelPrice && !axlePrice && <div>Wheels</div> }
                { !tonguePrice && !wheelPrice && !!axlePrice && <div>Axles</div> }
                { count > 1 &&
                <Form.Select value={sparePartType ?? ''} onChange={({target}) => setSparePartType(target.value as SparePartType)}>
                    { !!tonguePrice && <option value="Tongue">Tongues</option> }
                    { !!wheelPrice && <option value="Wheel">Wheels</option> }
                    { !!axlePrice && <option value="Axle">Axles</option> }
                </Form.Select>
                }
            </div>
        </ProductName>
    );

    const productPrice = (
        <ProductPrice active={active}>
            <>
            { sparePartType === 'Tongue' && !!tonguePrice && <span>buy for {formatCash(tonguePrice)}, sell for {formatCash(determineSellingPrice(tonguePrice, stats.trading))} per tongue.</span> }
            { sparePartType === 'Wheel' && !!wheelPrice && <span>buy for {formatCash(wheelPrice)}, sell for {formatCash(determineSellingPrice(wheelPrice, stats.trading))} per wheel.</span> }
            { sparePartType === 'Axle' && !!axlePrice && <span>buy for {formatCash(axlePrice)}, sell for {formatCash(determineSellingPrice(axlePrice, stats.trading))} per axle.</span> }
            </>
        </ProductPrice>
    );
    
    const productAmount = (
        <ProductAmount>
            <>
            { sparePartType === 'Tongue' && <span>{formatWagonTongues(wagonTongues)}</span> }
            { sparePartType === 'Wheel' && <span>{formatWagonWheels(wagonWheels)}</span> }
            { sparePartType === 'Axle' && <span>{formatWagonAxles(wagonAxles)}</span> }
            </>
        </ProductAmount>
    );

    const money = (amount: number, mode: 'buy' | 'sell') => {
        const factor = mode === 'buy' ? 1 : .5;
        if (sparePartType === 'Tongue') {
            return tonguePrice * factor * amount;
        } else if (sparePartType === 'Wheel') {
            return wheelPrice * factor * amount;
        } else if (sparePartType === 'Axle') {
            return axlePrice * factor * amount;
        } else {
            return 0;
        }
    };

    const canAfford = (amount: number) => {
        if (sparePartType === 'Tongue') {
            return amount * tonguePrice <= cash.amount;
        } else if (sparePartType === 'Wheel') {
            return amount * wheelPrice <= cash.amount;
        } else if (sparePartType === 'Axle') {
            return amount * axlePrice <= cash.amount;
        } else {
            return false;
        }
    };

    const amountInStock = () => {
        if (sparePartType === 'Tongue') {
            return wagonTongues.amount;
        } else if (sparePartType === 'Wheel') {
            return wagonWheels.amount;
        } else if (sparePartType === 'Axle') {
            return wagonAxles.amount;
        } else {
            return 0;
        }
    };

    const formatUnits = (units: number) => {
        if (sparePartType === 'Tongue') {
            return formatWagonTongues(units);
        } else if (sparePartType === 'Wheel') {
            return formatWagonWheels(units);
        } else if (sparePartType === 'Axle') {
            return formatWagonAxles(units);
        } else {
            return `${units}`;
        }
    };

    const tradingButtons = (
        <TradingButtons
            amountInStock={amountInStock()}
            amountPerUnit={1}
            canAfford={canAfford}
            money={money}
            formatUnits={formatUnits}
            minAmount={0}
            maxAmount={capacity}
            buy={buy}
            sell={sell}
        />
    );

    return (
        <StoreItem
            gameIcon="tinker"
            productName={productName}
            productPrice={productPrice}
            productAmount={productAmount}
            tradingButtons={tradingButtons}
        />
    );
}