import { BanditAttack, makeBanditAttack, makeFightingResult } from "../../types/BanditAttack";
import { enableInjury, isInjured, makeRandomInjury } from "../../types/health/Injury";
import { enableAnimalAttackCooldown, enableRidersApproachingCooldown, enableThiefCooldown, enableTravelCooldown } from "../../types/Limits";
import { isLastMessageMatching, makeBrokeWagonMessage, makeFleeingFromAnimalsMessage, makeFleeingFromBanditsMessage, makeNightlyThiefMessage, makePlayerInjuredMessage, makeRidersApproachingMessage, makeWildAnimalsAttackingMessage, PlayerInjuredMessage } from "../../types/Message";
import { isDead, Player } from "../../types/Player";
import { routingCurrentLocation } from "../../types/location/Routing";
import { randomElement, randomEvent, randomInt } from "../../types/Random";
import { applyWagon, breakWagonComponent, countBrokenAxles, countBrokenWheels, hasBrokenTongue, isAnyWagonBroken } from "../../types/Wagon";
import { GameState } from "../game/gameSlice";
import { decreaseInventoryItem, InventoryItemName } from "../../types/inventory/InventoryItem";

import FightingBanditsSystem from "./FightingBanditsSystem";

export default class NpcSystem {

    static gettingRobbedByThief(game: GameState): GameState {
        let { limits, inventory, date } = game;
        const messages = [...game.messages];

        if (limits.thiefCooldownDays === 0) {
            const probabilityOfGettingRobbed = 0.04;
            if (randomEvent(probabilityOfGettingRobbed)) {
                limits = enableThiefCooldown(limits, 31);

                const candidates: InventoryItemName[] = [
                    'Clothes', 'Clothes', 'Clothes', 'Clothes',
                    'Food', 'Food',
                    'Medicine', 'Medicine',
                    'Bullets', 'Bullets', 'Bullets',
                ];

                const candidate = randomElement(candidates)!;

                if (candidate === 'Clothes' && inventory.clothes.amount > 0) {
                    const amount = randomInt(1, Math.min(4, inventory.clothes.amount));
                    const clothes = decreaseInventoryItem(inventory.clothes, amount);
                    inventory = { ...inventory, clothes };
                    messages.push(makeNightlyThiefMessage({
                        food: 0,
                        clothes: amount,
                        bullets: 0,
                        medicine: 0,
                    }, date));
                }

                else if (candidate === 'Food' && inventory.food.amount > 0) {
                    const amount = randomInt(1, Math.min(100, inventory.food.amount));
                    const food = decreaseInventoryItem(inventory.food, amount);
                    inventory = { ...inventory, food };
                    messages.push(makeNightlyThiefMessage({
                        food: amount,
                        clothes: 0,
                        bullets: 0,
                        medicine: 0,
                    }, date));
                }

                else if (candidate === 'Medicine' && inventory.medicine.amount > 0) {
                    const amount = randomInt(1, inventory.medicine.amount);
                    const medicine = decreaseInventoryItem(inventory.medicine, amount);
                    inventory = { ...inventory, medicine };
                    messages.push(makeNightlyThiefMessage({
                        food: 0,
                        clothes: 0,
                        bullets: 0,
                        medicine: amount,
                    }, date));
                }

                else if (candidate === 'Bullets' && inventory.bullets.amount > 0) {
                    const amount = randomInt(1, Math.min(200, inventory.bullets.amount));
                    const bullets = decreaseInventoryItem(inventory.bullets, amount);
                    inventory = { ...inventory, bullets };
                    messages.push(makeNightlyThiefMessage({
                        food: 0,
                        clothes: 0,
                        bullets: amount,
                        medicine: 0,
                    }, date));
                }
            }
        }

        return { ...game, inventory, limits, messages };
    }

    static gettingAttackedByWildAnimals(game: GameState): GameState {
        let { limits, animalAttack, trail, date } = game;
        const messages = [...game.messages];
        const alreadyGettingAttacked = messages.length > 0
            && (isLastMessageMatching(messages, message => message.type === 'Wild Animals Attacking')
            || isLastMessageMatching(messages, message => message.type === 'Riders Approaching'));
        
        const currentLocation = routingCurrentLocation(trail.routing);

        const wildAnimalAttackAllowed = limits.animalAttackCooldownDays === 0
            && !alreadyGettingAttacked               // not when we're already being attacked
            && currentLocation === null              // not at any location, there would be too many people
            && animalAttack === null                 // not already fighting back an attack
            && !isAnyWagonBroken(game.wagons)        // please don't attack if we're stuck in place
            && game.limits.travelCooldownDays === 0; // no attack when we're stuck in general
        
        if (wildAnimalAttackAllowed) {
            const probabilityOfGettingAttacked = 0.04;
            if (randomEvent(probabilityOfGettingAttacked)) {
                limits = enableAnimalAttackCooldown(limits, 31);
                messages.push(makeWildAnimalsAttackingMessage(date));
            }
        }

        return { ...game, limits, messages };
    }

    static ridersApproaching(game: GameState): GameState {
        let { limits, banditAttack, trail, date } = game;
        const messages = [...game.messages];
        const alreadyGettingAttacked = messages.length > 0
            && (isLastMessageMatching(messages, message => message.type === 'Wild Animals Attacking')
            || isLastMessageMatching(messages, message => message.type === 'Riders Approaching'));
        
        const currentLocation = routingCurrentLocation(trail.routing);

        const ridersApproachingAllowed = limits.ridersApproachingCooldownDays === 0
            && !alreadyGettingAttacked               // not when we're already being attacked
            && currentLocation === null              // not at any location, there would be too many people
            && banditAttack === null                 // not already fighting back an attack
            && game.limits.travelCooldownDays === 0; // no attack when we're stuck in general
        
        if (ridersApproachingAllowed) {
            const probabilityOfGettingAttacked = 0.04;
            if (randomEvent(probabilityOfGettingAttacked)) {
                limits = enableRidersApproachingCooldown(limits, 31);
                messages.push(makeRidersApproachingMessage(date));
            }
        }

        return { ...game, limits, messages };
    }

    static fleeFromWildAnimals(game: GameState): GameState {
        const { date } = game;
        const probabilityOfGettingInjured = 0.15;
        const probabilityOfBreakingWagon = 0.03;
        const messages = [...game.messages];
        
        // Injure random players.
        const injurePlayer = (addMessage: (message: PlayerInjuredMessage) => void) => (player: Player) => {
            if (!isDead(player) && !isInjured(player)) {
                if (randomEvent(probabilityOfGettingInjured)) {
                    const injury = makeRandomInjury(game.stats.health);
                    const injuredPlayer = enableInjury(player, injury);
                    addMessage(makePlayerInjuredMessage(injuredPlayer, injury, date));
                    return injuredPlayer;
                }
            }
            return player;
        };

        const players = [...game.players]
            .map(injurePlayer(message => messages.push(message)));

        // Break the wagon.
        const breakingWagon = randomEvent(probabilityOfBreakingWagon);
        let wagons = game.wagons;
        if (breakingWagon && wagons.length > 0) {
            let wagon = wagons[0];
            if (countBrokenWheels(wagon) < 4) {
                wagon = breakWagonComponent(wagon, 'Wheels');
                messages.push(makeBrokeWagonMessage(['Wheels'], date));
            } else if (countBrokenAxles(wagon) < 2) {
                wagon = breakWagonComponent(wagon, 'Axles');
                messages.push(makeBrokeWagonMessage(['Axles'], date));
            } else if (!hasBrokenTongue(wagon)) {
                wagon = breakWagonComponent(wagon, 'Tongue');
                messages.push(makeBrokeWagonMessage(['Tongue'], date));
            }
            wagons = applyWagon(wagons, wagon);
        }

        // Lose days.
        const lostDays = breakingWagon ? 0 : 3;
        const limits = enableTravelCooldown(game.limits, game.limits.travelCooldownDays + lostDays);
        messages.push(makeFleeingFromAnimalsMessage(lostDays, date));
        
        return { ...game, players, wagons, limits, messages };
    }

    static fleeFromRiders(game: GameState): GameState {
        const { inventory, players, date, wagons } = game;

        const probabilityOfFleeingSuccessfully = 0.33;
        if (randomEvent(probabilityOfFleeingSuccessfully)) {
            const messages = [...game.messages]
            messages.push(makeFleeingFromBanditsMessage(0, date));
            return { ...game, messages };
        } else {
            const attack = makeBanditAttack([]);
            const result = makeFightingResult(attack, players, inventory, wagons);
            const banditAttack: BanditAttack = { ...attack, result };
            return FightingBanditsSystem.stop({ ...game, banditAttack });
        }
    }

}