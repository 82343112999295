import { Form } from "react-bootstrap";

export default function AutoTravelSwitch({ checked, toggle }: { checked: boolean, toggle: () => void }) {
    const noop = () => {};

    return (
        <div className="w-100" onClick={toggle}>
            <Form.Check type="switch" label="Auto Travel" checked={checked} onChange={noop} />
        </div>
    );
}