import { climateNumericValue } from "../Climate";
import { Store } from "../Store";
import { rollDice } from "../Random";
import { Location, LocationStatus, LocationType, LocationVisibility, RegionName } from "./Location";
import { resourceAbundancesNumericValue } from "./Resource";

import * as townsJson from "./towns.json";
import { Inn } from "./Inn";

export type TownFacility = 'Shop' | 'Saloon' | 'Butcher' | 'Blacksmith' | 'Church' | 'Pawn Shop' | 'Pharmacy' | 'Cartography' | 'Railway Station' | 'Forge';

export interface ShopParameters {
    rarity: number;
    priceFactor: number;
}

export interface Town extends Location {
    townNumber: number;
    facilities: TownFacility[];
    shopParameters: ShopParameters | null;
    store: Store | null;
    inn: Inn | null;
    population: number;
}

function calculateTownNumber(town: Town, checkStoredValue: boolean = false): number {
    const resources = resourceAbundancesNumericValue(town.resources);
    const climateValue = climateNumericValue(town.climate);
    const climateBonus = climateValue >= 2 ? -2 * climateValue + 6 : -2;
    const townNumber = resources + climateBonus;

    if (checkStoredValue) {
        if (townNumber === undefined || isNaN(townNumber)) {
            console.warn(`Unable to calculate town number for ${town.name}, using stored value of ${town.townNumber} instead.`);
            return town.townNumber;
        }
    }

    return townNumber;
}

function determineFacilities(townNumber: number): TownFacility[] {
    switch (townNumber) {
        case -2: return [];
        case -1: return ['Shop'];
        case  0: return ['Shop', 'Saloon'];
        case  1: return ['Shop', 'Saloon', 'Butcher'];
        case  2: return ['Shop', 'Saloon', 'Butcher', 'Blacksmith'];
        case  3: return ['Shop', 'Saloon', 'Butcher', 'Blacksmith', 'Church'];
        case  4: return ['Shop', 'Saloon', 'Butcher', 'Blacksmith', 'Church', 'Pawn Shop'];
        case  5: return ['Shop', 'Saloon', 'Butcher', 'Blacksmith', 'Church', 'Pawn Shop', 'Pharmacy'];
        case  6: return ['Shop', 'Saloon', 'Butcher', 'Blacksmith', 'Church', 'Pawn Shop', 'Pharmacy', 'Cartography'];
        case  7: return ['Shop', 'Saloon', 'Butcher', 'Blacksmith', 'Church', 'Pawn Shop', 'Pharmacy', 'Cartography', 'Railway Station'];
        case  8: return ['Shop', 'Saloon', 'Butcher', 'Blacksmith', 'Church', 'Pawn Shop', 'Pharmacy', 'Cartography', 'Railway Station', 'Forge'];
        default: throw new Error(`Invalid town number ${townNumber}`);
    }
}

function determineShopRarity(townNumber: number): number {
    switch (townNumber) {
        case -1: return 0.0;
        case  0: return 0.1;
        case  1: return 0.2;
        case  2: return 0.3;
        case  3: return 0.4;
        case  4: return 0.5;
        case  5: return 0.6;
        case  6: return 0.7;
        case  7: return 0.8;
        case  8: return 0.9;
        default: throw new Error(`Invalid town number ${townNumber}`);
    }
}

function determinePriceFactor(townNumber: number): number {
    switch (townNumber) {
        case -1: return 3.0;
        case  0: return 2.5;
        case  1: return 2.0;
        case  2: return 1.5;
        case  3: return 1.0;
        case  4: return 1.0;
        case  5: return 1.5;
        case  6: return 2.0;
        case  7: return 2.5;
        case  8: return 3.0;
        default: throw new Error(`Invalid town number ${townNumber}`);
    }
}

function calculateShopParameters(townNumber: number): ShopParameters {
    const rarity = determineShopRarity(townNumber);
    const priceFactor = determinePriceFactor(townNumber);
    
    return { rarity, priceFactor };
}

function randomPopulation(townNumber: number): number {
    return (townNumber + 2) * (townNumber + 2) * 200 + rollDice(400);
}

export function resolveTown(json: Town | any): Town {
    const type: LocationType = 'Town';
    const regions: RegionName[] = ['all'];
    const status: LocationStatus = 'Pending';
    const visibility: LocationVisibility = 'visible';
    const townNumber = calculateTownNumber(json, true);
    const facilities = determineFacilities(townNumber);
    const hasShop = facilities.includes('Shop');
    const shopParameters = hasShop ? calculateShopParameters(townNumber) : null;
    const population = randomPopulation(townNumber);

    return { ...json, type, regions, status, visibility, townNumber, facilities, shopParameters, population };
}

export function loadTowns(): Town[] {
    return Array.from(townsJson).map(resolveTown);
}
