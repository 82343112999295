import { ALL_ROLES, Role } from "./Role";

export type StatName = 'Make Do'
    | 'Dexterity'
    | 'Luck'
    | 'Strength'
    | 'Trading'
    | 'Wealth'
    | 'Health'
    | 'Morale'
    | 'Stamina'
    | 'Pathfinding'
    | 'Hunting'
    | 'Experience'
    ;

export type StatCode = 'MDO'
    | 'DEX'
    | 'LUC'
    | 'STR'
    | 'TRA'
    | 'WEA'
    | 'HEA'
    | 'MOR'
    | 'STA'
    | 'PAT'
    | 'HUN'
    | 'EXP'
    ;

export const ALL_STAT_NAMES: StatName[] = ['Make Do', 'Dexterity', 'Luck', 'Strength', 'Trading', 'Wealth', 'Health', 'Morale', 'Stamina', 'Pathfinding', 'Hunting', 'Experience'];
export const ALL_STAT_CODES: StatCode[] = ['MDO', 'DEX', 'LUC', 'STR', 'TRA', 'WEA', 'HEA', 'MOR', 'STA', 'PAT', 'HUN', 'EXP'];

export function determineInitialStatPoints(stat: StatCode, role: Role): number {
    if (stat === 'EXP') {
        return 0;
    }
    
    const values = [5, 2, 1, -1, -2, -3, -3, -2, -1, 1, 2];
    const count = values.length;
    const stats = [...ALL_STAT_CODES].slice(0, count);
    const roles = [...ALL_ROLES].slice(0, count);
    const statIndex = stats.indexOf(stat);
    const roleIndex = roles.indexOf(role);
    const index = (statIndex - roleIndex + count) % count;
    
    return values[index];
}

export interface StatModifier {
    name: string;
    value: number;
    accumulatedValue: number;
    timeToLive?: number;
}

export function makeStatModifier(name: string, value: number, timeToLive?: number): StatModifier {
    const accumulatedValue = 0;
    return { name, value, accumulatedValue, timeToLive };
}

export interface Stat {
    name: StatName;
    code: StatCode;
    value: number;
    modifiers: StatModifier[];
}

export interface MakeDoStat extends Stat {
    name: 'Make Do';
    code: 'MDO';
}

export function makeMakeDoStat(value: number = 0): MakeDoStat {
    const modifiers: StatModifier[] = [];
    return { name: 'Make Do', code: 'MDO', value, modifiers };
}

export interface DexterityStat extends Stat {
    name: 'Dexterity';
    code: 'DEX';
}

export function makeDexterityStat(value: number = 0): DexterityStat {
    const modifiers: StatModifier[] = [];
    return { name: 'Dexterity', code: 'DEX', value, modifiers };
}

export interface LuckStat extends Stat {
    name: 'Luck';
    code: 'LUC';
}

export function makeLuckStat(value: number = 0): LuckStat {
    const modifiers: StatModifier[] = [];
    return { name: 'Luck', code: 'LUC', value, modifiers };
}

export interface StrengthStat extends Stat {
    name: 'Strength';
    code: 'STR';
}

export function makeStrengthStat(value: number = 0): StrengthStat {
    const modifiers: StatModifier[] = [];
    return { name: 'Strength', code: 'STR', value, modifiers };
}

export interface TradingStat extends Stat {
    name: 'Trading';
    code: 'TRA';
}

export function makeTradingStat(value: number = 0): TradingStat {
    const modifiers: StatModifier[] = [];
    return { name: 'Trading', code: 'TRA', value, modifiers };
}

export interface WealthStat extends Stat {
    name: 'Wealth';
    code: 'WEA';
}

export function makeWealthStat(value: number = 0): WealthStat {
    const modifiers: StatModifier[] = [];
    return { name: 'Wealth', code: 'WEA', value, modifiers };
}

export interface HealthStat extends Stat {
    name: 'Health';
    code: 'HEA';
}

export function makeHealthStat(value: number = 0): HealthStat {
    const modifiers: StatModifier[] = [];
    return { name: 'Health', code: 'HEA', value, modifiers };
}

export interface MoraleStat extends Stat {
    name: 'Morale';
    code: 'MOR';
}

export function makeMoraleStat(value: number = 0): MoraleStat {
    const modifiers: StatModifier[] = [];
    return { name: 'Morale', code: 'MOR', value, modifiers };
}

export interface StaminaStat extends Stat {
    name: 'Stamina';
    code: 'STA';
}

export function makeStaminaStat(value: number = 0): StaminaStat {
    const modifiers: StatModifier[] = [];
    return { name: 'Stamina', code: 'STA', value, modifiers };
}

export interface PathfindingStat extends Stat {
    name: 'Pathfinding';
    code: 'PAT';
}

export function makePathfindingStat(value: number = 0): PathfindingStat {
    const modifiers: StatModifier[] = [];
    return { name: 'Pathfinding', code: 'PAT', value, modifiers };
}

export interface HuntingStat extends Stat {
    name: 'Hunting';
    code: 'HUN';
}

export function makeHuntingStat(value: number = 0): HuntingStat {
    const modifiers: StatModifier[] = [];
    return { name: 'Hunting', code: 'HUN', value, modifiers };
}

const HUNTING_RIFLE_MOD = 'Hunting Rifle';

export function hasHuntingRifleModifier(stats: HuntingStat): boolean {
    return stats.modifiers.find(modifier => modifier.name === HUNTING_RIFLE_MOD) !== undefined;
}

export function addHuntingRifleModifier(stats: HuntingStat): HuntingStat {
    const modifier = makeStatModifier(HUNTING_RIFLE_MOD, 2);
    return addStatModifier(modifier, stats);
}

export function removeHuntingRifleModifier(stats: HuntingStat): HuntingStat {
    return removeStatModifier(HUNTING_RIFLE_MOD, stats);
}

const SNIPER_RIFLE_MOD = 'Sniper Rifle';

export function hasSniperRifleModifier(stats: HuntingStat): boolean {
    return stats.modifiers.find(modifier => modifier.name === SNIPER_RIFLE_MOD) !== undefined;
}

export function addSniperRifleModifier(stats: HuntingStat): HuntingStat {
    const modifier = makeStatModifier(SNIPER_RIFLE_MOD, 3);
    return addStatModifier(modifier, stats);
}

export function removeSniperRifleModifier(stats: HuntingStat): HuntingStat {
    return removeStatModifier(SNIPER_RIFLE_MOD, stats);
}

const COLT_MOD = '1836 Colt';

export function has1836ColtModifier(stats: Stats): boolean {
    return stats.hunting.modifiers.find(modifier => modifier.name === COLT_MOD) !== undefined
        && stats.trading.modifiers.find(modifier => modifier.name === COLT_MOD) !== undefined
        && stats.wealth.modifiers.find(modifier => modifier.name === COLT_MOD) !== undefined
        && stats.strength.modifiers.find(modifier => modifier.name === COLT_MOD) !== undefined
        && stats.health.modifiers.find(modifier => modifier.name === COLT_MOD) !== undefined;
}

export function add1836ColtModifier(stats: Stats): Stats {
    const huntingMod = makeStatModifier(COLT_MOD, 5)
    const hunting = addStatModifier(huntingMod, stats.hunting);

    const tradingMod = makeStatModifier(COLT_MOD, 2);
    const trading = addStatModifier(tradingMod, stats.trading);

    const wealthMod = makeStatModifier(COLT_MOD, 2);
    const wealth = addStatModifier(wealthMod, stats.wealth);

    const strengthMod = makeStatModifier(COLT_MOD, 1);
    const strength = addStatModifier(strengthMod, stats.strength);

    const healthMod = makeStatModifier(COLT_MOD, 1);
    const health = addStatModifier(healthMod, stats.health);

    return { ...stats, hunting, trading, wealth, strength, health };
}

export function remove1836ColtModifier(stats: Stats): Stats {
    const hunting = removeStatModifier(COLT_MOD, stats.hunting);
    const trading = removeStatModifier(COLT_MOD, stats.trading);
    const wealth = removeStatModifier(COLT_MOD, stats.wealth);
    const strength = removeStatModifier(COLT_MOD, stats.strength);
    const health = removeStatModifier(COLT_MOD, stats.health);

    return { ...stats, hunting, trading, wealth, strength, health };
}

export interface ExperienceStat extends Stat {
    name: 'Experience';
    code: 'EXP';
}

export function makeExperienceStat(value: number = 0): ExperienceStat {
    const modifiers: StatModifier[] = [];
    return { name: 'Experience', code: 'EXP', value, modifiers };
}

export interface Stats {
    makeDo: MakeDoStat;
    dexterity: DexterityStat;
    luck: LuckStat;
    strength: StrengthStat;
    trading: TradingStat;
    wealth: WealthStat;
    health: HealthStat;
    morale: MoraleStat;
    stamina: StaminaStat;
    pathfinding: PathfindingStat;
    hunting: HuntingStat;
    experience: ExperienceStat;
}

export function allStats(stats: Stats): Stat[] {
    const { makeDo, dexterity, luck, strength, trading, wealth, health, morale, stamina, pathfinding, hunting, experience } = stats;
    return [makeDo, dexterity, luck, strength, trading, wealth, health, morale, stamina, pathfinding, hunting, experience];
}

export function makeStats(role?: Role): Stats {
    if (role) {
        const makeDo = makeMakeDoStat(determineInitialStatPoints('MDO', role));
        const dexterity = makeDexterityStat(determineInitialStatPoints('DEX', role));
        const luck = makeLuckStat(determineInitialStatPoints('LUC', role));
        const strength = makeStrengthStat(determineInitialStatPoints('STR', role));
        const trading = makeTradingStat(determineInitialStatPoints('TRA', role));
        const wealth = makeWealthStat(determineInitialStatPoints('WEA', role));
        const health = makeHealthStat(determineInitialStatPoints('HEA', role));
        const morale = makeMoraleStat(determineInitialStatPoints('MOR', role));
        const stamina = makeStaminaStat(determineInitialStatPoints('STA', role));
        const pathfinding = makePathfindingStat(determineInitialStatPoints('PAT', role));
        const hunting = makeHuntingStat(determineInitialStatPoints('HUN', role));
        const experience = makeExperienceStat();

        return { makeDo, dexterity, luck, strength, trading, wealth, health, morale, stamina, pathfinding, hunting, experience };
    } else {
        const makeDo = makeMakeDoStat();
        const dexterity = makeDexterityStat();
        const luck = makeLuckStat();
        const strength = makeStrengthStat();
        const trading = makeTradingStat();
        const wealth = makeWealthStat();
        const health = makeHealthStat();
        const morale = makeMoraleStat();
        const stamina = makeStaminaStat();
        const pathfinding = makePathfindingStat();
        const hunting = makeHuntingStat();
        const experience = makeExperienceStat();

        return { makeDo, dexterity, luck, strength, trading, wealth, health, morale, stamina, pathfinding, hunting, experience };
    }
}

function advanceStatModifier(modifier: StatModifier): [StatModifier, number, boolean] {
    if (modifier.timeToLive === undefined) {
        return [modifier, 0, false]; // one-time modifier, already used
    } else if (modifier.timeToLive < 1) {
        return [modifier, 0, true]; // already expired, should remove
    } else if (modifier.accumulatedValue > 0) {
        const timeToLive = modifier.timeToLive - 1;
        return [{ ...modifier, timeToLive }, 0, false]; // already used
    } else {
        const accumulatedValue = modifier.accumulatedValue + modifier.value;
        const timeToLive = modifier.timeToLive - 1;
        return [{ ...modifier, accumulatedValue, timeToLive }, modifier.value, false];
    }
}

function advanceSingleStat<S extends Stat>(stat: S): S {
    const modifiersAndValues = stat.modifiers.map(advanceStatModifier);

    const modifiers = modifiersAndValues.map(tuple => tuple[0]);
    const valuesToAdd = modifiersAndValues.map(tuple => tuple[1]).reduce((prev, curr) => prev + curr, 0);
    const indicesToRemove = modifiersAndValues.map((tuple, index) => tuple[2] ? index : -1).filter(index => index !== -1);
    indicesToRemove.reverse();

    const modifiersToRemove = indicesToRemove.map(index => modifiers[index].name);
    const value = stat.value + valuesToAdd;
    const advancedStat: S = { ...stat, value, modifiers };

    return modifiersToRemove
        .reduce((previousStat, name) => removeStatModifier(name, previousStat), advancedStat);
}

export function advanceStats(stats: Stats): Stats {
    const makeDo = advanceSingleStat(stats.makeDo);
    const dexterity = advanceSingleStat(stats.dexterity);
    const luck = advanceSingleStat(stats.luck);
    const strength = advanceSingleStat(stats.strength);
    const trading = advanceSingleStat(stats.trading);
    const wealth = advanceSingleStat(stats.wealth);
    const health = advanceSingleStat(stats.health);
    const morale = advanceSingleStat(stats.morale);
    const stamina = advanceSingleStat(stats.stamina);
    const pathfinding = advanceSingleStat(stats.pathfinding);
    const hunting = advanceSingleStat(stats.hunting);

    return { ...stats, makeDo, dexterity, luck, strength, trading, wealth, health, morale, stamina, pathfinding, hunting };
}

export function addStatModifier<S extends Stat>(modifier: StatModifier, stat: S): S {
    const index = stat.modifiers.findIndex(m => m.name === modifier.name);
    if (index === -1) {
        const value = stat.value + modifier.value;
        const accumulatedValue = modifier.value;
        const timeToLive = modifier.timeToLive === undefined ? undefined : modifier.timeToLive - 1;
        const newModifier: StatModifier = { ...modifier, accumulatedValue, timeToLive };
        const modifiers = [...stat.modifiers, newModifier];
        return { ...stat, value, modifiers };
    } else {
        const oldModifier = stat.modifiers[index];
        const value = stat.value - oldModifier.accumulatedValue + modifier.value;
        const accumulatedValue = modifier.value;
        const timeToLive = modifier.timeToLive === undefined ? undefined : modifier.timeToLive - 1;
        const newModifier: StatModifier = { ...modifier, accumulatedValue, timeToLive };
        const modifiers = [...stat.modifiers.filter(m => m.name !== modifier.name), newModifier];
        return { ...stat, value, modifiers };
    }
}

export function removeStatModifier<S extends Stat>(name: string, stat: S): S {
    const index = stat.modifiers.findIndex(m => m.name === name);
    if (index === -1) {
        return stat;
    }

    const modifier = stat.modifiers[index];
    const value = stat.value - modifier.accumulatedValue;
    const modifiers = stat.modifiers.filter(m => m.name !== name);
    return { ...stat, value, modifiers };
}

export function applyStat<S extends Stat>(stats: Stats, stat: S): Stats {
    switch (stat.code) {
    case 'MDO': return { ...stats, makeDo: stat as MakeDoStat };
    case 'DEX': return { ...stats, dexterity: stat as DexterityStat };
    case 'LUC': return { ...stats, luck: stat as LuckStat };
    case 'STR': return { ...stats, strength: stat as StrengthStat };
    case 'TRA': return { ...stats, trading: stat as TradingStat };
    case 'WEA': return { ...stats, wealth: stat as WealthStat };
    case 'HEA': return { ...stats, health: stat as HealthStat };
    case 'MOR': return { ...stats, morale: stat as MoraleStat };
    case 'STA': return { ...stats, stamina: stat as StaminaStat };
    case 'PAT': return { ...stats, pathfinding: stat as PathfindingStat };
    case 'HUN': return { ...stats, hunting: stat as HuntingStat };
    case 'EXP': return { ...stats, experience: stat as ExperienceStat };
    }
}