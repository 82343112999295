export default function GameIcon({ icon, fixedWidth, size, className, title }: { icon: string, fixedWidth?: boolean, size?: '3x', className?: string, title?: string }) {
    let cls = `game-icon game-icon-${icon}`;
    if (className) {
        cls += ' ' + className;
    }
    if (fixedWidth) {
        cls += ' fw';
    }
    if (size) {
        cls += ` size${size}`;
    }
    
    return <i className={cls} title={title}></i>;
}