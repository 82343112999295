import { Inventory } from "../inventory/Inventory";
import { LocationName } from "./Location";
import { determineProbabilityOfTippingOver, RiverCrossing } from "./RiverCrossing";
import { Player } from "../Player";
import { randomElement, randomEvent, randomInt } from "../Random";
import { StaminaStat } from "../Stat";

export type RiverCrossingOptionName = 'Ford' | 'Float' | 'Ferry' | 'Guide';

export interface RiverCrossingOption {
    name: RiverCrossingOptionName;
    destination: LocationName;
}

export interface FordRiverCrossingOption extends RiverCrossingOption {
    name: 'Ford';
    drownedPlayerName: string | null;
    oxenGotStuck: boolean;
    lostClothesCount: number;
    lostBulletsCount: number;
    lostFoodCount: number;
    injuredPlayerName: string | null;
}

function calculateDrowningProbabilityWhenFordingRiver(depth: number): number {
    if (depth > 6) {
        return 1;
    } else if (depth > 2.5) {
        return .2;
    } else {
        return 0;
    }
}

function calculateStaminaModifier(staminaStat: StaminaStat): number {
    return Math.abs((5 - staminaStat.value) / 5);
}

export function makeFordRiverCrossingOption(riverCrossing: RiverCrossing, players: Player[], inventory: Inventory, destination: LocationName, staminaStat: StaminaStat): FordRiverCrossingOption {
    const staminaModifier = calculateStaminaModifier(staminaStat);
    const probabilityOfDrowning = calculateDrowningProbabilityWhenFordingRiver(riverCrossing.depth) * staminaModifier;
    const probabilityOfOxenGettingStuck = 0.20 * staminaModifier;
    const probabilityOfLosingInventoryWhenStuck = 0.50 * staminaModifier;
    const probabilityOfInjuryWhenStuck = 0.40 * staminaModifier;
    const probabilityOfInjuryWhenNotStuck = 0.10 * staminaModifier;

    // Will somebody drown?
    const drownedPlayer = randomEvent(probabilityOfDrowning) ? randomElement(players.filter(player => player.hp > 0)) : null;
    const drownedPlayerName = drownedPlayer?.name ?? null;

    // Will an ox get stuck?
    const oxenGotStuck = randomEvent(probabilityOfOxenGettingStuck);

    // Will we lose inventory?
    const loseInventory = oxenGotStuck && randomEvent(probabilityOfLosingInventoryWhenStuck);
    const lostClothesCount = loseInventory ? randomInt(1, inventory.clothes.amount) : 0;
    const lostBulletsCount = loseInventory ? randomInt(1, inventory.bullets.amount) : 0;
    const lostFoodCount = loseInventory ? randomInt(1, inventory.food.amount) : 0;

    // Will somebody get injured?
    const probabilityOfInjury = oxenGotStuck ? probabilityOfInjuryWhenStuck : probabilityOfInjuryWhenNotStuck;
    const injuredPlayer = randomEvent(probabilityOfInjury) ? randomElement(players.filter(player => player.hp > 0 && player.name !== drownedPlayerName)) : null;
    const injuredPlayerName = injuredPlayer?.name ?? null;

    return {
        name: 'Ford',
        drownedPlayerName,
        oxenGotStuck,
        lostClothesCount,
        lostBulletsCount,
        lostFoodCount,
        injuredPlayerName,
        destination,
    };
}

export interface FloatRiverCrossingOption extends RiverCrossingOption {
    name: 'Float';
    tippedOver: boolean;
    drownedPlayerName: string | null;
    lostClothesCount: number;
    lostBulletsCount: number;
    lostFoodCount: number;
    injuredPlayerName: string | null;
}

export function makeFloatRiverCrossingOption(riverCrossing: RiverCrossing, players: Player[], inventory: Inventory, destination: LocationName, staminaStat: StaminaStat): FloatRiverCrossingOption {
    const staminaModifier = calculateStaminaModifier(staminaStat);
    const probabilityOfTippingOver = determineProbabilityOfTippingOver(riverCrossing) * staminaModifier;
    const probabilityOfDrowningWhenTippedOver = 0.10 * staminaModifier;
    const probabilityOfLosingInventoryWhenTippedOver = 0.50 * staminaModifier;
    const probabilityOfInjuryWhenTippedOver = 0.40 * staminaModifier;
    const probabilityOfInjuryWhenNotTippedOver = 0.10 * staminaModifier;

    // Will the floating raft tip over?
    const tippedOver = randomEvent(probabilityOfTippingOver);

    // Will somebody drown?
    const drownedPlayer = tippedOver && randomEvent(probabilityOfDrowningWhenTippedOver) ? randomElement(players.filter(player => player.hp > 0)) : null;
    const drownedPlayerName = drownedPlayer?.name ?? null;

    // Will we lose inventory?
    const loseInventory = tippedOver && randomEvent(probabilityOfLosingInventoryWhenTippedOver);
    const lostClothesCount = loseInventory ? randomInt(1, inventory.clothes.amount) : 0;
    const lostBulletsCount = loseInventory ? randomInt(1, inventory.bullets.amount) : 0;
    const lostFoodCount = loseInventory ? randomInt(1, inventory.food.amount) : 0;

    // Will somebody get injured?
    const probabilityOfInjury = tippedOver ? probabilityOfInjuryWhenTippedOver : probabilityOfInjuryWhenNotTippedOver;
    const injuredPlayer = randomEvent(probabilityOfInjury) ? randomElement(players.filter(player => player.hp > 0 && player.name !== drownedPlayerName)) : null;
    const injuredPlayerName = injuredPlayer?.name ?? null;

    return {
        name: 'Float',
        tippedOver,
        drownedPlayerName,
        lostClothesCount,
        lostBulletsCount,
        lostFoodCount,
        injuredPlayerName,
        destination,
    };
}

export interface GuideRiverCrossingOption extends RiverCrossingOption {
    name: 'Guide';
    payed: boolean;
}

export function makeGuideRiverCrossingOption(payed: boolean, destination: LocationName): GuideRiverCrossingOption {
    return {
        name: 'Guide',
        payed,
        destination,
    };
}

export interface FerryRiverCrossingOption extends RiverCrossingOption {
    name: 'Ferry';
    payed: boolean;
}

export function makeFerryRiverCrossingOption(payed: boolean, destination: LocationName): FerryRiverCrossingOption {
    return {
        name: 'Ferry',
        payed,
        destination,
    };
}