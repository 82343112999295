import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSackDollar } from "@fortawesome/free-solid-svg-icons";
import { useAppSelector } from "../../../../hooks";
import { selectGame } from "../../../gameSlice";

import ProductName from "./ProductName";
import ProductAmount from "./ProductAmount";
import { formatCash } from "../../../../../types/inventory/Cash";

export default function CashStoreItem() {
    const { inventory } = useAppSelector(selectGame);
    const { cash } = inventory;

    return (
        <div className="d-flex justify-content-start align-items-center">
            <FontAwesomeIcon icon={faSackDollar} fixedWidth size="3x" className="text-lighter" />
            <div className="ms-3 flex-grow-1">
                <ProductName>Cash</ProductName>
                <ProductAmount>{formatCash(cash)}</ProductAmount>
            </div>
        </div>
    );
}