import { Button, ButtonGroup, ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faCodeCommit, faCodeFork, faExclamationTriangle, faCheck, faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { addWagon, changeWagon, removeWagon, selectGame } from "../gameSlice";
import { selectSettings } from "../../settings/settingsSlice";
import { breakWagonComponent, countBrokenAxles, countBrokenWheels, fixWagonComponent, hasBrokenTongue, makeWagon, Wagon, WagonComponent } from "../../../types/Wagon";

import GameIcon from "../../misc/GameIcon";

export default function WagonView({ expanded }: { expanded?: boolean }) {
    const dispatch = useAppDispatch();
    const { cheatMode } = useAppSelector(selectSettings);
    const { wagons } = useAppSelector(selectGame);

    const wheelCount = wagons.map(wagon => wagon.wheels.items.length).reduce((prev, curr) => prev + curr, 0);
    const brokenWheelCount = wagons.map(countBrokenWheels).reduce((prev, curr) => prev + curr, 0);
    const breakWheel = (index: number) => dispatch(changeWagon(breakWagonComponent(wagons[index], 'Wheels')));
    const fixWheel = (index: number) => dispatch(changeWagon(fixWagonComponent(wagons[index], 'Wheels')));

    const axleCount = wagons.map(wagon => wagon.wheels.items.length).reduce((prev, curr) => prev + curr, 0);
    const brokenAxleCount = wagons.map(countBrokenAxles).reduce((prev, curr) => prev + curr, 0);
    const breakAxle = (index: number) => dispatch(changeWagon(breakWagonComponent(wagons[index], 'Axles')));
    const fixAxle = (index: number) => dispatch(changeWagon(fixWagonComponent(wagons[index], 'Axles')));

    const tongueCount = wagons.length;
    const brokenTongueCount = wagons.filter(hasBrokenTongue).length;
    const breakTongue = (index: number) => dispatch(changeWagon(breakWagonComponent(wagons[index], 'Tongue')));
    const fixTongue = (index: number) => dispatch(changeWagon(fixWagonComponent(wagons[index], 'Tongue')));

    const increaseWagonCount = () => dispatch(addWagon(makeWagon("Pilgrim's Wagon")));
    const decreaseWagonCount = () => dispatch(removeWagon(wagons[wagons.length - 1]));

    return (
        <ListGroup>
            <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <div className="d-flex w-100 justify-content-between align-items-center me-2">
                    <div>
                        <GameIcon icon="old-wagon" fixedWidth className="text-lighter me-2" />
                        <strong className="d-none d-lg-inline-block text-lighter text-truncate pe-2">Wagons</strong>
                    </div>
                    <div>{formatWagons(wagons)}</div>
                </div>
                { expanded && cheatMode === 'on' &&
                    <ButtonGroup>
                        <Button variant="secondary" size="sm" onClick={increaseWagonCount}><FontAwesomeIcon icon={faPlus} fixedWidth /></Button>
                        <Button variant="secondary" size="sm" onClick={decreaseWagonCount}><FontAwesomeIcon icon={faMinus} fixedWidth /></Button>
                    </ButtonGroup>
                }
            </ListGroup.Item>
            { wagons.length > 0 &&
            <ListGroup.Item>
                <WagonComponentsView components={wagons.map(wagon => wagon.wheels)} gameIcon="cartwheel" expanded={!!expanded}
                    breakComponent={!!expanded && cheatMode === 'on' && brokenWheelCount < wheelCount ? breakWheel : undefined}
                    fixComponent={!!expanded && cheatMode === 'on' && brokenWheelCount > 0 ? fixWheel : undefined} />
            </ListGroup.Item>
            }
            { wagons.length > 0 &&
            <ListGroup.Item>
                <WagonComponentsView components={wagons.map(wagon => wagon.axles)} icon={faCodeCommit} expanded={!!expanded}
                    breakComponent={!!expanded && cheatMode === 'on' && brokenAxleCount < axleCount ? breakAxle : undefined}
                    fixComponent={!!expanded && cheatMode === 'on' && brokenAxleCount > 0 ? fixAxle : undefined} />
            </ListGroup.Item>
            }
            { wagons.length > 0 &&
            <ListGroup.Item>
                <WagonComponentsView components={wagons.map(wagon => wagon.tongue)} icon={faCodeFork} expanded={!!expanded}
                    breakComponent={!!expanded && cheatMode === 'on' && brokenTongueCount < tongueCount ? breakTongue : undefined}
                    fixComponent={!!expanded && cheatMode === 'on' && brokenTongueCount > 0 ? fixTongue : undefined} />
            </ListGroup.Item>
            }
        </ListGroup>
    );
}

interface WagonComponentsViewProps {
    components: WagonComponent[];
    expanded: boolean;
    icon?: IconDefinition;
    gameIcon?: string;
    breakComponent?: (index: number) => void;
    fixComponent?: (index: number) => void;
}

function WagonComponentsView({ components, expanded, icon, gameIcon, breakComponent, fixComponent }: WagonComponentsViewProps) {
    const getBreakFn = (index: number) => breakComponent ? () => breakComponent(index) : undefined;
    const getFixFn = (index: number) => fixComponent ? () => fixComponent(index) : undefined;

    const componentViews = components.map((component, index) => (
        <WagonComponentView key={index} component={component}
            expanded={expanded}
            breakComponent={getBreakFn(index)}
            fixComponent={getFixFn(index)}
        />
    ));

    if (components.length === 0) {
        return null;
    }

    return (
        <div className="d-flex justify-content-between align-items-center">
            <div>
                <strong>
                    { !!icon && <FontAwesomeIcon icon={icon} fixedWidth className="text-lighter me-2" style={{ fontSize: '150%' }} />  }
                    { !!gameIcon && <GameIcon icon={gameIcon} fixedWidth className="text-lighter me-2" /> }
                    <span className="d-sm-none d-md-none d-lg-inline-block text-lighter">{components[0].name}</span>
                </strong>
            </div>
            <div className="d-flex justify-content-end align-items-center">
                {componentViews}
            </div>
        </div>
    );
}

interface WagonComponentViewProps {
    component: WagonComponent;
    expanded: boolean;
    breakComponent?: () => void;
    fixComponent?: () => void;
}

function WagonComponentView({ component, expanded, breakComponent, fixComponent }: WagonComponentViewProps) {
    const borken = component.items.filter(item => item === 'broken').length > 0;
    const canBreak = expanded && !!breakComponent;
    const canFix = expanded && borken && !!fixComponent;

    const onBreakButtonClicked = () => {
        if (breakComponent) {
            breakComponent();
        }
    };

    const onFixButtonClicked = () => {
        if (fixComponent) {
            fixComponent();
        }
    };

    return (
        <>
        { borken && <div className="flex-grow-1 me-1 text-warning text-end"><FontAwesomeIcon icon={faExclamationTriangle} fixedWidth /></div> }
        { !borken && <div className="flex-grow-1 me-1 text-muted text-end"><FontAwesomeIcon icon={faCheck} fixedWidth /></div> }
        { canBreak && <Button variant="secondary" size="sm" className="ms-1" onClick={onBreakButtonClicked}>break</Button> }
        { canFix && <Button variant="secondary" size="sm" className="ms-1" onClick={onFixButtonClicked}>fix</Button> }
        </>
    );
}

function formatWagons(wagons: Wagon[]): string {
    if (wagons.length === 1) {
        return '1 wagon';
    } else {
        return `${wagons.length} wagons`;
    }
}