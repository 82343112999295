import { faMap } from "@fortawesome/free-solid-svg-icons";
import { formatCash } from "../../../../../types/inventory/Cash";
import { formatMapItem, formatMaps, getMapLODValue, MapLevelOfDetail } from "../../../../../types/inventory/Maps";
import { getMapItem } from "../../../../../types/inventory/QuestInventory";
import { RegionName } from "../../../../../types/location/Location";
import { buyMap, StoreItemName } from "../../../../../types/Store";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { boughtSomething, changeInventory, changeQuestInventory, selectGame } from "../../../gameSlice";

import ProductAmount from "./ProductAmount";
import ProductName from "./ProductName";
import ProductPrice from "./ProductPrice";
import StoreItem from "./StoreItem";
import TradingButtons from "./TradingButtons";

interface MapStoreItemProps {
    price: number;
    name: RegionName,
    levelOfDetail: MapLevelOfDetail;
    selection: [selection: StoreItemName | null, setSelection: (name: StoreItemName | null) => void];
}

export default function MapStoreItem({ price, name, levelOfDetail, selection }: MapStoreItemProps) {
    const dispatch = useAppDispatch();
    const { inventory, questInventory } = useAppSelector(selectGame);
    const { maps } = questInventory;
    const { cash } = inventory;
    const currentMapItem = getMapItem(questInventory, name);
    const available = currentMapItem === null || getMapLODValue(currentMapItem.levelOfDetail) < getMapLODValue(levelOfDetail);

    const [selectedItem, setSelectedItem] = selection;
    const active = selectedItem === 'Maps';

    let buy: ((amount: number) => void) | undefined = undefined;
    if (cash.amount >= price && available) {
        buy = (amount) => {
            const [changedInventory, changedQuestInventory] = buyMap(inventory, questInventory, name, levelOfDetail, price);
            dispatch(changeInventory(changedInventory));
            dispatch(changeQuestInventory(changedQuestInventory));
            dispatch(boughtSomething({ price: price * amount, item: 'map' }));
            setSelectedItem(null);
        };
    }

    const productName = <ProductName>Map</ProductName>;

    const productPrice = (
        <ProductPrice active={active}>
            <span>buy a map of {name} for {formatCash(price)}.</span>
        </ProductPrice>
    );

    const productAmount = <ProductAmount>{formatMaps(maps)}</ProductAmount>;

    const money = (amount: number, mode: 'buy' | 'sell') => {
        const factor = mode === 'buy' ? 1 : 0.5;
        return price * factor * amount;
    };

    const tradingButtons = (
        <TradingButtons
            amountInStock={currentMapItem === null ? 0 : 1}
            amountPerUnit={1}
            canAfford={(amount) => amount * price <= cash.amount}
            money={money}
            formatUnits={(units) => units ? formatMapItem({ regionName: name, levelOfDetail }) : ''}
            minAmount={0}
            maxAmount={1}
            buy={buy}
        />
    );

    return (
        <StoreItem
            productIcon={faMap}
            productName={productName}
            productPrice={productPrice}
            productAmount={productAmount}
            tradingButtons={tradingButtons}
        />
    );
}