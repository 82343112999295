import { useState } from "react";
import { Button, Col, Form, Image, InputGroup, Modal, Row } from "react-bootstrap";
import { TwitchEmote, useTwitchManager } from "../twitch/TwitchManager";
import { CheatMode, DEFAULT_TWITCH_COMMANDS, Settings, TwitchCommands } from "../../types/Settings";
import { useAppDispatch, useAppSelector } from "../hooks";
import { selectSettings, setSettings } from "./settingsSlice";
import { uniqueArray } from "../../types/Utilities";
import { db } from "../db";

import TwitchButtons from "../twitch/TwitchButtons";

type ValidationState = 'Empty' | 'Valid' | 'Invalid';

interface ValidationStates {
    channelName: ValidationState;
    joinCommand: ValidationState;
    leaveCommand: ValidationState;
    lurkCommand: ValidationState;
    unlurkCommand: ValidationState;
    guessCommand: ValidationState;
    cheatMode: ValidationState;
    huntingDuration: ValidationState;
}

const initialValidationStates: ValidationStates = {
    channelName: 'Empty',
    joinCommand: 'Empty',
    leaveCommand: 'Empty',
    lurkCommand: 'Empty',
    unlurkCommand: 'Empty',
    guessCommand: 'Empty',
    cheatMode: 'Empty',
    huntingDuration: 'Empty',
};

function isInvalid(states: ValidationStates): boolean {
    return states.channelName === 'Invalid'
        || states.joinCommand === 'Invalid'
        || states.leaveCommand === 'Invalid'
        || states.lurkCommand === 'Invalid'
        || states.unlurkCommand === 'Invalid'
        || states.guessCommand === 'Invalid'
        || states.cheatMode === 'Invalid'
        || states.huntingDuration === 'Invalid'
}

function makeInitialValidationStates(settings: Settings): ValidationStates {
    const validationStates: ValidationStates = { ...initialValidationStates };
    return validationStates;
}

export default function SettingsModal({ open }: { open: [boolean, (open: boolean) => void]}) {
    const dispatch = useAppDispatch();
    const settings = useAppSelector(selectSettings);
    const twitch = useTwitchManager();

    const [isOpen, setOpen] = open;
    const [channelName, setChannelName] = useState(settings.twitchChannelName);
    const [joinCommand, setJoinCommand] = useState(settings.twitchCommands.join === DEFAULT_TWITCH_COMMANDS.join ? '' : settings.twitchCommands.join);
    const [leaveCommand, setLeaveCommand] = useState(settings.twitchCommands.leave === DEFAULT_TWITCH_COMMANDS.leave ? '' : settings.twitchCommands.leave);
    const [lurkCommand, setLurkCommand] = useState(settings.twitchCommands.lurk === DEFAULT_TWITCH_COMMANDS.lurk ? '' : settings.twitchCommands.lurk);
    const [unlurkCommand, setUnlurkCommand] = useState(settings.twitchCommands.unlurk === DEFAULT_TWITCH_COMMANDS.unlurk ? '' : settings.twitchCommands.unlurk);
    const [guessCommand, setGuessCommand] = useState(settings.twitchCommands.guess === DEFAULT_TWITCH_COMMANDS.guess ? '' : settings.twitchCommands.guess);
    const [cheatMode, setCheatMode] = useState(settings.cheatMode);
    const [huntingDuration, setHuntingDuration] = useState(settings.huntingDuration);
    const [emoteNames, setEmoteNames] = useState(settings.emoteNames);
    const [emoteEditMode, setEmoteEditMode] = useState<'none' | 'selection'>('none');
    const [validationStates, setValidationStates] = useState(makeInitialValidationStates(settings));

    const invalid = isInvalid(validationStates);

    const onChannelNameChanged = (value: string) => {
        let name = (value ?? '').trim();
        if (name.startsWith('@')) {
            name = name.substring(1);
        }
        setChannelName(name);

        const empty = name === '';
        const matchesPattern = /^[a-z][a-z0-9_]*$/.test(name);
        const allowed = twitch.isChannelNameAllowed(name);
        const valid = matchesPattern && allowed;
        setValidationStates({ ...validationStates, channelName: empty ? 'Empty' : valid ? 'Valid' : 'Invalid' });
    };

    const onJoinCommandChanged = (value: string) => {
        let command = (value ?? '').trim();
        if (command.startsWith('!')) {
            command = command.substring(1);
        }
        setJoinCommand(command);

        const empty = command === '';
        const matchesPattern = /^[a-z]+$/.test(command);
        setValidationStates({ ...validationStates, joinCommand: empty ? 'Empty' : matchesPattern ? 'Valid' : 'Invalid' });
    };

    const onLeaveCommandChanged = (value: string) => {
        let command = (value ?? '').trim();
        if (command.startsWith('!')) {
            command = command.substring(1);
        }
        setLeaveCommand(command);

        const empty = command === '';
        const matchesPattern = /^[a-z]+$/.test(command);
        setValidationStates({ ...validationStates, leaveCommand: empty ? 'Empty' : matchesPattern ? 'Valid' : 'Invalid' });
    };

    const onLurkCommandChanged = (value: string) => {
        let command = (value ?? '').trim();
        if (command.startsWith('!')) {
            command = command.substring(1);
        }
        setLurkCommand(command);

        const empty = command === '';
        const matchesPattern = /^[a-z]+$/.test(command);
        setValidationStates({ ...validationStates, lurkCommand: empty ? 'Empty' : matchesPattern ? 'Valid' : 'Invalid' });
    };

    const onUnlurkCommandChanged = (value: string) => {
        let command = (value ?? '').trim();
        if (command.startsWith('!')) {
            command = command.substring(1);
        }
        setUnlurkCommand(command);

        const empty = command === '';
        const matchesPattern = /^[a-z]+$/.test(command);
        setValidationStates({ ...validationStates, unlurkCommand: empty ? 'Empty' : matchesPattern ? 'Valid' : 'Invalid' });
    };

    const onGuessCommandChanged = (value: string) => {
        let command = (value ?? '').trim();
        if (command.startsWith('!')) {
            command = command.substring(1);
        }
        setGuessCommand(command);

        const empty = command === '';
        const matchesPattern = /^[a-z]+$/.test(command);
        setValidationStates({ ...validationStates, guessCommand: empty ? 'Empty' : matchesPattern ? 'Valid' : 'Invalid' });
    };

    const onCheatModeChanged = (value: string) => {
        const cheatMode: CheatMode = value === 'on' ? 'on' : 'off';
        setCheatMode(cheatMode);
        setValidationStates({ ...validationStates, cheatMode: 'Valid' });
    };

    const onHuntingDurationChanged = (value: string | number) => {
        let duration = 0;
        if (value && typeof value === 'string') {
            duration = parseInt(value);
        } else if (value && typeof value === 'number') {
            duration = value;
        }
        if (isNaN(duration) || !isFinite(duration)) {
            duration = 0;
        }
        setHuntingDuration(duration);

        const empty = duration === 0;
        const matchesRange = duration >= 5 && duration <= 30;
        setValidationStates({ ...validationStates, huntingDuration: empty ? 'Empty' : matchesRange ? 'Valid' : 'Invalid' });
    };

    const isEmoteEnabled = (name: string) => emoteNames.includes(name);

    const toggleEmoteEnabled = (name: string) => {
        if (isEmoteEnabled(name)) {
            setEmoteNames(emoteNames.filter(n => n !== name));
        } else {
            setEmoteNames([...emoteNames, name]);
        }
    };

    const onAuthorizingWithTwitch = () => {
        dispatch(setSettings({
            ...settings,
            twitchChannelName: channelName,
        }));
    };

    const cancel = () => {
        setChannelName(settings.twitchChannelName);
        setJoinCommand(settings.twitchCommands.join === DEFAULT_TWITCH_COMMANDS.join ? '' : settings.twitchCommands.join);
        setLeaveCommand(settings.twitchCommands.leave === DEFAULT_TWITCH_COMMANDS.leave ? '' : settings.twitchCommands.leave);
        setLurkCommand(settings.twitchCommands.lurk === DEFAULT_TWITCH_COMMANDS.lurk ? '' : settings.twitchCommands.lurk);
        setUnlurkCommand(settings.twitchCommands.unlurk === DEFAULT_TWITCH_COMMANDS.unlurk ? '' : settings.twitchCommands.unlurk);
        setGuessCommand(settings.twitchCommands.guess === DEFAULT_TWITCH_COMMANDS.guess ? '' : settings.twitchCommands.guess);
        setCheatMode(settings.cheatMode);
        setHuntingDuration(settings.huntingDuration);
        setEmoteNames(settings.emoteNames);
        setValidationStates(makeInitialValidationStates(settings));
        setEmoteEditMode('none');
        setOpen(false);
    };

    const save = () => {
        if (!invalid) {
            const twitchCommands: TwitchCommands = {
                leave: leaveCommand === '' ? DEFAULT_TWITCH_COMMANDS.leave : leaveCommand,
                join: joinCommand === '' ? DEFAULT_TWITCH_COMMANDS.join : joinCommand,
                lurk: lurkCommand === '' ? DEFAULT_TWITCH_COMMANDS.lurk : lurkCommand,
                unlurk: unlurkCommand === '' ? DEFAULT_TWITCH_COMMANDS.unlurk : unlurkCommand,
                guess: guessCommand === '' ? DEFAULT_TWITCH_COMMANDS.guess : guessCommand,
            };

            const newSettings: Settings = {
                ...settings,
                twitchChannelName: channelName,
                twitchCommands,
                cheatMode,
                huntingDuration,
                emoteNames: uniqueArray(emoteNames),
            };

            setEmoteNames(newSettings.emoteNames);

            dispatch(setSettings(newSettings));
            setValidationStates(makeInitialValidationStates(newSettings));
            setEmoteEditMode('none');
            setOpen(false);
        }
    };

    const clear = () => {
        sessionStorage.removeItem('persist:root');
        localStorage.removeItem('persist:settings');
        db.deleteDatabase();
        document.location.reload();
    };

    return (
        <Modal show={isOpen} backdrop="static" keyboard={false} size="lg">
            <Modal.Header>
                <Modal.Title>Settings</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate onSubmit={save}>
                    <Form.Group as={Row}>
                        <Form.Label column>Twitch Channel</Form.Label>
                        <Col sm={6} md={8}>
                            <InputGroup>
                                <InputGroup.Text>@</InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    value={channelName}
                                    isValid={validationStates.channelName === 'Valid'}
                                    isInvalid={validationStates.channelName === 'Invalid'}
                                    onChange={({target}) => onChannelNameChanged(target.value)}
                                />
                                <Form.Control.Feedback type="invalid">This channel name is not allowed. Make sure it is spelled correctly and is one of the allowed channels.</Form.Control.Feedback>
                            </InputGroup>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column>Chat Commands</Form.Label>
                        <Col sm={6} md={8}>
                            <InputGroup>
                                <InputGroup.Text>!</InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    value={joinCommand}
                                    placeholder="join"
                                    isValid={validationStates.joinCommand === 'Valid'}
                                    isInvalid={validationStates.joinCommand === 'Invalid'}
                                    onChange={({target}) => onJoinCommandChanged(target.value)}
                                />
                                <Form.Control.Feedback type="invalid">The command name may only contain letters.</Form.Control.Feedback>
                            </InputGroup>
                            <InputGroup>
                                <InputGroup.Text>!</InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    value={leaveCommand}
                                    placeholder="leave"
                                    isValid={validationStates.leaveCommand === 'Valid'}
                                    isInvalid={validationStates.leaveCommand === 'Invalid'}
                                    onChange={({target}) => onLeaveCommandChanged(target.value)}
                                />
                                <Form.Control.Feedback type="invalid">The command name may only contain letters.</Form.Control.Feedback>
                            </InputGroup>
                            <InputGroup>
                                <InputGroup.Text>!</InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    value={lurkCommand}
                                    placeholder="lurk"
                                    isValid={validationStates.lurkCommand === 'Valid'}
                                    isInvalid={validationStates.lurkCommand === 'Invalid'}
                                    onChange={({target}) => onLurkCommandChanged(target.value)}
                                />
                                <Form.Control.Feedback type="invalid">The command name may only contain letters.</Form.Control.Feedback>
                            </InputGroup>
                            <InputGroup>
                                <InputGroup.Text>!</InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    value={unlurkCommand}
                                    placeholder="unlurk"
                                    isValid={validationStates.unlurkCommand === 'Valid'}
                                    isInvalid={validationStates.unlurkCommand === 'Invalid'}
                                    onChange={({target}) => onUnlurkCommandChanged(target.value)}
                                />
                                <Form.Control.Feedback type="invalid">The command name may only contain letters.</Form.Control.Feedback>
                            </InputGroup>
                            <InputGroup>
                                <InputGroup.Text>!</InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    value={guessCommand}
                                    placeholder="guess"
                                    isValid={validationStates.guessCommand === 'Valid'}
                                    isInvalid={validationStates.guessCommand === 'Invalid'}
                                    onChange={({target}) => onGuessCommandChanged(target.value)}
                                />
                            </InputGroup>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column>Hunting Duration</Form.Label>
                        <Col sm={6} md={8}>
                            <InputGroup>
                                <Form.Control
                                    type="number"
                                    value={huntingDuration === 0 ? '' : huntingDuration}
                                    isValid={validationStates.huntingDuration === 'Valid'}
                                    isInvalid={validationStates.huntingDuration === 'Invalid'}
                                    onChange={({target}) => onHuntingDurationChanged(target.value)}
                                    />
                                <InputGroup.Text>seconds</InputGroup.Text>
                                <Form.Control.Feedback type="invalid">The hunting duration may be between 5 and 30 seconds.</Form.Control.Feedback>
                            </InputGroup>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column>Emotes</Form.Label>
                        <Col sm={6} md={8}>
                            { emoteEditMode === 'none' &&
                                <div className="d-flex w-100 justify-content-between align-items-center">
                                <div>{ twitch.emotes.filter(emote => isEmoteEnabled(emote.name)).map(emote => <Emote key={emote.name} emote={emote} />) }</div>
                                <Button variant="secondary" size="sm" className="ms-2" onClick={() => setEmoteEditMode('selection')}>Select…</Button>
                                </div>
                            }
                            { emoteEditMode === 'selection' && twitch.emotes.map(emote => (<Emote key={emote.name} emote={emote} enabled={isEmoteEnabled(emote.name)} onClick={() => toggleEmoteEnabled(emote.name)} />)) }
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col sm={12}>
                            <Form.Check
                                type="switch"
                                label="Cheat Mode"
                                checked={cheatMode === 'on'}
                                onChange={({target}) => onCheatModeChanged(target.checked ? 'on' : 'off')}
                            />
                        </Col>
                    </Form.Group>
                </Form>
                <hr/>
                <TwitchButtons channelName={channelName} onPendingAuthorization={onAuthorizingWithTwitch} />
            </Modal.Body>
            <Modal.Footer>
                <div className="w-100 d-flex justify-content-between align-items-center">
                    <div>
                        <Button variant="danger" onClick={clear}>Clear</Button>
                    </div>
                    <div>
                        <Button variant="secondary" onClick={cancel} className="me-1">Cancel</Button>
                        <Button variant="primary" onClick={save} disabled={invalid}>OK</Button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
}


function Emote({ emote, enabled, onClick }: { emote: TwitchEmote, enabled?: boolean, onClick?: () => void }) {
    let cls = 'd-flex w-100 h-100 m-1 justify-content-center align-items-center rounded';
    if (enabled) {
        cls += ' bg-primary';
    }

    return (
        <div className="d-inline-block" onClick={onClick} style={{ width: '40px', height: '40px' }}>
            <div className={cls}>
                <Image src={emote.url} width={32} />
            </div>
        </div>
    );
}