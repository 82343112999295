import { Button, Image } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { removeEvent, selectGame } from "../gameSlice";
import { GameStartEvent } from "../../../types/GameEvent";

export default function GameStartEventView({ event }: { event: GameStartEvent }) {
    const dispatch = useAppDispatch();

    const close = () => dispatch(removeEvent(event.id));

    return (
        <div className="d-flex w-100 h-100 justify-content-between align-items-top">
            <div className="text-start p-4 d-flex flex-column justify-content-between align-items-start">
                <div className="mb-4">
                    <h3>Welcome to the Valley Lodge Quest!</h3>
                    <div className="mt-3 mb-3">
                        <StoryIntro />
                    </div>
                    <div>
                        In order to start your quest you need to buy some oxen and anything else 
                        you might need.
                    </div>
                    <div className="text-muted">
                        Click on the "Store" button to go shopping.
                    </div>
                </div>
                <Button variant="primary" onClick={close}>Close</Button>
            </div>
            <Image src="/store.png" className="rounded float-end" width={300} />
        </div>
    );
}

function StoryIntro() {
    const { role } = useAppSelector(selectGame);
    
    switch (role) {
    case 'Wayfarer':   return null;
    case 'Carpenter':  return null;
    case 'Miner':      return null;
    case 'Runaway':    return null;
    case 'Trader':     return null;
    case 'Heir':       return <HeirStoryIntro />;
    case 'Doctor':     return null;
    case 'Missionary': return null;
    case 'Pioneer':    return null;
    case 'Surveyor':   return null;
    case 'Hunter':     return null;
    }
}

function HeirStoryIntro() {
    return (
        <div>
            <h4>Episode I: The Heir</h4>
            A relative who lives far away told you a long lost cousin has just died and he 
            donated to you Valley Lodge, a large piece of land with a 17th century villa in it. 
            Sadly he didn't tell you where Valley Lodge is precisely so you have to find it.
        </div>
    );
}