import { RegionName } from "../location/Location";
import { formatAnd } from "../Utilities";
import { QuestInventoryItem } from "./QuestInventoryItem";

export type MapLevelOfDetail = 'Nothing' | 'Roads' | 'Names' | 'Details';

export interface MapItem {
    regionName: RegionName;
    levelOfDetail: MapLevelOfDetail;
}

export interface Maps extends QuestInventoryItem {
    name: 'Maps';
    items: MapItem[];
}

export function makeMaps(): Maps {
    return { name: 'Maps', items: [] };
}

export function getMapLODValue(lod: MapLevelOfDetail): number {
    switch (lod) {
        case 'Nothing': return 0;
        case 'Names': return 1;
        case 'Roads': return 2;
        case 'Details': return 3;
    }
}

export function addMap(maps: Maps, regionName: RegionName, levelOfDetail: MapLevelOfDetail): Maps {
    const index = maps.items.findIndex(map => map.regionName === regionName);
    if (index === -1) {
        const items: MapItem[] = [...maps.items, { regionName, levelOfDetail } ];
        return { ...maps, items };
    } else {
        const item = maps.items[index];
        const oldLOD = getMapLODValue(item.levelOfDetail);
        const newLOD = getMapLODValue(levelOfDetail);
        if (newLOD <= oldLOD) {
            return maps; // no change
        } else {
            const items: MapItem[] = [...maps.items];
            items[index] = { regionName, levelOfDetail };
            return { ...maps, items };
        }
    }
}

export function removeMap(maps: Maps, regionName: RegionName): Maps {
    const index = maps.items.findIndex(map => map.regionName === regionName);
    if (index === -1) {
        return maps;
    }

    const items = [...maps.items];
    items.splice(index, 1);

    return { ...maps, items };
}

export function formatMapItem(item: MapItem, includingLOD: boolean = false): string {
    const formatLOD = (lod: MapLevelOfDetail) => {
        switch (lod) {
            case 'Nothing': return '';
            case 'Names': return '¹';
            case 'Roads': return '²';
            case 'Details': return '³';
        }
    };

    if (includingLOD) {
        const name = item.regionName;
        const lod = formatLOD(item.levelOfDetail);
        return `${name} ${lod}`;
    } else {
        return item.regionName;
    }
}

export function formatMaps(maps: Maps, includingLOD: boolean = false): string {
    const items = maps.items.map(item => formatMapItem(item, includingLOD));
    return formatAnd(items, 'no maps');
}