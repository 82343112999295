import { InventoryItem } from "./InventoryItem";
import { countItemsInArray, formatAnd, POUND_PER_KILOGRAM } from "../Utilities";
import { Inventory } from "./Inventory";

export type RifleName = "Grandpa's Rifle" | 'Hunting Rifle' | 'Sniper Rifle' | '1836 Colt';

export interface Rifles extends InventoryItem {
    name: 'Rifles';
    names: RifleName[];
}

export function makeRifles(names: RifleName[] = []): Rifles {
    const amount = names.length;
    return { amount, names, name: 'Rifles' };
}

export function addRifle(name: RifleName, rifles: Rifles): Rifles {
    const names = [...rifles.names, name];
    const amount = names.length;
    return { ...rifles, names, amount };
}

export function removeRifle(name: RifleName, rifles: Rifles): Rifles {
    const names = [...rifles.names];
    const index = names.findIndex(n => n === name);
    if (index === -1) {
        return rifles;
    }

    names.splice(index, 1);
    const amount = names.length;
    return { ...rifles, names, amount };
}

export function formatRifles(rifles: Rifles | number, modifier: 'short' | 'long' = 'long'): string {
    if (typeof rifles === 'number') {
        if (rifles === 1) {
            return '1 rifle';
        } else {
            return `${rifles} rifles`;
        }
    } else if (modifier === 'long') {
        const counts = countItemsInArray(rifles.names);
        const formattedCounts = counts.map(count => {
            if (count.count === 1) {
                return count.item.toLowerCase();
            } else {
                return `${count.count} ${count.item}s`.toLowerCase();
            }
        });
        return formatAnd(formattedCounts, 'no rifles');
    } else {
        if (rifles.amount === 1) {
            return '1 rifle';
        } else {
            return `${rifles.amount} rifles`;
        }
    }
}

export function hasHuntingRifle(inventory: Inventory): boolean {
    return inventory.rifles.names.find(rifle => rifle === 'Hunting Rifle') !== undefined;
}

export function hasSniperRifle(inventory: Inventory): boolean {
    return inventory.rifles.names.find(rifle => rifle === 'Sniper Rifle') !== undefined;
}

export function has1836Colt(inventory: Inventory): boolean {
    return inventory.rifles.names.find(rifle => rifle === '1836 Colt') !== undefined;
}

function determineGrandpasRifleWeight(count: number): number {
    return count * 2 / POUND_PER_KILOGRAM;
}

function determineGrandpasRiflesForWeight(weight: number): number {
    return Math.floor(weight / 2 * POUND_PER_KILOGRAM);
}

function determineHuntingRifleWeight(count: number): number {
    return count * 2 / POUND_PER_KILOGRAM;
}

function determineHuntingRiflesForWeight(weight: number): number {
    return Math.floor(weight / 2 * POUND_PER_KILOGRAM);
}

function determineSniperRifleWeight(count: number): number {
    return count * 2 / POUND_PER_KILOGRAM;
}

function determineSniperRiflesForWeight(weight: number): number {
    return Math.floor(weight / 2 * POUND_PER_KILOGRAM);
}

function determine1836ColtWeight(count: number): number {
    return count * 2 / POUND_PER_KILOGRAM;
}

function determine1836ColtsForWeight(weight: number): number {
    return Math.floor(weight / 2 * POUND_PER_KILOGRAM);
}

export function determineRifleWeight(rifles: Rifles): number {
    return determineGrandpasRifleWeight(rifles.names.filter(name => name === "Grandpa's Rifle").length)
        + determineHuntingRifleWeight(rifles.names.filter(name => name === 'Hunting Rifle').length)
        + determineSniperRifleWeight(rifles.names.filter(name => name === 'Sniper Rifle').length)
        + determine1836ColtWeight(rifles.names.filter(name => name === '1836 Colt').length);
}

export function determineRiflesForWeight(weight: number, name: RifleName): number {
    switch (name) {
        case "Grandpa's Rifle": return determineGrandpasRiflesForWeight(weight);
        case 'Hunting Rifle':   return determineHuntingRiflesForWeight(weight);
        case 'Sniper Rifle':    return determineSniperRiflesForWeight(weight);
        case '1836 Colt':       return determine1836ColtsForWeight(weight);
    }
}
