export interface Limits {
    travelCooldownDays: number;
    thiefCooldownDays: number;
    animalAttackCooldownDays: number;
    ridersApproachingCooldownDays: number;
    findingAbandonedWagonCooldownDays: number;
}

export function makeLimits(): Limits {
    return {
        travelCooldownDays: 0,
        thiefCooldownDays: 0,
        animalAttackCooldownDays: 0,
        ridersApproachingCooldownDays: 0,
        findingAbandonedWagonCooldownDays: 0,
    };
}

export function enableTravelCooldown(limits: Limits, travelCooldownDays: number): Limits {
    return { ...limits, travelCooldownDays };
}

export function enableThiefCooldown(limits: Limits, thiefCooldownDays: number): Limits {
    return { ...limits, thiefCooldownDays };
}

export function enableAnimalAttackCooldown(limits: Limits, animalAttackCooldownDays: number): Limits {
    return { ...limits, animalAttackCooldownDays };
}

export function enableRidersApproachingCooldown(limits: Limits, ridersApproachingCooldownDays: number): Limits {
    return { ...limits, ridersApproachingCooldownDays };
}

export function enableFindingAbandonedWagonCooldown(limits: Limits, findingAbandonedWagonCooldownDays: number): Limits {
    return { ...limits, findingAbandonedWagonCooldownDays };
}

export function advanceLimits(limits: Limits): Limits {
    const travelCooldownDays = Math.max(0, limits.travelCooldownDays - 1);
    const thiefCooldownDays = Math.max(0, limits.thiefCooldownDays - 1);
    const animalAttackCooldownDays = Math.max(0, limits.animalAttackCooldownDays - 1);
    const ridersApproachingCooldownDays = Math.max(0, limits.ridersApproachingCooldownDays - 1);
    const findingAbandonedWagonCooldownDays = Math.max(0, limits.findingAbandonedWagonCooldownDays - 1);
    return { ...limits, travelCooldownDays, thiefCooldownDays, animalAttackCooldownDays, ridersApproachingCooldownDays, findingAbandonedWagonCooldownDays };
}