import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Image, ListGroup, Modal, Overlay, Tooltip } from "react-bootstrap";
import { ChestLock, ChestLockTry } from "../../../../../types/ChestLock";
import { makePlayer, Player } from "../../../../../types/Player";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { selectSettings } from "../../../../settings/settingsSlice";
import { TwitchEmote, useTwitchManager } from "../../../../twitch/TwitchManager";
import { closeChest, selectGame } from "../../../gameSlice";

import StyledMessageView from "../../../message/StyledMessageView";
import AvatarImage from "../../../passenger/AvatarImage";

interface OpenChestLockModalProps {
    lock: ChestLock;
    open: [boolean, (open: boolean) => void];
}

let __toggleNextSymbolTooltipTimer: any | null = null;

export default function OpenChestLockModal({ lock, open }: OpenChestLockModalProps) {
    const dispatch = useAppDispatch();
    const { players, messages } = useAppSelector(selectGame);
    const { cheatMode, twitchCommands } = useAppSelector(selectSettings);
    const [isAnswerVisible, setAnswerVisible] = useState(false);
    const scrollAreaRef = useRef<HTMLDivElement | null>(null);
    const [symbolIndex, setSymbolIndex] = useState(0);
    const twitch = useTwitchManager();

    const [isModalOpen,] = open;
    const lastMessage = messages[messages.length - 1];

    const allSymbols = lock.availableSymbols.map((symbol, index) => <SymbolView key={index} symbol={symbol} emotes={twitch.emotes} showTooltip={symbolIndex === index} />);
    const expectedSymbols = lock.expectedSymbols.map((symbol, index) => <SymbolView key={index} symbol={symbol} emotes={twitch.emotes} />);
    const availableSymbolsCount = lock.availableSymbols.length;

    const toggleNextSymbolTooltip = useCallback(() => setSymbolIndex((symbolIndex + 1) % availableSymbolsCount), [symbolIndex, setSymbolIndex, availableSymbolsCount]);

    const onSymbolsClicked = () => {
        if (cheatMode === 'on') {
            setAnswerVisible(!isAnswerVisible);
        }
    };

    useEffect(() => {
        const top = (lock.tries.length - 5) * 50;
        const scrollArea = scrollAreaRef.current;
        if (top > 0 && scrollArea) {
            scrollArea.scrollTo({ top });
        }
    }, [scrollAreaRef, lock]);

    useEffect(() => {
        if (__toggleNextSymbolTooltipTimer === null) {
            __toggleNextSymbolTooltipTimer = setTimeout(() => toggleNextSymbolTooltip(), 3000);
        }
        return () => {
            clearTimeout(__toggleNextSymbolTooltipTimer);
            __toggleNextSymbolTooltipTimer = null;
        };
    }, [toggleNextSymbolTooltip]);

    const guesses = lock.tries.map((guess, index) => (
        <ListGroup.Item key={index}>
            <GuessView guess={guess} players={players} emotes={twitch.emotes} />
        </ListGroup.Item>
    ));

    for (let index = guesses.length; index < lock.maxTries; ++index) {
        guesses.push((
            <ListGroup.Item key={index}>
                <div className="text-muted text-uppercase p-2">Guess {index + 1}</div>
            </ListGroup.Item>
        ));
    }

    const onCloseClicked = () => dispatch(closeChest());

    return (
        <Modal show={isModalOpen} backdrop="static">
            <Modal.Header>
                <Modal.Title className="d-flex w-100 justify-content-between align-items-center">
                    <div>Open Chest Lock</div>
                    <div className="text-uppercase fs-6"><span className={lock.result === 'locked' ? 'text-danger' : 'text-success'}>{ lock.result }</span></div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center pb-5">
                <div onClick={onSymbolsClicked}>
                    { isAnswerVisible && <div>{expectedSymbols}</div>}
                    { !isAnswerVisible && <div>{allSymbols}</div> }
                </div>
                <hr/>
                <div>Find the {lock.expectedSymbols.length} emotes in the right order.</div>
                <div>Write <span className="text-warning">!{twitchCommands.guess}</span> followed by emotes in chat.</div>
                <div className="rounded" ref={scrollAreaRef} style={{ height: '400px', overflow: 'auto' }}>
                    <ListGroup>{guesses}</ListGroup>
                </div>
                <hr/>
                <StyledMessageView text={lastMessage.text} />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onCloseClicked}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

function GuessView({ guess, players, emotes }: { guess: ChestLockTry, players: Player[], emotes: TwitchEmote[] }) {
    const player: Player = players.find(p => p.name.toLowerCase() === guess.playerName?.toLowerCase()) ?? makePlayer(guess.playerName ?? '', true);

    const symbols = guess.symbols.map((symbol, index) => <SymbolView key={index} symbol={symbol} emotes={emotes} />);

    return (
        <div className="d-flex w-100 justify-content-between align-items-center">
            <AvatarImage player={player} size={48} />
            <div className="text-center">{symbols}</div>
            <div style={{ width: '48px' }}>
                <div className="text-success">{guess.numberOfCorrectSymbolsInCorrectPlace}</div>
                <div className="text-warning">{guess.numberOfCorrectSymbolsInWrongPlace}</div>
            </div>
        </div>
    );
}

function SymbolView({ symbol, emotes, showTooltip }: { symbol: string, emotes: TwitchEmote[], showTooltip?: boolean }) {
    const imageRef = useRef<any | null>(null);

    const emote = emotes.find(e => e.name === symbol);
    const url = emote?.url;

    if (url === undefined) {
        if (emotes.length > 0) {
            console.warn(`Emote ${symbol} not found!`);
        }
        return null;
    }

    return (
        <>
        <Image ref={imageRef} src={url} width={48} title={symbol} className="ms-2" />
        { showTooltip !== undefined &&
        <Overlay target={imageRef.current} show={showTooltip} placement="bottom">
            {(props) => (
                <Tooltip {...props}>{symbol}</Tooltip>
            )}
        </Overlay>
        }
        </>
    );
}
