import { useState } from "react";
import { Image } from "react-bootstrap";
import { isSick } from "../../../types/health/Disease";
import { isInjured } from "../../../types/health/Injury";
import { isDead, Player } from "../../../types/Player";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { giveMedicine, selectGame } from "../gameSlice";

export default function AvatarImage({ player, size }: { player: Player, size?: number }) {
    const dispatch = useAppDispatch();
    const { inventory, status } = useAppSelector(selectGame);
    const [isHovering, setHovering] = useState(false);

    const sick = isSick(player);
    const injured = isInjured(player);
    const dead = isDead(player);
    const naked = !!player.naked;
    const medicineAvailable = !dead && (sick || injured) && inventory.medicine.amount > 0 && status === 'Playing';
    const url = (isHovering && medicineAvailable) ? '/medicine.png' : player.avatarURL ?? '/avatar-placeholder.png';

    const classNames: string[] = ['avatar-image'];
    if (dead) {
        classNames.push('monochrome');
    } else if (naked) {
        classNames.push('blurred');
    }
    if (!!size && size > 90) {
        classNames.push('large');
    }
    const className = classNames.join(' ');
    
    const onImageClicked = () => {
        if (medicineAvailable) {
            dispatch(giveMedicine(player.name));
        }
    };

    const onHover = () => setHovering(true);
    const onUnhover = () => setHovering(false);

    return (
        <Image src={url} fluid roundedCircle width={size ?? 32}
            className={className}
            onClick={onImageClicked}
            onMouseEnter={onHover}
            onMouseLeave={onUnhover} />
    );
}