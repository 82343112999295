import { useEffect, useState } from "react";
import { Button, ListGroup, Modal } from "react-bootstrap";
import { makeEmptyStore, StoreItemName } from "../../../../../types/Store";
import { useAppSelector } from "../../../../hooks";
import { selectSettings } from "../../../../settings/settingsSlice";
import { RiflesStoreItem } from "./RiflesStoreItem";
import { selectGame } from "../../../gameSlice";
import { routingCurrentLocation } from "../../../../../types/location/Routing";
import { Town } from "../../../../../types/location/Town";

import MapStoreItem from "./MapStoreItem";
import OxStoreItem from "./OxStoreItem";
import MedicineStoreItem from "./MedicineStoreItem";
import ClothesStoreItem from "./ClothesStoreItem";
import BulletsStoreItem from "./BulletsStoreItem";
import WaterStoreItem from "./WaterStoreItem";
import FoodStoreItem from "./FoodStoreItem";
import CashStoreItem from "./CashStoreItem";
import SparePartsStoreItem from "./SparePartsStoreItem";
import WagonStoreItem from "./WagonStoreItem";

export default function StoreModal({ open, close }: { open: boolean, close: () => void }) {
    const { trail } = useAppSelector(selectGame);
    const { cheatMode } = useAppSelector(selectSettings);
    const location = routingCurrentLocation(trail.routing);
    const town = location?.type === 'Town' ? location as Town : null;
    const store = town?.store ?? null;
    const { name, foodPrice, waterBottlePrice, waterCanisterPrice, bulletPrice, clothesPrice, medicinePrice, wagonTonguePrice, wagonWheelPrice, wagonAxlePrice, oxPrice, map, pilgrimsWagonPrice, baseWagonPrice, largeWagonPrice, doubleBaseWagonPrice, doubleLargeWagonPrice, grandpasRiflePrice, huntingRiflePrice, sniperRiflePrice } = store ?? makeEmptyStore('Store');

    const [selectedItem, setSelectedItem] = useState<StoreItemName | null>(null);

    useEffect(() => {
        if (open) {
            setSelectedItem(null);
        }
    }, [open, setSelectedItem]);

    if (store === null) {
        return null;
    }

    return (
        <Modal show={open} onHide={close} size="xl">
            <Modal.Header>
                <Modal.Title>{name}</Modal.Title>
                { cheatMode === 'on' && <small className="text-muted">[rarity: {store.shopParameters.rarity}, price factor: {store.shopParameters.priceFactor}]</small> }
            </Modal.Header>
            <Modal.Body>
                <ListGroup>
                    <ListGroup.Item>
                        <CashStoreItem />
                    </ListGroup.Item>
                    { !!foodPrice &&
                    <ListGroup.Item active={selectedItem === 'Food'}>
                        <FoodStoreItem price={foodPrice} selection={[selectedItem, setSelectedItem]} />
                    </ListGroup.Item>
                    }
                    { (!!waterBottlePrice || !!waterCanisterPrice) &&
                    <ListGroup.Item active={selectedItem === 'Water'}>
                        <WaterStoreItem bottlePrice={waterBottlePrice ?? 0} canisterPrice={waterCanisterPrice ?? 0} selection={[selectedItem, setSelectedItem]} />
                    </ListGroup.Item>
                    }
                    { !!bulletPrice &&
                    <ListGroup.Item active={selectedItem === 'Bullets'}>
                        <BulletsStoreItem price={bulletPrice} selection={[selectedItem, setSelectedItem]} />
                    </ListGroup.Item>
                    }
                    { (!!grandpasRiflePrice || !!huntingRiflePrice || !!sniperRiflePrice) &&
                    <ListGroup.Item active={selectedItem === 'Rifles'}>
                        <RiflesStoreItem grandpasRiflePrice={grandpasRiflePrice ?? 0} huntingRiflePrice={huntingRiflePrice ?? 0} sniperRiflePrice={sniperRiflePrice ?? 0} selection={[selectedItem, setSelectedItem]} />
                    </ListGroup.Item>
                    }
                    { !!clothesPrice &&
                    <ListGroup.Item active={selectedItem === 'Clothes'}>
                        <ClothesStoreItem price={clothesPrice} selection={[selectedItem, setSelectedItem]} />
                    </ListGroup.Item>
                    }
                    { !!medicinePrice &&
                    <ListGroup.Item active={selectedItem === 'Medicine'}>
                        <MedicineStoreItem price={medicinePrice} selection={[selectedItem, setSelectedItem]} />
                    </ListGroup.Item>
                    }
                    { (!!wagonTonguePrice || !!wagonWheelPrice || !!wagonAxlePrice) &&
                    <ListGroup.Item active={selectedItem === 'Wagon Tongues' || selectedItem === 'Wagon Wheels' || selectedItem === 'Wagon Axles'}>
                        <SparePartsStoreItem tonguePrice={wagonTonguePrice ?? 0} wheelPrice={wagonWheelPrice ?? 0} axlePrice={wagonAxlePrice ?? 0} selection={[selectedItem, setSelectedItem]} />
                    </ListGroup.Item>
                    }
                    { !!oxPrice &&
                    <ListGroup.Item active={selectedItem === 'Oxen'}>
                        <OxStoreItem price={oxPrice} selection={[selectedItem, setSelectedItem]} />
                    </ListGroup.Item>
                    }
                    { !!map &&
                    <ListGroup.Item active={selectedItem === 'Maps'}>
                        <MapStoreItem price={map.price} name={map.name} levelOfDetail={map.levelOfDetail} selection={[selectedItem, setSelectedItem]} />
                    </ListGroup.Item>
                    }
                    { (!!pilgrimsWagonPrice || !!baseWagonPrice || !!largeWagonPrice || !!doubleBaseWagonPrice || !!doubleLargeWagonPrice) &&
                    <ListGroup.Item active={selectedItem === 'Pilgrims Wagon'}>
                        <WagonStoreItem pilgrimsWagonPrice={pilgrimsWagonPrice ?? 0} baseWagonPrice={baseWagonPrice ?? 0} largeWagonPrice={largeWagonPrice ?? 0} doubleBaseWagonPrice={doubleBaseWagonPrice ?? 0} doubleLargeWagonPrice={doubleLargeWagonPrice ?? 0} selection={[selectedItem, setSelectedItem]} />
                    </ListGroup.Item>
                    }
                </ListGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={close}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}