import { Achievement, makeAchievement, makeDefaultAchievements, makeLegendary1836ColtAchievement } from "../../types/Achievement";
import { makeUnlockedAchievementEvent } from "../../types/GameEvent";
import { makeUnlockedAchievementMessage } from "../../types/Message";
import { db } from "../db";
import { GameState } from "../game/gameSlice";

export default class AchievementSystem {

    private static achievementLoadingState: 'loading' | 'loaded' | null = null;
    private static cachedAchievements: Achievement[] = [];

    static get defaultAchievements(): Achievement[] {
        return makeDefaultAchievements();
    }

    static get isAchievementsActive(): boolean {
        if (this.achievementLoadingState === null) {
            this.achievementLoadingState = 'loading';
            db.achievements.toArray()
                .then(achievements => {
                    this.achievementLoadingState = 'loaded';
                    this.cachedAchievements = achievements;
                })
                .catch(console.error);
        }
        return process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test';
    }

    private static unlockAchievement(achievement: Achievement, game: GameState): GameState {
        if (!this.isAchievementsActive) {
            return game;
        }

        if (this.cachedAchievements.findIndex(a => a.name === achievement.name) !== -1) {
            return game;
        }
        
        db.createAchievement(achievement);

        const message = makeUnlockedAchievementMessage(achievement, game.date);
        const event = makeUnlockedAchievementEvent(achievement);

        const achievements = [...game.achievements, achievement];
        const messages = [...game.messages, message];
        const events = [...game.events, event];

        return { ...game, achievements, messages, events };
    }

    static unlockDemoAchievement(name: string, game: GameState): GameState {
        const achievement = makeAchievement(name, 'Vel libero inventore sit doloribus quos distinctio rerum. Maiores est ipsum earum qui aut. Nulla ut vel sit aut sit velit. Similique reiciendis voluptas ut sint. Vitae aspernatur consequatur quia minus ab. Quia quos nemo sint.', null);
        return this.unlockAchievement(achievement, game);
    }

    static unlock1836Colt(game: GameState): GameState {
        const achievement = makeLegendary1836ColtAchievement();
        return this.unlockAchievement(achievement, game);
    }

}