import { formatBullets } from "../../../../../types/inventory/Bullets";
import { formatCash } from "../../../../../types/inventory/Cash";
import { calculateBulletsCapacity } from "../../../../../types/inventory/Inventory";
import { buyBullets, determineSellingPrice, sellBullets, StoreItemName } from "../../../../../types/Store";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { boughtSomething, changeInventory, selectGame } from "../../../gameSlice";

import ProductAmount from "./ProductAmount";
import ProductName from "./ProductName";
import ProductPrice from "./ProductPrice";
import StoreItem from "./StoreItem";
import TradingButtons from "./TradingButtons";

interface BulletsStoreItemProps {
    price: number;
    selection: [selection: StoreItemName | null, setSelection: (name: StoreItemName | null) => void];
}

export default function BulletsStoreItem({ price, selection }: BulletsStoreItemProps) {
    const dispatch = useAppDispatch();
    const { inventory, stats, wagons, players } = useAppSelector(selectGame);
    const { cash, bullets } = inventory;

    const [selectedItem, setSelectedItem] = selection;
    const active = selectedItem === 'Bullets';
    const bulletsPerBox = 20;
    const capacity = calculateBulletsCapacity(inventory, wagons, players);

    let buy: ((amount: number) => void) | undefined = undefined;
    if (cash.amount >= price && capacity >= bulletsPerBox) {
        buy = (amount) => {
            const a = Math.min(amount, capacity);
            dispatch(changeInventory(buyBullets(inventory, a, price)));
            dispatch(boughtSomething({ price: price * a, item: 'bullets' }));
            setSelectedItem(null);
        };
    }

    let sell: ((amount: number) => void) | undefined = undefined;
    if (bullets.amount > 0) {
        sell = (amount) => {
            dispatch(changeInventory(sellBullets(inventory, amount, determineSellingPrice(price, stats.trading))));
            setSelectedItem(null);
        };
    }

    const productName = <ProductName>Bullets</ProductName>;

    const productPrice = (
        <ProductPrice active={active}>
            <span>buy for {formatCash(price * bulletsPerBox)}, sell for {formatCash(determineSellingPrice(price, stats.trading) * bulletsPerBox)} per box, with {formatBullets(bulletsPerBox)} per box.</span>
        </ProductPrice>
    );

    const productAmount = <ProductAmount>{formatBullets(bullets)}</ProductAmount>;

    const money = (amount: number, mode: 'buy' | 'sell') => {
        const factor = mode === 'buy' ? 1 : 0.5;
        return price * factor * amount;
    };

    const tradingButtons = (
        <TradingButtons
            amountInStock={bullets.amount}
            amountPerUnit={bulletsPerBox}
            canAfford={(amount) => amount * price <= cash.amount}
            money={money}
            formatUnits={(units) => formatBullets(units * bulletsPerBox)}
            minAmount={0}
            maxAmount={capacity}
            buy={buy}
            sell={sell}
        />
    );

    return (
        <StoreItem
            gameIcon="bullets"
            productName={productName}
            productPrice={productPrice}
            productAmount={productAmount}
            tradingButtons={tradingButtons}
        />
    );
}