import { ChatUserstate, Client } from "tmi.js";
import { format } from "date-fns";
import { uniqueArray } from "../../types/Utilities";
import { randomString } from "../../types/Random";
import { createContext, useContext } from "react";

const ALLOWED_CHANNELS = new Set(['jabu10245', 'benez256']);

export interface TwitchOAuthContext {
    clientID: string;
    username: string;
    userID: string;
    scopes: string[];
    expiresIn: number;
}

export interface TwitchUserInfo {
    name: string;
    id: string;
    avatarURL?: string;
}

export interface TwitchEmote {
    id: string;
    name: string;
    url: string;
    type: 'global' | 'follower' | 'subscriber' | 'bitstier' | string;
}

export interface TwitchMessageEvent {
    channel: string;
    message: string;
    userstate: ChatUserstate;
    client: Client;
}

export interface TwitchConnectEvent {
    channel: string;
    username: string;
    client: Client;
}

export interface TwitchDisconnectEvent {}

type TwitchEventName = 'connect' | 'disconnect' | 'message';

export interface TwitchEventListener {
    (event: any): void;
}

class NamedTwitchEventListener {
    readonly listenerName: string;
    readonly eventName: TwitchEventName;
    readonly eventListener: TwitchEventListener;

    constructor(listenerName: string, eventName: TwitchEventName, eventListener: TwitchEventListener) {
        this.listenerName = listenerName;
        this.eventName = eventName;
        this.eventListener = eventListener;
    }

    notifyMessageEvent(event: TwitchMessageEvent) {
        if (this.eventName === 'message') {
            this.eventListener(event);
        }
    }

    notifyConnectEvent(event: TwitchConnectEvent) {
        if (this.eventName === 'connect') {
            this.eventListener(event);
        }
    }

    notifyDisconnectEvent(event: TwitchDisconnectEvent) {
        if (this.eventName === 'disconnect') {
            this.eventListener(event);
        }
    }
}

class TwitchManager {

    private _client: Client | null = null;
    private _connecting = false;
    private _enabled = true;
    private _listeners: NamedTwitchEventListener[] = [];
    private _emotes: TwitchEmote[] | null = null;

    get token(): string | null {
        return OAuthToken.restore();
    }

    get context(): TwitchOAuthContext | null {
        return OAuthContext.restore();
    }

    get client(): Client | null {
        return this._client;
    }

    get enabled(): boolean {
        return this._enabled;
    }

    set enabled(enabled: boolean) {
        this._enabled = enabled;
    }

    get connected(): boolean {
        return this._client !== null;
    }

    get connecting(): boolean {
        return this._connecting;
    }

    get channelName(): string | null {
        return ChannelName.restore();
    }
    
    get emotes(): TwitchEmote[] {
        return uniqueArray(this._emotes ?? [], (lhs, rhs) => lhs.name === rhs.name);
    }

    printLog(clear: boolean = false) {
        OAuthLog.restore().forEach(printLogEntry);
        if (clear) {
            OAuthLog.store([]);
        }
    }

    log(message: string) {
        const entry = OAuthLog.append(message);
        printLogEntry(entry);
    }

    addEventListener(name: string, eventName: TwitchEventName, listener: TwitchEventListener) {
        const index = this._listeners.findIndex(l => l.listenerName === name && l.eventName === eventName);
        if (index === -1) {
            this._listeners.push(new NamedTwitchEventListener(name, eventName, listener));
        } else {
            this._listeners[index] = new NamedTwitchEventListener(name, eventName, listener);
        }
    }

    removeEventListener(name: string, eventName: TwitchEventName) {
        this._listeners = this._listeners
            .filter(l => l.listenerName !== name || l.eventName !== eventName);
    }

    isChannelNameAllowed(channelName: string): boolean {
        return !!channelName?.length && ALLOWED_CHANNELS.has(channelName);
    }

    requestAuthorization(channelName: string) {
        const scope = ['chat:read', 'chat:edit'].join(' ');
        const state = randomString(20);
        const clientID = process.env.REACT_APP_TWITCH_CLIENT_ID ?? '';
        const redirectURI = process.env.NODE_ENV === 'production' ? 'https://valleylodge.quest' : 'http://localhost:3000';

        this.log(`requesting authorization for ${channelName}`);

        ChannelName.store(channelName);
        OAuthState.store(state);

        const params = new URLSearchParams();
        params.append('response_type', 'token');
        params.append('client_id', clientID);
        params.append('redirect_uri', redirectURI);
        params.append('scope', scope);
        params.append('state', state);

        const url = `https://id.twitch.tv/oauth2/authorize?${params.toString()}`;
        window.location.href = url;
    }

    processAuthorization() {
        const expectedState = OAuthState.restore();
        if (expectedState === null) {
            return;
        }

        const hash = window.location.hash;
        if (!!hash?.length && hash.length) {
            OAuthState.store(null);

            const params = new URLSearchParams(hash.substring(1));
            const token = params.get('access_token');
            const state = params.get('state');
            if (token === null || state !== expectedState) {
                return;
            }
            
            OAuthToken.store(token);
            this.log('Accepted oauth token');

            // redirect to prevent the token to be leaked.
            const url = process.env.NODE_ENV === 'production' ? 'https://valleylodge.quest/' : 'http://localhost:3000/';
            setTimeout(() => window.location.href = url, 800);
        }
    }

    deauthorize() {
        this.disconnect();

        const token = OAuthToken.restore();
        const context = OAuthContext.restore();
        if (token && context) {
            TwitchAPI.revokeToken(context.clientID, token);
        }

        OAuthToken.store(null);
        OAuthContext.store(null);
        this.log('deauthorized oauth token');
    }

    connect() {
        const token = OAuthToken.restore();
        const channelName = ChannelName.restore();

        if (token && channelName && this._client === null && !this._connecting && this._enabled) {
            this._connecting = true;
            this.log(`Connecting to ${channelName}`);

            const connectToChat = async (context: TwitchOAuthContext) => {
                OAuthContext.store(context);
                const username = context.username;
                const scopes = context.scopes.join(', ');
                this.log(`Connected to Twitch as ${username} with ${scopes}`);

                const client = createChatClient(channelName, username, token);
                await client.connect();

                return client;
            };

            const registerClient = (client: Client) => {
                const username = OAuthContext.restore()?.username ?? '';
                this._client = client;
                this._connecting = false;
                this.onConnect(channelName, username, client);

                client.on('message', (channel, userstate, message, self) => {
                    if (!self) {
                        this.onMessage(channel, message, userstate, client);
                    }
                });
            };

            const collectEmotes = async () => {
                const token = OAuthToken.restore();
                const context = OAuthContext.restore();
                if (!token || !context) {
                    return;
                }

                const globalEmotes = await TwitchAPI.getGlobalEmotes(context.clientID, token);
                const channelEmotes = await TwitchAPI.getChannelEmotes(channelName, context.clientID, token);

                this._emotes = [...globalEmotes, ...channelEmotes];
            };

            const reportError = (error: Error | any) => {
                this._connecting = false;
                console.error(error);
            };

            TwitchAPI.validateToken(token)
                .then(connectToChat)
                .then(registerClient)
                .then(collectEmotes)
                .catch(reportError);
        }
    }

    disconnect() {
        const client = this._client;
        this._client = null;
        
        if (client) {
            client.disconnect()
                .then(_ => this.onDisconnect());
        }
    }

    private onConnect(channel: string, username: string, client: Client) {
        this.log(`Connected to chat in ${channel} as ${username}`);
        const event: TwitchConnectEvent = { channel, username, client };
        this._listeners.forEach(listener => listener.notifyConnectEvent(event));
    }

    private onDisconnect() {
        this.log(`Disconnected from chat`);
        const event: TwitchDisconnectEvent = {};
        this._listeners.forEach(listener => listener.notifyDisconnectEvent(event));
    }

    private onMessage(channel: string, message: string, userstate: ChatUserstate, client: Client) {
        const event: TwitchMessageEvent = { channel, message, userstate, client };
        this._listeners.forEach(listener => listener.notifyMessageEvent(event));
    }

    sendMessage(message: string): boolean {
        const client = this._client;
        if (!client) {
            console.warn(`Won't send message "${message}", because we're not connected to chat.`);
            return false;
        }

        const channel = ChannelName.restore();
        if (!channel) {
            console.warn(`Won't send message "${message}", because we don't have a channel name.`);
            return false;
        }

        client.say(channel, message);
        return true;
    }

    async getUser(name: string): Promise<TwitchUserInfo> {
        const token = OAuthToken.restore();
        const context = OAuthContext.restore();
        if (!token || !context) {
            throw new Error('Not authorized to use Twitch');
        }

        return await TwitchAPI.getTwitchUser(name, context.clientID, token);
    }

}

interface OAuthLogEntry {
    date: number;
    message: string;
}

function printLogEntry(entry: OAuthLogEntry) {
    const time = format(entry.date, 'HH:mm');
    console.log(`[${time}] Twitch: ${entry.message}`);
}

class OAuthLog {

    static readonly key = 'twitch_oauth_log';

    static restore(): OAuthLogEntry[] {
        const json = localStorage.getItem(OAuthLog.key);
        if (!!json?.length) {
            return JSON.parse(json);
        }
        return [];
    }

    static store(entries: OAuthLogEntry[]) {
        if (entries.length) {
            const json = JSON.stringify(entries);
            localStorage.setItem(OAuthLog.key, json);
        } else {
            localStorage.removeItem(OAuthLog.key);
        }
    }

    static append(message: string): OAuthLogEntry {
        const entries = OAuthLog.restore();
        while (entries.length > 19) {
            entries.splice(0, 1);
        }

        const date = new Date().getTime();
        const entry: OAuthLogEntry = { date, message };
        entries.push(entry);
        OAuthLog.store(entries);

        return entry;
    }
    
}

class OAuthState {

    static readonly key = 'twitch_oauth_state';

    static restore(): string | null {
        const state = localStorage.getItem(OAuthState.key);
        if (!!state?.length) {
            return state;
        }
        return null;
    }

    static store(state: string | null) {
        if (state?.length) {
            localStorage.setItem(OAuthState.key, state);
        } else {
            localStorage.removeItem(OAuthState.key);
        }
    }
    
}

class OAuthToken {

    static readonly key = 'twitch_oauth_token';

    static restore(): string | null {
        const token = localStorage.getItem(OAuthToken.key);
        if (!!token?.length) {
            return token;
        }
        return null;
    }

    static store(token: string | null) {
        if (token?.length) {
            localStorage.setItem(OAuthToken.key, token);
        } else {
            localStorage.removeItem(OAuthToken.key);
        }
    }
    
}

class ChannelName {

    static readonly key = 'twitch_channel_name';

    static restore(): string | null {
        const name = localStorage.getItem(ChannelName.key);
        if (!!name?.length) {
            return name;
        }
        return null;
    }

    static store(name: string | null) {
        if (name?.length) {
            localStorage.setItem(ChannelName.key, name);
        } else {
            localStorage.removeItem(ChannelName.key);
        }
    }
    
}

class OAuthContext {

    static readonly key = 'twitch_oauth_context';

    static restore(): TwitchOAuthContext | null {
        const json = localStorage.getItem(OAuthContext.key);
        if (!!json?.length) {
            return JSON.parse(json);
        }
        return null;
    }

    static store(context: TwitchOAuthContext | null) {
        if (context) {
            const json = JSON.stringify(context);
            localStorage.setItem(OAuthContext.key, json);
        } else {
            localStorage.removeItem(OAuthContext.key);
        }
    }
    
}

class TwitchAPI {

    static async validateToken(token: string): Promise<TwitchOAuthContext> {
        const url = 'https://id.twitch.tv/oauth2/validate';

        const response = await fetch(url, {
            headers: {
                'Authorization': `OAuth ${token}`,
            },
        });

        if (response.status === 200) {
            const json = await response.json();
            const clientID = json['client_id'] as string;
            const username = json['login'] as string;
            const scopes = json['scopes'] as string[];
            const userID = json['user_id'] as string;
            const expiresIn = json['expires_in'] as number;

            return { clientID, username, scopes, userID, expiresIn };
        }

        if (response.status === 401) {
            const message = await response.text();
            throw new Error(`Twitch token validation failed: ${message}`);
        }

        throw new Error(`Twitch token validation failed: unhandled status code ${response.status}`);
    }

    static async revokeToken(clientID: string, token: string) {
        const params = new URLSearchParams();
        params.append('client_id', clientID);
        params.append('token', token);

        const url = `https://id.twitch.tv/oauth2/revoke?${params.toString()}`;

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });

        if (response.status === 400) {
            throw new Error(`Twitch token could not be revoked: invalid token.`);
        }

        if (response.status === 404) {
            throw new Error(`Twitch token could not be revoked: token doesn't exist.`);
        }

        throw new Error(`Twitch revoking token failed: unhandled status code ${response.status}`);
    }

    static async getTwitchUser(name: string, clientID: string, token: string): Promise<TwitchUserInfo> {
        const params = new URLSearchParams();
        params.append('login', name);

        const url = `https://api.twitch.tv/helix/users?${params.toString()}`;

        const response = await fetch(url, {
            headers: {
                'Accept': 'application/vnd.twitchtv.v5+json',
                'Client-ID': clientID,
                'Authorization': `Bearer ${token}`,
            },
        });

        if (response.status === 200) {
            const json = await response.json();
            const dataArray = json['data'] as any[];
            if (!dataArray?.length) {
                throw new Error(`Twitch user ${name} not found`);
            }

            const data = dataArray[0];
            const id = data['id'] as string;
            const avatarURL = data['profile_image_url'] as string;

            return { name, id, avatarURL };
        }

        if (response.status === 404) {
            throw new Error(`Twitch user ${name} not found`);
        }

        throw new Error(`Twitch getting user failed: unhandled status code ${response.status}`);
    }

    static async getGlobalEmotes(clientID: string, token: string): Promise<TwitchEmote[]> {
        const url = 'https://api.twitch.tv/helix/chat/emotes/global';

        const response = await fetch(url, {
            headers: {
                'Accept': 'application/vnd.twitchtv.v5+json',
                'Client-ID': clientID,
                'Authorization': `Bearer ${token}`,
            },
        });

        if (response.status === 200) {
            const json = await response.json();
            const dataArray = json['data'] as any[];
            if (!dataArray?.length) {
                throw new Error(`Twitch global emotes couldn't be parsed!`);
            }

            const template: string = json['template'];

            const parseEmote: (data: any) => TwitchEmote = (data) => {
                const id: string = data['id'];
                const name: string = data['name'];
                const formats: string[] = data['format'];
                const format = formats.includes('static') ? 'static' : formats[0];
                const scales: string[] = data['scale'];
                const scale = scales.includes('3.0') ? '3.0' : scales[scales.length - 1];
                const theme_modes: string[] = data['theme_mode'];
                const theme_mode = theme_modes.includes('dark') ? 'dark' : theme_modes[theme_modes.length - 1];

                const url = `${template}`
                    .replace(/\{\{id\}\}/, id)
                    .replace(/\{\{format\}\}/, format)
                    .replace(/\{\{theme_mode\}\}/, theme_mode)
                    .replace(/\{\{scale\}\}/, scale);

                return { id, name, url, type: 'global' };
            };

            return dataArray.map(parseEmote);
        }

        throw new Error(`Twitch getting global emotes failed: unhandled status code ${response.status}`);
    }

    static async getChannelEmotes(channelName: string, clientID: string, token: string): Promise<TwitchEmote[]> {
        const user = await TwitchAPI.getTwitchUser(channelName, clientID, token);

        const params = new URLSearchParams();
        params.append('broadcaster_id', `${user.id}`);

        const url = `https://api.twitch.tv/helix/chat/emotes?${params.toString()}`;

        const response = await fetch(url, {
            headers: {
                'Accept': 'application/vnd.twitchtv.v5+json',
                'Client-ID': clientID,
                'Authorization': `Bearer ${token}`,
            },
        });

        if (response.status === 200) {
            const json = await response.json();
            const dataArray = json['data'] as any[];
            if (!dataArray?.length) {
                return [];
            }

            const template: string = json['template'];

            const parseEmote: (data: any) => TwitchEmote = (data) => {
                const id: string = data['id'];
                const name: string = data['name'];
                const formats: string[] = data['format'];
                const format = formats.includes('static') ? 'static' : formats[0];
                const type: string = data['emote_type'];
                const scales: string[] = data['scale'];
                const scale = scales.includes('3.0') ? '3.0' : scales[scales.length - 1];
                const theme_modes: string[] = data['theme_mode'];
                const theme_mode = theme_modes.includes('dark') ? 'dark' : theme_modes[theme_modes.length - 1];

                const url = `${template}`
                    .replace(/\{\{id\}\}/, id)
                    .replace(/\{\{format\}\}/, format)
                    .replace(/\{\{theme_mode\}\}/, theme_mode)
                    .replace(/\{\{scale\}\}/, scale);

                return { id, name, url, type };
            };

            return dataArray.map(parseEmote);
        }

        throw new Error(`Twitch getting channel emotes failed: unhandled status code ${response.status}`);
    }

}

function createChatClient(channelName: string, username: string, token: string): Client {
    return new Client({
        options: {
            debug: process.env.NODE_ENV === 'development',
            skipUpdatingEmotesets: true,
        },
        identity: {
            username: username,
            password: `oauth:${token}`,
        },
        channels: [channelName],
    });
}


export const defaultTwitchManager = new TwitchManager();
export const TwitchContext = createContext(defaultTwitchManager);
TwitchContext.displayName = 'TwitchContext';

export const useTwitchManager = () => useContext(TwitchContext);