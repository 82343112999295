import { makePlayerDiedEvent } from "../../types/GameEvent";
import { HealthVariants } from "../../types/health/Health";
import { decreaseInjuryDamageTaken, decreaseInjuryRemainingDays, enableInjury, increaseInjuryDamageTaken, Injury, isInjured, isInjuryDamaging, isInjuryHealing, makeRandomInjury } from "../../types/health/Injury";
import { Inventory } from "../../types/inventory/Inventory";
import { decreaseInventoryItem } from "../../types/inventory/InventoryItem";
import { makeBadMedicineMessage, makeOxDiedMessage, makeOxExhaustedMessage, makeOxInjuredMessage, makeOxRanOffMessage, makePlayerDiedMessage, makePlayerExhaustedMessage, makePlayerHealedMessage, makePlayerInjuredMessage, makePlayerRevivedMessage, makePlayersHungryMessage, makePlayerSickMessage, makePlayersThirstyMessage, OxExhaustedMessage, PlayerExhaustedMessage, PlayerInjuredMessage, PlayerRevivedMessage, PlayerSickMessage } from "../../types/Message";
import { capOxHP, changeOxHP, enableOxInjury, isOxDead, isOxInjured, Ox, enableOxDeath, enableOxExhaustion, disableOxExhaustion, resetOxExhaustionDamageTaken, isOxExhausted, increaseOxExhaustionDamageTaken, isOxRanOff, enableOxRanOff } from "../../types/Ox";
import { capHP, changeHP, disableDeath, enableDeath, isDead, isDoctor, isNaked, isWet, Player, resetHealthStats } from "../../types/Player";
import { randomEvent, randomInt } from "../../types/Random";
import { GameState } from "../game/gameSlice";
import { calculateFoodRations, disableHunger, enableHunger, isHungry } from "../../types/health/Hunger";
import { calculateWaterRations, disableThirst, enableThirst, isThirsty } from "../../types/health/Thirst";
import { disableExhaustion, enableExhaustion, exhaustionMalus, increaseExhaustionDamageTaken, isExhausted, resetExhaustionDamageTaken } from "../../types/health/Exhaustion";
import { decreaseDiseaseDamageTaken, decreaseDiseaseRemainingDays, Disease, enableDisease, increaseDiseaseDamageTaken, isDiseaseDamaging, isDiseaseHealing, isSick, makeRandomDisease } from "../../types/health/Disease";
import { disableFreezing, enableFreezing, freezingMalus, isFreezing } from "../../types/health/Freezing";

export default class HealthSystem {

    static resetHealthStats(game: GameState): GameState {
        const players = [...game.players]
            .map(resetHealthStats);
        
        return { ...game, players };
    }

    static visitDoctor(playerName: string, price: number, game: GameState): GameState {
        let { players, messages, inventory } = game;

        let player = players.find(p => p.name.toLowerCase() === playerName.toLowerCase());
        if (!player) {
            return game;
        }

        const injuriesAndHeal: [Injury, number][] = player.injuries.map(injury => {
            if (isInjuryDamaging(injury)) {
                const heal = Math.ceil(injury.damageTaken / 2);
                injury = decreaseInjuryRemainingDays(injury, injury.remainingDays);
                injury = decreaseInjuryDamageTaken(injury, heal);
                return [injury, heal];
            }
            return [injury, 0];
        });

        const diseasesAndHeal: [Disease, number][] = player.diseases.map(disease => {
            if (isDiseaseDamaging(disease)) {
                const heal = Math.ceil(disease.damageTaken / 2);
                disease = decreaseDiseaseRemainingDays(disease, disease.remainingDays);
                disease = decreaseDiseaseDamageTaken(disease, heal);
                return [disease, heal];
            }
            return [disease, 0];
        });

        const injuries = injuriesAndHeal.map(tuple => tuple[0]);
        const diseases = diseasesAndHeal.map(tuple => tuple[0]);
        const heal = [...injuriesAndHeal.map(tuple => tuple[1]), ...diseasesAndHeal.map(tuple => tuple[1])].reduce((prev, curr) => prev + curr, 0);

        let healedPlayer: Player = changeHP(player, 'doctor', heal);
        healedPlayer = { ...healedPlayer, injuries, diseases };
        players = players.map(p => p.name === healedPlayer.name ? healedPlayer : p);

        const cash = decreaseInventoryItem(inventory.cash, price);
        inventory = { ...inventory, cash };

        messages.push(makePlayerHealedMessage(healedPlayer, 'by Doctor Hoo', game.date));

        return { ...game, players, inventory, messages };
    }

    static giveMedicine(playerName: string, game: GameState, options?: { disallowBadMedicine: boolean }): GameState {
        let players = [...game.players];
        let inventory = { ...game.inventory };
        const messages = [...game.messages];
        
        const doctorPresent = players.filter(player => !isDead(player) && isDoctor(player)).length > 0;

        const playerIndex = game.players.findIndex(player => player.name === playerName);
        if (playerIndex === -1) {
            return { ...game, players, inventory }; // No such player.
        }

        // Make sure the player can be treated.
        let player = game.players[playerIndex];
        const dead = isDead(player);
        const sick = isSick(player);
        const injured = isInjured(player);
        if (dead) {
            return { ...game, players, inventory };
        } else if (!sick && !injured) {
            return { ...game, players, inventory };
        }

        // Make sure we have medicine to treat them.
        if (game.inventory.medicine.amount === 0) {
            return { ...game, players, inventory };
        }

        // Find the most pressing issue.
        const getPoints = (issue: (Disease | Injury)) => issue.damagePerDay * issue.remainingDays;
        const issues = [...player.diseases, ...player.injuries]
            .sort((a, b) => getPoints(b) - getPoints(a));
        const issue = issues.length > 0 ? issues[0] : undefined;
        if (!issue?.remainingDays) {
            return { ...game, players, inventory };
        }

        // Figure out the potency and whether the medicine will work.
        const potency = randomInt(2, 6 + game.stats.health.value);
        const disallowBadMedicine = options?.disallowBadMedicine ?? false;
        const probabilityOfBadMedicine = (doctorPresent || disallowBadMedicine)
            ? 0.0
            : Math.max(0, (8 - game.stats.health.value - 3) * 0.05)
        const badMedicine = randomEvent(probabilityOfBadMedicine);
        const isDisease = player.diseases.find(d => d.name === issue.name) !== undefined;
        const isInjury = player.injuries.find(i => i.name === issue.name) !== undefined;

        // Apply bad medicine.
        if (badMedicine) {
            player = changeHP(player, 'bad medicine', -potency * 5);
            players[playerIndex] = { ...player };
            messages.push(makeBadMedicineMessage(player, issue.name, game.date));
        }

        // Or apply good medicine.
        else if (isDisease) {
            let disease = issue as Disease;
            disease = decreaseDiseaseRemainingDays(disease, potency);
            player = changeHP(player, 'medicine', potency * 5);
            players[playerIndex] = { ...player, diseases: player.diseases.map(d => d.name === disease.name ? disease : d) };
        } else if (isInjury) {
            let injury = issue as Injury;
            injury = decreaseInjuryRemainingDays(injury, potency);
            player = changeHP(player, 'medicine', potency * 5);
            players[playerIndex] = { ...player, injuries: player.injuries.map(i => i.name === injury.name ? injury : i) };
        }

        // Decrease amount of medicine in the inventory.
        const medicine = decreaseInventoryItem(game.inventory.medicine, 1);
        inventory = { ...game.inventory, medicine };

        return { ...game, players, inventory, messages };
    }

    static makeRandomPlayerInjuredOrSick(game: GameState): GameState {
        const { weather } = game;

        const wetWeather = weather.condition === 'Rain'
            || weather.condition === 'Freezing Drizzle'
            || weather.condition === 'Scattered Showers'
            || weather.condition === 'Scattered Thunderstorm'
            || weather.condition === 'Snow'
            || weather.condition === 'Snow Showers'
            || weather.condition === 'Light Snow'
            || weather.condition === 'Icy';
        
        const extremeWeather = weather.weatherEvent.name === 'Hail Storm'
            || weather.weatherEvent.name === 'Severe Weather';
        
        const apply = (addMessage: (message: PlayerSickMessage | PlayerInjuredMessage) => void) => (player: Player) => {
            if (!isDead(player)) {
                const freezing = isFreezing(player);
                const wet = isWet(player);
                const thirsty = isThirsty(player);
                const hungry = isHungry(player);
                const weak = player.hp < HealthVariants.poor.maximum;

                // Determine probabilities for adding an injury and a disease.
                let injuryProbability = 0;
                let diseaseProbability = 0;
                if (wetWeather) {
                    injuryProbability += 0.05;
                    diseaseProbability += 0.05;
                }
                if (extremeWeather) {
                    injuryProbability += 0.20;
                }
                if (freezing) {
                    diseaseProbability += 0.05;
                }
                if (wet) {
                    injuryProbability += 0.05;
                }
                if (thirsty && hungry) {
                    diseaseProbability += 0.05;
                }
                if (weak) {
                    diseaseProbability += 0.10;
                }

                // Zero out probabilities if we are already injured/sick.
                if (isInjured(player)) {
                    injuryProbability = 0;
                }
                if (isSick(player)) {
                    diseaseProbability = 0;
                }

                // Make a random disease.
                if (randomEvent(diseaseProbability)) {
                    const disease = makeRandomDisease(game.stats.health);
                    if (!player.diseases.find(d => d.name === disease.name)) {
                        player = enableDisease(player, disease);
                        addMessage(makePlayerSickMessage(player, disease, game.date));
                    }
                }

                // Make a random injury.
                if (randomEvent(injuryProbability)) {
                    const injury = makeRandomInjury(game.stats.health);
                    if (!player.injuries.find(i => i.name === injury.name)) {
                        player = enableInjury(player, injury);
                        addMessage(makePlayerInjuredMessage(player, injury, game.date));
                    }
                }
            }
            return player;
        };

        const messages = [...game.messages];
        const players = game.players
            .map(apply(message => messages.push(message)));

        return { ...game, messages, players };
    }

    static makeRandomPlayerExhausted(game: GameState): GameState {
        const { pace, date } = game;

        const applyRandomExhaustion = (addMessage: (message: PlayerExhaustedMessage) => void) => (player: Player) => {
            if (!isDead(player)) {
                const hungry = isHungry(player);
                const weak = player.hp < HealthVariants.poor.maximum;

                let probabilityOfGettingExhausted = 0;
                if (hungry && pace !== 'Steady') {
                    probabilityOfGettingExhausted += 0.05;
                }
                if (weak && pace !== 'Steady') {
                    probabilityOfGettingExhausted += 0.01;
                }
                if (pace === 'Grueling') {
                    probabilityOfGettingExhausted += 0.01;
                }

                if (randomEvent(probabilityOfGettingExhausted)) {
                    const exhaustedPlayer = enableExhaustion(player);
                    addMessage(makePlayerExhaustedMessage(exhaustedPlayer, date));
                    return exhaustedPlayer;
                } else if (probabilityOfGettingExhausted === 0) {
                    return disableExhaustion(player);
                }
            }

            return player;
        };

        const messages = [...game.messages];
        const players = [...game.players]
            .map(applyRandomExhaustion(message => messages.push(message)));
        
        return { ...game, players, messages };
    }

    static damagePlayersFromDiseases(game: GameState): GameState {
        const { date } = game;

        const applyHealing = (player: Player) => {
            if (!isDead(player)) {
                const diseasesAndHealings: [Disease, number][] = player.diseases.map(disease => {
                    if (isDiseaseHealing(disease)) {
                        const healing = disease.healingPerDay;
                        disease = decreaseDiseaseDamageTaken(disease, healing);
                        return [disease, healing];
                    }
                    return [disease, 0];
                });

                const diseases = diseasesAndHealings.map(tuple => tuple[0]);
                const healing = diseasesAndHealings.map(tuple => tuple[1]).reduce((prev, curr) => prev + curr, 0);

                if (healing > 0) {
                    player = changeHP({ ...player, diseases }, 'healing', healing);
                }
            }
            return player;
        };

        const applyDamage = (onPlayerDied: (player: Player) => void) => (player: Player) => {
            if (!isDead(player)) {
                const diseasesAndDamages: [Disease, number, string][] = player.diseases.map(disease => {
                    if (isDiseaseDamaging(disease)) {
                        const damage = disease.damagePerDay;
                        disease = increaseDiseaseDamageTaken(disease, damage);
                        disease = decreaseDiseaseRemainingDays(disease);
                        return [disease, damage, disease.name.toLowerCase()];
                    }
                    return [disease, 0, ''];
                });

                const diseases = diseasesAndDamages.map(tuple => tuple[0]);
                const damage = diseasesAndDamages.map(tuple => tuple[1]).reduce((prev, curr) => prev + curr, 0);
                const causes = diseasesAndDamages.map(tuple => tuple[2]).filter(cause => cause.length > 0).join(', ');

                player = changeHP({ ...player, diseases }, causes, -damage);
                if (player.hp === 0) {
                    const deadPlayer = enableDeath(player, causes);
                    onPlayerDied(deadPlayer);
                    return deadPlayer;
                }
            }
            return player;
        };

        const filterActiveDiseases = (player: Player) => {
            const diseases = player.diseases
                .filter(disease => isDiseaseDamaging(disease) || isDiseaseHealing(disease));

            return { ...player, diseases };
        };

        const messages = [...game.messages];
        const events = [...game.events];
        const players = [...game.players]
            .map(applyHealing)
            .map(applyDamage(player => {
                messages.push(makePlayerDiedMessage(player, date));
                events.push(makePlayerDiedEvent(player));
            }))
            .map(filterActiveDiseases);
        
        return { ...game, players, messages, events };
    }

    static damagePlayersFromInjuries(game: GameState): GameState {
        const { date } = game;

        const applyHealing = (player: Player) => {
            if (!isDead(player)) {
                const injuriesAndHealings: [Injury, number][] = player.injuries.map(injury => {
                    if (isInjuryHealing(injury)) {
                        const healing = injury.healingPerDay;
                        injury = decreaseInjuryDamageTaken(injury, healing);
                        return [injury, healing];
                    }
                    return [injury, 0];
                });

                const injuries = injuriesAndHealings.map(tuple => tuple[0]);
                const healing = injuriesAndHealings.map(tuple => tuple[1]).reduce((prev, curr) => prev + curr, 0);

                if (healing > 0) {
                    player = changeHP({ ...player, injuries }, 'healing', healing);
                }
            }
            return player;
        };

        const applyDamage = (onPlayerDied: (player: Player) => void) => (player: Player) => {
            if (!isDead(player)) {
                const injuriesAndDamages: [Injury, number, string][] = player.injuries.map(injury => {
                    if (isInjuryDamaging(injury)) {
                        const damage = injury.damagePerDay;
                        injury = increaseInjuryDamageTaken(injury, damage);
                        injury = decreaseInjuryRemainingDays(injury);
                        return [injury, damage, injury.name.toLowerCase()];
                    }
                    return [injury, 0, ''];
                });

                const injuries = injuriesAndDamages.map(tuple => tuple[0]);
                const damage = injuriesAndDamages.map(tuple => tuple[1]).reduce((prev, curr) => prev + curr, 0);
                const causes = injuriesAndDamages.map(tuple => tuple[2]).filter(cause => cause.length > 0).join(', ');

                player = changeHP({ ...player, injuries }, causes, -damage);
                if (player.hp === 0) {
                    const deadPlayer = enableDeath(player, causes);
                    onPlayerDied(deadPlayer);
                    return deadPlayer;
                }
            }
            return player;
        };

        const filterActiveInjuries = (player: Player) => {
            const injuries = player.injuries
                .filter(injury => isInjuryDamaging(injury) || isInjuryHealing(injury));
            
            return { ...player, injuries };
        };

        const messages = [...game.messages];
        const events = [...game.events];
        const players = [...game.players]
            .map(applyHealing)
            .map(applyDamage(player => {
                messages.push(makePlayerDiedMessage(player, date));
                events.push(makePlayerDiedEvent(player));
            }))
            .map(filterActiveInjuries);
        
        return { ...game, players, messages, events };
    }

    static damagePlayersFromExhaustion(game: GameState): GameState {
        const { date } = game;

        const applyDamage = (onPlayerDied: (player: Player) => void) => (player: Player) => {
            if (!isDead(player) && isExhausted(player)) {
                const damage = exhaustionMalus();
                player = changeHP(player, 'exhaustion', -damage);
                player = increaseExhaustionDamageTaken(player, damage);

                if (player.hp === 0) {
                    const deadPlayer = enableDeath(player, 'exhaustion');
                    onPlayerDied(deadPlayer);
                    return deadPlayer;
                }
            }
            return player;
        };

        const messages = [...game.messages];
        const events = [...game.events];
        const players = [...game.players]
            .map(applyDamage(player => {
                messages.push(makePlayerDiedMessage(player, date));
                events.push(makePlayerDiedEvent(player));
            }));
        
        return { ...game, players, messages, events };
    }

    static healPlayerExhaustion(game: GameState, reason: 'resting'): GameState {
        let healingAllowed = false;
        if (reason === 'resting') {
            healingAllowed = !!game.travelOptions.rest;
        }

        if (healingAllowed) {
            const applyHeal = (player: Player) => {
                if (!isDead(player) && player.exhaustion.damageTaken > 0) {
                    const heal = player.exhaustion.damageTaken;
                    player = changeHP(player, reason, heal);
                    player = disableExhaustion(player);
                    player = resetExhaustionDamageTaken(player);
                }
                return player;
            };

            const players = game.players.map(applyHeal);

            return { ...game, players };
        }

        return game;
    }

    static damagePlayersFromFreezing(game: GameState): GameState {
        const { weather, date } = game;

        const applyDamage = (onPlayerDied: (player: Player) => void) => (player: Player) => {
            if (!isDead(player) && isFreezing(player)) {
                const damage = freezingMalus(weather.temperature);
                player = changeHP(player, 'freezing', -damage);

                if (player.hp === 0) {
                    const deadPlayer = enableDeath(player, 'turned into an ice cube');
                    onPlayerDied(deadPlayer);
                    return deadPlayer;
                }
            }
            return player;
        };

        const messages = [...game.messages];
        const events = [...game.events];
        const players = [...game.players]
            .map(applyDamage(player => {
                messages.push(makePlayerDiedMessage(player, date));
                events.push(makePlayerDiedEvent(player));
            }));
        
        return { ...game, players, messages, events };
    }

    static healPlayers(game: GameState, reason: 'Spell'): GameState {
        const { date } = game;
        
        const applyHealing = (addMessage: (message: PlayerRevivedMessage) => void) => (player: Player) => {
            if (isDead(player)) {
                let explanation: string = reason;
                if (reason === 'Spell') {
                    explanation = `They were revived using powerful magic.`;
                }
                const revivedPlayer = disableDeath(player, HealthVariants.good.maximum, reason);
                addMessage(makePlayerRevivedMessage(player, explanation, date));
                return revivedPlayer;
            } else {
                const healedPlayer: Player = {
                    ...changeHP(player, reason, HealthVariants.good.maximum),
                    diseases: [],
                    injuries: [],
                    wet: false,
                    freezing: false,
                };
                return healedPlayer;
            }
        };
        
        const messages = [...game.messages];
        const players = [...game.players]
            .map(applyHealing(message => messages.push(message)));

        return { ...game, players, messages };
    }

    static feedPlayers(game: GameState): GameState {
        const { pace, ration, date } = game;
        const messages = [...game.messages];
        const events = [...game.events];

        const alivePlayerCount = game.players
            .filter(player => !isDead(player))
            .length;
        
        const foodAvailable = game.inventory.food.amount;
        const foodRation = calculateFoodRations(pace, ration, alivePlayerCount, foodAvailable);
        const totalFood = foodRation.food * alivePlayerCount;

        const applyPlayer: (player: Player) => Player = (player) => {
            if (!isDead(player)) {
                player = changeHP(player, 'hunger', -foodRation.hpMalus);
                player = changeHP(player, 'food', foodRation.hpBonus);
                if (player.hp <= 0) {
                    const deadPlayer = enableDeath(player, 'starvation');
                    messages.push(makePlayerDiedMessage(deadPlayer, date));
                    events.push(makePlayerDiedEvent(deadPlayer));
                    return deadPlayer;
                } else if (foodRation.fraction < 0.5 && player.hp <= HealthVariants.poor.maximum) {
                    if (!isHungry(player)) {
                        player = enableHunger(player);
                    }
                } else {
                    player = disableHunger(player);
                }
            }
            return player;
        };

        const players = [...game.players].map(applyPlayer);
        const hungryPlayers = players.filter(player => isHungry(player));
        if (hungryPlayers.length > 0) {
            messages.push(makePlayersHungryMessage(hungryPlayers, date));
        }

        const food = decreaseInventoryItem(game.inventory.food, totalFood);
        const inventory: Inventory = { ...game.inventory, food };

        return { ...game, players, inventory, messages, events };
    }

    static giveWater(game: GameState): GameState {
        const { weather, date } = game;
        const messages = [...game.messages];
        const events = [...game.events];

        const alivePlayerCount = game.players
            .filter(player => !isDead(player))
            .length;

        const waterAvailable = game.inventory.water.amount;
        const waterRation = calculateWaterRations(weather.temperature, alivePlayerCount, waterAvailable);
        const totalWater = waterRation.water * alivePlayerCount;

        const applyPlayer: (player: Player) => Player = (player) => {
            if (!isDead(player)) {
                player = changeHP(player, 'thirst', -waterRation.hpMalus);
                player = changeHP(player, 'water', waterRation.hpBonus);
                if (player.hp <= 0) {
                    const deadPlayer = enableDeath(player, 'thirst');
                    messages.push(makePlayerDiedMessage(deadPlayer, date));
                    events.push(makePlayerDiedEvent(deadPlayer));
                    return deadPlayer;
                } else if (waterRation.fraction < 0.5 && player.hp <= HealthVariants.poor.maximum) {
                    if (!isThirsty(player)) {
                        player = enableThirst(player);
                    }
                } else {
                    player = disableThirst(player);
                }
            }
            return player;
        };

        const players = [...game.players].map(applyPlayer);
        const thirstyPlayers = players.filter(player => isThirsty(player));
        if (thirstyPlayers.length > 0) {
            messages.push(makePlayersThirstyMessage(thirstyPlayers, date));
        }

        const water = decreaseInventoryItem(game.inventory.water, totalWater);
        const inventory: Inventory = { ...game.inventory, water };

        return { ...game, players, inventory, messages, events };
    }

    static applyFreezing(game: GameState): GameState {
        const { weather } = game;

        // At below -10°C all players are freezing.
        if (weather.temperature < -10) {
            const players = [...game.players]
                .map(enableFreezing);
            
            return { ...game, players };
        }

        // At below +10°C all naked and wet players are freezing.
        else if (weather.temperature < 10) {
            const players = [...game.players]
                .map(player => (isNaked(player) || isWet(player)) ? enableFreezing(player) : disableFreezing(player));
            
            return { ...game, players };
        }

        // Otherwise only wet players are freezing.
        else {
            const players = [...game.players]
                .map(player => isWet(player) ? enableFreezing(player) : disableFreezing(player));

            return { ...game, players };
        }
    }

    static makeRandomOxenDeadOrInjuredOrRunningOff(game: GameState): GameState {
        const { weather } = game;

        const wetWeather = weather.condition === 'Rain'
            || weather.condition === 'Freezing Drizzle'
            || weather.condition === 'Scattered Showers'
            || weather.condition === 'Scattered Thunderstorm'
            || weather.condition === 'Snow'
            || weather.condition === 'Snow Showers'
            || weather.condition === 'Light Snow'
            || weather.condition === 'Icy';
        
        const extremeWeather = weather.weatherEvent.name === 'Hail Storm'
            || weather.weatherEvent.name === 'Severe Weather';
        
        const icy = weather.temperature < -5;

        let probabilityOfInjury = .05;
        if (wetWeather) {
            probabilityOfInjury += .02;
        }
        if (extremeWeather) {
            probabilityOfInjury += .1;
        }
        if (icy) {
            probabilityOfInjury += .1;
        }

        let probabilityOfDeath = .005;
        if (extremeWeather || icy) {
            probabilityOfDeath += .005;
        }

        const probabilityOfRunningOff = game.oxen.filter(ox => !isOxDead(ox) && !isOxRanOff(ox)).length < 2 ? 0 : .01;

        const apply = (oxDied: (ox: Ox) => void, oxInjured: (ox: Ox) => void, oxRanOff: (ox: Ox) => void) => (ox: Ox) => {
            if (!isOxDead(ox) && !isOxRanOff(ox)) {
                if (randomEvent(probabilityOfDeath)) {
                    const deadOx = enableOxDeath(ox, 'sudden death');
                    oxDied(deadOx);
                    return deadOx;
                } else if (randomEvent(probabilityOfInjury) && !isOxInjured(ox)) {
                    const injuredOx = enableOxInjury(ox, extremeWeather || icy ? 'severe' : 'mild');
                    oxInjured(injuredOx);
                    return injuredOx;
                } else if (randomEvent(probabilityOfRunningOff)) {
                    const ranOffOx = enableOxRanOff(ox, game.date, game.trail.mileage);
                    oxRanOff(ranOffOx);
                    return ranOffOx;
                }
            }
            return ox;
        };

        const messages = [...game.messages];

        const onOxDied = (ox: Ox) => messages.push(makeOxDiedMessage(ox, game.date));
        const onOxInjured = (ox: Ox) => messages.push(makeOxInjuredMessage(ox, game.date));
        const onOxRanOff = (ox: Ox) => messages.push(makeOxRanOffMessage(ox, game.date));

        const oxen = game.oxen
            .map(apply(onOxDied, onOxInjured, onOxRanOff))
            .filter(ox => ox !== null)
            .map(ox => ox!);

        return { ...game, messages, oxen };
    }

    static damageOxenFromInjuries(game: GameState): GameState {
        const { date } = game;

        const applyHealing = (ox: Ox) => {
            if (!isOxDead(ox) && !isOxRanOff(ox)) {
                const injuriesAndHealings: [Injury, number][] = ox.injuries.map(injury => {
                    if (isInjuryHealing(injury)) {
                        const healing = injury.healingPerDay;
                        injury = decreaseInjuryDamageTaken(injury, healing);
                        return [injury, healing];
                    }
                    return [injury, 0];
                });

                const injuries = injuriesAndHealings.map(tuple => tuple[0]);
                const healing = injuriesAndHealings.map(tuple => tuple[1]).reduce((prev, curr) => prev + curr, 0);

                if (healing > 0) {
                    ox = changeOxHP({ ...ox, injuries }, healing);
                }
            }
            return ox;
        };

        const applyDamage = (onOxDied: (ox: Ox) => void) => (ox: Ox) => {
            if (!isOxDead(ox) && !isOxRanOff(ox)) {
                const injuriesAndDamages: [Injury, number, string][] = ox.injuries.map(injury => {
                    if (isInjuryDamaging(injury)) {
                        const damage = injury.damagePerDay;
                        injury = increaseInjuryDamageTaken(injury, damage);
                        injury = decreaseInjuryRemainingDays(injury);
                        return [injury, damage, injury.name.toLowerCase()];
                    }
                    return [injury, 0, ''];
                });

                const injuries = injuriesAndDamages.map(tuple => tuple[0]);
                const damage = injuriesAndDamages.map(tuple => tuple[1]).reduce((prev, curr) => prev + curr, 0);
                const causes = injuriesAndDamages.map(tuple => tuple[2]).filter(cause => cause.length > 0).join(', ');

                ox = changeOxHP({ ...ox, injuries }, -damage);
                if (ox.hp === 0) {
                    const deadOx = enableOxDeath(ox, causes);
                    onOxDied(deadOx);
                    return deadOx;
                }
            }
            return ox;
        };

        const filterActiveInjuries = (ox: Ox) => {
            const injuries = ox.injuries
                .filter(injury => isInjuryDamaging(injury) || isInjuryHealing(injury));
            
            return { ...ox, injuries };
        };

        const messages = [...game.messages];
        const events = [...game.events];
        const oxen = [...game.oxen]
            .map(applyHealing)
            .map(applyDamage(ox => messages.push(makeOxDiedMessage(ox, date))))
            .map(filterActiveInjuries);
        
        return { ...game, oxen, messages, events };
    }

    static makeRandomOxenExhausted(game: GameState): GameState {
        const { pace, date } = game;

        let probabilityOfGettingExhausted = 0;
        let probabilityOfHealingExhaustion = 0;
        if (game.traveling) {
            if (pace === 'Steady') {
                probabilityOfGettingExhausted = .2;
                probabilityOfHealingExhaustion = .1
            } else if (pace === 'Strenuous') {
                probabilityOfGettingExhausted = .4;
                probabilityOfHealingExhaustion = .05;
            } else if (pace === 'Grueling') {
                probabilityOfGettingExhausted = .8;
                probabilityOfHealingExhaustion = .02;
            }
        } else {
            probabilityOfHealingExhaustion = 1;
        }

        const applyRandomExhaustion = (addMessage: (message: OxExhaustedMessage) => void) => (ox: Ox) => {
            if (!isOxDead(ox) && !isOxRanOff(ox)) {
                if (randomEvent(probabilityOfGettingExhausted)) {
                    const exhaustedOx = enableOxExhaustion(ox);
                    addMessage(makeOxExhaustedMessage(exhaustedOx, date));
                    return exhaustedOx;
                } else if (probabilityOfGettingExhausted === 0 || randomEvent(probabilityOfHealingExhaustion)) {
                    return disableOxExhaustion(ox);
                }
            }

            return ox;
        };

        const messages = [...game.messages];
        const oxen = game.oxen.map(applyRandomExhaustion(message => messages.push(message)));
        
        return { ...game, oxen, messages };
    }

    static damageOxenFromExhaustion(game: GameState): GameState {
        const { date } = game;

        const applyDamage = (onOxDied: (ox: Ox) => void) => (ox: Ox) => {
            if (!isOxDead(ox) && !isOxRanOff(ox) && isOxExhausted(ox)) {
                const damage = exhaustionMalus();
                ox = changeOxHP(ox, -damage);
                ox = increaseOxExhaustionDamageTaken(ox, damage);

                if (ox.hp === 0) {
                    const deadOx = enableOxDeath(ox, 'exhaustion');
                    onOxDied(deadOx);
                    return deadOx;
                }
            }
            return ox;
        };

        const messages = [...game.messages];
        const oxen = game.oxen.map(applyDamage(ox => messages.push(makeOxDiedMessage(ox, date))));
        
        return { ...game, oxen, messages };
    }

    static healOxenExhaustion(game: GameState, reason: 'resting'): GameState {
        let healingAllowed = false;
        if (reason === 'resting') {
            healingAllowed = !!game.travelOptions.rest;
        }

        if (healingAllowed) {
            const applyHeal = (ox: Ox) => {
                if (!isOxDead(ox) && !isOxRanOff(ox) && ox.exhaustion.damageTaken > 0) {
                    const heal = ox.exhaustion.damageTaken;
                    ox = changeOxHP(ox, heal);
                    ox = disableOxExhaustion(ox);
                    ox = resetOxExhaustionDamageTaken(ox);
                }
                return ox;
            };

            const oxen = game.oxen.map(applyHeal);

            return { ...game, oxen };
        }

        return game;
    }

    static capHP(game: GameState): GameState {
        const players = [...game.players].map(capHP);
        const oxen = [...game.oxen].map(capOxHP);
        return { ...game, players, oxen };
    }

}