import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { formatCash } from "../../../../../types/inventory/Cash";
import { calculateWaterCapacity } from "../../../../../types/inventory/Inventory";
import { formatWater, formatWaterContainers, WaterContainerType, WATER_PER_BOTTLE, WATER_PER_CANISTER } from "../../../../../types/inventory/Water";
import { buyWaterBottle, buyWaterCanister, determineSellingPrice, sellWaterBottle, sellWaterCanister, StoreItemName } from "../../../../../types/Store";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { boughtSomething, changeInventory, selectGame } from "../../../gameSlice";

import ProductAmount from "./ProductAmount";
import ProductName from "./ProductName";
import ProductPrice from "./ProductPrice";
import StoreItem from "./StoreItem";
import TradingButtons from "./TradingButtons";

interface WaterStoreItemProps {
    bottlePrice: number;
    canisterPrice: number;
    selection: [selection: StoreItemName | null, setSelection: (name: StoreItemName | null) => void];
}

export default function WaterStoreItem({ bottlePrice, canisterPrice, selection }: WaterStoreItemProps) {
    const dispatch = useAppDispatch();
    const { inventory, stats, wagons, players } = useAppSelector(selectGame);
    const { cash, water } = inventory;

    const [selectedItem, setSelectedItem] = selection;
    const [containerType, setContainerType] = useState<WaterContainerType | null>(null);
    const active = selectedItem === 'Water';
    
    let price: number | undefined = undefined;
    if (containerType === 'Bottle') {
        price = bottlePrice;
    } else if (containerType === 'Canister') {
        price = canisterPrice;
    }

    let capacity = 0;
    if (containerType === 'Bottle') {
        capacity = Math.floor(calculateWaterCapacity(inventory, wagons, players) / WATER_PER_BOTTLE);
    } else if (containerType === 'Canister') {
        capacity = Math.floor(calculateWaterCapacity(inventory, wagons, players) / WATER_PER_CANISTER);
    }

    let buy: ((amount: number) => void) | undefined = undefined;
    if (price !== undefined && cash.amount >= price && capacity > 0) {
        buy = (amount) => {
            const a = Math.min(amount, capacity);
            if (containerType === 'Bottle' && !!bottlePrice) {
                dispatch(changeInventory(buyWaterBottle(inventory, a, bottlePrice)));
                dispatch(boughtSomething({ price: (price ?? 0) * a, item: 'water' }));
                setSelectedItem(null);
            } else if (containerType === 'Canister' && !!canisterPrice) {
                dispatch(changeInventory(buyWaterCanister(inventory, a, canisterPrice)));
                dispatch(boughtSomething({ price: (price ?? 0) * a, item: 'water' }));
                setSelectedItem(null);
            } else {
                console.warn(`Water container type ${containerType} not implemented!`);
            }
        };
    }

    let sell: ((amount: number) => void) | undefined = undefined;
    if (water.amount > 0) {
        sell = (amount) => {
            if (containerType === 'Bottle' && !!bottlePrice) {
                dispatch(changeInventory(sellWaterBottle(inventory, amount, determineSellingPrice(bottlePrice, stats.trading))));
                setSelectedItem(null);
            } else if (containerType === 'Canister' && !!canisterPrice) {
                dispatch(changeInventory(sellWaterCanister(inventory, amount, determineSellingPrice(canisterPrice, stats.trading))));
                setSelectedItem(null);
            } else {
                console.warn(`Water container type ${containerType} not implemented!`);
            }
        };
    }

    useEffect(() => {
        const bottle = !!bottlePrice;
        const canister = !!canisterPrice;
        
        if (bottle && !canister && containerType !== 'Bottle') {
            setContainerType('Bottle');
        } else if (canister && !bottle && containerType !== 'Canister') {
            setContainerType('Canister');
        } else if (canister && bottle && containerType === null) {
            setContainerType('Canister');
        } else if (!canister && !bottle && containerType !== null) {
            setContainerType(null);
        }
    }, [bottlePrice, canisterPrice, containerType, setContainerType]);

    const productName = (
        <ProductName>
            <div className="d-flex justify-content-start align-items-center">
                <div className="me-2">Water</div>
                { !!bottlePrice && !canisterPrice && <div>Bottles</div> }
                { !bottlePrice && !!canisterPrice && <div>Canisters</div> }
                { !!bottlePrice && !!canisterPrice &&
                <Form.Select value={containerType ?? ''} onChange={({target}) => setContainerType(target.value as WaterContainerType)}>
                    { !!bottlePrice && <option value="Bottle">Bottles</option> }
                    { !!canisterPrice && <option value="Canister">Canisters</option> }
                </Form.Select>
                }
            </div>
        </ProductName>
    );

    const productPrice = (
        <ProductPrice active={active}>
            <>
            { containerType === 'Bottle' && !!bottlePrice && <span>buy for {formatCash(bottlePrice)}, sell for {formatCash(determineSellingPrice(bottlePrice, stats.trading))} per {formatWater(WATER_PER_BOTTLE)} bottle.</span> }
            { containerType === 'Canister' && !!canisterPrice && <span>buy for {formatCash(canisterPrice)}, sell for {formatCash(determineSellingPrice(canisterPrice, stats.trading))} per {formatWater(WATER_PER_CANISTER)} canister.</span> }
            </>
        </ProductPrice>
    );

    const containers = water.containers
            .filter(container => container.type === containerType);

    const productAmount = <ProductAmount>{formatWaterContainers(containers)}</ProductAmount>;

    const money = (amount: number, mode: 'buy' | 'sell') => {
        const factor = mode === 'buy' ? 1 : 0.5;
        if (containerType === 'Bottle' && !!bottlePrice) {
            return bottlePrice * factor * amount;
        } else if (containerType === 'Canister' && !!canisterPrice) {
            return canisterPrice * factor * amount;
        } else {
            return 0;
        }
    };
    
    const canAfford = (amount: number) => {
        if (containerType === 'Bottle' && !!bottlePrice) {
            return amount * bottlePrice <= cash.amount;
        } else if (containerType === 'Canister' && !!canisterPrice) {
            return amount * canisterPrice <= cash.amount;
        } else {
            return false;
        }
    };

    const tradingButtons = (
        <TradingButtons
            amountInStock={containers.length}
            amountPerUnit={1}
            canAfford={canAfford}
            money={money}
            formatUnits={(units) => formatWaterContainers({ count: units, type: containerType ?? 'Bottle' })}
            minAmount={0}
            maxAmount={capacity}
            buy={buy}
            sell={sell}
        />
    );

    return (
        <StoreItem
            gameIcon="water-flask"
            productName={productName}
            productPrice={productPrice}
            productAmount={productAmount}
            tradingButtons={tradingButtons}
        />
    );
}