import { Player } from "../Player";

const THIRST_HP = 20;       // HP per person per day
const WATER_PER_PERSON = 2; // liter of water per person per day

function thirstFactor(temperature: number): number {
    if (temperature > 26) {
        return 2;
    } else {
        return 1;
    }
}

export function thirstMalus(temperature: number): number {
    const factor = thirstFactor(temperature);
    return THIRST_HP * factor;
}

export function thirstBonus(temperature: number): number {
    const factor = thirstFactor(temperature);
    return THIRST_HP * factor;
}

function determineIdealRationPerPerson(temperature: number): number {
    const factor = thirstFactor(temperature);
    return WATER_PER_PERSON * factor;
}

interface RationedWater {
    water: number;
    fraction: number;
}

function calculateRationedWaterPerPerson(temperature: number, waterAvailable: number): RationedWater {
    if (waterAvailable === 0) {
        return { water: 0, fraction: 0 };
    }

    const idealRation = determineIdealRationPerPerson(temperature);

    if (!isFinite(waterAvailable) || idealRation <= waterAvailable) {
        return { water: idealRation, fraction: 1 };
    } else {
        const fraction = waterAvailable / idealRation;
        return { water: waterAvailable, fraction };
    }
}

export interface WaterRationPerPerson {
    water: number; // volume of water
    fraction: number; // of ideal ration
    hpBonus: number; // from giving water
    hpMalus: number; // from thirst
}

export function calculateWaterRationPerPerson(temperature: number, waterAvailable: number = Infinity): WaterRationPerPerson {
    const rationedWater = calculateRationedWaterPerPerson(temperature, waterAvailable);
    const hpBonus = Math.floor(thirstBonus(temperature) * rationedWater.fraction);
    const hpMalus = thirstMalus(temperature);

    return { ...rationedWater, hpBonus, hpMalus };
}

export function calculateWaterRations(temperature: number, peopleCount: number, totalWaterAvailable: number): WaterRationPerPerson {
    if (peopleCount === 0) {
        return { water: 0, fraction: 0, hpBonus: 0, hpMalus: 0 };
    } else if (peopleCount === 1) {
        return calculateWaterRationPerPerson(temperature, totalWaterAvailable);
    } else {
        const waterAvailable = totalWaterAvailable / peopleCount;
        return calculateWaterRationPerPerson(temperature, waterAvailable);
    }
}

export function isThirsty(player: Player): boolean {
    return player.thirsty === true;
}

export function enableThirst(player: Player): Player {
    return { ...player, thirsty: true };
}

export function disableThirst(player: Player): Player {
    return { ...player, thirsty: false };
}
