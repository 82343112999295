import { Achievement } from "./Achievement";
import { AnimalAttack } from "./AnimalAttack";
import { BanditAttack } from "./BanditAttack";
import { HuntedAnimalName, Hunting, HuntingHit, HuntingMiss, pluralizeHuntedAnimalName } from "./Hunting";
import { Injury } from "./health/Injury";
import { formatBullets } from "./inventory/Bullets";
import { formatCash } from "./inventory/Cash";
import { Chest } from "./inventory/Chest";
import { formatClothes } from "./inventory/Clothes";
import { formatFood } from "./inventory/Food";
import { MapItem } from "./inventory/Maps";
import { formatMedicine } from "./inventory/Medicine";
import { Location } from "./location/Location";
import { RiverCrossing } from "./location/RiverCrossing";
import { TollStation } from "./location/TollStation";
import { formatOxen, Ox } from "./Ox";
import { Player, reportHealthStats } from "./Player";
import { countItemsInArray, formatAnd } from "./Utilities";
import { WagonComponentName } from "./Wagon";
import { WeatherEventName } from "./Weather";
import { Disease } from "./health/Disease";
import { HangmanGame, HangmanTry } from "./Hangman";
import { Inn } from "./location/Inn";
import { formatRifles } from "./inventory/Rifles";

export type MessageType = 'Text'
    | 'Konami Code Found'
    | 'Hunting Started'
    | 'Hunting Ended'
    | 'Player Sick'
    | 'Player Injured'
    | 'Player Exhausted'
    | 'Players Hungry'
    | 'Players Thirsty'
    | 'Player Health Stats'
    | 'Player Died'
    | 'Player Revived'
    | 'Player Healed'
    | 'Bad Medicine'
    | 'Ox Died'
    | 'Ox Injured'
    | 'Ox Exhausted'
    | 'Ox Ran Off'
    | 'Found Ox Again'
    | 'Arrived'
    | 'Found Legendary Town'
    | 'Payed At Toll Station'
    | 'Crossed River'
    | 'Weather Happened'
    | 'Found Wild Fruit'
    | 'Found Water'
    | 'Found Abandoned Wagon'
    | 'Broke Wagon'
    | 'Nightly Thief'
    | 'Wild Animals Attacking'
    | 'Fighting Animals Started'
    | 'Fighting Animals Ended'
    | 'Fleeing From Animals'
    | 'Fleeing From Bandits'
    | 'Riders Approaching'
    | 'Fighting Bandits Started'
    | 'Fighting Bandits Ended'
    | 'Opening Chest'
    | 'Opening Chest Lock'
    | 'Unlocked Achievement'
    | 'Hangman Guess'
    | 'Hangman Ended'
    | 'Ate At Inn'
    | 'Found 1836 Colt';

export interface Message {
    type: MessageType;
    date: number;
    time: number;
    roundIndex: number;
    text: string;
}

export function incrementRound(message: Message): Message {
    const roundIndex = message.roundIndex + 1;
    return { ...message, roundIndex };
}

export function canMessageAppearInDiary(message: Message): boolean {
    return message.date !== 0 && message.type !== 'Hangman Guess';
}

export function canMessageStopAutoTravel(message: Message): boolean {
    const { type } = message;
    return type === 'Player Sick' || type === 'Player Injured' || type === 'Player Died' || type === 'Ox Died' || type === 'Ox Ran Off' || type === 'Crossed River' || type === 'Arrived' || type === 'Found Legendary Town' || type === 'Found Abandoned Wagon' || type === 'Broke Wagon' || type === 'Nightly Thief' || type === 'Wild Animals Attacking' || type === 'Riders Approaching' || type === 'Konami Code Found';
}

export function isLastMessageMatching(messages: Message[], predicate: (message: Message) => boolean): boolean {
    if (messages.length === 0) {
        return false;
    } else {
        const lastMessage = messages[messages.length - 1];
        return predicate(lastMessage);
    }
}

function currentTime(): number {
    return new Date().getTime();
}

export interface TextMessage extends Message {
    type: 'Text';
}

export function makeTextMessage(text: string, date: number): TextMessage {
    const time = currentTime();
    const roundIndex = 0;
    return { type: 'Text', date, time, roundIndex, text };
}

export interface KonamiCodeFoundMessage extends Message {
    type: 'Konami Code Found';
}

export function makeKonamiCodeFoundMessage(date: number): KonamiCodeFoundMessage {
    const time = currentTime();
    const roundIndex = 0;
    const text = `A magic :yellow:{Konami code spell} was cast. It's a very powerful spell, but it comes at a :red:{terrible cost}.`;
    return { type: 'Konami Code Found', date, time, roundIndex, text };
}

export interface HuntingStartedMessage extends Message {
    type: 'Hunting Started';
    hunting: Hunting;
}

export function makeHuntingStartedMessage(hunting: Hunting, date: number): HuntingStartedMessage {
    const time = currentTime();
    const roundIndex = 0;
    const text = `:blue:{${hunting.playerName}} is going hunting.`;
    return { type: 'Hunting Started', date, time, roundIndex, text, hunting };
}

export interface HuntingEndedMessage extends Message {
    type: 'Hunting Ended';
    hunting: Hunting;
    mode: 'canceled' | 'timed out' | 'finished';
}

export function makeHuntingEndedMessage(hunting: Hunting, mode: 'canceled' | 'timed out' | 'finished', date: number): HuntingEndedMessage {
    const time = currentTime();
    const roundIndex = 0;
    let text: string;

    if (mode === 'canceled') {
        if (hunting.result?.type === 'Miss') {
            const result = hunting.result as HuntingMiss;
            if (result.bullets) {
                text = `Hunting was canceled, we wasted ${formatBullets(result.bullets)}.`;
            } else {
                text = `Hunting was canceled.`;
            }
        } else {
            text = `Hunting was canceled.`;
        }
    }

    else if (mode === 'timed out') {
        if (hunting.result?.type === 'Miss') {
            const result = hunting.result as HuntingMiss;
            if (result.bullets) {
                text = `:blue:{${hunting.playerName}} took to long and wasted ${formatBullets(result.bullets)}.`;
            } else {
                text = `:blue:{${hunting.playerName}} took to long.`;
            }
        } else {
            text = `:blue:{${hunting.playerName}} took to long.`;
        }
    }

    else if (mode === 'finished') {
        if (hunting.result?.type === 'Miss') {
            const result = hunting.result as HuntingMiss;
            if (result.bullets) {
                text = `:blue:{${hunting.playerName}} came back without food and wasted ${formatBullets(result.bullets)}.`;
            } else {
                text = `:blue:{${hunting.playerName}} came back without food.`;
            }
        } else {
            const result = hunting.result as HuntingHit;
            const animalNames = result.animals.map(animal => animal.name);
            const animalWeight = result.animals.map(animal => animal.weight).reduce((prev, curr) => prev + curr, 0);
            const countedAnimals = countItemsInArray(animalNames);
            const formatCountedAnimal = (name: HuntedAnimalName, count: number) => {
                let lowercasedName = name.toLowerCase();
                if (count === 1) {
                    if (lowercasedName.startsWith('a') || lowercasedName.startsWith('e') || lowercasedName.startsWith('i') || lowercasedName.startsWith('o') || lowercasedName.startsWith('u')) {
                        return `an ${lowercasedName}`;
                    } else {
                        return `a ${lowercasedName}`;
                    }
                } else {
                    lowercasedName = pluralizeHuntedAnimalName(name).toLowerCase();
                    return `${count} ${lowercasedName}`;
                }
            };
            const animals = formatAnd(countedAnimals.map(entry => formatCountedAnimal(entry.item, entry.count)).map(s => `:yellow:{${s}}`));
            if (animalWeight > result.food) {
                text = `:blue:{${hunting.playerName}} shot ${animals} for ${formatFood(animalWeight)} of meat, but we could only carry ${formatFood(result.food)}.`;
            } else {
                text = `:blue:{${hunting.playerName}} shot ${animals} for ${formatFood(result.food)} of meat.`;
            }
        }
    }

    else {
        text = `:blue:{${hunting.playerName}} returned from hunting but won't tell us what happened.`;
    }

    return { type: 'Hunting Ended', date, time, roundIndex, text, hunting, mode };
}

export interface PlayerSickMessage extends Message {
    type: 'Player Sick';
    player: Player;
    disease: Disease;
}

export function makePlayerSickMessage(player: Player, disease: Disease, date: number): PlayerSickMessage {
    const time = currentTime();
    const roundIndex = 0;
    const text = `:blue:{${player.name}} has ${disease.name.toLocaleLowerCase()}.`;
    return { type: 'Player Sick', date, time, roundIndex, text, player, disease };
}

export interface PlayerInjuredMessage extends Message {
    type: 'Player Injured';
    player: Player;
    injury: Injury;
}

export function makePlayerInjuredMessage(player: Player, injury: Injury, date: number): PlayerInjuredMessage {
    const time = currentTime();
    const roundIndex = 0;
    const text = `:blue:{${player.name}} has a ${injury.name.toLowerCase()}.`;
    return { type: 'Player Injured', date, time, roundIndex, text, player, injury };
}

export interface PlayerExhaustedMessage extends Message {
    type: 'Player Exhausted';
    player: Player;
}

export function makePlayerExhaustedMessage(player: Player, date: number): PlayerExhaustedMessage {
    const time = currentTime();
    const roundIndex = 0;
    const text = `:blue:{${player.name}} is exhausted.`;
    return { type: 'Player Exhausted', date, time, roundIndex, text, player };
}

export interface PlayersHungryMessage extends Message {
    type: 'Players Hungry';
    players: Player[];
}

export function makePlayersHungryMessage(players: Player[], date: number): PlayersHungryMessage {
    const time = currentTime();
    const roundIndex = 0;
    const playerNames = players.map(player => player.name);
    const who = formatAnd(playerNames, 'Nobody');
    const text = `:blue:{${who}} ${players.length === 1 ? "is" : "are"} hungry.`;
    return { type: 'Players Hungry', date, time, roundIndex, text, players };
}

export interface PlayersThirstyMessage extends Message {
    type: 'Players Thirsty';
    players: Player[];
}

export function makePlayersThirstyMessage(players: Player[], date: number): PlayersThirstyMessage {
    const time = currentTime();
    const roundIndex = 0;
    const playerNames = players.map(player => player.name);
    const who = formatAnd(playerNames, 'Nobody');
    const text = `:blue:{${who}} ${players.length === 1 ? "is" : "are"} thirsty.`;
    return { type: 'Players Thirsty', date, time, roundIndex, text, players };
}

export interface PlayerHealthStatsMessage extends Message {
    type: 'Player Health Stats';
}

export function makePlayerHealthStatsMessage(player: Player): PlayerHealthStatsMessage {
    const date = 0;
    const time = currentTime();
    const roundIndex = 0;
    const stats = reportHealthStats(player).join(' :dark:{\u2192} ');
    const text = `:blue:{${player.name}} ${stats}`;
    return { type: 'Player Health Stats', date, time, roundIndex, text };
}

export interface PlayerDiedMessage extends Message {
    type: 'Player Died';
    player: Player;
    causeOfDeath: string;
}

export function makePlayerDiedMessage(player: Player, date: number): PlayerDiedMessage {
    const time = currentTime();
    const roundIndex = 0;
    const causeOfDeath = player.causeOfDeath ?? '';
    const text = `:blue:{${player.name}} died of :red:{${causeOfDeath.toLowerCase()}}.`;
    return { type: 'Player Died', date, time, roundIndex, text, player, causeOfDeath };
}

export interface PlayerRevivedMessage extends Message {
    type: 'Player Revived';
    player: Player;
    reason: string;
}

export function makePlayerRevivedMessage(player: Player, reason: string, date: number): PlayerRevivedMessage {
    const time = currentTime();
    const roundIndex = 0;
    const text = `:blue:{${player.name}} suddenly came back to life. ${reason}`;
    return { type: 'Player Revived', date, time, roundIndex, text, player, reason };
}

export interface PlayerHealedMessage extends Message {
    type: 'Player Healed';
    player: Player;
    how: string;
}

export function makePlayerHealedMessage(player: Player, how: string, date: number): PlayerHealedMessage {
    const time = currentTime();
    const roundIndex = 0;
    const text = `:blue:{${player.name}} healed ${how}.`;
    return { type: 'Player Healed', date, time, roundIndex, text, player, how };
}

export interface BadMedicineMessage extends Message {
    type: 'Bad Medicine';
    player: Player;
    issue: string;
}

export function makeBadMedicineMessage(player: Player, issue: string, date: number): BadMedicineMessage {
    const time = currentTime();
    const roundIndex = 0;
    const text = `:red:{Bad medicine} was given to :blue:{${player.name}}.`;
    return { type: 'Bad Medicine', date, time, roundIndex, text, player, issue };
}

export interface OxDiedMessage extends Message {
    type: 'Ox Died';
    ox: Ox;
    causeOfDeath: string;
}

export function makeOxDiedMessage(ox: Ox, date: number): OxDiedMessage {
    const time = currentTime();
    const roundIndex = 0;
    const causeOfDeath = ox.causeOfDeath ?? '';
    const text = `:blue:{${ox.name}} died of :red:{${causeOfDeath.toLowerCase()}}.`;
    return { type: 'Ox Died', date, time, roundIndex, text, ox, causeOfDeath };
}

export interface OxInjuredMessage extends Message {
    type: 'Ox Injured';
    ox: Ox;
}

export function makeOxInjuredMessage(ox: Ox, date: number): OxInjuredMessage {
    const time = currentTime();
    const roundIndex = 0;
    const text = `:blue:{${ox.name}} was injured.`;
    return { type: 'Ox Injured', date, time, roundIndex, text, ox };
}

export interface OxExhaustedMessage extends Message {
    type: 'Ox Exhausted';
    ox: Ox;
}

export function makeOxExhaustedMessage(ox: Ox, date: number): OxExhaustedMessage {
    const time = currentTime();
    const roundIndex = 0;
    const text = `:blue:{${ox.name}} is exhausted.`;
    return { type: 'Ox Exhausted', date, time, roundIndex, text, ox };
}

export interface OxRanOffMessage extends Message {
    type: 'Ox Ran Off';
    ox: Ox;
}

export function makeOxRanOffMessage(ox: Ox, date: number): OxRanOffMessage {
    const time = currentTime();
    const roundIndex = 0;
    const text = `:blue:{${ox.name}} ran off.`;
    return { type: 'Ox Ran Off', date, time, roundIndex, text, ox };
}

export interface FoundOxAgainMessage extends Message {
    type: 'Found Ox Again';
    ox: Ox;
}

export function makeFoundOxAgainMessage(ox: Ox, date: number): FoundOxAgainMessage {
    const time = currentTime();
    const roundIndex = 0;
    const text = `Luckily we have found :blue:{${ox.name}} again.`;
    return { type: 'Found Ox Again', date, time, roundIndex, text, ox };
}

export interface ArrivedMessage extends Message {
    type: 'Arrived';
    location: Location;
}

export function makeArrivedMessage(location: Location, date: number): ArrivedMessage {
    const time = currentTime();
    const roundIndex = 0;
    const text = `We've arrived at :blue:{${location.name}}.`;
    return { type: 'Arrived', date, time, roundIndex, text, location };
}

export interface FoundLegendaryTownMessage extends Message {
    type: 'Found Legendary Town';
    location: Location;
}

export function makeFoundLegendaryTownMessage(location: Location, date: number): FoundLegendaryTownMessage {
    const time = currentTime();
    const roundIndex = 0;
    const text = `Yay, we found the legendary :blue:{${location.name}}!`;
    return { type: 'Found Legendary Town', date, time, roundIndex, text, location };
}

export interface PayedAtTollStationMessage extends Message {
    type: 'Payed At Toll Station';
    tollStation: TollStation;
}

export function makePayedAtTollStationMessage(tollStation: TollStation, date: number): PayedAtTollStationMessage {
    const time = currentTime();
    const roundIndex = 0;
    const text = `Payed ${formatCash(tollStation.price)} at :blue:{${tollStation.name}}.`;
    return { type: 'Payed At Toll Station', date, time, roundIndex, text, tollStation };
}

export interface CrossedRiverMessage extends Message {
    type: 'Crossed River';
    riverCrossing: RiverCrossing;
    tippedOver: boolean;
    lostInventory?: {
        food: number;
        clothes: number;
        bullets: number;
    };
}

export function makeCrossedRiverMessage(riverCrossing: RiverCrossing, tippedOver: boolean, date: number, lostInventory?: { food: number, clothes: number, bullets: number }): CrossedRiverMessage {
    const time = currentTime();
    const roundIndex = 0;

    const lostStuff: string[] = [];
    if (!!lostInventory?.food) {
        lostStuff.push(formatFood(lostInventory.food) + ' of food');
    }
    if (!!lostInventory?.clothes) {
        lostStuff.push(formatClothes(lostInventory.clothes, true));
    }
    if (!!lostInventory?.bullets) {
        lostStuff.push(formatBullets(lostInventory.bullets));
    }

    let text: string;
    if (tippedOver && lostStuff.length === 0) {
        text = `This was a wild ride, the wagon tipped over!`;
    } else if (tippedOver && lostStuff.length > 0) {
        const stuff = formatAnd(lostStuff.map(thing => `:yellow:{${thing}}`));
        text = `This was a wild ride, the wagon tipped over! We lost ${stuff}.`;
    } else if (lostStuff.length > 0) {
        const stuff = formatAnd(lostStuff.map(thing => `:yellow:{${thing}}`));
        text = `This was a wild ride, we lost ${stuff}.`;
    } else {
        text = `We made it across :blue:{${riverCrossing.name}}.`;
    }

    return { type: 'Crossed River', date, time, roundIndex, text, riverCrossing, tippedOver, lostInventory };
}

export interface WeatherHappenedMessage extends Message {
    type: 'Weather Happened';
    name: WeatherEventName;
    losingDaysCount: number;
}

export function makeWeatherHappenedMessage(name: WeatherEventName, losingDaysCount: number, date: number): WeatherHappenedMessage {
    const time = currentTime();
    const roundIndex = 0;

    let text: string;
    if (losingDaysCount === 1) {
        text = `${name}, losing one day.`;
    } else if (losingDaysCount > 1) {
        text = `${name}, losing ${losingDaysCount} days.`;
    } else {
        text = `${name}.`;
    }

    return { type: 'Weather Happened', date, time, roundIndex, text, name, losingDaysCount };
}

export interface FoundWildFruitMessage extends Message {
    type: 'Found Wild Fruit';
    food: number;
}

export function makeFoundWildFruitMessage(food: number, date: number): FoundWildFruitMessage {
    const time = currentTime();
    const roundIndex = 0;
    const text = `:green:{Found wild fruit.}`;
    return { type: 'Found Wild Fruit', date, time, roundIndex, text, food };
}

export interface FoundWaterMessage extends Message {
    type: 'Found Water';
    water: number;
}

export function makeFoundWaterMessage(water: number, date: number): FoundWaterMessage {
    const time = currentTime();
    const roundIndex = 0;
    const text = `:green:{Found water.}`;
    return { type: 'Found Water', date, time, roundIndex, text, water };
}

export interface FoundAbandonedWagonMessage extends Message {
    type: 'Found Abandoned Wagon';
    items: {
        oxen: number;
        clothes: number;
        bullets: number;
        rifles: number;
        medicine: number;
        map: MapItem | null;
    };
}

export function makeFoundAbandonedWagonMessage(items: { oxen: number, clothes: number, bullets: number, rifles: number, medicine: number, map: MapItem | null }, date: number): FoundAbandonedWagonMessage {
    const time = currentTime();
    const roundIndex = 0;

    const stuff: string[] = [];
    if (items.oxen) {
        stuff.push(formatOxen(items.oxen));
    }
    if (items.clothes) {
        stuff.push(formatClothes(items.clothes, true));
    }
    if (items.bullets) {
        stuff.push(formatBullets(items.bullets));
    }
    if (items.rifles) {
        stuff.push(formatRifles(items.rifles));
    }
    if (items.medicine) {
        stuff.push(formatMedicine(items.medicine, true));
    }
    if (items.map) {
        stuff.push(`Map of ${items.map.regionName}`);
    }
    const what = formatAnd(stuff, 'nothing inside');
    const text = `:green:{Found an abandoned wagon with ${what}.}`;

    return { type: 'Found Abandoned Wagon', date, time, roundIndex, text, items };
}

export interface BrokeWagonMessage extends Message {
    type: 'Broke Wagon';
    brokenComponents: WagonComponentName[];
}

export function makeBrokeWagonMessage(brokenComponents: WagonComponentName[], date: number): BrokeWagonMessage {
    const time = currentTime();
    const roundIndex = 0;
    const things = formatAnd(brokenComponents, 'nothing');
    const text = `The wagon broke, lost :yellow:{${things}}.`;
    return { type: 'Broke Wagon', date, time, roundIndex, text, brokenComponents };
}

export interface NightlyThiefMessage extends Message {
    type: 'Nightly Thief';
    stolenItems: {
        food: number;
        clothes: number;
        bullets: number;
        medicine: number;
    };
}

export function makeNightlyThiefMessage(stolenItems: { food: number, clothes: number, bullets: number, medicine: number }, date: number): NightlyThiefMessage {
    const time = currentTime();
    const roundIndex = 0;

    const stuff: string[] = [];
    if (stolenItems.food) {
        stuff.push(formatFood(stolenItems.food) + ' of food');
    }
    if (stolenItems.clothes) {
        stuff.push(formatClothes(stolenItems.clothes, true));
    }
    if (stolenItems.bullets) {
        stuff.push(formatBullets(stolenItems.bullets));
    }
    if (stolenItems.medicine) {
        stuff.push(formatMedicine(stolenItems.medicine, true));
    }
    const things = formatAnd(stuff.map(thing => `:yellow:{${thing}}`), 'nothing');
    const text = `A thief came during the night and stole ${things}.`;
    
    return { type: 'Nightly Thief', date, time, roundIndex, text, stolenItems };
}

export interface WildAnimalsAttackingMessage extends Message {
    type: 'Wild Animals Attacking';
}

export function makeWildAnimalsAttackingMessage(date: number): WildAnimalsAttackingMessage {
    const time = currentTime();
    const roundIndex = 0;
    const text = `We're getting attacked by wild animals.`;
    return { type: 'Wild Animals Attacking', date, time, roundIndex, text };
}

export interface FightingAnimalsStartedMessage extends Message {
    type: 'Fighting Animals Started';
    animalAttack: AnimalAttack;
}

export function makeFightingAnimalsStartedMessage(animalAttack: AnimalAttack, date: number): FightingAnimalsStartedMessage {
    const time = currentTime();
    const roundIndex = 0;
    const text = `We decide to fight them off.`;
    return { type: 'Fighting Animals Started', date, time, roundIndex, text, animalAttack };
}

export interface FightingAnimalsEndedMessage extends Message {
    type: 'Fighting Animals Ended';
    animalAttack: AnimalAttack;
}

export function makeFightingAnimalsEndedMessage(animalAttack: AnimalAttack, date: number): FightingAnimalsEndedMessage {
    const formatKills = (count: number) => {
        if (count === 0) {
            return 'No animal was killed.';
        } else if (count === 1) {
            return ':yellow:{One} animal was killed. Call PETA!';
        } else {
            return `:yellow:{${count}} animals were killed. Call PETA!`;
        }
    };

    const time = currentTime();
    const roundIndex = 0;
    const kills = formatKills(animalAttack.result?.hits ?? 0);
    const food = animalAttack.result?.food ? ` We get ${formatFood(animalAttack.result.food)} of food from that.` : '';
    const text = kills + food;
    return { type: 'Fighting Animals Ended', date, time, roundIndex, text, animalAttack };
}

export interface FleeingFromAnimalsMessage extends Message {
    type: 'Fleeing From Animals';
    lostDays: number;
}

export function makeFleeingFromAnimalsMessage(lostDays: number, date: number): FleeingFromAnimalsMessage {
    const time = currentTime();
    const roundIndex = 0;
    const lostDaysText = lostDays > 0 ? ` We lost :yellow:{${lostDays} days}.` : '';
    const text = `It was rough, but we managed to flee from the animals. ${lostDaysText}`;
    return { type: 'Fleeing From Animals', date, time, roundIndex, text, lostDays };
}

export interface FleeingFromBanditsMessage extends Message {
    type: 'Fleeing From Bandits';
    lostDays: number;
}

export function makeFleeingFromBanditsMessage(lostDays: number, date: number): FleeingFromBanditsMessage {
    const time = currentTime();
    const roundIndex = 0;
    const lostDaysText = lostDays > 0 ? ` We lost :yellow:{${lostDays} days}.` : '';
    const text = `It was rough, but we managed to flee from the bandits. ${lostDaysText}`;
    return { type: 'Fleeing From Bandits', date, time, roundIndex, text, lostDays };
}

export interface RidersApproachingMessage extends Message {
    type: 'Riders Approaching';
}

export function makeRidersApproachingMessage(date: number): RidersApproachingMessage {
    const time = currentTime();
    const roundIndex = 0;
    const text = `We're hearing riders approaching. They sound hostile.`;
    return { type: 'Riders Approaching', date, time, roundIndex, text };
}

export interface FightingBanditsStartedMessage extends Message {
    type: 'Fighting Bandits Started';
    banditAttack: BanditAttack;
}

export function makeFightingBanditsStartedMessage(banditAttack: BanditAttack, date: number): FightingBanditsStartedMessage {
    const time = currentTime();
    const roundIndex = 0;
    const text = `We decided to fight.`;
    return { type: 'Fighting Bandits Started', date, time, roundIndex, text, banditAttack };
}

export interface FightingBanditsEndedMessage extends Message {
    type: 'Fighting Bandits Ended';
    banditAttack: BanditAttack;
}

export function makeFightingBanditsEndedMessage(banditAttack: BanditAttack, date: number): FightingBanditsEndedMessage {
    const time = currentTime();
    const roundIndex = 0;

    let text: string;
    if (banditAttack.result) {
        const results = banditAttack.result;
        if (results.bullets < 0 || results.clothes < 0 || results.cash < 0) {
            const stolenStuff: string[] = [];
            if (results.bullets < 0) {
                stolenStuff.push(formatBullets(-results.bullets));
            }
            if (results.clothes < 0) {
                stolenStuff.push(formatClothes(-results.clothes, true));
            }
            if (results.cash < 0) {
                stolenStuff.push(formatCash(-results.cash) + ' of cash');
            }
            const things = formatAnd(stolenStuff.map(thing => `:yellow:{${thing}}`));
            text = `It was a hard fight, they were able to steal ${things} from us.`;
        } else if (results.bullets > 0 || results.clothes > 0 || results.cash > 0) {
            const stolenStuff: string[] = [];
            if (results.bullets < 0) {
                stolenStuff.push(formatBullets(results.bullets));
            }
            if (results.clothes < 0) {
                stolenStuff.push(formatClothes(results.clothes, true));
            }
            if (results.cash < 0) {
                stolenStuff.push(formatCash(results.cash) + ' of cash');
            }
            const things = formatAnd(stolenStuff.map(thing => `:yellow:{${thing}}`));
            text = `It was a hard fight, but we made it. We were able to steal ${things} from them.`;
        } else {
            text = `We could scare them away before they were able to attack.`;
        }
    } else {
        text = `We could scare them away before they were able to attack.`;
    }

    return { type: 'Fighting Bandits Ended', date, time, roundIndex, text, banditAttack };
}

export interface OpeningChestMessage extends Message {
    type: 'Opening Chest';
    chest: Chest;
}

export function makeOpeningChestMessage(chest: Chest, date: number): OpeningChestMessage {
    const time = currentTime();
    const roundIndex = 0;

    let text: string;
    if (chest.lock !== null && chest.lock.result === 'unlocked' && chest.lock.tries.length > 0) {
        const attempt = chest.lock.tries[chest.lock.tries.length - 1];
        text = `:blue:{${attempt.playerName}} opened the chest... and finds :yellow:{${chest.contentType}}.`;
    } else if (chest.lock === null || chest.lock.result === 'unlocked') {
        text = `We're opening a chest... and find :yellow:{${chest.contentType}}.`;
    } else {
        text = `We're trying to open a chest, but it's locked.`;
    }
    
    return { type: 'Opening Chest', date, time, roundIndex, text, chest };
}

export interface OpeningChestLockMessage extends Message {
    type: 'Opening Chest Lock';
    chest: Chest;
    ended: boolean;
}

export function makeOpeningChestLockMessage(chest: Chest, date: number): OpeningChestLockMessage {
    const formatSymbols = (count: number, color: string) => {
        if (count === 0) {
            return 'no emotes';
        } else if (count === 1) {
            return `:${color}:{one} emote`;
        } else {
            return `:${color}:{${count}} emotes`;
        }
    };

    const time = currentTime();
    const roundIndex = 0;

    let text: string;
    let ended = false;
    let unlocked = false;

    const attempt = (chest.lock === null || chest.lock.tries.length === 0) ? null : chest.lock.tries[chest.lock.tries.length - 1];
    if (chest.lock !== null && attempt !== null) {
        ended = chest.lock.tries.length >= chest.lock.maxTries;
        unlocked = chest.lock.result === 'unlocked' && attempt.symbols.filter((symbol, index) => symbol === chest.lock?.expectedSymbols[index]).length === chest.lock.expectedSymbols.length;
        if (unlocked) {
            ended = true;
            text = `:blue:{${attempt.playerName}} unlocked the chest. Woo! Inside we found :yellow:{${chest.contentType.toLowerCase()}}.`;
        } else {
            text = `:blue:{${attempt.playerName}} tried their luck, but the lock didn't open.`;
            if (attempt.numberOfCorrectSymbolsInCorrectPlace) {
                text += ` ${formatSymbols(attempt.numberOfCorrectSymbolsInCorrectPlace, 'green')} are in the :green:{correct position}.`;
            }
            if (attempt.numberOfCorrectSymbolsInWrongPlace) {
                text += ` ${formatSymbols(attempt.numberOfCorrectSymbolsInWrongPlace, 'yellow')} are in the :yellow:{wrong position}.`;
            }
            if (ended) {
                text += ` Too bad, this was the last attempt. The chest is locked forever.`;
            } else {
                text += ` Try again.`;
            }
        }
    } else {
        text = `The lock didn't open up.`;
        ended = false;
    }

    return { type: 'Opening Chest Lock', date, time, roundIndex, text, chest, ended };
}

export interface UnlockedAchievementMessage extends Message {
    type: 'Unlocked Achievement';
    achievement: Achievement;
}

export function makeUnlockedAchievementMessage(achievement: Achievement, date: number): UnlockedAchievementMessage {
    const time = achievement.createdAt;
    const roundIndex = 0;
    const text = `Unlocked achievement :yellow:{${achievement.name}}.`;
    return { type: 'Unlocked Achievement', date, time, roundIndex, text, achievement };
}

export interface HangmanGuessMessage extends Message {
    type: 'Hangman Guess';
    guess: HangmanTry;
}

export function makeHangmanGuessMessage(guess: HangmanTry, date: number): HangmanGuessMessage {
    const time = currentTime();
    const roundIndex = 0;
    const text = guess.correct
        ? `:blue:{${guess.playerName}} guessed ${guess.guessedCharacter.toUpperCase()} :green:{correctly}.`
        : `:blue:{${guess.playerName}} guessed ${guess.guessedCharacter.toUpperCase()} :red:{wrong}.`;
    
    return { type: 'Hangman Guess', date, time, roundIndex, text, guess };
}

export interface HangmanEndedMessage extends Message {
    type: 'Hangman Ended';
    game: HangmanGame;
}

export function makeHangmanEndedMessage(game: HangmanGame, date: number): HangmanEndedMessage {
    const time = currentTime();
    const roundIndex = 0;
    const text = game.status === 'won'
        ? `Woohoo! We won the game of hangman!`
        : `Boooo! We lost the game of hangman!`;
    
    return { type: 'Hangman Ended', date, time, roundIndex, text, game };
}

export interface AteAtInnMessage extends Message {
    type: 'Ate At Inn';
    inn: Inn;
    price: number;
}

export function makeAteAtInnMessage(inn: Inn, price: number, date: number): AteAtInnMessage {
    const time = currentTime();
    const roundIndex = 0;
    const text = `We enjoyed a nice meal at :blue:{${inn.name}}.`;
    return { type: 'Ate At Inn', date, time, roundIndex, text, inn, price };
}

export interface Found1836ColtMessage extends Message {
    type: 'Found 1836 Colt';
}

export function makeFound1836ColtMessage(date: number): Found1836ColtMessage {
    const time = currentTime();
    const roundIndex = 0;
    const text = 'Found the :blue:{1836 Colt}.';
    return { type: 'Found 1836 Colt', date, time, roundIndex, text };
}
