import { chooseHuntedAnimals, determineWeightCarriedFromHunting } from "./Hunting";
import { Inventory } from "./inventory/Inventory";
import { isDead, Player } from "./Player";
import { randomElement, randomEvent } from "./Random";
import { HuntingStat } from "./Stat";
import { Wagon } from "./Wagon";

const FIGHTING_WORDS = ['shoot', 'pew', 'boom', 'bang', 'bazinga', 'vaffanculo'];

function randomFightingWord(): string {
    return randomElement(FIGHTING_WORDS)!;
}

export interface FightingResult {
    killedPlayerName: string | null;
    injuredPlayerName: string | null;
    bulletsUsed: number;
    hits: number;
    food: number;
}

export interface AnimalAttack {
    start: number;
    word: string;
    attackingPlayers: string[];
    hits: string[];
    result?: FightingResult;
}

export function makeAnimalAttack(attackingPlayers: string[]): AnimalAttack {
    const start = new Date().getTime();
    const word = randomFightingWord();
    const hits: string[] = [];

    return { start, word, attackingPlayers, hits };
}

export function registerShot(animalAttack: AnimalAttack, playerName: string, word: string): AnimalAttack {
    const attacker = animalAttack.attackingPlayers.includes(playerName);
    const attacked = animalAttack.hits.includes(playerName);
    if (word === animalAttack.word && attacker && !attacked) {
        const hits = [...animalAttack.hits, playerName];
        return { ...animalAttack, hits };
    }
    return animalAttack;
}

export function makeFightingResult(attack: AnimalAttack, players: Player[], huntingStat: HuntingStat, inventory: Inventory, wagons: Wagon[]): FightingResult {
    const hits = attack.hits;
    const bulletsUsed = Math.max(hits.length, attack.attackingPlayers.length * 3);
    const successRate = attack.attackingPlayers.length === 0 ? 0 : hits.length / attack.attackingPlayers.length;
    const animals = chooseHuntedAnimals(successRate * 100);
    const food = determineWeightCarriedFromHunting(animals.map(animal => animal.weight).reduce((prev, curr) => prev + curr, 0), attack.attackingPlayers.length, inventory, wagons);

    const probabilityOfKilledPlayer = successRate < 0.3 ? 0.03 : 0.005;
    let killedPlayerName: string | null = null;
    if (randomEvent(probabilityOfKilledPlayer)) {
        const player = randomElement(players.filter(player => !isDead(player)));
        if (player) {
            killedPlayerName = player.name;
        }
    }

    const probabilityOfInjuredPlayer = successRate < 0.6 ? 0.10 : 0.01;
    let injuredPlayerName: string | null = null;
    if (randomEvent(probabilityOfInjuredPlayer)) {
        const player = randomElement(players.filter(player => !isDead(player) && player.name !== killedPlayerName));
        if (player) {
            injuredPlayerName = player.name;
        }
    }

    return { hits: hits.length, bulletsUsed, food, killedPlayerName, injuredPlayerName };
}