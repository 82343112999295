export type ClimateName = 'Tropical' | 'Dry' | 'Moderate' | 'Continental' | 'Polar';

export interface Climate {
    name: ClimateName;
    avgTemperature: number;
    minTemperature: number;
    maxTemperature: number;
    avgHumidity: number;
    avgRainfall: number;
}

export function climateNumericValue(climate: ClimateName): number {
    switch (climate) {
        case 'Tropical':    return 0;
        case 'Dry':         return 1;
        case 'Moderate':    return 2;
        case 'Continental': return 3;
        case 'Polar':       return 4;
    }
}

const polar: Climate[] = [
    {
        name: 'Polar',
        avgTemperature: -2.9,
        maxTemperature: -0.2,
        minTemperature: -5.5,
        avgRainfall: 15,
        avgHumidity: 1,
    }, {
        name: 'Polar',
        avgTemperature: -9.5,
        maxTemperature: -6.3,
        minTemperature: -11.6,
        avgRainfall: 21.2,
        avgHumidity: 2,
    }, {
        name: 'Polar',
        avgTemperature: -18.2,
        maxTemperature: -14,
        minTemperature: -21.1,
        avgRainfall: 24.1,
        avgHumidity: 4,
    }, {
        name: 'Polar',
        avgTemperature: -20.7,
        maxTemperature: -17.4,
        minTemperature: -24.9,
        avgRainfall: 18.4,
        avgHumidity: 2,
    }, {
        name: 'Polar',
        avgTemperature: -21.7,
        maxTemperature: -19,
        minTemperature: -27.1,
        avgRainfall: 18.4,
        avgHumidity: 1,
    }, {
        name: 'Polar',
        avgTemperature: -23,
        maxTemperature: -19.1,
        minTemperature: -27.3,
        avgRainfall: 24.9,
        avgHumidity: 4,
    }, {
        name: 'Polar',
        avgTemperature: -25.7,
        maxTemperature: -21.7,
        minTemperature: -30.1,
        avgRainfall: 15.6,
        avgHumidity: 5,
    }, {
        name: 'Polar',
        avgTemperature: -26.1,
        maxTemperature: -22.8,
        minTemperature: -31.8,
        avgRainfall: 11.3,
        avgHumidity: 3,
    }, {
        name: 'Polar',
        avgTemperature: -24.6,
        maxTemperature: -20.8,
        minTemperature: -29.4,
        avgRainfall: 11.8,
        avgHumidity: 2,
    }, {
        name: 'Polar',
        avgTemperature: -18.9,
        maxTemperature: -15.5,
        minTemperature: -23.4,
        avgRainfall: 9.7,
        avgHumidity: 3,
    }, {
        name: 'Polar',
        avgTemperature: -9.7,
        maxTemperature: -6.7,
        minTemperature: -12.7,
        avgRainfall: 9.5,
        avgHumidity: 4,
    }, {
        name: 'Polar',
        avgTemperature: -3.4,
        maxTemperature: -0.8,
        minTemperature: -6,
        avgRainfall: 15.7,
        avgHumidity: 2,
    }
];

const continental: Climate[] = [
    {
        name: 'Continental',
        avgTemperature: 20.5,
        maxTemperature: 28,
        minTemperature: 13,
        avgRainfall: 54,
        avgHumidity: 53,
    }, {
        name: 'Continental',
        avgTemperature: 20,
        maxTemperature: 27,
        minTemperature: 13,
        avgRainfall: 55,
        avgHumidity: 54,
    }, {
        name: 'Continental',
        avgTemperature: 17,
        maxTemperature: 24,
        minTemperature: 10,
        avgRainfall: 63,
        avgHumidity: 58,
    }, {
        name: 'Continental',
        avgTemperature: 14,
        maxTemperature: 20,
        minTemperature: 7,
        avgRainfall: 55,
        avgHumidity: 58,
    }, {
        name: 'Continental',
        avgTemperature: 9,
        maxTemperature: 15,
        minTemperature: 3,
        avgRainfall: 52,
        avgHumidity: 71,
    }, {
        name: 'Continental',
        avgTemperature: 7,
        maxTemperature: 12,
        minTemperature: 1,
        avgRainfall: 49,
        avgHumidity: 79,
    }, {
        name: 'Continental',
        avgTemperature: 6,
        maxTemperature: 11,
        minTemperature: 0,
        avgRainfall: 41,
        avgHumidity: 78,
    }, {
        name: 'Continental',
        avgTemperature: 7,
        maxTemperature: 13,
        minTemperature: 1,
        avgRainfall: 50,
        avgHumidity: 70,
    }, {
        name: 'Continental',
        avgTemperature: 10,
        maxTemperature: 16,
        minTemperature: 3,
        avgRainfall: 41,
        avgHumidity: 62,
    }, {
        name: 'Continental',
        avgTemperature: 13,
        maxTemperature: 19,
        minTemperature: 6,
        avgRainfall: 70,
        avgHumidity: 55,
    }, {
        name: 'Continental',
        avgTemperature: 15,
        maxTemperature: 22,
        minTemperature: 8,
        avgRainfall: 54,
        avgHumidity: 53,
    }, {
        name: 'Continental',
        avgTemperature: 18.5,
        maxTemperature: 26,
        minTemperature: 11,
        avgRainfall: 47,
        avgHumidity: 53,
    }
];

const moderate: Climate[] = [
    {
        name: 'Moderate',
        avgTemperature: -6.1,
        maxTemperature: 8.6,
        minTemperature: -8.8,
        avgRainfall: 40,
        avgHumidity: 85,
    }, {
        name: 'Moderate',
        avgTemperature: -6,
        maxTemperature: 10.2,
        minTemperature: -8.8,
        avgRainfall: 31,
        avgHumidity: 82,
    }, {
        name: 'Moderate',
        avgTemperature: -1.4,
        maxTemperature: 14.9,
        minTemperature: -4.2,
        avgRainfall: 35,
        avgHumidity: 77,
    }, {
        name: 'Moderate',
        avgTemperature: 4.4,
        maxTemperature: 25.3,
        minTemperature: 1,
        avgRainfall: 33,
        avgHumidity: 71,
    }, {
        name: 'Moderate',
        avgTemperature: 10.9,
        maxTemperature: 30.9,
        minTemperature: 6.6,
        avgRainfall: 38,
        avgHumidity: 64,
    }, {
        name: 'Moderate',
        avgTemperature: 15.8,
        maxTemperature: 34.6,
        minTemperature: 11.8,
        avgRainfall: 64,
        avgHumidity: 66,
    }, {
        name: 'Moderate',
        avgTemperature: 18.1,
        maxTemperature: 45.3,
        minTemperature: 14.4,
        avgRainfall: 78,
        avgHumidity: 69,
    }, {
        name: 'Moderate',
        avgTemperature: 16.4,
        maxTemperature: 37.1,
        minTemperature: 13,
        avgRainfall: 77,
        avgHumidity: 74,
    }, {
        name: 'Moderate',
        avgTemperature: 11,
        maxTemperature: 30.4,
        minTemperature: 8.1,
        avgRainfall: 67,
        avgHumidity: 79,
    }, {
        name: 'Moderate',
        avgTemperature: 5.6,
        maxTemperature: 21,
        minTemperature: 3.4,
        avgRainfall: 65,
        avgHumidity: 82,
    }, {
        name: 'Moderate',
        avgTemperature: -0.1,
        maxTemperature: 12.3,
        minTemperature: -2.1,
        avgRainfall: 56,
        avgHumidity: 85,
    }, {
        name: 'Moderate',
        avgTemperature: -3.9,
        maxTemperature: 10.9,
        minTemperature: -6.4,
        avgRainfall: 49,
        avgHumidity: 86,
    }
];

const dry: Climate[] = [
    {
        name: 'Dry',
        avgTemperature: 27,
        maxTemperature: 33,
        minTemperature: 21,
        avgRainfall: 102,
        avgHumidity: 70,
    }, {
        name: 'Dry',
        avgTemperature: 26.5,
        maxTemperature: 33,
        minTemperature: 20,
        avgRainfall: 88,
        avgHumidity: 70,
    }, {
        name: 'Dry',
        avgTemperature: 26,
        maxTemperature: 33,
        minTemperature: 19,
        avgRainfall: 46,
        avgHumidity: 68,
    }, {
        name: 'Dry',
        avgTemperature: 24,
        maxTemperature: 32,
        minTemperature: 16,
        avgRainfall: 19,
        avgHumidity: 65,
    }, {
        name: 'Dry',
        avgTemperature: 20.5,
        maxTemperature: 29,
        minTemperature: 12,
        avgRainfall: 10,
        avgHumidity: 61,
    }, {
        name: 'Dry',
        avgTemperature: 18,
        maxTemperature: 27,
        minTemperature: 9,
        avgRainfall: 1,
        avgHumidity: 54,
    }, {
        name: 'Dry',
        avgTemperature: 17.5,
        maxTemperature: 27,
        minTemperature: 9,
        avgRainfall: 1,
        avgHumidity: 56,
    }, {
        name: 'Dry',
        avgTemperature: 21,
        maxTemperature: 30,
        minTemperature: 12,
        avgRainfall: 4,
        avgHumidity: 60,
    }, {
        name: 'Dry',
        avgTemperature: 25,
        maxTemperature: 34,
        minTemperature: 16,
        avgRainfall: 5,
        avgHumidity: 66,
    }, {
        name: 'Dry',
        avgTemperature: 28,
        maxTemperature: 36,
        minTemperature: 20,
        avgRainfall: 13,
        avgHumidity: 69,
    }, {
        name: 'Dry',
        avgTemperature: 28,
        maxTemperature: 35,
        minTemperature: 21,
        avgRainfall: 46,
        avgHumidity: 71,
    }, {
        name: 'Dry',
        avgTemperature: 27.5,
        maxTemperature: 34,
        minTemperature: 21,
        avgRainfall: 67,
        avgHumidity: 69,
    }
];

const tropical: Climate[] = [
    {
        name: 'Tropical',
        avgTemperature: 12,
        maxTemperature: 18,
        minTemperature: 7,
        avgRainfall: 40,
        avgHumidity: 73,
    }, {
        name: 'Tropical',
        avgTemperature: 13,
        maxTemperature: 18,
        minTemperature: 6,
        avgRainfall: 50,
        avgHumidity: 74,
    }, {
        name: 'Tropical',
        avgTemperature: 13,
        maxTemperature: 18,
        minTemperature: 7,
        avgRainfall: 80,
        avgHumidity: 77,
    }, {
        name: 'Tropical',
        avgTemperature: 13,
        maxTemperature: 19,
        minTemperature: 8,
        avgRainfall: 110,
        avgHumidity: 78,
    }, {
        name: 'Tropical',
        avgTemperature: 13,
        maxTemperature: 18,
        minTemperature: 8,
        avgRainfall: 100,
        avgHumidity: 79,
    }, {
        name: 'Tropical',
        avgTemperature: 13,
        maxTemperature: 18,
        minTemperature: 8,
        avgRainfall: 60,
        avgHumidity: 72,
    }, {
        name: 'Tropical',
        avgTemperature: 13,
        maxTemperature: 17,
        minTemperature: 8,
        avgRainfall: 40,
        avgHumidity: 70,
    }, {
        name: 'Tropical',
        avgTemperature: 12,
        maxTemperature: 17,
        minTemperature: 8,
        avgRainfall: 40,
        avgHumidity: 63,
    }, {
        name: 'Tropical',
        avgTemperature: 12,
        maxTemperature: 17,
        minTemperature: 7,
        avgRainfall: 50,
        avgHumidity: 62,
    }, {
        name: 'Tropical',
        avgTemperature: 13,
        maxTemperature: 18,
        minTemperature: 8,
        avgRainfall: 140,
        avgHumidity: 55,
    }, {
        name: 'Tropical',
        avgTemperature: 13,
        maxTemperature: 18,
        minTemperature: 8,
        avgRainfall: 110,
        avgHumidity: 55,
    }, {
        name: 'Tropical',
        avgTemperature: 12,
        maxTemperature: 18,
        minTemperature: 7,
        avgRainfall: 60,
        avgHumidity: 53,
    }
];

export function getClimates(name: ClimateName): Climate[] {
    switch (name) {
        case 'Polar': return polar;
        case 'Continental': return continental;
        case 'Moderate': return moderate;
        case 'Dry': return dry;
        case 'Tropical': return tropical;
    }
}

export function getClimate(name: ClimateName, date: Date): Climate {
    const month = date.getMonth();
    return getClimates(name)[month];
}