import { isDead, Player } from "../Player";
import { POUND_PER_KILOGRAM } from "../Utilities";
import { determineWagonWeightCapacity, Wagon } from "../Wagon";
import { Bullets, determineBulletsForWeight, determineBulletWeight, makeBullets } from "./Bullets";
import { Cash, makeCash } from "./Cash";
import { Clothes, determineClothesForWeight, determineClothesWeight, makeClothes } from "./Clothes";
import { determineFoodForWeight, determineFoodWeight, Food, makeFood } from "./Food";
import { InventoryItemName } from "./InventoryItem";
import { determineMedicineForWeight, determineMedicineWeight, makeMedicine, Medicine } from "./Medicine";
import { determineRiflesForWeight, determineRifleWeight, makeRifles, RifleName, Rifles } from "./Rifles";
import { determineWagonAxlesForWeight, determineWagonAxleWheel, determineWagonTonguesForWeight, determineWagonTongueWeight, determineWagonWheelsForWeight, determineWagonWheelWeight, makeWagonAxles, makeWagonTongues, makeWagonWheels, WagonAxles, WagonTongues, WagonWheels } from "./SpareParts";
import { determineWaterForWeight, determineWaterWeight, makeWater, Water } from "./Water";

export interface Inventory {
    cash: Cash;
    food: Food;
    water: Water;
    bullets: Bullets;
    clothes: Clothes;
    medicine: Medicine;
    wagonTongues: WagonTongues;
    wagonWheels: WagonWheels;
    wagonAxles: WagonAxles;
    rifles: Rifles;
}

export function makeInventory(initialInvestment: number = 200): Inventory {
    const cash = makeCash(initialInvestment);
    const food = makeFood();
    const water = makeWater(0, 0);
    const bullets = makeBullets();
    const clothes = makeClothes();
    const medicine = makeMedicine();
    const wagonTongues = makeWagonTongues();
    const wagonWheels = makeWagonWheels();
    const wagonAxles = makeWagonAxles();
    const rifles = makeRifles();

    return { cash, food, water, bullets, clothes, medicine, wagonTongues, wagonWheels, wagonAxles, rifles };
}

export function makeInventoryChanges(before: Inventory, after: Inventory): Array<{ name: InventoryItemName, change: number }> {
    const cash = after.cash.amount - before.cash.amount;
    const food = after.food.amount - before.food.amount;
    const water = after.water.amount - before.water.amount;
    const bullets = after.bullets.amount - before.bullets.amount;
    const clothes = after.clothes.amount - before.clothes.amount;
    const medicine = after.medicine.amount - before.medicine.amount;
    const wagonTongues = after.wagonTongues.amount - before.wagonTongues.amount;
    const wagonWheels = after.wagonWheels.amount - before.wagonWheels.amount;
    const wagonAxles = after.wagonAxles.amount - before.wagonAxles.amount;
    const rifles =  after.rifles.amount - before.rifles.amount;

    const changes: Array<{ name: InventoryItemName, change: number }> = [];
    
    const add = (name: InventoryItemName, change: number) => {
        if (change !== 0) {
            changes.push({ name, change });
        }
    };

    add('Cash', cash);
    add('Food', food);
    add('Water', water);
    add('Bullets', bullets);
    add('Clothes', clothes);
    add('Medicine', medicine);
    add('Wagon Tongues', wagonTongues);
    add('Wagon Wheels', wagonWheels);
    add('Wagon Axles', wagonAxles);
    add('Rifles', rifles);

    return changes;
}

export function determineInventoryWeight(inventory: Inventory): number {
    return determineFoodWeight(inventory.food)
        + determineWaterWeight(inventory.water)
        + determineBulletWeight(inventory.bullets)
        + determineClothesWeight(inventory.clothes)
        + determineMedicineWeight(inventory.medicine)
        + determineWagonTongueWeight(inventory.wagonTongues)
        + determineWagonWheelWeight(inventory.wagonWheels)
        + determineWagonAxleWheel(inventory.wagonAxles)
        + determineRifleWeight(inventory.rifles);
}

export function determineInventoryCapacity(inventory: Inventory, wagons: Wagon[], players: Player[]): number {
    const alivePlayerCount = players
        .filter(player => !isDead(player))
        .length;
    
    const carryWeightPerPerson = 10 / POUND_PER_KILOGRAM;
    const carryWeight = alivePlayerCount * carryWeightPerPerson;

    const totalWagonCapacity = wagons
        .map(determineWagonWeightCapacity)
        .reduce((prev, curr) => prev + curr, 0);
    
    return Math.max(totalWagonCapacity, carryWeight);
}

export function calculateFoodCapacity(inventory: Inventory, wagons: Wagon[], players: Player[]): number {
    const totalWeight = determineInventoryWeight(inventory);
    const capacity = determineInventoryCapacity(inventory, wagons, players);
    const remaining = Math.max(0, capacity - totalWeight);
    return determineFoodForWeight(remaining);
}

export function calculateWaterCapacity(inventory: Inventory, wagons: Wagon[], players: Player[]): number {
    const totalWeight = determineInventoryWeight(inventory);
    const capacity = determineInventoryCapacity(inventory, wagons, players);
    const remaining = Math.max(0, capacity - totalWeight);
    return determineWaterForWeight(remaining);
}

export function calculateBulletsCapacity(inventory: Inventory, wagons: Wagon[], players: Player[]): number {
    const totalWeight = determineInventoryWeight(inventory);
    const capacity = determineInventoryCapacity(inventory, wagons, players);
    const remaining = Math.max(0, capacity - totalWeight);
    return determineBulletsForWeight(remaining);
}

export function calculateClothesCapacity(inventory: Inventory, wagons: Wagon[], players: Player[]): number {
    const totalWeight = determineInventoryWeight(inventory);
    const capacity = determineInventoryCapacity(inventory, wagons, players);
    const remaining = Math.max(0, capacity - totalWeight);
    return determineClothesForWeight(remaining);
}

export function calculateMedicineCapacity(inventory: Inventory, wagons: Wagon[], players: Player[]): number {
    const totalWeight = determineInventoryWeight(inventory);
    const capacity = determineInventoryCapacity(inventory, wagons, players);
    const remaining = Math.max(0, capacity - totalWeight);
    return determineMedicineForWeight(remaining);
}

export function calculateWagonTonguesCapacity(inventory: Inventory, wagons: Wagon[], players: Player[]): number {
    const totalWeight = determineInventoryWeight(inventory);
    const capacity = determineInventoryCapacity(inventory, wagons, players);
    const remaining = Math.max(0, capacity - totalWeight);
    return determineWagonTonguesForWeight(remaining);
}

export function calculateWagonWheelsCapacity(inventory: Inventory, wagons: Wagon[], players: Player[]): number {
    const totalWeight = determineInventoryWeight(inventory);
    const capacity = determineInventoryCapacity(inventory, wagons, players);
    const remaining = Math.max(0, capacity - totalWeight);
    return determineWagonWheelsForWeight(remaining);
}

export function calculateWagonAxlesCapacity(inventory: Inventory, wagons: Wagon[], players: Player[]): number {
    const totalWeight = determineInventoryWeight(inventory);
    const capacity = determineInventoryCapacity(inventory, wagons, players);
    const remaining = Math.max(0, capacity - totalWeight);
    return determineWagonAxlesForWeight(remaining);
}

export function calculateRiflesCapacity(name: RifleName, inventory: Inventory, wagons: Wagon[], players: Player[]): number {
    const totalWeight = determineInventoryWeight(inventory);
    const capacity = determineInventoryCapacity(inventory, wagons, players);
    const remaining = Math.max(0, capacity - totalWeight);
    return determineRiflesForWeight(remaining, name);
}
