import { determineInventoryWeight, Inventory } from "./inventory/Inventory";
import { resourceAbundanceNumericValue } from "./location/Resource";
import { randomElement, randomInt, rollDice } from "./Random";
import { HuntingStat } from "./Stat";
import { Trail, trailInterpolatedValueBetweenLocations } from "./travel/Trail";
import { POUND_PER_KILOGRAM } from "./Utilities";
import { determineWagonWeightCapacity, Wagon } from "./Wagon";

const HUNTING_WORDS = ['shoot', 'pew', 'boom', 'bang', 'bazinga', 'vaffanculo'];

export type HuntedAnimalName = 'Deer' | 'Fox' | 'Hare' | 'Bear' | 'Rooster';

type Category = 0 | 1 | 2 | 3 | 4; // 0=bad, 4=great

export function pluralizeHuntedAnimalName(name: HuntedAnimalName): string {
    if (name === 'Deer') {
        return 'Deer';
    } else {
        return name + 's';
    }
}

interface HuntedAnimal {
    name: HuntedAnimalName;
    weight: number;
}

function randomHuntingWord(): string {
    return randomElement(HUNTING_WORDS)!;
}

export interface HuntingResult {
    type: 'Hit' | 'Miss';
    playerName: string;
    duration: number; // in seconds
    bullets: number;
}

export interface HuntingHit extends HuntingResult {
    type: 'Hit';
    successRate: number;
    food: number;
    animals: HuntedAnimal[];
}

export interface HuntingMiss extends HuntingResult {
    type: 'Miss';
}

export interface Hunting {
    playerName: string;
    start: number;
    word: string;
    result?: HuntingResult;
}

export function makeHunting(playerName: string): Hunting {
    const start = new Date().getTime();
    const word = randomHuntingWord();

    return { playerName, start, word };
}

export function determineBulletsCount(duration: number): number {
    return Math.ceil(duration);
}

export function calculateHuntingSuccessRate(duration: { actual: number, maximum: number }, bullets: { actual: number, maximum: number }): number {
    if (duration.maximum === 0 || bullets.maximum === 0) {
        return 0;
    }

    // each rate is between 0 and 1, where 1 would be perfect score
    const durationRate = Math.max(0, 1 - duration.actual / duration.maximum);
    const bulletRate = Math.max(0, 1 - bullets.actual / bullets.maximum);

    // scale that to the range of 0 through 1.3
    return 1.3 * durationRate * bulletRate;
}

export function determineCategoryFromFoodAbundance(value: number): Category {
    if (value >= 2) {
        return 4;
    } else if (value >= 1.5) {
        return 3;
    } else if (value >= 1) {
        return 2;
    } else if (value >= 0.5) {
        return 1;
    } else {
        return 0;
    }
}

export function determineMaxHuntingAnimalWeight(category: Category): number {
    switch (category) {
    case 0: return 0;
    case 1: return 150 / POUND_PER_KILOGRAM;
    case 2: return 300 / POUND_PER_KILOGRAM;
    case 3: return 450 / POUND_PER_KILOGRAM;
    case 4: return 600 / POUND_PER_KILOGRAM;
    }
}

export function determineActualHuntingAnimalWeight(category: Category, successRate: number, huntingStat: HuntingStat): number {
    switch (category) {
    case 0: return 0;
    case 1: return successRate * ( 25 + rollDice( 50) +  5 * huntingStat.value);
    case 2: return successRate * ( 50 + rollDice(100) + 10 * huntingStat.value);
    case 3: return successRate * ( 75 + rollDice(150) + 15 * huntingStat.value);
    case 4: return successRate * (100 + rollDice(200) + 20 * huntingStat.value);
    }
}

function makeDeer(): HuntedAnimal {
    return { name: 'Deer', weight: 50 };
}

function makeFox(): HuntedAnimal {
    return { name: 'Fox', weight: 7 };
}

function makeHare(): HuntedAnimal {
    return { name: 'Hare', weight: 5 };
}

function makeBear(): HuntedAnimal {
    return { name: 'Bear', weight: 200 };
}

function makeRooster(): HuntedAnimal {
    return { name: 'Rooster', weight: 21.31884 };
}

export function chooseHuntedAnimals(weight: number) {
    const deer = makeDeer();
    const fox = makeFox();
    const hare = makeHare();
    const bear = makeBear();
    const rooster = makeRooster();
    
    const choices = [deer, fox, hare, bear, rooster]
        .sort((a, b) => b.weight - a.weight);
    
    const totalWeight = (animals: HuntedAnimal[]) => animals
        .map(animal => animal.weight)
        .reduce((prev, curr) => prev + curr, 0);
    
    const animals: HuntedAnimal[] = [];

    while (totalWeight(animals) < weight) {
        const delta = weight - totalWeight(animals);
        const animal = choices.find(choice => choice.weight <= delta);
        if (animal !== undefined) {
            animals.push(animal);
        } else if (delta > 0) {
            animals.push(hare);
        }
    }

    return animals;
}

export function determineWeightCarriedFromHunting(totalWeight: number, peopleCount: number, inventory: Inventory, wagons: Wagon[]): number {
    const carryWeightPerPerson = 20;
    const carryWeight = carryWeightPerPerson * peopleCount;
    const totalInventoryWeight = determineInventoryWeight(inventory);
    const totalWagonCapacity = wagons.map(determineWagonWeightCapacity).reduce((prev, curr) => prev + curr, 0);
    const capacity = Math.max(0, totalWagonCapacity - totalInventoryWeight);

    return Math.min(carryWeight, totalWeight, capacity);
}

export function makeHit({ hunting, duration, bullets, peopleCount, huntingStat, trail, inventory, wagons }: { hunting: Hunting, duration: { actual: number, maximum: number }, bullets: { actual: number, maximum: number }, peopleCount: number, huntingStat: HuntingStat, trail: Trail, inventory: Inventory, wagons: Wagon[] }): HuntingHit {
    const { playerName } = hunting;

    // The success rate is a value between 0 and 1.3
    const successRate = calculateHuntingSuccessRate(duration, bullets);

    // Determine the weight of animals we hunted.
    const category = determineCategoryFromFoodAbundance(trailInterpolatedValueBetweenLocations(trail, location => resourceAbundanceNumericValue(location.resources.food)));
    const maxWeight = determineMaxHuntingAnimalWeight(category);
    const weight = Math.min(maxWeight, determineActualHuntingAnimalWeight(category, successRate, huntingStat));

    // Choose the animals matching roughly that weight.
    const animals = chooseHuntedAnimals(weight);
    const totalWeight = animals.map(animal => animal.weight).reduce((prev, curr) => prev + curr, 0);
    const food = determineWeightCarriedFromHunting(totalWeight, peopleCount, inventory, wagons);

    return { type: 'Hit', duration: duration.actual, playerName, bullets: bullets.actual, successRate, food, animals };
}

export function makeMiss({ hunting, duration, bullets }: { hunting: Hunting, duration: { actual: number, maximum: number }, bullets: { actual: number, maximum: number } }): HuntingMiss {
    const { playerName } = hunting;

    return { type: 'Miss', duration: duration.actual, playerName, bullets: bullets.actual };
}

export function makeHuntingResult({ hunting, word, maxDuration, maxBullets, peopleCount, huntingStat, trail, inventory, wagons }: { hunting: Hunting, word: string, maxDuration: number, maxBullets: number, peopleCount: number, huntingStat: HuntingStat, trail: Trail, inventory: Inventory, wagons: Wagon[] }): HuntingResult {
    const end = new Date().getTime();
    const duration = (end - hunting.start) / 1000;
    const bulletsRequired = determineBulletsCount(duration);
    const bullets = Math.min(bulletsRequired, maxBullets);

    // Correct word and enough bullets
    if (hunting.word === word && bullets === bulletsRequired) {
        return makeHit({
            hunting,
            duration: { actual: duration, maximum: maxDuration },
            bullets: { actual: bullets, maximum: maxBullets },
            peopleCount,
            huntingStat,
            trail,
            inventory,
            wagons,
        });
    }

    // Correct word but not enough bullets
    else if (hunting.word === word) {
        const hit = randomInt(0, bulletsRequired) <= bullets;
        if (hit) {
            return makeHit({
                hunting,
                duration: { actual: duration, maximum: maxDuration },
                bullets: { actual: bullets, maximum: maxBullets },
                peopleCount,
                huntingStat,
                trail,
                inventory,
                wagons,
            });
        } else {
            return makeMiss({
                hunting,
                duration: { actual: duration, maximum: maxDuration },
                bullets: { actual: bullets, maximum: maxBullets },
            });
        }
    }

    // Incorrect word
    else {
        return makeMiss({
            hunting,
            duration: { actual: duration, maximum: maxDuration },
            bullets: { actual: bullets, maximum: maxBullets },
        });
    }
}
