export interface TwitchCommands {
    join: string;
    leave: string;
    lurk: string;
    unlurk: string;
    guess: string;
}

export type CheatMode = 'on' | 'off';

export const DEFAULT_TWITCH_COMMANDS: TwitchCommands = {
    join: 'join',
    leave: 'leave',
    lurk: 'lurk',
    unlurk: 'unlurk',
    guess: 'guess',
};

export const DEFAULT_GLOBAL_EMOTE_NAMES = ['LUL', 'NotLikeThis', 'PizzaTime', 'GlitchCat', 'HSCheers', 'SabaPing', 'MrDestructoid'];

export interface Settings {
    twitchChannelName: string;
    twitchCommands: TwitchCommands;
    cheatMode: CheatMode;
    huntingDuration: number; // in seconds
    emoteNames: string[];
};

export function makeSettings(): Settings {
    return {
        twitchChannelName: "",
        twitchCommands: { ...DEFAULT_TWITCH_COMMANDS },
        cheatMode: 'off',
        huntingDuration: 20,
        emoteNames: [...DEFAULT_GLOBAL_EMOTE_NAMES],
    };
}