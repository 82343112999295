import { Button } from "react-bootstrap";
import { applySparePart, countBrokenAxles, countBrokenWheels, hasBrokenTongue, isWagonBroken } from "../../../../types/Wagon";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { changeInventory, changeWagon, selectGame } from "../../gameSlice";

export default function FixWagonButton() {
    const dispatch = useAppDispatch();
    const { inventory, wagons } = useAppSelector(selectGame);
    const { wagonTongues, wagonWheels, wagonAxles } = inventory;

    const brokenWagon = wagons.find(isWagonBroken) ?? null;
    const isTonguesBroken = wagons.filter(hasBrokenTongue).length > 0;
    const canFixTongues = isTonguesBroken && wagonTongues.amount > 0;
    const isWheelsBroken = wagons.filter(wagon => countBrokenWheels(wagon) > 0).length > 0;
    const canFixWheels = isWheelsBroken && wagonWheels.amount > 0;
    const isAxlesBroken = wagons.filter(wagon => countBrokenAxles(wagon) > 0).length > 0;
    const canFixAxles = isAxlesBroken && wagonAxles.amount > 0;

    const repair = () => {
        if (brokenWagon) {
            const result = applySparePart(brokenWagon, inventory);
            dispatch(changeInventory(result.inventory));
            dispatch(changeWagon(result.wagon));
        }
    };

    if (brokenWagon === null) {
        return null;
    } else {
        return <Button variant="primary" className="mb-1 ps-2 pe-2 text-truncate" disabled={!(canFixWheels || canFixAxles || canFixTongues)} onClick={repair}>Repair</Button>;
    }
}