import { makeHuntingFailureEvent, makeHuntingSucceededEvent } from "../../types/GameEvent";
import { determineBulletsCount, Hunting, HuntingHit, HuntingMiss, makeHuntingResult, makeMiss } from "../../types/Hunting";
import { decreaseInventoryItem, increaseInventoryItem } from "../../types/inventory/InventoryItem";
import { makeHuntingEndedMessage, makeHuntingStartedMessage } from "../../types/Message";
import { isDead } from "../../types/Player";
import { GameState } from "../game/gameSlice";

export default class HuntingSystem {

    static start(hunting: Hunting, game: GameState): GameState {
        const { date } = game;
        const message = makeHuntingStartedMessage(hunting, date);
        const messages = [...game.messages, message];
        return { ...game, hunting, messages };
    }

    static cancel(maxDuration: number, game: GameState): GameState {
        const { date } = game;
        const maxBullets = game.inventory.bullets.amount;
        let hunting = game.hunting;
        let inventory = game.inventory;
        const messages = [...game.messages];
        const events = [...game.events];

        if (hunting) {
            const end = new Date().getTime();
            const duration = (end - hunting.start) / 1000;
            const result = makeMiss({
                hunting,
                duration: {
                    actual: duration,
                    maximum: maxDuration,
                },
                bullets: {
                    actual: determineBulletsCount(duration),
                    maximum: maxBullets,
                },
            });

            hunting = { ...hunting, result };
            messages.push(makeHuntingEndedMessage(hunting, 'canceled', date));
            events.push(makeHuntingFailureEvent(result));
            const bullets = decreaseInventoryItem(game.inventory.bullets, result.bullets)
            inventory = { ...game.inventory, bullets };
        }

        return { ...game, hunting, inventory, messages, events };
    }

    static timeout(maxDuration: number, game: GameState): GameState {
        const { date } = game;
        const maxBullets = game.inventory.bullets.amount;
        let hunting = game.hunting;
        let inventory = game.inventory;
        const messages = [...game.messages];
        const events = [...game.events];

        if (hunting) {
            const result = makeMiss({
                hunting,
                duration: {
                    actual: maxDuration,
                    maximum: maxDuration,
                },
                bullets: {
                    actual: determineBulletsCount(maxDuration),
                    maximum: maxBullets,
                },
            });

            hunting = { ...hunting, result };
            messages.push(makeHuntingEndedMessage(hunting, 'timed out', date));
            events.push(makeHuntingFailureEvent(result));
            const bullets = decreaseInventoryItem(game.inventory.bullets, result.bullets)
            inventory = { ...game.inventory, bullets };
        }

        return { ...game, hunting, inventory, messages, events };
    }

    static end(message: string, maxDuration: number, game: GameState): GameState {
        const { date, stats, trail, wagons } = game;
        const maxBullets = game.inventory.bullets.amount;
        let hunting = game.hunting;
        let inventory = game.inventory;
        const messages = [...game.messages];
        const events = [...game.events];
        const peopleCount = game.players.filter(player => !isDead(player)).length;

        if (hunting && !hunting.result) {
            const result = makeHuntingResult({
                hunting,
                word: message,
                maxDuration,
                maxBullets,
                peopleCount,
                huntingStat: stats.hunting,
                trail,
                inventory,
                wagons,
            });
            
            hunting = { ...hunting, result };
            messages.push(makeHuntingEndedMessage(hunting, 'finished', date));
            const bullets = decreaseInventoryItem(inventory.bullets, result.bullets);
            inventory = { ...inventory, bullets };
            if (result.type === 'Hit') {
                const hit = result as HuntingHit;
                const food = increaseInventoryItem(inventory.food, hit.food);
                inventory = { ...inventory, food };
                events.push(makeHuntingSucceededEvent(hit));
            } else {
                const miss = result as HuntingMiss;
                events.push(makeHuntingFailureEvent(miss));
            }
        }

        return { ...game, hunting, inventory, messages };
    }

    static reset(game: GameState): GameState {
        return { ...game, hunting: null };
    }

}