import { Cash } from "./inventory/Cash";
import { Inventory } from "./inventory/Inventory";
import { decreaseInventoryItem, increaseInventoryItem, InventoryItem, InventoryItemName } from "./inventory/InventoryItem";
import { addMap, MapLevelOfDetail } from "./inventory/Maps";
import { QuestInventory } from "./inventory/QuestInventory";
import { addRifle, removeRifle, RifleName } from "./inventory/Rifles";
import { WaterContainer, WATER_PER_BOTTLE, WATER_PER_CANISTER } from "./inventory/Water";
import { RegionName } from "./location/Location";
import { ShopParameters, Town } from "./location/Town";
import { addOxen, isOxDead, isOxRanOff, Ox, removeOxen } from "./Ox";
import { Role } from "./Role";
import { TradingStat } from "./Stat";
import { POUND_PER_KILOGRAM, repeat } from "./Utilities";
import { makeWagon, Wagon, WagonName } from "./Wagon";

export interface Store {
    type: 'Store';
    name: 'Trading Outpost' | string;
    shopParameters: ShopParameters;
    foodPrice?: number;
    waterBottlePrice?: number;
    waterCanisterPrice?: number;
    bulletPrice?: number;
    clothesPrice?: number;
    medicinePrice?: number;
    wagonTonguePrice?: number;
    wagonWheelPrice?: number;
    wagonAxlePrice?: number;
    oxPrice?: number;
    map?: { name: RegionName, levelOfDetail: MapLevelOfDetail, price: number },
    pilgrimsWagonPrice?: number;
    baseWagonPrice?: number;
    largeWagonPrice?: number;
    doubleBaseWagonPrice?: number;
    doubleLargeWagonPrice?: number;
    grandpasRiflePrice?: number;
    huntingRiflePrice?: number;
    sniperRiflePrice?: number;
};

export type StoreItemName = InventoryItemName | 'Maps' | 'Oxen' | 'Pilgrims Wagon';

function buy<Item extends InventoryItem>(inventory: Inventory, item: Item, amount: number, price: number, updateInventory: (cash: Cash, item: Item) => Inventory): Inventory {
    if (amount <= 0 || price <= 0) {
        return inventory;
    }

    const total = amount * price;
    if (total > inventory.cash.amount) {
        const available = Math.floor(inventory.cash.amount / price);
        return buy(inventory, item, available, price, updateInventory);
    }

    const cash = decreaseInventoryItem(inventory.cash, total);
    const increasedItem = increaseInventoryItem(item, amount);
    return updateInventory(cash, increasedItem);
}

function sell<Item extends InventoryItem>(inventory: Inventory, item: Item, amount: number, price: number, updateInventory: (cash: Cash, item: Item) => Inventory): Inventory {
    if (amount <= 0 || price <= 0) {
        return inventory;
    }

    if (amount > item.amount) {
        const available = item.amount;
        return sell(inventory, item, available, price, updateInventory);
    }

    const total = amount * price;
    const cash = increaseInventoryItem(inventory.cash, total);
    const decreasedItem = decreaseInventoryItem(item, amount);
    return updateInventory(cash, decreasedItem);
}

export function buyFood(inventory: Inventory, amount: number, price: number): Inventory {
    return buy(inventory, inventory.food, amount, price,
        (cash, food) => ({ ...inventory, cash, food }));
}

export function sellFood(inventory: Inventory, amount: number, price: number): Inventory {
    return sell(inventory, inventory.food, amount, price,
        (cash, food) => ({ ...inventory, cash, food }));
}

export function buyWaterBottle(inventory: Inventory, count: number, price: number): Inventory {
    if (count < 1 || price <= 0) {
        return inventory;
    }

    const totalPrice = count * price;
    if (totalPrice > inventory.cash.amount) {
        return inventory;
    }

    const bottles: WaterContainer[] = [];
    const capacity = WATER_PER_BOTTLE;
    for (let index = 0; index < count; ++index) {
        bottles.push({ type: 'Bottle', capacity });
    }

    const containers = [...inventory.water.containers, ...bottles];
    const water = increaseInventoryItem({ ...inventory.water, containers }, capacity * count);
    const cash = decreaseInventoryItem(inventory.cash, totalPrice);
    return { ...inventory, water, cash };
}

export function sellWaterBottle(inventory: Inventory, count: number, price: number): Inventory {
    if (count < 1 || price <= 0) {
        return inventory;
    }

    const bottles = [...inventory.water.containers]
        .filter(container => container.type === 'Bottle');
    
    if (bottles.length < count) {
        return inventory;
    }

    bottles.splice(0, count);
    
    const otherContainers = inventory.water.containers
        .filter(container => container.type !== 'Bottle');
    
    const containers = [...bottles, ...otherContainers];
    const totalPrice = count * price;

    const maximum = containers
        .map(container => container.capacity)
        .reduce((prev, curr) => prev + curr, 0);
    
    const amount = Math.max(0, inventory.water.amount - maximum);
    const water = decreaseInventoryItem({ ...inventory.water, containers }, amount);
    const cash = increaseInventoryItem(inventory.cash, totalPrice);
    return { ...inventory, cash, water };
}

export function buyWaterCanister(inventory: Inventory, count: number, price: number): Inventory {
    if (count < 1 || price <= 0) {
        return inventory;
    }

    const totalPrice = count * price;
    if (totalPrice > inventory.cash.amount) {
        return inventory;
    }

    const bottles: WaterContainer[] = [];
    const capacity = WATER_PER_CANISTER;
    for (let index = 0; index < count; ++index) {
        bottles.push({ type: 'Canister', capacity });
    }

    const containers = [...inventory.water.containers, ...bottles];
    const water = increaseInventoryItem({ ...inventory.water, containers }, capacity * count);
    const cash = decreaseInventoryItem(inventory.cash, totalPrice);
    return { ...inventory, water, cash };
}

export function sellWaterCanister(inventory: Inventory, count: number, price: number): Inventory {
    if (count < 1 || price <= 0) {
        return inventory;
    }

    const canisters = [...inventory.water.containers]
        .filter(container => container.type === 'Canister');
    
    if (canisters.length < count) {
        return inventory;
    }

    canisters.splice(0, count);
    
    const otherContainers = inventory.water.containers
        .filter(container => container.type !== 'Canister');
    
    const containers = [...canisters, ...otherContainers];
    const totalPrice = count * price;

    const maximum = containers
        .map(container => container.capacity)
        .reduce((prev, curr) => prev + curr, 0);
    
    const amount = Math.max(0, inventory.water.amount - maximum);
    const water = decreaseInventoryItem({ ...inventory.water, containers }, amount);
    const cash = increaseInventoryItem(inventory.cash, totalPrice);
    return { ...inventory, cash, water };
}

export function buyBullets(inventory: Inventory, amount: number, price: number): Inventory {
    return buy(inventory, inventory.bullets, amount, price,
        (cash, bullets) => ({ ...inventory, cash, bullets }));
}

export function sellBullets(inventory: Inventory, amount: number, price: number): Inventory {
    return sell(inventory, inventory.bullets, amount, price,
        (cash, bullets) => ({ ...inventory, cash, bullets }));
}

export function buyClothes(inventory: Inventory, amount: number, price: number): Inventory {
    return buy(inventory, inventory.clothes, amount, price,
        (cash, clothes) => ({ ...inventory, cash, clothes }));
}

export function sellClothes(inventory: Inventory, amount: number, price: number): Inventory {
    return sell(inventory, inventory.clothes, amount, price,
        (cash, clothes) => ({ ...inventory, cash, clothes }));
}

export function buyMedicine(inventory: Inventory, amount: number, price: number): Inventory {
    return buy(inventory, inventory.medicine, amount, price,
        (cash, medicine) => ({ ...inventory, cash, medicine }));
}

export function sellMedicine(inventory: Inventory, amount: number, price: number): Inventory {
    return sell(inventory, inventory.medicine, amount, price,
        (cash, medicine) => ({ ...inventory, cash, medicine }));
}

export function buyWagonTongues(inventory: Inventory, amount: number, price: number): Inventory {
    return buy(inventory, inventory.wagonTongues, amount, price,
        (cash, wagonTongues) => ({ ...inventory, cash, wagonTongues }));
}

export function sellWagonTongues(inventory: Inventory, amount: number, price: number): Inventory {
    return sell(inventory, inventory.wagonTongues, amount, price,
        (cash, wagonTongues) => ({ ...inventory, cash, wagonTongues }));
}

export function buyWagonWheels(inventory: Inventory, amount: number, price: number): Inventory {
    return buy(inventory, inventory.wagonWheels, amount, price,
        (cash, wagonWheels) => ({ ...inventory, cash, wagonWheels }));
}

export function sellWagonWheels(inventory: Inventory, amount: number, price: number): Inventory {
    return sell(inventory, inventory.wagonWheels, amount, price,
        (cash, wagonWheels) => ({ ...inventory, cash, wagonWheels }));
}

export function buyWagonAxles(inventory: Inventory, amount: number, price: number): Inventory {
    return buy(inventory, inventory.wagonAxles, amount, price,
        (cash, wagonAxles) => ({ ...inventory, cash, wagonAxles }));
}

export function sellWagonAxles(inventory: Inventory, amount: number, price: number): Inventory {
    return sell(inventory, inventory.wagonAxles, amount, price,
        (cash, wagonAxles) => ({ ...inventory, cash, wagonAxles }));
}

export function buyOxen(inventory: Inventory, oxen: Ox[], amount: number, price: number): [Inventory, Ox[]] {
    if (amount <= 0 || price <= 0) {
        return [inventory, oxen];
    }

    const totalPrice = amount * price;
    if (totalPrice > inventory.cash.amount) {
        const available = Math.floor(inventory.cash.amount / price);
        return buyOxen(inventory, oxen, available, price);
    }

    const cash = decreaseInventoryItem(inventory.cash, totalPrice);
    const updatedOxen = addOxen(oxen, amount);
    const updatedInventory = { ...inventory, cash };

    return [updatedInventory, updatedOxen];
}

export function sellOxen(inventory: Inventory, oxen: Ox[], amount: number, price: number): [Inventory, Ox[]] {
    if (amount <= 0 || price <= 0) {
        return [inventory, oxen];
    }

    if (amount > oxen.filter(ox => !isOxDead(ox) && !isOxRanOff(ox)).length) {
        const available = oxen.filter(ox => !isOxDead(ox) && !isOxRanOff(ox)).length;
        return sellOxen(inventory, oxen, available, price);
    }

    const totalPrice = amount * price;
    const cash = increaseInventoryItem(inventory.cash, totalPrice);
    const updatedOxen = removeOxen(oxen, amount);
    const updatedInventory = { ...inventory, cash };

    return [updatedInventory, updatedOxen];
}

export function buyMap(inventory: Inventory, questInventory: QuestInventory, regionName: RegionName, levelOfDetail: MapLevelOfDetail, price: number): [Inventory, QuestInventory] {
    if (price <= 0 || price > inventory.cash.amount) {
        return [inventory, questInventory];
    }

    const maps = addMap(questInventory.maps, regionName, levelOfDetail);
    const updatedQuestInventory = { ...questInventory, maps };

    const cash = decreaseInventoryItem(inventory.cash, price);
    const updatedInventory = { ...inventory, cash };

    return [updatedInventory, updatedQuestInventory];
}

export function buyWagons(inventory: Inventory, wagons: Wagon[], name: WagonName, amount: number, price: number): [Inventory, Wagon[]] {
    if (amount <= 0 || price <= 0) {
        return [inventory, wagons];
    }

    const newWagons = repeat(name, amount).map(makeWagon);
    const updatedWagons = [...wagons, ...newWagons];

    const totalPrice = amount * price;
    if (totalPrice > inventory.cash.amount) {
        const available = Math.floor(inventory.cash.amount / price);
        return buyWagons(inventory, wagons, name, available, price);
    }
    
    const cash = decreaseInventoryItem(inventory.cash, price);
    const updatedInventory: Inventory = { ...inventory, cash };

    return [updatedInventory, updatedWagons];
}

export function sellWagons(inventory: Inventory, wagons: Wagon[], name: WagonName, amount: number, price: number): [Inventory, Wagon[]] {
    if (amount <= 0 || price <= 0) {
        return [inventory, wagons];
    }

    const matchingWagonCount = wagons.filter(wagon => wagon.name === name).length;
    if (amount > matchingWagonCount) {
        return sellWagons(inventory, wagons, name, matchingWagonCount, price);
    }

    const totalPrice = amount * price;
    const cash = increaseInventoryItem(inventory.cash, totalPrice);
    const updatedInventory: Inventory = { ...inventory, cash };

    const updatedWagons = [...wagons];
    for (let count = 0; count < amount; ++count) {
        const index = updatedWagons.findIndex(wagon => wagon.name === name);
        if (index !== -1) {
            updatedWagons.splice(index, 1);
        }
    }

    return [updatedInventory, updatedWagons];
}

export function buyRifle(inventory: Inventory, name: RifleName, amount: number, price: number): Inventory {
    if (amount < 1 || price <= 0) {
        return inventory;
    }

    const totalPrice = amount * price;
    if (totalPrice > inventory.cash.amount) {
        return inventory;
    }

    const rifles = repeat(name, amount)
        .reduce((previousRifle, rifleToBuy) => addRifle(rifleToBuy, previousRifle), inventory.rifles);
    
    const cash = decreaseInventoryItem(inventory.cash, totalPrice);
    return { ...inventory, rifles, cash };
}

export function sellRifle(inventory: Inventory, name: RifleName, amount: number, price: number): Inventory {
    if (price <= 0) {
        return inventory;
    }

    const available = inventory.rifles.names.filter(n => n === name).length;
    if (available < amount) {
        return inventory;
    }

    const totalPrice = amount * price;
    const rifles = repeat(name, amount)
        .reduce((previousRifles, rifleToSell) => removeRifle(rifleToSell, previousRifles), inventory.rifles);

    const cash = increaseInventoryItem(inventory.cash, totalPrice);
    return { ...inventory, rifles, cash };
}

export function determineSellingPrice(buyingPrice: number, tradingStat: TradingStat): number {
    return buyingPrice * (.5 + tradingStat.value * .03);
}

export type MakeStoreKind = 'Start Town';

function determineShopName(town: Town, kind?: MakeStoreKind): string {
    if (kind === 'Start Town') {
        return `Stan's Used Wagons`;
    } else {
        return 'Store';
    }
}

function determineFoodPrice(parameters: ShopParameters, kind?: MakeStoreKind): number | undefined {
    if (kind === 'Start Town') {
        return 0.20;
    } else if (parameters.rarity < .2) {
        return undefined;
    } else {
        const pricePerPound = 0.50 * parameters.priceFactor;
        return pricePerPound / POUND_PER_KILOGRAM;
    }
}

function determineWaterBottlePrice(parameters: ShopParameters, kind?: MakeStoreKind): number | undefined {
    if (kind === 'Start Town') {
        return 4.00;
    } else {
        return undefined;
    }
}

function determineWaterCanisterPrice(parameters: ShopParameters, kind?: MakeStoreKind): number | undefined {
    if (kind === 'Start Town') {
        return 10.00;
    } else {
        return undefined;
    }
}

function determineBulletPrice(parameters: ShopParameters, kind?: MakeStoreKind): number | undefined {
    if (kind === 'Start Town') {
        return 0.10;
    } else if (parameters.rarity < .0) {
        return undefined;
    } else {
        return 0.05 * parameters.priceFactor;
    }
}

function determineClothesPrice(parameters: ShopParameters, kind?: MakeStoreKind): number | undefined {
    if (kind === 'Start Town') {
        return 10.00;
    } else if (parameters.rarity < .0) {
        return undefined;
    } else {
        return 1.00 * parameters.priceFactor;
    }
}

function determineMedicinePrice(parameters: ShopParameters, kind?: MakeStoreKind): number | undefined {
    if (kind === 'Start Town') {
        return 5.00;
    } else if (parameters.rarity < .5) {
        return undefined;
    } else {
        return 10.00 * parameters.priceFactor;
    }
}

function determineWagonTonguePrice(parameters: ShopParameters, kind?: MakeStoreKind): number | undefined {
    if (kind === 'Start Town') {
        return 10.00;
    } else if (parameters.rarity < .3) {
        return undefined;
    } else {
        return 15.00 * parameters.priceFactor;
    }
}

function determineWagonWheelPrice(parameters: ShopParameters, kind?: MakeStoreKind): number | undefined {
    if (kind === 'Start Town') {
        return 10.00;
    } else if (parameters.rarity < .3) {
        return undefined;
    } else {
        return 15.00 * parameters.priceFactor;
    }
}

function determineWagonAxlePrice(parameters: ShopParameters, kind?: MakeStoreKind): number | undefined {
    if (kind === 'Start Town') {
        return 10.00;
    } else if (parameters.rarity < .3) {
        return undefined;
    } else {
        return 15.00 * parameters.priceFactor;
    }
}

function determineOxPrice(parameters: ShopParameters, kind?: MakeStoreKind): number | undefined {
    if (kind === 'Start Town') {
        return 20.00;
    } else if (parameters.rarity < .0) {
        return undefined;
    } else {
        return 20.00 * parameters.priceFactor;
    }
}

function determinePilgrimsWagonPrice(parameters: ShopParameters, kind?: MakeStoreKind): number | undefined {
    if (kind === 'Start Town') {
        return 70.00;
    } else if (parameters.rarity < .0) {
        return undefined;
    } else {
        return 70.00 * parameters.priceFactor;
    }
}

function determineBaseWagonPrice(parameters: ShopParameters): number | undefined {
    if (parameters.rarity < .3) {
        return undefined;
    } else {
        return 150.00 * parameters.priceFactor;
    }
}

function determineLargeWagonPrice(parameters: ShopParameters): number | undefined {
    if (parameters.rarity < .6) {
        return undefined;
    } else {
        return 200.00 * parameters.priceFactor;
    }
}

function determineDoubleBaseWagonPrice(parameters: ShopParameters): number | undefined {
    if (parameters.rarity < .6) {
        return undefined;
    } else {
        return 275.00 * parameters.priceFactor;
    }
}

function determineDoubleLargeWagonPrice(parameters: ShopParameters): number | undefined {
    if (parameters.rarity < .6) {
        return undefined;
    } else {
        return 350.00 * parameters.priceFactor;
    }
}

function determineGrandpasRiflePrice(parameters: ShopParameters, kind?: MakeStoreKind): number | undefined {
    if (kind === 'Start Town') {
        return 50.00;
    } else if (parameters.rarity < .0) {
        return undefined;
    } else {
        return 50.00 * parameters.priceFactor;
    }
}

function determineHuntingRiflePrice(parameters: ShopParameters): number | undefined {
    if (parameters.rarity < .2) {
        return undefined;
    } else {
        return 75.00 * parameters.priceFactor;
    }
}

function determineSniperRiflePrice(parameters: ShopParameters): number | undefined {
    if (parameters.rarity < .2) {
        return undefined;
    } else {
        return 100.00 * parameters.priceFactor;
    }
}

function determineMapPrice(parameters: ShopParameters, kind?: MakeStoreKind): { name: RegionName, levelOfDetail: MapLevelOfDetail, price: number } | undefined {
    if (kind === 'Start Town') {
        return { name: 'Home', levelOfDetail: 'Details', price: 2.00 };
    } else {
        return undefined;
    }
}

export function makeEmptyStore(name: string): Store {
    const shopParameters: ShopParameters = { rarity: 0, priceFactor: 1 };
    return { type: 'Store', name, shopParameters };
}

export function makeStore(town: Town, role: Role, kind?: MakeStoreKind): Store | null {
    const { shopParameters, facilities } = town;
    if (shopParameters === null || !facilities.includes('Shop')) {
        return null;
    }
    if (role === 'Trader') {
        shopParameters.priceFactor = 1.0; // Trader is "immune" to price factor
    }

    const name = determineShopName(town, kind);
    const foodPrice = determineFoodPrice(shopParameters, kind);
    const waterBottlePrice = determineWaterBottlePrice(shopParameters, kind);
    const waterCanisterPrice = determineWaterCanisterPrice(shopParameters, kind);
    const bulletPrice = determineBulletPrice(shopParameters, kind);
    const clothesPrice = determineClothesPrice(shopParameters, kind);
    const medicinePrice = determineMedicinePrice(shopParameters, kind);
    const wagonTonguePrice = determineWagonTonguePrice(shopParameters, kind);
    const wagonWheelPrice = determineWagonWheelPrice(shopParameters, kind);
    const wagonAxlePrice = determineWagonAxlePrice(shopParameters, kind);
    const oxPrice = determineOxPrice(shopParameters, kind);
    const pilgrimsWagonPrice = determinePilgrimsWagonPrice(shopParameters, kind);
    const baseWagonPrice = determineBaseWagonPrice(shopParameters);
    const largeWagonPrice = determineLargeWagonPrice(shopParameters);
    const doubleBaseWagonPrice = determineDoubleBaseWagonPrice(shopParameters);
    const doubleLargeWagonPrice = determineDoubleLargeWagonPrice(shopParameters);
    const grandpasRiflePrice = determineGrandpasRiflePrice(shopParameters, kind);
    const huntingRiflePrice = determineHuntingRiflePrice(shopParameters);
    const sniperRiflePrice = determineSniperRiflePrice(shopParameters);
    const map = determineMapPrice(shopParameters, kind);

    return {
        type: 'Store', shopParameters, name, foodPrice, waterBottlePrice, waterCanisterPrice,
        bulletPrice, clothesPrice, medicinePrice, wagonTonguePrice, wagonWheelPrice, wagonAxlePrice,
        oxPrice, pilgrimsWagonPrice, baseWagonPrice, largeWagonPrice, doubleBaseWagonPrice, doubleLargeWagonPrice,
        grandpasRiflePrice, huntingRiflePrice, sniperRiflePrice, map,
    };
}
