import { EastereggName, KonamiCodeEasteregg } from "../../types/Easteregg";
import { makeKonamiCodeFoundMessage } from "../../types/Message";
import { GameState } from "../game/gameSlice";

import HealthSystem from "./HealthSystem";

export default class EastereggSystem {

    static found(name: EastereggName, game: GameState): GameState {
        let { eastereggs, messages, date } = game;

        if (name === 'Konami Code') {
            const count = game.eastereggs.konamiCode.count + 1;
            const konamiCode: KonamiCodeEasteregg = { ...game.eastereggs.konamiCode, count };
            eastereggs = { ...eastereggs, konamiCode };
            messages.push(makeKonamiCodeFoundMessage(date));
            if (process.env.NODE_ENV !== 'test') {
                console.log('Found Konami code!', eastereggs);
            }
            return HealthSystem.healPlayers({ ...game, eastereggs, messages }, 'Spell');
        }

        return game;
    }

}