import { BanditAttack, makeFightingResult, registerShot } from "../../types/BanditAttack";
import { makePlayerDiedEvent } from "../../types/GameEvent";
import { enableInjury, makeRandomInjury } from "../../types/health/Injury";
import { decreaseInventoryItem, increaseInventoryItem } from "../../types/inventory/InventoryItem";
import { WATER_PER_BOTTLE } from "../../types/inventory/Water";
import { makeFightingBanditsEndedMessage, makeFightingBanditsStartedMessage, makePlayerDiedMessage, makePlayerInjuredMessage } from "../../types/Message";
import { enableDeath, isDead } from "../../types/Player";
import { GameState } from "../game/gameSlice";

export default class FightingBanditsSystem {

    static start(banditAttack: BanditAttack, game: GameState): GameState {
        const message = makeFightingBanditsStartedMessage(banditAttack, game.date);
        const messages = [...game.messages, message];
        return { ...game, banditAttack, messages };
    }

    static registerShot(playerName: string, word: string, game: GameState): GameState {
        let { banditAttack } = game;

        let finished = false;
        if (banditAttack !== null && banditAttack.result === undefined) {
            banditAttack = registerShot(banditAttack, playerName, word);
            if (banditAttack.hits.length === banditAttack.attackingPlayers.length) {
                finished = true;
            }
        }

        if (finished) {
            return this.stop({ ...game, banditAttack });
        } else {
            return { ...game, banditAttack };
        }
    }

    static stop(game: GameState): GameState {
        let { banditAttack, inventory, date, wagons } = game;
        const messages = [...game.messages];
        const events = [...game.events];
        const players = [...game.players];

        if (banditAttack) {
            const result = makeFightingResult(banditAttack, players, inventory, wagons);
            banditAttack = { ...banditAttack, result };
            messages.push(makeFightingBanditsEndedMessage(banditAttack, date));
            const usedBullets = decreaseInventoryItem(inventory.bullets, result.bulletsUsed);
            const bullets = increaseInventoryItem(usedBullets, result.bullets);
            const clothes = increaseInventoryItem(inventory.clothes, result.clothes);
            const cash = increaseInventoryItem(inventory.cash, result.cash);
            if (result.waterBottles) {
                const containers = [...inventory.water.containers];
                const capacity = WATER_PER_BOTTLE;
                for (let index = 0; index < result.waterBottles; ++index) {
                    containers.push({ type: 'Bottle', capacity });
                }
                const increase = result.waterBottles * capacity;
                const water = increaseInventoryItem({ ...inventory.water, containers }, increase);
                inventory = { ...inventory, water };
            }
            inventory = { ...inventory, bullets, clothes, cash };

            if (result.killedPlayerName) {
                const index = players.findIndex(player => player.name === result.killedPlayerName && !isDead(player));
                if (index !== -1) {
                    const killedPlayer = enableDeath(players[index], 'Killed by bandits');
                    players[index] = killedPlayer;
                    messages.push(makePlayerDiedMessage(killedPlayer, date));
                    events.push(makePlayerDiedEvent(killedPlayer));
                }
            }

            if (result.injuredPlayerName) {
                const index = players.findIndex(player => player.name === result.injuredPlayerName && !isDead(player));
                if (index !== -1) {
                    const injury = makeRandomInjury(game.stats.health);
                    const injuredPlayer = enableInjury(players[index], injury);
                    players[index] = injuredPlayer;
                    messages.push(makePlayerInjuredMessage(injuredPlayer, injury, date));
                }
            }
        }

        return { ...game, banditAttack, inventory, messages, events, players };
    }

    static reset(game: GameState): GameState {
        return { ...game, banditAttack: null };
    }

}