import { HealthVariants } from "./health/Health";
import { Injury } from "./health/Injury";
import { Disease } from "./health/Disease";
import { Exhaustion, makeExhaustion } from "./health/Exhaustion";

export interface Player {
    name: string;
    avatarURL?: string;
    twitchUser: boolean;
    hp: number;
    injuries: Injury[];
    diseases: Disease[];
    exhaustion: Exhaustion;
    active: boolean,
    naked?: boolean;
    freezing?: boolean;
    wet?: boolean;
    hungry?: boolean;
    thirsty?: boolean;
    channelOwner?: boolean;
    causeOfDeath?: string;
    stats?: HealthStats;
}

interface HealthStatsChange {
    reason: string;
    delta: number;
}

export interface HealthStats {
    hpBefore: number;
    changes: HealthStatsChange[];
}

export function makePlayer(name: string, twitchUser: boolean, active: boolean = true): Player {
    return {
        name,
        twitchUser,
        hp: HealthVariants.good.maximum,
        injuries: [],
        diseases: [],
        exhaustion: makeExhaustion(),
        active,
    };
}

export function enablePlayer(player: Player): Player {
    if (player.active || !player.twitchUser || isDead(player)) {
        return player;
    } else {
        return { ...player, active: true };
    }
}

export function disablePlayer(player: Player): Player {
    if (player.active && !isDead(player)) {
        return { ...player, active: false };
    } else {
        return player
    }
}

export function resetHealthStats(player: Player): Player {
    const stats: HealthStats = {
        hpBefore: player.hp,
        changes: [],
    };

    return { ...disableWet(player), stats };
}

export function changeHP(player: Player, reason: string, delta: number): Player {
    const hp = Math.max(0, player.hp + delta);
    if (player.stats && delta !== 0) {
        const change: HealthStatsChange = { reason, delta };
        const changes = [...player.stats.changes, change];
        const stats: HealthStats = { ...player.stats, changes };
        return { ...player, stats, hp };
    }
    return { ...player, hp };
}

export function capHP(player: Player): Player {
    const hp = Math.min(HealthVariants.good.maximum, Math.max(0, player.hp));
    return { ...player, hp };
}

export function reportHealthStats(player: Player): string[] {
    const stats = player.stats;
    if (!stats) {
        return [];
    }

    const report: string[] = [
        `HP :yellow:{${stats.hpBefore}}`
    ];

    stats.changes
        .map(change => `${change.reason} :${change.delta < 0 ? 'red' : 'green'}:{${change.delta}}`)
        .forEach(line => report.push(line));

    report.push(`HP :yellow:{${player.hp}}`);

    return report;
}

export function isNaked(player: Player): boolean {
    return player.naked === true;
}

export function enableNaked(player: Player): Player {
    return { ...player, naked: true };
}

export function disableNaked(player: Player): Player {
    return { ...player, naked: false };
}

export function isWet(player: Player): boolean {
    return player.wet === true;
}

export function enableWet(player: Player): Player {
    return { ...player, wet: true };
}

export function disableWet(player: Player): Player {
    return { ...player, wet: false };
}

export function isDead(player: Player): boolean {
    return player.hp === 0;
}

export function enableDeath(player: Player, cause: string): Player {
    return {
        ...changeHP(player, cause, -player.hp),
        hp: 0,
        causeOfDeath: cause,
        injuries: [],
        diseases: [],
        wet: false,
        freezing: false,
        active: false,
    };
}

export function disableDeath(player: Player, hp: number, reason: string): Player {
    return {
        ...changeHP(player, reason, hp),
        hp,
        causeOfDeath: undefined,
        injuries: [],
        diseases: [],
        wet: false,
        freezing: false,
    };
}

export function isDoctor(player: Player): boolean {
    return false; // TODO: implement doctor prefession
}
