import { Button } from "react-bootstrap";
import { routingCurrentLocation } from "../../../../types/location/Routing";
import { travelPreventions } from "../../../../types/travel/Travel";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { advanceGame, selectGame, setTravelOptions } from "../../gameSlice";

export default function RestButton() {
    const dispatch = useAppDispatch();
    const game = useAppSelector(selectGame);
    const { travelOptions, status, traveling, trail } = game;
    const location = routingCurrentLocation(trail.routing);
    const preventions = travelPreventions(game);

    const rest = () => {
        dispatch(setTravelOptions({ ...travelOptions, rest: true }));
        dispatch(advanceGame());
    };

    if (preventions.includes('under attack') || location?.type === 'Town') {
        return null;
    } else {
        return <Button variant="primary" className="mb-1 ps-2 pe-2 text-truncate" disabled={status !== 'Playing' || traveling} onClick={rest} style={{ minWidth: '6rem' }}>Rest</Button>;
    }
}