import { formatCash } from "../../../../../types/inventory/Cash";
import { formatOxen, isOxDead, isOxRanOff } from "../../../../../types/Ox";
import { buyOxen, determineSellingPrice, sellOxen, StoreItemName } from "../../../../../types/Store";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { boughtSomething, changeInventory, changeOxen, selectGame } from "../../../gameSlice";

import ProductAmount from "./ProductAmount";
import ProductName from "./ProductName";
import ProductPrice from "./ProductPrice";
import StoreItem from "./StoreItem";
import TradingButtons from "./TradingButtons";

interface OxStoreItemProps {
    price: number;
    selection: [selection: StoreItemName | null, setSelection: (name: StoreItemName | null) => void];
}

export default function OxStoreItem({ price, selection }: OxStoreItemProps) {
    const dispatch = useAppDispatch();
    const { inventory, oxen, stats } = useAppSelector(selectGame);
    const { cash } = inventory;

    const [selectedItem, setSelectedItem] = selection;
    const active = selectedItem === 'Oxen';

    let buy: ((amount: number) => void) | undefined = undefined;
    if (cash.amount >= price) {
        buy = (amount) => {
            const [changedInventory, changedOxen] = buyOxen(inventory, oxen, amount, price);
            dispatch(changeInventory(changedInventory));
            dispatch(changeOxen(changedOxen));
            dispatch(boughtSomething({ price: price * amount, item: 'oxen' }));
            setSelectedItem(null);
        };
    }

    let sell: ((amount: number) => void) | undefined = undefined;
    const aliveOxenCount = oxen.filter(ox => !isOxDead(ox) && !isOxRanOff(ox)).length;
    if (aliveOxenCount > 0) {
        sell = (amount) => {
            const [changedInventory, changedOxen] = sellOxen(inventory, oxen, amount, determineSellingPrice(price, stats.trading));
            dispatch(changeInventory(changedInventory));
            dispatch(changeOxen(changedOxen));
            setSelectedItem(null);
        };
    }

    const productName = <ProductName>Oxen</ProductName>;

    const productPrice = (
        <ProductPrice active={active}>
            <span>buy for {formatCash(price * 2)}, sell for {formatCash(determineSellingPrice(price, stats.trading) * 2)} per yoke, with 2 oxen per yoke.</span>
        </ProductPrice>
    );

    const productAmount = <ProductAmount>{formatOxen(oxen)}</ProductAmount>;

    const money = (amount: number, mode: 'buy' | 'sell') => {
        const factor = mode === 'buy' ? 1 : 0.5;
        return price * factor * amount;
    };

    const tradingButtons = (
        <TradingButtons
            amountInStock={aliveOxenCount}
            amountPerUnit={2}
            canAfford={(amount) => amount * price <= cash.amount}
            money={money}
            formatUnits={(units) => formatOxen(units * 2)}
            minAmount={0}
            maxAmount={Infinity}
            buy={buy}
            sell={sell}
        />
    );

    return (
        <StoreItem
            gameIcon="bull-horns"
            productName={productName}
            productPrice={productPrice}
            productAmount={productAmount}
            tradingButtons={tradingButtons}
        />
    );
}