import { Card } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { selectSettings } from "../../../settings/settingsSlice";
import { endHangman, selectGame } from "../../gameSlice";

import HangmanGameModal from "./hangman/HangmanGameModal";
import PlayHangmanButton from "./hangman/PlayHangmanButton";

export default function ExtrasInteractions() {
    const dispatch = useAppDispatch();
    const { hangmanGame } = useAppSelector(selectGame);
    const { cheatMode } = useAppSelector(selectSettings);

    const open = hangmanGame !== null;
    const close = () => dispatch(endHangman());

    if (cheatMode === 'off') {
        return null;
    }
    
    return (
        <>
        <HangmanGameModal open={open} close={close} />
        <Card>
            <Card.Header>
                Extras
            </Card.Header>
            <Card.Body className="d-flex flex-column justify-content-start align-items-stretch">
                <PlayHangmanButton />
            </Card.Body>
        </Card>
        </>
    );
}