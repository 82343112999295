import { AdjacencyMatrix, DistanceMatrix } from "./Graph";
import { Routing } from "./Routing";
import { loadAdjacencyMatrix, Location, LocationName } from "./Location";
import { loadRiverCrossings, RiverCrossing } from "./RiverCrossing";
import { loadTollStations, TollStation } from "./TollStation";
import { loadTowns, Town } from "./Town";
import { randomElement } from "../Random";
import { makeStore } from "../Store";
import { makeValleyLodge, ValleyLodge } from "./ValleyLodge";
import { makeInn } from "./Inn";
import { Role } from "../Role";

export interface ChooseStartLocationFn {
    (adjacencyMatrix: AdjacencyMatrix<Location>): LocationName;
}

export interface ChooseDestinationLocationFn {
    (adjacencyMatrix: AdjacencyMatrix<Location>, start: LocationName): LocationName;
}

export interface GenerateTownsFn {
    (): Town[];
}

export interface GenerateRiverCrossingsFn {
    (): RiverCrossing[];
}

export interface GenerateTollStationsFn {
    (): TollStation[];
}

export interface MakeAdjacencyMatrixFn {
    (locations: Location[]): AdjacencyMatrix<Location>;
}

export interface RouteNode {
    location: LocationName;
    next: LocationName[];
}

class RouteNodeObj {
    private _location: Location;
    readonly next: RouteNodeObj[];

    constructor(location: Location, next: RouteNodeObj[] = []) {
        this._location = location;
        this.next = next;
    }

    get location(): Location {
        return this._location;
    }

    addNext(node: RouteNodeObj, directionality: 'unidirectional' | 'bidirectional' = 'bidirectional') {
        const contained = this.next.findIndex(next => next.location.name === node.location.name) !== -1;
        if (!contained) {
            this.next.push(node);
            if (directionality === 'bidirectional') {
                node.addNext(this);
            }
        }
    }

    replaceNext(node: RouteNodeObj) {
        this.next.splice(0, this.next.length);
        this.next.push(node);
    }

    replaceLocation(location: Location) {
        this._location = location;
    }

    toRouteNode(): RouteNode {
        const location = this.location.name;
        const next = this.next.map(node => node.location.name);
        
        return { location, next };
    }

    find(name: LocationName, visited: Set<RouteNodeObj> = new Set()): RouteNodeObj | null {
        if (visited.has(this)) {
            return null;
        } else {
            visited.add(this);
        }

        if (this.location.name === name) {
            return this;
        }

        for (let index = 0; index < this.next.length; ++index) {
            const node = this.next[index].find(name, visited);
            if (node !== null) {
                return node;
            }
        }

        return null;
    }

    static fromRouteNode({ locations, nodes }: { locations: Location[], nodes: RouteNode[] }, node: RouteNode, objects: Set<RouteNodeObj> = new Set()): RouteNodeObj | null {
        const location = locations.find(l => l.name === node.location);
        if (location === undefined) {
            return null;
        }

        const obj = new RouteNodeObj(location);
        objects.add(obj);

        node.next.forEach(next => {
            const objectsArray = Array.from(objects);
            const index = objectsArray.findIndex(o => o.location.name === next);
            if (index === -1) {
                const nextNode = nodes.find(n => n.location === next);
                if (nextNode === undefined) {
                    console.warn(`No route node found for ${next}`);
                } else {
                    const nextObj = RouteNodeObj.fromRouteNode({ locations, nodes }, nextNode, objects);
                    if (nextObj === null) {
                        console.warn(`No route node created for ${next}`);
                    } else {
                        obj.addNext(nextObj);
                    }
                }
            } else {
                obj.addNext(objectsArray[index]);
            }
        });

        return obj;
    }

    static buildGraph(locations: Location[], startName: LocationName, destinationName: LocationName, adjacencyMatrix: AdjacencyMatrix<Location>): RouteNodeObj {

        // Build basic adjacency matrix, connect every location near to each other.
        const start = locations.find(location => startName === location.name);
        const destination = locations.find(location => destinationName === location.name);
        if (!start) {
            throw new Error(`Start location ${startName} not found`);
        } else if (!destination) {
            throw new Error(`Destination location ${destinationName} not found`);
        }

        // Helpers for managing route nodes.
        const nodes = new Map<LocationName, RouteNodeObj>();
        const visitedNodes = new Set<RouteNodeObj>();
        const makeNode: (location: Location) => RouteNodeObj = (location) => {
            if (nodes.has(location.name)) {
                return nodes.get(location.name)!;
            } else {
                const node = new RouteNodeObj(location);
                nodes.set(location.name, node);
                return node;
            }
        };

        // Traverse route…
        const root = makeNode(start);
        const queue: RouteNodeObj[] = [root];
        while (queue.length > 0) {

            // Get next node from the queue.
            const [node] = queue.splice(0, 1);
            if (visitedNodes.has(node) || node.location.name === destinationName) {
                continue;
            }
            visitedNodes.add(node);

            // Enqueue all neighbors.
            const neighbors = adjacencyMatrix.neighbors(node.location, (a, b) => a.name === b.name);
            neighbors.forEach(neighbor => {
                const next = makeNode(neighbor);
                queue.push(next);
                node.addNext(next);
            });
        }

        return root;
    }
}

function googleMapsCoordinates(location: Location): google.maps.LatLngLiteral {
    const lat = location.latitude;
    const lng = location.longitude;
    
    return { lat, lng };
}

export function createMapGraph(
    generateTowns: GenerateTownsFn,
    generateRiverCrossings: GenerateRiverCrossingsFn,
    generateTollStations: GenerateTollStationsFn,
    makeAdjacencyMatrix: MakeAdjacencyMatrixFn,
    chooseStartLocation: ChooseStartLocationFn,
    chooseDestinationLocation: ChooseDestinationLocationFn,
    options: { connectComponents: boolean, role: Role }
): { start: RouteNodeObj, destination: RouteNodeObj } {

    // Generate locations and make the adjacency matrix.
    const towns = generateTowns().map(town => ({ ...town, store: makeStore(town, options.role), inn: makeInn(town) } as Town));
    const riverCrossings = generateRiverCrossings();
    const tollStations = generateTollStations();
    const matrix = makeAdjacencyMatrix([...towns, ...riverCrossings, ...tollStations]);
    
    // Make sure the graph is connected.
    const components = matrix.components();
    if (components.length > 1) {
        console.warn('[RouteNode] Adjacency matrix is not connected!', components);
        if (options.connectComponents) {
            const distanceMatrix = new DistanceMatrix(matrix.nodes, googleMapsCoordinates);
            matrix.autoconnectComponents(distanceMatrix);
        }
    }

    // Choose start and destination towns.
    const startLocationName = chooseStartLocation(matrix);
    const destinationLocationName = chooseDestinationLocation(matrix, startLocationName);

    // Build a graph.
    const start = RouteNodeObj.buildGraph(matrix.nodes, startLocationName, destinationLocationName, matrix);
    if (start.location.name !== startLocationName) {
        throw new Error(`Root node should have name ${startLocationName}, but it's named ${start.location.name}`);
    }

    // Make sure the destination is accessible.
    const destination = start.find(destinationLocationName);
    if (destination === null) {
        throw new Error(`Destination node ${destinationLocationName} not found`);
    }

    return { start, destination };
}

function collectConnectedTowns(matrix: AdjacencyMatrix<Location>): Set<LocationName> {
    const visited = new Set<LocationName>();
    const candidates = new Set<LocationName>();

    const visit = (location: Location) => {
        if (!visited.has(location.name)) {
            visited.add(location.name);
            if (location.type === 'Town') {
                const town = location as Town;
                if (town.facilities.includes('Shop')) {
                    candidates.add(town.name);
                }
            }

            matrix.neighbors(location, (a, b) => a.name === b.name)
                .forEach(visit);
        }
    };

    const components = matrix.components().sort((a, b) => b.length - a.length);
    visit(components[0][0]);

    return candidates;
}

const chooseStartTown: ChooseStartLocationFn = (matrix: AdjacencyMatrix<Location>) => {
    const candidates = collectConnectedTowns(matrix);
    return randomElement(Array.from(candidates))!
};

const chooseDestinationTown: ChooseDestinationLocationFn = (matrix: AdjacencyMatrix<Location>, start: LocationName) => {
    const candidates = collectConnectedTowns(matrix);
    candidates.delete(start);
    return randomElement(Array.from(candidates))!;
};

function enableStartTown(town: Town, role: Role): Town {
    return {
        ...town,
        status: 'Arrived',
        store: makeStore(town, role, 'Start Town'),
    };
}

function enableDestinationTown(town: Town, destination: RouteNodeObj): [Town, ValleyLodge] {
    const valleyLodge = makeValleyLodge(town);
    
    const node = new RouteNodeObj(valleyLodge);
    destination.addNext(node);

    if (process.env.NODE_ENV !== 'test') {
        console.info(`Added valley lodge to ${town.name}.`);
    }

    return [town, valleyLodge];
}

function markHomeRegion(start: RouteNodeObj, locations: Location[]): Location[] {
    const names = start.next.map(next => next.location.name);

    const apply = (location: Location) => {
        if (names.includes(location.name)) {
            return {
                ...location,
                regions: [...location.regions, 'Home'],
            };
        }
        return location;
    };

    return locations.map(apply);
}

export function generateRouting(start: RouteNodeObj, destination: RouteNodeObj, role: Role): Routing {

    // Modify the start town, mark it as arrived and install a store there.
    const startTown = enableStartTown(start.location as Town, role);
    start.replaceLocation(startTown);

    // Modify the destination town, adding Valley Lodge there.
    const [destinationTown, valleyLodge] = enableDestinationTown(destination.location as Town, destination);
    destination.replaceLocation(destinationTown);

    // Collect all locations and nodes.
    const locations: Location[] = [];
    const nodes: RouteNode[] = [];
    const pathTaken: LocationName[] = [start.location.name];

    const visit = (node: RouteNodeObj) => {
        const contained = locations.findIndex(location => location.name === node.location.name) !== -1;
        if (!contained) {
            locations.push(node.location);
            nodes.push(node.toRouteNode());
            node.next.forEach(visit);
        }
    };

    visit(start);
    
    return {
        root: start.toRouteNode(),
        destinationName: valleyLodge.name,
        locations: markHomeRegion(start, locations),
        nodes,
        pathTaken,
    };
}

export function generateMap(role: Role): Routing {
    const { start, destination } = createMapGraph(
        loadTowns,
        loadRiverCrossings,
        loadTollStations,
        loadAdjacencyMatrix,
        chooseStartTown,
        chooseDestinationTown,
        {
            connectComponents: false,
            role,
        }
    );

    return generateRouting(start, destination, role);
}
