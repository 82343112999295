import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { makeSettings, Settings } from "../../types/Settings";
import { RootState } from "../store";

export interface SettingsState {
    settings: Settings;
}

const initialState: SettingsState = {
    settings: makeSettings(),
};

export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setSettings: (state, action: PayloadAction<Settings>) => {
            state.settings = action.payload;
        },
    },
});

export const {
    setSettings,
} = settingsSlice.actions;

export const selectSettings = (state: RootState) => state.settings.settings;

export default settingsSlice.reducer;