import { useLiveQuery } from "dexie-react-hooks";
import { Button, ListGroup, Modal, Spinner } from "react-bootstrap";
import { db } from "../../db";
import { Achievement } from "../../../types/Achievement";
import { AchievementSystem } from "../../system";

import AchievementView from "./AchievementView";

interface AchievementsModalProps {
    open: [boolean, (open: boolean) => void];
}

export function AchievementsModal({ open }: AchievementsModalProps) {
    const achievements = useLiveQuery(() => db.achievements.toArray());
    const missingDefaultAchievements = AchievementSystem.defaultAchievements
        .filter(achievement => (achievements ?? []).findIndex(a => a.name === achievement.name) === -1);
    
    const [isOpen, setOpen] = open;

    const close = () => setOpen(false);

    return (
        <Modal show={isOpen} onHide={close} size="lg">
            <Modal.Header>
                <Modal.Title>Achievements</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { achievements === undefined && <Spinner animation="border" variant="secondary" /> }
                { achievements !== undefined && <AchievementsView achievements={achievements} missingAchievements={missingDefaultAchievements} /> }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={close}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

function AchievementsView({ achievements, missingAchievements }: { achievements: Achievement[], missingAchievements: Achievement[] }) {
    const listItems = achievements.map(achievement => (
        <ListGroup.Item key={achievement.id!}>
            <AchievementView achievement={achievement} />
        </ListGroup.Item>
    ));

    const missingItems = missingAchievements.map(achievement => (
        <ListGroup.Item key={achievement.name}>
            <AchievementView achievement={achievement} missing />
        </ListGroup.Item>
    ));

    return (
        <>
        { achievements.length > 0 &&
        <>
        <h6 className="text-muted text-uppercase mt-2 mb-2">Unlocked Achievements</h6>
        <ListGroup>{listItems}</ListGroup>
        </>
        }
        { missingAchievements.length > 0 &&
        <>
        <h6 className="text-muted text-uppercase mt-2 mb-2">Locked Achievements</h6>
        <ListGroup>{missingItems}</ListGroup>
        </>
        }
        </>
    );
}