import { POUND_PER_KILOGRAM } from "../Utilities";
import { InventoryItem } from "./InventoryItem";

export interface Bullets extends InventoryItem {
    name: 'Bullets';
}

export function makeBullets(amount: number = 0): Bullets {
    return { amount, name: 'Bullets' };
}

export function formatBullets(bullets: Bullets | number): string {
    if (typeof bullets === 'number') {
        if (bullets === 1) {
            return '1 bullet';
        } else {
            return `${bullets} bullets`;
        }
    } else if (bullets.amount === 1) {
        return '1 bullet';
    } else {
        return `${bullets.amount} bullets`;
    }
}

export function determineBulletWeight(bullets: Bullets): number {
    return bullets.amount * .05 / POUND_PER_KILOGRAM;
}

export function determineBulletsForWeight(weight: number): number {
    return Math.floor(weight / .05 * POUND_PER_KILOGRAM);
}
