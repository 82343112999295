import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSkull } from "@fortawesome/free-solid-svg-icons";
import { formatOxCreationDate, isOxDead, isOxInjured, isOxRanOff, Ox } from "../../../types/Ox";

import GameIcon from "../../misc/GameIcon";
import { HealthVariants } from "../../../types/health/Health";
import { formatAnd } from "../../../types/Utilities";
import { useAppSelector } from "../../hooks";
import { selectGame } from "../gameSlice";

export default function OxAvatar({ ox, large }: { ox: Ox, large?: boolean }) {
    if (!!large) {
        return <LargeOxAvatar ox={ox} />
    } else {
        return <DefaultOxAvatar ox={ox} />
    }
}

function DefaultOxAvatar({ ox }: { ox: Ox }) {
    const dead = isOxDead(ox);
    const ranOff = isOxRanOff(ox);
    
    return (
        <div className="ox-avatar d-flex flex-row justify-content-start align-items-center h-100">
            <GameIcon icon={ranOff ? 'help' : 'bull-horns'} className={!dead && !ranOff ? '' : 'text-muted'} />
            <div className={"ox-name flex-grow-1 ms-2 fs-5 text-truncate text-nowrap d-none d-md-block " + (!dead && !ranOff ? '' : 'text-muted')}>{ox.name}</div>
            { dead && <FontAwesomeIcon icon={faSkull} className="ms-1 text-secondary" title={ox.causeOfDeath ?? "dead"} /> }
        </div>
    );
}

function LargeOxAvatar({ ox }: { ox: Ox }) {
    const { date } = useAppSelector(selectGame);

    const dead = isOxDead(ox);
    const ranOff = isOxRanOff(ox);
    const injured = isOxInjured(ox);

    const injuries = (injured ? ox.injuries : [])
        .filter(injury => injury.remainingDays > 0)
        .map(injury => injury.name);

    const hpPercentage = (100 * ox.hp / HealthVariants.good.maximum).toFixed(0) + '%';

    return (
        <div className="d-flex flex-column justify-content-center align-items-center">
            <GameIcon icon="bull-horns" size="3x" />
            <h5>{ox.name}</h5>
            { dead && <div className="text-muted">{ox.causeOfDeath ?? 'dead'}</div> }
            { ranOff && <div className="text-muted">Ox ran off.</div> }
            { !dead && !ranOff &&
            <table>
                <tbody>
                    <tr>
                        <td><strong>Bought:</strong></td>
                        <td>{formatOxCreationDate(ox, date)}</td>
                    </tr>
                    <tr>
                        <td><strong>Health:</strong></td>
                        <td>{hpPercentage}</td>
                    </tr>
                    <tr>
                        <td><strong>Injury:</strong></td>
                        <td>{formatAnd(injuries, 'none')}</td>
                    </tr>
                </tbody>
            </table>
            }
        </div>
    );
}