import { POUND_PER_KILOGRAM } from "../Utilities";
import { InventoryItem } from "./InventoryItem";

export interface Clothes extends InventoryItem {
    name: 'Clothes';
}

export function makeClothes(amount: number = 0): Clothes {
    return { amount, name: 'Clothes' };
}

export function formatClothes(clothes: Clothes | number, extendedUnit: boolean = false): string {
    const singularUnit = extendedUnit ? 'set of clothes' : 'set';
    const pluralUnit = extendedUnit ? 'sets of clothes' : 'sets';
    if (typeof clothes === 'number') {
        if (clothes === 1) {
            return `1 ${singularUnit}`;
        } else {
            return `${clothes} ${pluralUnit}`;
        }
    } else if (clothes.amount === 1) {
        return `1 ${singularUnit}`;
    } else {
        return `${clothes.amount} ${pluralUnit}`;
    }
}

export function determineClothesWeight(clothes: Clothes): number {
    return clothes.amount / POUND_PER_KILOGRAM;
}

export function determineClothesForWeight(weight: number): number {
    return Math.floor(weight * POUND_PER_KILOGRAM);
}
