import { Modal, Spinner } from "react-bootstrap";
import { useAppSelector } from "../../../../hooks";
import { selectGame } from "../../../gameSlice";

export default function BanditAttackModal() {
    const { banditAttack } = useAppSelector(selectGame);

    return (
        <Modal show={!!banditAttack && !banditAttack.result} backdrop="static">
            <Modal.Body className="text-center fs-2 pt-5 pb-5">
                { !!banditAttack &&
                <div>
                    <div>You're being attacked by a group of bandits!</div>
                    <div className="fs-4">Everybody grab your guns and start shooting!</div>
                    <div className="text-warning">&quot;{banditAttack.word}&quot;</div>
                </div>
                }
                <Spinner animation="grow" variant="danger" />
            </Modal.Body>
        </Modal>
    );
}