import { InventoryItem } from "./InventoryItem";

export interface Cash extends InventoryItem {
    name: 'Cash';
}

export function makeCash(amount: number = 0): Cash {
    return { amount, name: 'Cash' };
}

export function formatCash(cash: Cash | number): string {
    if (typeof cash === 'number') {
        return '$' + cash.toFixed(2);
    } else {
        return '$' + cash.amount.toFixed(2);
    }
}