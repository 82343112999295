import { db } from "../app/db";

export interface Achievement {
    id?: number;
    name: string;
    createdAt: number;
    description: string;
    iconName: string | null;
}

export function makeAchievement(name: string, description: string, iconName: string | null): Achievement {
    const createdAt = new Date().getTime();
    return { name, createdAt, description, iconName };
}

export function makeLegendary1836ColtAchievement(): Achievement {
    return makeAchievement('Legendary Colt', 'Find the legendary 1836 Colt.', 'colt-m1911');
}

export function makeDefaultAchievements(): Achievement[] {
    return [
        makeLegendary1836ColtAchievement(),
    ];
}

export function unlockAchievement(achievement: Achievement, dispatchAchievement: (achievement: Achievement) => void) {
    db.createAchievement(achievement)
        .then(achievement => {
            if (achievement) {
                dispatchAchievement(achievement);
            }
        })
        .catch(console.error);
}