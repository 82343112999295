import { useEffect, useState } from "react";
import { format } from "date-fns";
import { Button, Container, Dropdown, Navbar } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { addPlayer, endGame, selectGame } from "./app/game/gameSlice";
import { defaultTwitchManager, TwitchContext, useTwitchManager } from "./app/twitch/TwitchManager";
import { defaultKeyboardInputManager, KeyboardInputContext } from "./app/system/KeyboardInputManager";
import { defaultEastereggManager, EastereggManagerContext } from "./app/system/EastereggManager";
import { selectSettings } from "./app/settings/settingsSlice";
import { makePlayer } from "./types/Player";
import { AchievementsModal } from "./app/game/achievement/AchievementsModal";

import TwitchBot from "./app/twitch/TwitchBot";
import SettingsModal from "./app/settings/SettingsModal";
import GameView from "./app/game/GameView";
import GameIcon from "./app/misc/GameIcon";
import { AchievementSystem } from "./app/system";

export default function App() {

	// Process Twitch authorization response.
	useEffect(() => defaultTwitchManager.processAuthorization(), []);

	// Process key events.
	useEffect(() => {
		const onKeyPressed = (event: KeyboardEvent) => defaultKeyboardInputManager.handle(event);
		document.addEventListener('keydown', onKeyPressed);
		return () => document.removeEventListener('keydown', onKeyPressed);
	}, []);

	// Register easteregg manager.
	useEffect(() => {
		defaultEastereggManager.registerKeyboardEvents(defaultKeyboardInputManager);
		return () => defaultEastereggManager.unregisterKeyboardEvents(defaultKeyboardInputManager);
	}, []);

	return (
		<KeyboardInputContext.Provider value={defaultKeyboardInputManager}>
			<TwitchContext.Provider value={defaultTwitchManager}>
				<EastereggManagerContext.Provider value={defaultEastereggManager}>
					<div className="main container-fluid bg-dark">
						<MainArea />
					</div>
				</EastereggManagerContext.Provider>
			</TwitchContext.Provider>
		</KeyboardInputContext.Provider>
  	);
}

function MainArea() {
	let versionNumber = process.env.REACT_APP_VERSION;
	if (process.env.NODE_ENV === 'development') {
		versionNumber += '\uFF5Fdev\uFF60';
	} else if (process.env.NODE_ENV === 'test') {
		versionNumber += '\uFF5Ftest\uFF60';
	}

	const dispatch = useAppDispatch();
	const twitchManager = useTwitchManager();
	const game = useAppSelector(selectGame);
	const settings = useAppSelector(selectSettings);
	const achievements = AchievementSystem.isAchievementsActive;
	
	const [isSettingsOpen, setSettingsOpen] = useState(false);
	const [isAchievementsOpen, setAchievementsOpen] = useState(false);

	const onSettingsButtonClicked = () => setSettingsOpen(true);
	const onAchievementsButtonClicked = () => setAchievementsOpen(true);

	const onResetClicked = () => {
		const channel = twitchManager.enabled && twitchManager.connected && twitchManager.channelName !== null ? twitchManager.channelName : '';
		const channelOwner = game.players.find(player => player.name.toLowerCase() === channel.toLocaleLowerCase());

		dispatch(endGame());

		// Re-add channel owner as player.
		if (channelOwner) {
			const { name, avatarURL } = channelOwner;
			dispatch(addPlayer({ ...makePlayer(name, true), avatarURL, channelOwner: true }));
		}
	};

	const onSaveClicked = () => {
		const data = sessionStorage.getItem('persist:root');
		if (data?.length) {
			const timestamp = format(new Date(), 'yyyyMMddHHmmss');
			const utf8 = new Array(data.length);
			for (let index = 0; index < data.length; ++index) {
				utf8[index] = data.charCodeAt(index);
			}

			const bytes = new Uint8Array(utf8);
			const blob = new Blob([bytes], { type: 'application/json' });
			const url = URL.createObjectURL(blob);

			const link = document.createElement('a');
			link.href = url;
			link.download = `trail_${timestamp}.json`;
			link.click();
		}
	};

	const onLoadClicked = () => {
		const input = document.createElement('input');
		input.type = 'file';
		input.addEventListener('change', (event) => {
			const count = input.files?.length ?? 0;
			if (count > 0) {
				const file = input.files!.item(0)!;
				if (file.name.endsWith('.json') && file.type === 'application/json') {
					file.text().then(json => {
						sessionStorage.setItem('persist:root', json);
						window.location.reload();
					});
				}
			}
		});
		input.click();
	};

	return (
		<>
			<Navbar expand="lg" variant="dark" bg="dark">
				<Container fluid>
					<Navbar.Brand>
						<span>The Valley Lodge Quest</span>
						<small className="text-muted ms-2">{versionNumber}</small>
					</Navbar.Brand>
					<div className="ms-2 fs-4">
						<TwitchBot />
					</div>
					<div>
						{ settings.cheatMode === 'on' &&
						<Dropdown className="d-inline">
							<Dropdown.Toggle variant="secondary" title="Load/Save">
								<GameIcon icon="save" />
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item onClick={onSaveClicked}>Save Game</Dropdown.Item>
								<Dropdown.Item onClick={onLoadClicked}>Load Game</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
						}
						{ achievements &&
						<Button variant="secondary" className="ms-1" onClick={onAchievementsButtonClicked} title="Achievements">
							<GameIcon icon="achievement" />
						</Button>
						}
						<Button variant="secondary" className="ms-1" onClick={onSettingsButtonClicked} title="Settings">
							<GameIcon icon="cog" />
						</Button>
						<Button variant="secondary" className="ms-1" onClick={onResetClicked} title="Reset Game">
							<GameIcon icon="power-button" />
						</Button>
					</div>
				</Container>
			</Navbar>
			<SettingsModal open={[isSettingsOpen, setSettingsOpen]} />
			<AchievementsModal open={[isAchievementsOpen, setAchievementsOpen]} />
			<GameView />
		</>
	);
}