import { useState } from "react";
import { Button } from "react-bootstrap";
import { useAppSelector } from "../../../../hooks";
import { selectGame } from "../../../gameSlice";

import DiaryModal from "./DiaryModal";

export default function DiaryInteractions() {
    const { messages } = useAppSelector(selectGame);
    const [isOpen, setOpen] = useState(false);
    
    const open = () => setOpen(true);

    return (
        <>
        <Button variant="secondary" size="sm" onClick={open}>open</Button>
        <DiaryModal messages={messages} open={[isOpen, setOpen]} />
        </>
    );
}