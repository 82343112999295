import { makeBrokeWagonMessage, makeOxDiedMessage } from "../../types/Message";
import { enableOxDeath, isOxDead, isOxRanOff } from "../../types/Ox";
import { randomEvent } from "../../types/Random";
import { applyWagon, breakWagonComponent, countBrokenAxles, countBrokenWheels, hasBrokenTongue, isWagonBroken, repair } from "../../types/Wagon";
import { GameState } from "../game/gameSlice";

export default class WagonSystem {

    static killOxen(game: GameState): GameState {
        const { weather, date } = game;
        let { oxen } = game;
        const messages = [...game.messages];

        if (oxen.filter(ox => !isOxDead(ox) && !isOxRanOff(ox)).length > 0) {
            let probabilityOfKillingAnOx = 0;
            let causeOfDeath = '';
            if (weather.weatherEvent.name === 'Hail Storm') {
                probabilityOfKillingAnOx = 0.05;
                causeOfDeath = 'spikey hail stone';
            } else if (weather.weatherEvent.name === 'Heavy Fog') {
                probabilityOfKillingAnOx = 0.03;
                causeOfDeath = 'blindly walked into the abyss';
            } else if (weather.weatherEvent.name === 'Severe Weather') {
                probabilityOfKillingAnOx = 0.02;
                causeOfDeath = 'tornado';
            }

            if (randomEvent(probabilityOfKillingAnOx)) {
                const index = oxen.findIndex(ox => !isOxDead(ox) && !isOxRanOff(ox));
                if (index !== -1) {
                    const deadOx = enableOxDeath(oxen[index], causeOfDeath);
                    oxen[index] = deadOx;
                    messages.push(makeOxDiedMessage(deadOx, date));
                }
            }
        }

        return { ...game, messages, oxen };
    }

    static repairWagon(game: GameState): GameState {
        let { wagons } = game;

        const index = wagons.findIndex(wagon => isWagonBroken(wagon) && wagon.repairStatus < 1);
        if (index !== -1) {
            wagons[index] = repair(wagons[index]);
        }

        return { ...game, wagons };
    }

    static breakWagon(game: GameState): GameState {
        const { weather, date } = game;
        let { wagons } = game;
        const messages = [...game.messages];

        const wheelCount = wagons.map(wagon => wagon.wheels.items.length - countBrokenWheels(wagon)).reduce((prev, curr) => prev + curr, 0);
        const axleCount = wagons.map(wagon => wagon.axles.items.length - countBrokenAxles(wagon)).reduce((prev, curr) => prev + curr, 0);
        const tongueCount = wagons.filter(wagon => !hasBrokenTongue(wagon)).length;

        let probabilityOfBreakingWheel = 0;
        let probabilityOfBreakingAxle = 0;
        let probabilityOfBreakingTongue = 0;

        if (weather.weatherEvent.name === 'Severe Weather') {
            probabilityOfBreakingWheel = wheelCount > 0 ? 0.03 : 0;
            probabilityOfBreakingAxle = axleCount > 0 ? 0.01 : 0;
            probabilityOfBreakingTongue = tongueCount > 0 ? 0.01 : 0;
        }

        if (randomEvent(probabilityOfBreakingWheel)) {
            const index = wagons.findIndex(wagon => wagon.wheels.items.length - countBrokenWheels(wagon) > 0);
            const wagon = breakWagonComponent(wagons[index], 'Wheels');
            wagons = applyWagon(wagons, wagon);
            messages.push(makeBrokeWagonMessage(['Wheels'], date));
        }
        
        if (randomEvent(probabilityOfBreakingAxle)) {
            const index = wagons.findIndex(wagon => wagon.axles.items.length - countBrokenAxles(wagon) > 0);
            const wagon = breakWagonComponent(wagons[index], 'Axles');
            wagons = applyWagon(wagons, wagon);
            messages.push(makeBrokeWagonMessage(['Axles'], date));
        }
        
        if (randomEvent(probabilityOfBreakingTongue)) {
            const index = wagons.findIndex(wagon => !hasBrokenTongue(wagon));
            const wagon = breakWagonComponent(wagons[index], 'Tongue');
            wagons = applyWagon(wagons, wagon);
            messages.push(makeBrokeWagonMessage(['Tongue'], date));
        }

        return { ...game, wagons, messages };
    }

}