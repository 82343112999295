import { useCallback, useEffect, useRef, useState } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";

import { getCenterCoordinates } from "../../../types/location/Graph";
import { Location } from "../../../types/location/Location";
import { Routing, routingCurrentLocation, routingDistanceToNextLocation, routingLastLocation, routingLocation, routingLocationConnections, routingLocationsForMapRegions, routingNextLocation, routingPathTaken } from "../../../types/location/Routing";
import { useAppSelector } from "../../hooks";
import { selectGame } from "../gameSlice";
import { selectSettings } from "../../settings/settingsSlice";
import { Trail } from "../../../types/travel/Trail";
import { Town } from "../../../types/location/Town";
import { getMapLODValue, MapItem } from "../../../types/inventory/Maps";

import * as styles from "./MapStyles.json";

export default function MapView() {
    const apiKey = process.env.REACT_APP_GOOGLE_API_KEY ?? '';

    const { trail, questInventory } = useAppSelector(selectGame);
    const { cheatMode } = useAppSelector(selectSettings);
    const currentCoordinates = calculateCurrentCoordinates(trail);

    const renderMap = (status: Status) => {
        switch (status) {
            case Status.LOADING:
                return <div className="text-muted">Loading map…</div>;
            case Status.FAILURE:
                return <div className="text-warning">Error loading map.</div>;
            case Status.SUCCESS:
                return <MyMapView
                    routing={trail.routing}
                    items={questInventory.maps.items}
                    currentCoordinates={currentCoordinates}
                    showClickedCoords={cheatMode === 'on'}
                />;
        }
    };

    return <Wrapper apiKey={apiKey} render={renderMap} />;
}

function getCoordinate(location: Location): google.maps.LatLngLiteral {
    const lat = location.latitude;
    const lng = location.longitude;
    return { lat, lng };
}

function getCenter(routing: Routing): google.maps.LatLngLiteral | undefined {
    const visitedLocations = routingPathTaken(routing);
    if (visitedLocations.length > 0) {
        const lastVisitedLocation = visitedLocations[visitedLocations.length - 1];
        return getCoordinate(lastVisitedLocation);
    }

    const positions = routing.locations.map(getCoordinate);
    return getCenterCoordinates(positions);
}

function MyMapView({ routing, items, currentCoordinates, showClickedCoords }: { routing: Routing, items: MapItem[], currentCoordinates: google.maps.LatLngLiteral | null, showClickedCoords: boolean }) {
    const ref = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<google.maps.Map | null>(null);

    const showMapRegion = useCallback((map: google.maps.Map) => {
        const markers: google.maps.Marker[] = [];
        const lines: google.maps.Polyline[] = [];
        const visitedLocationNames = new Set(routingPathTaken(routing).map(l => l.name));

        const getLocationLevelOfDetail = (location: Location) => {
            const item = items.find(item => location.regions.includes(item.regionName));
            const lod = item === undefined ? 'Nothing' : item.levelOfDetail;
            if (lod === 'Nothing' && visitedLocationNames.has(location.name)) {
                return 'Names';
            }
            return lod;
        };

        const getConnectionLevelOfDetail = (source: Location, destination: Location) => {
            const lod1 = getLocationLevelOfDetail(source);
            const lod2 = getLocationLevelOfDetail(destination);
            if (lod1 === 'Names' && lod2 === 'Names' && visitedLocationNames.has(source.name) && visitedLocationNames.has(destination.name)) {
                return 'Roads';
            }
            return getMapLODValue(lod1) < getMapLODValue(lod2) ? lod2 : lod1;
        };

        const regionNames = items.map(item => item.regionName);
        const locations = routingLocationsForMapRegions(routing, regionNames);
        visitedLocationNames.forEach(name => {
            const contained = locations.findIndex(location => location.name === name) !== -1;
            if (!contained) {
                const location = routingLocation(routing, name);
                if (location !== null) {
                    locations.push(location);
                }
            }
        });
        if (locations.length === 0) {
            return { lines, markers };
        }

        const acceptLocation = (location: Location) => locations.findIndex(l => l.name === location.name) !== -1;
        const connections = routingLocationConnections(routing, acceptLocation);

        locations.forEach(location => {
            if (location.visibility !== 'hidden' && location.type !== 'ValleyLodge') {
                const position = getCoordinate(location);
                const lod = getLocationLevelOfDetail(location);
    
                let infoWindow: google.maps.InfoWindow | null;
                if (lod === 'Details') {
                    let content = `<div>${location.name}</div>`;
                    if (location.type === 'Town') {
                        const town = location as Town;
                        content += '<ul>';
                        town.facilities.forEach(facility => content += `<li>${facility}</li>`);
                        content += '</ul>';
                    }
                    infoWindow = new google.maps.InfoWindow({
                        content: `<div class="text-black">${content}</div>`,
                        ariaLabel: location.name,
                    });
                } else {
                    infoWindow = null;
                }
    
                const icon: google.maps.Symbol = {
                    path: google.maps.SymbolPath.CIRCLE,
                    strokeColor: 'red',
                    scale: 5,
                };
    
                let marker: google.maps.Marker;
                if (lod === 'Names' || lod === 'Roads' || lod === 'Details') {
                    const label: google.maps.MarkerLabel = {
                        text: location.name,
                        className: 'maplabel',
                    };
                    marker = new google.maps.Marker({ position, map, label, icon });
                } else {
                    marker = new google.maps.Marker({ position, map, icon });
                }
    
                if (infoWindow !== null) {
                    marker.addListener('click', () => {
                        const anchor = marker;
                        infoWindow?.open({ anchor, map });
                    });
                }
    
                markers.push(marker);
            }
        });

        connections.forEach(([source, destination]) => {
            const lod = getConnectionLevelOfDetail(source, destination);
            if (lod === 'Nothing' || lod === 'Names') {
                return;
            }

            const path = [getCoordinate(source), getCoordinate(destination)];
            
            const line = new google.maps.Polyline({
                path,
                map,
                geodesic: true,
                strokeColor: 'red',
                strokeOpacity: 1.0,
                strokeWeight: 4,
            });

            lines.push(line);
        });

        return { lines, markers };
    }, [routing, items]);

    useEffect(() => {
        const element = ref.current;
        if (element && !map) {
            const center = currentCoordinates ?? getCenter(routing);

            const map = new google.maps.Map(element, {
                center,
                zoom: 7,
                mapTypeId: 'terrain',
                styles: styles,
            });

            const { lines, markers } = showMapRegion(map);

            if (showClickedCoords) {
                map.addListener('click', (event: google.maps.MapMouseEvent) => {
                    const lat = event.latLng?.lat();
                    const lng = event.latLng?.lng();
                    if (lat && lng) {
                        const position: google.maps.LatLngLiteral = { lat, lng };
                        const marker = new google.maps.Marker({ position, map });
                        const infoWindow = new google.maps.InfoWindow({ content: `<div class="text-black">${lat}, ${lng}</div>` });
                        marker.addListener('click', () => {
                            infoWindow.open({ anchor: marker, map });
                        });
                    }
                    event.stop();
                });
            }

            map.addListener('zoom_changed', () => {
                const zoom = map.getZoom() ?? 0;
                if (zoom <= 5) {
                    markers.forEach(marker => marker.setVisible(false));
                    lines.forEach(line => line.setOptions({ strokeWeight: 1 }));
                } else {
                    markers.forEach(marker => marker.setVisible(true));
                    lines.forEach(line => line.setOptions({ strokeWeight: 4 }));
                }
            });

            if (currentCoordinates) {
                new google.maps.Marker({ position: currentCoordinates, map });
            }

            setMap(map);
        }
    }, [ref, routing, map, currentCoordinates, showClickedCoords, setMap, showMapRegion]);

    return <div ref={ref} id="map" className="text-white" style={{ width: '100%', height: '100%', minHeight: '200px' }}></div>;
}

function calculateCurrentCoordinates(trail: Trail): google.maps.LatLngLiteral | null {
    const { routing, distanceSinceLastLocation } = trail;
    const currentLocation = routingCurrentLocation(routing);
    if (currentLocation !== null) {
        return getCoordinate(currentLocation);
    }

    const lastLocation = routingLastLocation(routing)!;
    const nextLocation = routingNextLocation(routing, trail.direction);
    if (nextLocation !== null) {
        const dlat = nextLocation.latitude - lastLocation.latitude;
        const dlng = nextLocation.longitude - lastLocation.longitude;
        const distance = routingDistanceToNextLocation(routing, trail.direction);
        if (distance !== 0) {
            const scale = distanceSinceLastLocation / distance;
            const lat = lastLocation.latitude + dlat * scale;
            const lng = lastLocation.longitude + dlng * scale;
            return { lat, lng };
        }
    }

    return null;
}