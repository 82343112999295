import { Button, Image } from "react-bootstrap";
import { useAppDispatch } from "../../hooks";
import { removeEvent } from "../gameSlice";
import { HuntingSucceededEvent } from "../../../types/GameEvent";
import { HuntingHit } from "../../../types/Hunting";
import { formatAnd } from "../../../types/Utilities";
import { formatBullets } from "../../../types/inventory/Bullets";
import { formatFood } from "../../../types/inventory/Food";

export default function HuntingSucceededEventView({ event }: { event: HuntingSucceededEvent }) {
    const dispatch = useAppDispatch();
    
    const formattedAnimals = formatAnimals(event.hit);
    const imageName = getHuntingImageName(event.hit);
    
    const onlyRooster = event.hit.animals.length === 1 && event.hit.animals[0].name === 'Rooster';
    
    const totalWeight = event.hit.animals
        .map(animal => animal.weight)
        .reduce((prev, curr) => prev + curr, 0);
    
    const what = onlyRooster
        ? `${formattedAnimals}`
        : `${formattedAnimals} with a total weight of ${formatFood(totalWeight)}`;
    
    const close = () => dispatch(removeEvent(event.id));

    return (
        <div className="d-flex w-100 h-100 justify-content-start align-items-center">
            <div className="text-start p-4">
                <h3>{event.hit.playerName}, your hunting trip is over.</h3>
                <div>You shot {what} and used {formatBullets(event.hit.bullets)}.</div>
                { event.hit.food < totalWeight &&
                    <div>But you can only carry {formatFood(event.hit.food)} back to the group.</div>
                }
                <Button variant="primary" onClick={close}>Close</Button>
            </div>
            <Image src={imageName} height={240} rounded />
        </div>
    );
}

function getHuntingImageName(hit: HuntingHit): string {
    const bears = hit.animals.filter(animal => animal.name === 'Bear').length;
    const deers = hit.animals.filter(animal => animal.name === 'Deer').length;
    const hares = hit.animals.filter(animal => animal.name === 'Hare').length;
    const foxes = hit.animals.filter(animal => animal.name === 'Fox').length;
    const roosters = hit.animals.filter(animal => animal.name === 'Rooster').length;

    if (deers) {
        return '/deer.png';
    } else if (bears) {
        return '/gun.png';
    } else if (roosters) {
        return '/rooster.jpg';
    } else if (hares) {
        return '/hare.png';
    } else if (foxes) {
        return '/fox.png';
    } else {
        return '';
    }
}

function formatAnimals(hit: HuntingHit): string {
    const animalNames = hit.animals.map(animal => animal.name);
    const bears = animalNames.filter(name => name === 'Bear').length;
    const deers = animalNames.filter(name => name === 'Deer').length;
    const hares = animalNames.filter(name => name === 'Hare').length;
    const foxes = animalNames.filter(name => name === 'Fox').length;
    const roosters = animalNames.filter(name => name === 'Rooster').length;

    const formattedNames: string[] = [];
    if (bears) {
        formattedNames.push(formatBears(bears));
    }
    if (deers) {
        formattedNames.push(formatDeers(deers));
    }
    if (hares) {
        formattedNames.push(formatHares(hares));
    }
    if (foxes) {
        formattedNames.push(formatFoxes(foxes));
    }
    if (roosters) {
        formattedNames.push(formatRoosters(roosters));
    }

    return formatAnd(formattedNames);
}

function formatBears(count: number): string {
    if (count === 1) {
        return '1 bear';
    } else {
        return `${count} bears`;
    }
}

function formatDeers(count: number): string {
    if (count === 1) {
        return '1 deer';
    } else {
        return `${count} deers`;
    }
}

function formatHares(count: number): string {
    if (count === 1) {
        return '1 hare';
    } else {
        return `${count} hares`;
    }
}

function formatFoxes(count: number): string {
    if (count === 1) {
        return '1 fox';
    } else {
        return `${count} foxes`;
    }
}

function formatRoosters(count: number): string {
    if (count === 1) {
        return 'one 47 pound rooster';
    } else {
        return `${count} 47 pound roosters`;
    }
}