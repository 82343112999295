const π = Math.PI;
const abs = Math.abs;
const sin = Math.sin;
const cos = Math.cos;
const acos = Math.acos;

export function getCenterCoordinates(points: google.maps.LatLngLiteral[]): google.maps.LatLngLiteral | undefined {
    if (points.length === 0) {
        return undefined;
    } else if (points.length === 1) {
        return points[0];
    }

    const latRange: { min?: number, max?: number } = {};
    const lngRange: { min?: number, max?: number } = {};

    points.forEach(point => {
        const { lat, lng } = point;
        if (latRange.min === undefined || latRange.min > lat) {
            latRange.min = lat;
        }
        if (latRange.max === undefined || latRange.max < lat) {
            latRange.max = lat;
        }
        if (lngRange.min === undefined || lngRange.min > lng) {
            lngRange.min = lng;
        }
        if (lngRange.max === undefined || lngRange.max < lng) {
            lngRange.max = lng;
        }
    });

    const minLat = latRange.min ?? 0;
    const maxLat = latRange.max ?? 0;
    const latStride = Math.abs(maxLat - minLat);

    const minLng = lngRange.min ?? 0;
    const maxLng = lngRange.max ?? 0;
    const lngStride = Math.abs(maxLng - minLng);

    const lat = minLat + latStride / 2;
    const lng = minLng + lngStride / 2;

    return { lat, lng };
}

export function getDistanceInMiles(a: google.maps.LatLngLiteral, b: google.maps.LatLngLiteral): number {
    const r = 3958.8; // radius of earth, in miles
    const radians = (degrees: number) => degrees * π / 180;

    const aLat = radians(a.lat);
    const aLng = radians(a.lng);
    const bLat = radians(b.lat);
    const bLng = radians(b.lng);
    const deltaLng = abs(aLng - bLng);
    const angle = acos(sin(aLat) * sin(bLat) + cos(aLat) * cos(bLat) * cos(deltaLng));

    return angle * r;
}

export interface Neighbor<T> {
    neighbor: T;
    distance: number;
}

export interface Neighbors<T> {
    neighbors: [T, T];
    distance: number;
}

export type Component<T> = Array<T>;

export type EqualsFn<T> = (a: T, b: T) => boolean;
export type AcceptNearestNeighborFn<T> = (neighbor: Neighbor<T>) => boolean;
export type FilterNearestNeighborsFn<T> = (neighbors: Neighbor<T>[]) => Neighbor<T>[];

function defaultEquals<T>(): EqualsFn<T> {
    return (a, b) => a === b;
}

function defaultAcceptNearestNeighbor<T>(): AcceptNearestNeighborFn<T> {
    return (neighbor) => true;
}

function defaultFilterNearestNeighbors<T>(): FilterNearestNeighborsFn<T> {
    return neighbors => neighbors;
}

class ComponentsRef<T> {
    components: Component<T>[];

    constructor(components: Component<T>[] = []) {
        this.components = components;
    }

    update(components: Component<T>[]) {
        this.components = components;
    }
}

interface NearestNeighborsOptions<T> {
    accept?: AcceptNearestNeighborFn<T>;
    filter?: FilterNearestNeighborsFn<T>;
    equals?: EqualsFn<T>;
}

export class AdjacencyMatrix<T> {
    readonly nodes: T[];
    readonly matrix: boolean[][];

    constructor(nodes: T[]) {
        this.nodes = nodes;
        this.matrix = [];

        const count = nodes.length;

        for (let i = 0; i < count; ++i) {
            const array: boolean[] = [];
            for (let j = 0; j < count; ++j) {
                array.push(false);
            }
            this.matrix.push(array);
        }
    }

    connect(a: T, b: T, equals?: EqualsFn<T>) {
        const $equals = equals ?? defaultEquals();

        const count = this.nodes.length;
        const indexA = this.nodes.findIndex(node => $equals(node, a));
        const indexB = this.nodes.findIndex(node => $equals(node, b));

        if (indexA === indexB) {
            return; // can't connect to itself
        } else if (indexA === -1 || indexB === -1 || indexA >= count || indexB >= count) {
            return; // nodes not found
        } else {
            this.matrix[indexA][indexB] = true;
            this.matrix[indexB][indexA] = true;
        }
    }

    disconnect(a: T, b: T, equals?: EqualsFn<T>) {
        const $equals = equals ?? defaultEquals();

        const indexA = this.nodes.findIndex(node => $equals(node, a));
        const indexB = this.nodes.findIndex(node => $equals(node, b));

        if (indexA !== -1 && indexB !== -1) {
            this.matrix[indexA][indexB] = false;
            this.matrix[indexB][indexA] = false;
        }
    }

    neighbors(item: T, equals?: EqualsFn<T>): T[] {
        const $equals = equals ?? defaultEquals();
        const index = this.nodes.findIndex(node => $equals(node, item));
        const count = this.nodes.length;

        if (index === -1 || index >= count) {
            return [];
        } else {
            return this.matrix[index]
                .map((connected, j) => connected ? this.nodes[j] : null)
                .filter(node => node !== null)
                .map(node => node!);
        }
    }

    components(): Component<T>[] {
        const count = this.nodes.length;
        const indices: number[] = [];
        const visited: number[] = [];
        const components: Component<T>[] = [];
        let currentComponent: Component<T> = [];

        for (let index = 0; index < count; ++index) {
            indices.push(index);
        }

        const isVisited = (index: number) => visited.includes(index);
        const markVisited = (index: number) => visited.push(index);

        const dfs = (v: number) => {
            markVisited(v);
            currentComponent.push(this.nodes[v]);

            for (let x = 0; x < count; ++x) {
                const connected = this.matrix[v][x];
                if (connected && !isVisited(x)) {
                    dfs(x);
                }
            }
        };

        for (let v = 0; v < count; ++v) {
            if (!isVisited(v)) {
                dfs(v);
                components.push(currentComponent);
                currentComponent = [];
            }
        }

        return components;
    }

    autoconnectComponents(distanceMatrix: DistanceMatrix<T>) {
        const componentsRef = new ComponentsRef<T>(this.components());
        while (componentsRef.components.length > 1) {
            distanceMatrix
                .connectComponentsToNearestNeighbors(componentsRef.components)
                .forEach(({ neighbors }) => {
                    const [a, b] = neighbors;
                    this.connect(a, b);
                    componentsRef.update(this.components());
                });
        }
    }
}

export class DistanceMatrix<T> {
    readonly locations: T[];
    readonly matrix: number[][];
    readonly coordinates: (location: T) => google.maps.LatLngLiteral;

    constructor(locations: T[], coordinates: (location: T) => google.maps.LatLngLiteral) {
        this.locations = locations;
        this.matrix = [];
        this.coordinates = coordinates;

        const count = locations.length;

        for (let i = 0; i < count; ++i) {
            const I = locations[i];
            const array: number[] = [];

            for (let j = 0; j < count; ++j) {
                if (i === j) {
                    array.push(0);
                } else {
                    const J = locations[j];
                    const distance = getDistanceInMiles(coordinates(I), coordinates(J));
                    array.push(distance);
                }
            }

            this.matrix.push(array);
        }
    }

    distance(a: T, b: T, equals?: EqualsFn<T>): number {
        const $equals = equals ?? defaultEquals();
        if ($equals(a, b)) {
            return 0;
        }

        const count = this.locations.length;
        const indexA = this.locations.findIndex(location => $equals(location, a));
        const indexB = this.locations.findIndex(location => $equals(location, b));

        if (indexA === indexB) {
            return 0;
        } else if (indexA === -1 || indexB === -1 || indexA >= count || indexB >= count) {
            return NaN;
        } else {
            return this.matrix[indexA][indexB];
        }
    }

    nearestNeighbors(location: T, count: number, { accept, filter, equals }: NearestNeighborsOptions<T> = {}): Neighbor<T>[] {
        const $accept = accept ?? defaultAcceptNearestNeighbor();
        const $filter = filter ?? defaultFilterNearestNeighbors();
        const $equals = equals ?? defaultEquals();

        let neighbors: Neighbor<T>[] = [];

        this.locations.forEach(neighbor => {
            const distance = this.distance(location, neighbor, $equals);
            if (!isNaN(distance) && distance !== 0) {
                neighbors.push({ neighbor, distance });
            }
        });

        neighbors.sort((a, b) => {
            if (a.distance < b.distance) {
                return -1;
            } else if (a.distance > b.distance) {
                return 1;
            } else {
                return 0;
            }
        });

        return $filter(neighbors)
            .filter($accept)
            .slice(0, count);
    }

    connectComponentsToNearestNeighbors(components: Component<T>[], equals?: EqualsFn<T>): Neighbors<T>[] {
        const count = components.length;
        const neighbors: Neighbors<T>[] = [];

        for (let index = 0; index < count; ++index) {
            const component = components[index];
            const otherComponents = components.filter((c, i) => index !== i);

            let nearest: Neighbors<T> | null = null;

            otherComponents.forEach(otherComponent => {
                component.forEach(location => {
                    otherComponent.forEach(otherLocation => {
                        const distance = this.distance(location, otherLocation, equals);
                        if (nearest === null || nearest.distance > distance) {
                            nearest = { neighbors: [location, otherLocation], distance };
                        }
                    });
                });
            });

            if (nearest !== null) {
                neighbors.push(nearest);
            }
        }

        return neighbors;
    }
}
