import { Button, ListGroup, Modal } from "react-bootstrap";
import { allStats, Stat, StatModifier } from "../../../types/Stat";
import { useAppSelector } from "../../hooks";
import { selectGame } from "../gameSlice";

interface StatsModalProps {
    open: [boolean, (open: boolean) => void];
}

export default function StatsModal({ open }: StatsModalProps) {
    const [isOpen, setOpen] = open;
    const { stats } = useAppSelector(selectGame);

    const close = () => setOpen(false);

    const statViews = allStats(stats).map(stat => (
        <ListGroup.Item key={stat.name}>
            <StatDetail stat={stat} />
        </ListGroup.Item>
    ));

    return (
        <Modal show={isOpen} onHide={close}>
            <Modal.Header>
                <Modal.Title>Stats</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ListGroup>{statViews}</ListGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={close}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

function StatDetail({ stat }: { stat: Stat }) {
    let color = 'text-muted';
    if (stat.value < 0) {
        color = 'text-danger';
    } else if (stat.value > 0) {
        color = 'text-success';
    }

    const modifierViews = stat.modifiers
        .map((modifier, index) => <ModDetail key={index} modifier={modifier} />);

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center">
                <div>{stat.name}</div>
                <div className={[color].join(' ')}>{stat.value}</div>
            </div>
            {modifierViews}
        </div>
    );
}

function ModDetail({ modifier }: { modifier: StatModifier }) {
    return (
        <div className="ms-4">
            <span className="me-2">{modifier.name}</span>
            { modifier.value < 0 && <span>{modifier.value}</span> }
            { modifier.value > 0 && <span>+{modifier.value}</span> }
        </div>
    );
}