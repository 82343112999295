import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { isSick } from "../../../../../types/health/Disease";
import { isInjured } from "../../../../../types/health/Injury";
import { formatCash } from "../../../../../types/inventory/Cash";
import { routingCurrentLocation } from "../../../../../types/location/Routing";
import { Town } from "../../../../../types/location/Town";
import { isDead } from "../../../../../types/Player";
import { formatAnd } from "../../../../../types/Utilities";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { selectGame, visitDoctor } from "../../../gameSlice";

export default function DoctorModal({ open, close }: { open: boolean, close: () => void }) {
    const dispatch = useAppDispatch();
    const { players, trail, inventory } = useAppSelector(selectGame);
    const location = routingCurrentLocation(trail.routing);
    const town = location?.type === 'Town' ? location as Town : null;
    const patients = players.filter(player => !isDead(player) && (isSick(player) || isInjured(player)));
    const [playerName, setPlayerName] = useState('');

    const selectedPatient = patients.find(patient => patient.name === playerName);
    const issues = selectedPatient === undefined ? [] : [...selectedPatient.diseases, ...selectedPatient.injuries]
        .filter(issue => issue.remainingDays > 0)
        .map(issue => issue.name);

    const price = 30;
    const canAfford = price <= inventory.cash.amount;

    const onPlayerSelected = (name: string) => setPlayerName(name);
    const onAccept = () => {
        dispatch(visitDoctor({ playerName, price }));
        close();
    };

    useEffect(() => {
        const index = patients.findIndex(patient => patient.name === playerName);
        if (index === -1 && patients.length > 0) {
            setPlayerName(patients[0].name);
        } else if (index === -1 && playerName.length > 0) {
            setPlayerName('');
        }
    }, [playerName, setPlayerName, patients]);

    if (town === null || patients.length === 0) {
        return null;
    }

    return (
        <Modal show={open} onHide={close}>
            <Modal.Header>
                <Modal.Title>Doctor Francis Hoo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>Welcome to the doctor's office. What can we do for you?</div>
                <Form.Label className="mt-2">Select a player to heal.</Form.Label>
                <Form.Select onChange={({target}) => onPlayerSelected(target.value)}>
                    { patients.map(patient => (<option key={patient.name} value={patient.name}>{patient.name}</option>)) }
                </Form.Select>
                { selectedPatient !== undefined && issues.length > 0 &&
                <div>
                    <hr />
                    Treating <span className="text-warning">{formatAnd(issues)}</span> will 
                    cost you <span className="text-info">{formatCash(price)}</span>. Trust me, I'm the doctor.
                </div>
                }
                { !canAfford &&
                <div className="mt-2 text-danger">It seems like you can't afford a doctor.</div>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={close}>Cancel</Button>
                <Button variant="primary" onClick={onAccept} disabled={!selectedPatient || !issues.length || !canAfford}>Accept</Button>
            </Modal.Footer>
        </Modal>
    );
}