import { faShirt } from "@fortawesome/free-solid-svg-icons";
import { formatCash } from "../../../../../types/inventory/Cash";
import { formatClothes } from "../../../../../types/inventory/Clothes";
import { calculateClothesCapacity } from "../../../../../types/inventory/Inventory";
import { buyClothes, determineSellingPrice, sellClothes, StoreItemName } from "../../../../../types/Store";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { boughtSomething, changeInventory, selectGame } from "../../../gameSlice";

import ProductAmount from "./ProductAmount";
import ProductName from "./ProductName";
import ProductPrice from "./ProductPrice";
import StoreItem from "./StoreItem";
import TradingButtons from "./TradingButtons";

interface ClothesStoreItemProps {
    price: number;
    selection: [selection: StoreItemName | null, setSelection: (name: StoreItemName | null) => void];
}

export default function ClothesStoreItem({ price, selection }: ClothesStoreItemProps) {
    const dispatch = useAppDispatch();
    const { inventory, stats, wagons, players } = useAppSelector(selectGame);
    const { cash, clothes } = inventory;

    const [selectedItem, setSelectedItem] = selection;
    const active = selectedItem === 'Clothes';
    const capacity = calculateClothesCapacity(inventory, wagons, players);

    let buy: ((amount: number) => void) | undefined = undefined;
    if (cash.amount >= price && capacity > 0) {
        buy = (amount) => {
            const a = Math.min(amount, capacity);
            dispatch(changeInventory(buyClothes(inventory, a, price)));
            dispatch(boughtSomething({ price: price * a, item: 'clothes' }));
            setSelectedItem(null);
        };
    }

    let sell: ((amount: number) => void) | undefined = undefined;
    if (clothes.amount > 0) {
        sell = (amount) => {
            dispatch(changeInventory(sellClothes(inventory, amount, determineSellingPrice(price, stats.trading))));
            setSelectedItem(null);
        };
    }

    const productName = <ProductName>Clothes</ProductName>;

    const productPrice = (
        <ProductPrice active={active}>
            <span>buy for {formatCash(price)}, sell for {formatCash(determineSellingPrice(price, stats.trading))} per set.</span>
        </ProductPrice>
    );

    const productAmount = <ProductAmount>{formatClothes(clothes, true)}</ProductAmount>;

    const money = (amount: number, mode: 'buy' | 'sell') => {
        const factor = mode === 'buy' ? 1 : 0.5;
        return price * factor * amount;
    };

    const tradingButtons = (
        <TradingButtons
            amountInStock={clothes.amount}
            amountPerUnit={1}
            canAfford={(amount) => amount * price <= cash.amount}
            money={money}
            formatUnits={(units) => formatClothes(units, false)}
            minAmount={0}
            maxAmount={capacity}
            buy={buy}
            sell={sell}
        />
    );

    return (
        <StoreItem
            productIcon={faShirt}
            productName={productName}
            productPrice={productPrice}
            productAmount={productAmount}
            tradingButtons={tradingButtons}
        />
    );
}