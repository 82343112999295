import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { Button, ButtonGroup } from "react-bootstrap";
import { useAppSelector } from "../../hooks";
import { selectGame } from "../gameSlice";

import AmountChangeIndicator from "../amountchange/AmountChangeIndicator";
import GameIcon from "../../misc/GameIcon";
import { InventoryItemName } from "../../../types/inventory/InventoryItem";
import { QuestInventoryItemName } from "../../../types/inventory/QuestInventoryItem";

interface InventoryItemViewProps {
    name: InventoryItemName | QuestInventoryItemName | string;
    icon?: IconDefinition;
    gameIcon?: string;
    children: JSX.Element | string;
    increase?: () => void;
    decrease?: () => void;
    showAmountChangeIndicator?: true;
}

export default function InventoryItemView({ name, icon, gameIcon, children, increase, decrease, showAmountChangeIndicator }: InventoryItemViewProps) {
    const { amountChangeIndications } = useAppSelector(selectGame);

    const canIncrease = !!increase;
    const canDecrease = !!decrease;

    const onIncreaseButtonClicked = canIncrease ? () => increase() : () => {};
    const onDecreaseButtonClicked = canDecrease ? () => decrease() : () => {};

    const amountChangeIndicators = (showAmountChangeIndicator ? amountChangeIndications : [])
        .filter(indication => indication.kind === name)
        .map(indication => <AmountChangeIndicator key={indication.id} indication={indication} />);

    return (
        <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-start align-items-center">
                { !!icon && <FontAwesomeIcon icon={icon} fixedWidth className="text-lighter me-2" style={{ fontSize: '150%' }} /> }
                { !!gameIcon && <GameIcon icon={gameIcon} fixedWidth className="text-lighter me-2" /> }
                <strong className="d-none d-lg-inline-block text-lighter text-truncate pe-2">{name}</strong>
                <span>&nbsp;{amountChangeIndicators}</span>
            </div>
            <div className="text-nowrap text-end flex-grow-1 me-1">
                {children}
            </div>
            { (canIncrease || canDecrease) &&
            <ButtonGroup className="d-none d-xl-block">
                <Button variant="secondary" size="sm" onClick={onIncreaseButtonClicked} disabled={!canIncrease}>
                    <FontAwesomeIcon icon={faPlus} />
                </Button>
                <Button variant="secondary" size="sm" onClick={onDecreaseButtonClicked} disabled={!canDecrease}>
                    <FontAwesomeIcon icon={faMinus} />
                </Button>
            </ButtonGroup>
            }
        </div>
    );
}