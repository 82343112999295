import { Button, Image } from "react-bootstrap";
import { ArrivedInDickshooterEvent } from "../../../types/GameEvent";
import { useAppDispatch } from "../../hooks";
import { removeEvent } from "../gameSlice";

export default function FoundDickshooterView({ event }: { event: ArrivedInDickshooterEvent }) {
    const dispatch = useAppDispatch();

    const close = () => dispatch(removeEvent(event.id));

    return (
        <div className="d-flex w-100 h-100 justify-content-start align-items-top">
            <div className="text-start p-4">
                <h3>We arrived at Dickshooter, Idaho!</h3>
                <div>As soon as we arrive we are greeted by the mayor, Richard Shooter.</div>
                <Button variant="primary" onClick={close} className="mt-4">Close</Button>
            </div>
            <Image src="/richardshooter.jpg" rounded />
        </div>
    );
}