import { Button } from "react-bootstrap";
import { makeAsyncHangmanGame } from "../../../../../types/Hangman";
import { useAppDispatch } from "../../../../hooks";
import { startHangman } from "../../../gameSlice";

export default function PlayHangmanButton() {
    const dispatch = useAppDispatch();

    const onClick = () => {
        makeAsyncHangmanGame()
            .then(game => dispatch(startHangman(game)))
            .catch(console.error);
    };

    return (
        <Button variant="primary" className="mb-1 ps-2 pe-2 text-truncate" onClick={onClick}>Play Hangman</Button>
    );
}