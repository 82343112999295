import { ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBatteryFull, faBatteryThreeQuarters, faBatteryHalf, faBatteryQuarter, faBatteryEmpty, faBottleWater, faSackDollar, faShirt, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { changeInventory, selectGame } from "../gameSlice";
import { selectSettings } from "../../settings/settingsSlice";
import { POUND_PER_KILOGRAM, repeat } from "../../../types/Utilities";
import { decreaseInventoryItem, increaseInventoryItem } from "../../../types/inventory/InventoryItem";
import { Water, WaterContainer, WATER_PER_CANISTER } from "../../../types/inventory/Water";
import { formatCash } from "../../../types/inventory/Cash";
import { formatClothes } from "../../../types/inventory/Clothes";
import { formatFood } from "../../../types/inventory/Food";
import { formatBullets } from "../../../types/inventory/Bullets";
import { formatMedicine } from "../../../types/inventory/Medicine";
import { formatSpareParts } from "../../../types/inventory/SpareParts";

import InventoryItemView from "./InventoryItemView";
import GameIcon from "../../misc/GameIcon";
import { addRifle, formatRifles, removeRifle } from "../../../types/inventory/Rifles";
import { calculateBulletsCapacity, calculateClothesCapacity, calculateFoodCapacity, calculateMedicineCapacity, calculateRiflesCapacity, calculateWagonAxlesCapacity, calculateWagonTonguesCapacity, calculateWagonWheelsCapacity, calculateWaterCapacity } from "../../../types/inventory/Inventory";

export default function InventoryView({ expanded }: { expanded?: boolean }) {
    const dispatch = useAppDispatch();
    const { cheatMode } = useAppSelector(selectSettings);
    const { inventory, wagons, players } = useAppSelector(selectGame);
    const { cash, clothes, food, water, bullets, medicine, wagonTongues, wagonWheels, wagonAxles, rifles } = inventory;

    const increaseCash = () => dispatch(changeInventory({ ...inventory, cash: increaseInventoryItem(cash, 200) }));
    const decreaseCash = () => dispatch(changeInventory({ ...inventory, cash: decreaseInventoryItem(cash, 200) }));

    const increaseClothes = cheatMode === 'on' && expanded === true && calculateClothesCapacity(inventory, wagons, players) > 0
        ? () => dispatch(changeInventory({ ...inventory, clothes: increaseInventoryItem(clothes, 1) }))
        : undefined;

    const decreaseClothes = cheatMode === 'on' && expanded === true && clothes.amount > 0
        ? () => dispatch(changeInventory({ ...inventory, clothes: decreaseInventoryItem(clothes, 1) }))
        : undefined;

    const increaseFood = cheatMode === 'on' && expanded === true && calculateFoodCapacity(inventory, wagons, players) >= (50 / POUND_PER_KILOGRAM)
        ? () => dispatch(changeInventory({ ...inventory, food: increaseInventoryItem(food, 50 / POUND_PER_KILOGRAM) }))
        : undefined;
    
    const decreaseFood = cheatMode === 'on' && expanded === true && food.amount > 0
        ? () => dispatch(changeInventory({ ...inventory, food: decreaseInventoryItem(food, 50 / POUND_PER_KILOGRAM) }))
        : undefined;

    const increaseWater = cheatMode === 'on' && expanded === true && calculateWaterCapacity(inventory, wagons, players) >= WATER_PER_CANISTER
        ? () => {
            const containers = [...water.containers];
            const capacity = WATER_PER_CANISTER;
            containers.push({ type: 'Canister', capacity });
            dispatch(changeInventory({ ...inventory, water: increaseInventoryItem({ ...water, containers }, capacity) }));
        }
        : undefined;

    const decreaseWater = cheatMode === 'on' && expanded === true && water.amount > 0
        ? () => {
            const containers = [...water.containers];
            const container = containers.pop();
            if (container !== undefined) {
                dispatch(changeInventory({ ...inventory, water: decreaseInventoryItem({ ...water, containers }, container.capacity) }));
            }
        }
        : undefined;

    const increaseBullets = cheatMode === 'on' && expanded === true && calculateBulletsCapacity(inventory, wagons, players) > 20
        ? () => dispatch(changeInventory({ ...inventory, bullets: increaseInventoryItem(bullets, 20) }))
        : undefined;
    
    const decreaseBullets = cheatMode === 'on' && expanded === true && bullets.amount > 0
        ? () => dispatch(changeInventory({ ...inventory, bullets: decreaseInventoryItem(bullets, 20) }))
        : undefined;

    const increaseMedicine = cheatMode === 'on' && expanded === true && calculateMedicineCapacity(inventory, wagons, players) > 0
        ? () => dispatch(changeInventory({ ...inventory, medicine: increaseInventoryItem(medicine, 1) }))
        : undefined;

    const decreaseMedicine = cheatMode === 'on' && expanded === true && medicine.amount > 0
        ? () => dispatch(changeInventory({ ...inventory, medicine: decreaseInventoryItem(medicine, 1) }))
        : undefined;

    const increaseSpareParts = cheatMode === 'on' && expanded === true && calculateWagonTonguesCapacity(inventory, wagons, players) > 0 && calculateWagonWheelsCapacity(inventory, wagons, players) > 0 && calculateWagonAxlesCapacity(inventory, wagons, players) > 0
        ? () => dispatch(changeInventory({
            ...inventory,
            wagonTongues: increaseInventoryItem(wagonTongues, 1),
            wagonWheels: increaseInventoryItem(wagonWheels, 1),
            wagonAxles: increaseInventoryItem(wagonAxles, 1),
        }))
        : undefined;

    const decreaseSpareParts = cheatMode === 'on' && expanded === true && (wagonTongues.amount > 0 || wagonWheels.amount > 0 || wagonAxles.amount > 0)
        ? () => dispatch(changeInventory({
            ...inventory,
            wagonTongues: decreaseInventoryItem(wagonTongues, 1),
            wagonWheels: decreaseInventoryItem(wagonWheels, 1),
            wagonAxles: decreaseInventoryItem(wagonAxles, 1),
        }))
        : undefined;

    const increaseRifles = cheatMode === 'on' && expanded === true && calculateRiflesCapacity("Grandpa's Rifle", inventory, wagons, players) > 0
        ? () => dispatch(changeInventory({ ...inventory, rifles: addRifle("Grandpa's Rifle", rifles) }))
        : undefined;

    const decreaseRifles = cheatMode === 'on' && expanded && rifles.names.includes("Grandpa's Rifle")
        ? () => dispatch(changeInventory({ ...inventory, rifles: removeRifle("Grandpa's Rifle", rifles) }))
        : undefined;

    return (
        <ListGroup>
            <ListGroup.Item>
                <InventoryItemView name="Cash" icon={faSackDollar} showAmountChangeIndicator increase={!!expanded && cheatMode === 'on' ? increaseCash : undefined} decrease={!!expanded && cheatMode === 'on' && cash.amount ? decreaseCash : undefined}>
                    {formatCash(cash)}
                </InventoryItemView>
            </ListGroup.Item>
            <ListGroup.Item>
                <InventoryItemView name="Clothes" icon={faShirt} showAmountChangeIndicator increase={increaseClothes} decrease={decreaseClothes}>
                    {formatClothes(clothes)}
                </InventoryItemView>
            </ListGroup.Item>
            <ListGroup.Item>
                <InventoryItemView name="Food" gameIcon="meat" showAmountChangeIndicator increase={increaseFood} decrease={decreaseFood}>
                    {formatFood(food)}
                </InventoryItemView>
            </ListGroup.Item>
            <ListGroup.Item>
                <InventoryItemView name="Water" gameIcon="water-flask" showAmountChangeIndicator increase={increaseWater} decrease={decreaseWater}>
                    <WaterView water={water} />
                </InventoryItemView>
            </ListGroup.Item>
            <ListGroup.Item>
                <InventoryItemView name="Bullets" gameIcon="bullets" showAmountChangeIndicator increase={increaseBullets} decrease={decreaseBullets}>
                    {formatBullets(bullets)}
                </InventoryItemView>
            </ListGroup.Item>
            { (rifles.amount > 0 || (!!expanded && cheatMode === 'on')) &&
            <ListGroup.Item>
                <InventoryItemView name="Rifles" gameIcon="winchester-rifle" showAmountChangeIndicator increase={increaseRifles} decrease={decreaseRifles}>
                    {formatRifles(rifles)}
                </InventoryItemView>
            </ListGroup.Item>
            }
            { (medicine.amount > 0 || (!!expanded && cheatMode === 'on')) &&
            <ListGroup.Item>
                <InventoryItemView name="Medicine" gameIcon="medicines" showAmountChangeIndicator increase={increaseMedicine} decrease={decreaseMedicine}>
                    {formatMedicine(medicine)}
                </InventoryItemView>
            </ListGroup.Item>
            }
            { ((wagonTongues.amount > 0 || wagonWheels.amount > 0 || wagonAxles.amount > 0 || (!!expanded && cheatMode === 'on'))) &&
            <ListGroup.Item>
                <InventoryItemView name="Spare Parts" gameIcon="tinker" showAmountChangeIndicator increase={increaseSpareParts} decrease={decreaseSpareParts}>
                    {formatSpareParts(wagonTongues, wagonWheels, wagonAxles)}
                </InventoryItemView>
            </ListGroup.Item>
            }
        </ListGroup>
    );
}

function WaterView({ water }: { water: Water }) {
    const capacity = water.containers
        .map(container => container.capacity)
        .reduce((prev, curr) => prev + curr, 0);

    return (
        <div className="d-flex justify-content-end">
        <WaterFillView amount={water.amount} capacity={capacity} />
        <WaterContainersView containers={water.containers} />
        </div>
    );
}

function WaterFillView({ amount, capacity }: { amount: number, capacity: number }) {
    const ratio = capacity === 0 ? 0 : amount / capacity;
    let icon: IconDefinition;
    if (ratio >= 1) {
        icon = faBatteryFull;
    } else if (ratio >= 0.75) {
        icon = faBatteryThreeQuarters;
    } else if (ratio >= 0.5) {
        icon = faBatteryHalf;
    } else if (ratio >= 0.25) {
        icon = faBatteryQuarter;
    } else {
        icon = faBatteryEmpty;
    }
    const percentage = (100 * ratio).toFixed(0) + '%';
    return <span className="ms-1"><small>{percentage}</small><FontAwesomeIcon icon={icon} rotation={270} /></span>;
}

function WaterContainersView({ containers }: { containers: WaterContainer[] }) {
    const bottles = containers.filter(container => container.type === 'Bottle').length;
    const canisters = containers.filter(container => container.type === 'Canister').length;

    if (bottles === 0 && canisters === 0) {
        return null;
    } else {
        return (
            <div>
            { bottles > 0 && <WaterBottleView count={bottles} /> }
            { canisters > 0 && <WaterCanisterView count={canisters} /> }
            </div>
        );
    }
}

function WaterBottleView({ count }: { count: number }) {
    if (count === 0) {
        return null;
    } else if (count < 6) {
        const icons = repeat(null, count).map((_, index) => <FontAwesomeIcon key={`bottle${index}`} icon={faBottleWater} />);
        return <span>{icons}</span>;
    } else {
        return <span className="ms-1"><small>{count}&times;</small><FontAwesomeIcon icon={faBottleWater} /></span>;
    }
}

function WaterCanisterView({ count }: { count: number }) {
    if (count === 0) {
        return null;
    } else if (count < 4) {
        const icons = repeat(null, count).map((_, index) => <GameIcon key={`canister${index}`} icon="water-gallon" />);
        return <span>{icons}</span>;
    } else {
        return <span className="ms-1"><small>{count}&times;</small><GameIcon icon="water-gallon" /></span>;
    }
}