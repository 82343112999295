import { FormEvent, useState } from "react";
import { Button, Card, Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { makePlayer, Player } from "../../../types/Player";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { addPlayer, selectGame } from "../gameSlice";

import PlayerAvatar from "./PlayerAvatar";

export default function StartPlayerList() {
    const dispatch = useAppDispatch();
    const { players } = useAppSelector(selectGame);

    const [name, setName] = useState("");

    const onAddButtonClicked = (event: FormEvent) => {
        if (name.length > 0) {
            dispatch(addPlayer({ ...makePlayer(name, false), active: false }));
            setName("");
        }
        event.preventDefault();
    };

    return (
        <>
            <div className="d-flex flex-wrap w-100 justify-content-center mb-5">
                { players.map((player, index) => (
                    <div className="m-1" key={`player${index}`}>
                        <PlayerCard player={player} />
                    </div>
                )) }
            </div>

            <Form noValidate onSubmit={onAddButtonClicked} className="w-50 m-auto">
                <InputGroup>
                    <Form.Control
                        type="text"
                        placeholder="Player Name"
                        value={name}
                        onChange={({target}) => setName(target.value)}
                    />
                    <Button type="submit" disabled={!name?.length}>
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                </InputGroup>
            </Form>
        </>
    );
}

function PlayerCard({ player }: { player: Player }) {
    return (
        <Card className="player-view">
            <Card.Body>
                <PlayerAvatar player={player} removeButton />
            </Card.Body>
        </Card>
    );
}