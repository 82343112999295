import { db } from "../app/db";
import { randomElement } from "./Random";


export type HangmanImagePart = 'Head' | 'Torso' | 'Left Arm' | 'Right Arm' | 'Left Leg' | 'Right Leg' | 'Pole' | 'Pole Support Beam' | 'Plank' | 'Plank Support Beam' | 'Rope';
export const HANGMAN_IMAGE_PARTS: HangmanImagePart[] = ['Head', 'Torso', 'Left Arm', 'Right Arm', 'Left Leg', 'Right Leg', 'Pole', 'Pole Support Beam', 'Plank', 'Plank Support Beam', 'Rope'];
const MAX_TRIES = HANGMAN_IMAGE_PARTS.length;

export interface HangmanTry {
    guessedCharacter: string;
    playerName: string;
    correct: boolean;
}

export interface HangmanGame {
    word: string;
    tries: HangmanTry[];
    parts: HangmanImagePart[];
    status: number | 'won' | 'lost'; // number is the remaining tries
}

export async function makeAsyncHangmanGame(options?: { minWordLength?: number, maxWordLength?: number }): Promise<HangmanGame> {
    const minWordLength = options?.minWordLength;
    const maxWordLength = options?.maxWordLength;

    let words = await db.getWords();
    if (!!minWordLength && !!maxWordLength) {
        words = words.filter(word => word.length >= minWordLength && word.length <= maxWordLength);
    } else if (!!minWordLength) {
        words = words.filter(word => word.length >= minWordLength);
    } else if (!!maxWordLength) {
        words = words.filter(word => word.length <= maxWordLength);
    }

    const word = randomElement(words)!.toLowerCase();
    
    return {
        word,
        tries: [],
        parts: [],
        status: MAX_TRIES,
    };
}

function wordCharacters(word: string): Set<string> {
    const characters = new Set<string>();

    for (let index = 0; index < word.length; ++index) {
        characters.add(word.charAt(index));
    }

    return characters;
}

function buildImageParts(count: number): HangmanImagePart[] {
    return Array.from(HANGMAN_IMAGE_PARTS.slice(0, count));
}

export function updateHangmanGame(guessedCharacter: string, playerName: string, game: HangmanGame): HangmanGame {
    guessedCharacter = guessedCharacter.toLowerCase();
    const expectedCharacters = wordCharacters(game.word);
    const guessedCharacters = new Set(game.tries.map(t => t.guessedCharacter));
    if (guessedCharacters.has(guessedCharacter)) {
        return game;
    }

    const correct = expectedCharacters.has(guessedCharacter);
    guessedCharacters.add(guessedCharacter);

    const nextTry: HangmanTry = { guessedCharacter, playerName, correct };
    const tries: HangmanTry[] = [...game.tries, nextTry];

    const correctCharacters = Array.from(guessedCharacters).filter(c => expectedCharacters.has(c) === true);
    const incorrectCharacters = Array.from(guessedCharacters).filter(c => expectedCharacters.has(c) === false);

    if (correctCharacters.length === expectedCharacters.size) {
        return { ...game, tries, status: 'won' };
    } else if (correct) {
        const remainder = MAX_TRIES - incorrectCharacters.length;
        return { ...game, tries, status: remainder <= 0 ? 'lost' : remainder };
    }

    const parts = buildImageParts(incorrectCharacters.length);
    const remainder = MAX_TRIES - incorrectCharacters.length;
    return { ...game, tries, parts, status: remainder <= 0 ? 'lost' : remainder };
}

export function calculateResolvedHangmanWord(game: HangmanGame): string {
    const guessed = new Set(game.tries.filter(t => t.correct).map(t => t.guessedCharacter));
    const characters: string[] = [];

    for (let index = 0; index < game.word.length; ++index) {
        const c = game.word.charAt(index);
        characters.push(guessed.has(c) ? c : '_');
    }

    return characters.join('');
}