import { TwitchEmote } from "../app/twitch/TwitchManager";
import { removeRandomElement } from "./Random";

export interface ChestLockTry {
    symbols: string[];
    playerName: string | null;
    numberOfCorrectSymbolsInWrongPlace: number;
    numberOfCorrectSymbolsInCorrectPlace: number;
}

export interface ChestLock {
    availableSymbols: string[];
    expectedSymbols: string[];
    tries: ChestLockTry[];
    maxTries: number;
    result: 'locked' | 'unlocked';
}

function findEmoteNames(names: string[], emotes: TwitchEmote[], filter: (emote: TwitchEmote) => boolean): string[] {
    return names
        .map(name => emotes.find(emote => filter(emote) && emote.name === name))
        .filter(emote => emote !== undefined)
        .map(emote => emote!.name);
}

export function makeChestLock(symbolCount: number, maxTries: number, emotes: TwitchEmote[], enabledEmoteNames: string[]): ChestLock {
    const expectedSymbols: string[] = [];

    const enabledGlobalEmoteNames = findEmoteNames(enabledEmoteNames, emotes, emote => emote.type === 'global');
    const enabledChannelEmoteNames = findEmoteNames(enabledEmoteNames, emotes, emote => emote.type !== 'global');
    
    const totalCount = Math.floor(symbolCount * 2.5);
    const availableSymbols = [...enabledChannelEmoteNames, ...enabledGlobalEmoteNames].slice(0, totalCount);
    const copyOfAvailableSymbols = [...availableSymbols];
    
    for (let index = 0; index < symbolCount && copyOfAvailableSymbols.length > 0; ++index) {
        const symbol = removeRandomElement(copyOfAvailableSymbols)!;
        expectedSymbols.push(symbol);
    }

    return {
        availableSymbols,
        expectedSymbols,
        tries: [],
        maxTries,
        result: 'locked',
    };
}

export function tryUnlockingChest(lock: ChestLock, playerName: string, symbols: string[]): ChestLock {
    if (lock.result === 'unlocked') {
        return lock; // already unlocked
    } else if (lock.tries.length >= lock.maxTries) {
        return lock; // no more tries
    }

    let numberOfCorrectSymbolsInCorrectPlace = 0;
    let numberOfCorrectSymbolsInWrongPlace = 0;

    for (let symbolIndex = 0; symbolIndex < lock.expectedSymbols.length; ++symbolIndex) {
        const expected = lock.expectedSymbols[symbolIndex];
        const given = symbols.length > symbolIndex ? symbols[symbolIndex] : '';
        const matches = expected === given;
        if (matches) {
            ++numberOfCorrectSymbolsInCorrectPlace;
        } else {
            const index = symbols.findIndex(symbol => expected === symbol);
            if (index !== -1) {
                ++numberOfCorrectSymbolsInWrongPlace;
            }
        }
    }

    const next: ChestLockTry = {
        symbols,
        playerName,
        numberOfCorrectSymbolsInWrongPlace,
        numberOfCorrectSymbolsInCorrectPlace,
    };

    const tries: ChestLockTry[] = [...lock.tries, next];

    const unlocked = next.numberOfCorrectSymbolsInWrongPlace === 0 && next.numberOfCorrectSymbolsInCorrectPlace === lock.expectedSymbols.length;
    if (unlocked) {
        return { ...lock, tries, result: 'unlocked' };
    } else {
        return { ...lock, tries, result: 'locked' };
    }
}