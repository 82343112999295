import { createContext, useContext } from "react";

export interface KeyboardInputHandler {
    (event: KeyboardEvent, top: boolean): boolean;
}

interface NamedKeyboardInputHandler {
    name: string;
    handle: KeyboardInputHandler;
}

interface Predicate {
    (handler: NamedKeyboardInputHandler): boolean;
}

const isModalHandler: Predicate = (handler) => handler.name.toLowerCase().includes('modal');

function not(predicate: Predicate): Predicate {
    return handler => !predicate(handler);
}

export class KeyboardInputManager {

    private _stack: NamedKeyboardInputHandler[] = [];

    push(name: string, handler: KeyboardInputHandler) {
        const index = this._stack.findIndex(handler => name === handler.name);
        if (index === -1) {
            this._stack.push({ name, handle: handler });
        }
    }

    pop(name: string) {
        const index = this._stack.findIndex(handler => name === handler.name);
        if (index !== -1) {
            this._stack.splice(index, 1);
        }
    }

    handle(event: KeyboardEvent): boolean {
        const handlers = [...this._stack].reverse();
        const modalHandlers = handlers.filter(isModalHandler);
        const otherHandlers = handlers.filter(not(isModalHandler));
        const prioritizedHandlers = [...modalHandlers, ...otherHandlers];

        for (let index = 0; index < prioritizedHandlers.length; ++index) {
            const handler = prioritizedHandlers[index];
            const top = index === 0;

            if (handler.handle(event, top)) {
                event.stopPropagation();
                event.preventDefault();
                return true;
            }
        }
        
        return false;
    }

}

export const defaultKeyboardInputManager = new KeyboardInputManager();
export const KeyboardInputContext = createContext(defaultKeyboardInputManager);
KeyboardInputContext.displayName = 'KeyboardInputContext';

export const useKeyboardInput = () => useContext(KeyboardInputContext);