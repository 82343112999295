import { Button } from "react-bootstrap";
import { routingCurrentLocation } from "../../../../../types/location/Routing";
import { Town } from "../../../../../types/location/Town";
import { useAppSelector } from "../../../../hooks";
import { selectGame } from "../../../gameSlice";

export default function StoreButton({ openStore }: { openStore: () => void }) {
    const { status, trail } = useAppSelector(selectGame);
    const location = routingCurrentLocation(trail.routing);
    const town = location?.type === 'Town' ? location as Town : null;
    const store = town?.store ?? null;
    const tradingOutpost = store?.name === 'Trading Outpost';

    if (store === null) {
        return null;
    } else {
        return <Button variant="primary" className="mb-1 ps-2 pe-2 text-truncate" onClick={openStore} disabled={status !== 'Playing'}>{ tradingOutpost ? "Trade" : store.name }</Button>;
    }
}