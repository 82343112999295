import { GameState } from "../../app/game/gameSlice";
import { AnimalAttack } from "../AnimalAttack";
import { BanditAttack } from "../BanditAttack";
import { Hunting } from "../Hunting";
import { routingCurrentLocation, routingNextVisibleLocations, routingRiverCrossingDirections } from "../location/Routing";
import { isLastMessageMatching, Message } from "../Message";
import { isOxDead, isOxRanOff } from "../Ox";
import { isAnyWagonBroken } from "../Wagon";
import { Trail, TravelOptions } from "./Trail";

export type TravelAvailabilityPrevention = 'not playing' | 'cooldown' | 'no oxen' | 'broken wagon' | 'no wagon' | 'under attack' | 'hunting' | 'no destination chosen' | 'no river crossing option chosen';

export function travelPreventions(game: GameState): TravelAvailabilityPrevention[] {
    const { status, limits, trail, travelOptions, oxen, wagons, messages, banditAttack, animalAttack, hunting } = game;
    const { travelCooldownDays } = limits;
    const preventions: TravelAvailabilityPrevention[] = [];

    if (status !== 'Playing') {
        preventions.push('not playing');
    }
    if (travelCooldownDays > 0) {
        preventions.push('cooldown');
    }
    if (oxen.filter(ox => !isOxDead(ox) && !isOxRanOff(ox)).length === 0) {
        preventions.push('no oxen');
    }
    if (wagons.length === 0) {
        preventions.push('no wagon');
    }
    if (isAnyWagonBroken(wagons)) {
        preventions.push('broken wagon');
    }
    if (isGettingAttacked(banditAttack, animalAttack, messages)) {
        preventions.push('under attack');
    }
    if (isHunting(hunting)) {
        preventions.push('hunting');
    }
    if (isNoDestinationChosen(trail, travelOptions)) {
        preventions.push('no destination chosen');
    }
    if (isNoRiverCrossingOptionChosen(trail, travelOptions)) {
        preventions.push('no river crossing option chosen');
    }

    return preventions;
}

export function canTravel(game: GameState): boolean {
    return travelPreventions(game).length === 0;
}

function isGettingAttacked(banditAttack: BanditAttack | null, animalAttack: AnimalAttack | null, messages: Message[]): boolean {
    if (banditAttack !== null && banditAttack.result === undefined) {
        return true;
    } else if (animalAttack !== null && animalAttack.result === undefined) {
        return true;
    }
    
    const currentMessages = messages.filter(message => message.roundIndex === 0);
    const ridersApproaching = isLastMessageMatching(currentMessages, message => message.type === 'Riders Approaching');
    const animalsAttacking = isLastMessageMatching(currentMessages, message => message.type === 'Wild Animals Attacking');
    if (ridersApproaching || animalsAttacking) {
        return true;
    }

    return false;
}

function isHunting(hunting: Hunting | null): boolean {
    if (hunting !== null && hunting.result === undefined) {
        return true;
    }

    return false;
}

function isNoDestinationChosen(trail: Trail, travelOptions: TravelOptions): boolean {
    const currentLocation = routingCurrentLocation(trail.routing);
    const directions = routingNextVisibleLocations(trail.routing).map(location => location.name);
    if (currentLocation !== null && directions.length > 1) {
        if (travelOptions.chosenDestinationName) {
            const index = directions.findIndex(location => location === travelOptions.chosenDestinationName);
            if (index === -1) {
                return true;
            }
        } else {
            return true;
        }
    }

    return false;
}

function isNoRiverCrossingOptionChosen(trail: Trail, travelOptions: TravelOptions): boolean {
    const currentLocation = routingCurrentLocation(trail.routing);

    if (currentLocation?.type === 'RiverCrossing') {
        if (!travelOptions.chosenRiverCrossingOption) {
            let missingRiverCrossingOption = true;
            if (travelOptions.chosenDestinationName) {
                const directions = routingRiverCrossingDirections(trail.routing, 'backwards');
                if (directions.includes(travelOptions.chosenDestinationName)) {
                    // we're going back and won't cross the river
                    missingRiverCrossingOption = false;
                }
            }
            if (missingRiverCrossingOption) {
                return true;
            }
        }
    }

    return false;
}