import { createSlice, Action, PayloadAction } from "@reduxjs/toolkit";
import { Achievement } from "../../types/Achievement";
import { addInventoryChangeIndications, AmountChangeIndication, displayed } from "../../types/AmountChangeIndication";
import { AnimalAttack } from "../../types/AnimalAttack";
import { BanditAttack } from "../../types/BanditAttack";
import { EastereggName, Eastereggs, makeEastereggs } from "../../types/Easteregg";
import { GameEvent, makeGameStartEvent, makeUnlockedAchievementEvent } from "../../types/GameEvent";
import { Hunting } from "../../types/Hunting";
import { Limits, makeLimits } from "../../types/Limits";
import { canMessageStopAutoTravel, makeHangmanEndedMessage, makeHangmanGuessMessage, makePlayerHealthStatsMessage, makeTextMessage, makeUnlockedAchievementMessage, Message } from "../../types/Message";
import { Ox } from "../../types/Ox";
import { Pace } from "../../types/travel/Pace";
import { disablePlayer, enablePlayer, Player } from "../../types/Player";
import { makeQuestInventory, QuestInventory } from "../../types/inventory/QuestInventory";
import { Ration } from "../../types/inventory/Ration";
import { makeEmptyTrail, makeRandomTrail, Trail, TravelOptions } from "../../types/travel/Trail";
import { applyWagon, Wagon } from "../../types/Wagon";
import { makeWeather, Weather } from "../../types/Weather";
import { RootState } from "../store";
import { advance, CheatSystem, ChestLockSystem, EastereggSystem, EnvironmentSystem, FightingAnimalsSystem, FightingBanditsSystem, HealthSystem, HuntingSystem, InventorySystem, jumpToNextLocation, NpcSystem, TownSystem } from "../system";
import { Inventory, makeInventory } from "../../types/inventory/Inventory";
import { makeCash } from "../../types/inventory/Cash";
import { makeClothes } from "../../types/inventory/Clothes";
import { makeStats, Stats } from "../../types/Stat";
import { Role } from "../../types/Role";
import { HangmanGame, updateHangmanGame } from "../../types/Hangman";

export interface GameState {
    status: 'Start' | 'Playing' | 'Game Over';
    role: Role;
    date: number;
    traveling: boolean;
    players: Player[];
    oxen: Ox[];
    pace: Pace;
    ration: Ration;
    inventory: Inventory;
    questInventory: QuestInventory;
    wagons: Wagon[];
    trail: Trail;
    travelOptions: TravelOptions;
    stats: Stats;
    limits: Limits;
    weather: Weather;
    hunting: Hunting | null;
    animalAttack: AnimalAttack | null;
    banditAttack: BanditAttack | null;
    openingChestAttempt: boolean;
    eastereggs: Eastereggs;
    achievements: Achievement[];
    messages: Message[];
    events: GameEvent[];
    amountChangeIndications: AmountChangeIndication[];
    hangmanGame: HangmanGame | null;
}

const INITIAL_BASE_CASH_PER_PERSON = 80;
const INITIAL_MIN_CASH_TOTAL = 200;

const initialState: GameState = {
    status: 'Start',
    role: 'Heir',
    date: 0,
    traveling: false,
    players: [],
    oxen: [],
    pace: 'Steady',
    ration: 'Filling',
    inventory: makeInventory(INITIAL_MIN_CASH_TOTAL),
    questInventory: makeQuestInventory(),
    wagons: [],
    trail: makeEmptyTrail(),
    stats: makeStats(),
    travelOptions: {},
    limits: makeLimits(),
    weather: makeWeather(),
    hunting: null,
    animalAttack: null,
    banditAttack: null,
    openingChestAttempt: false,
    eastereggs: makeEastereggs(),
    achievements: [],
    messages: [],
    events: [],
    amountChangeIndications: [],
    hangmanGame: null,
};

export const gameSlice = createSlice({
    name: 'game',
    initialState,
    reducers: {
        startGame: (state, action: PayloadAction<Role>) => {
            const role = action.payload;
            if (state.status === 'Start') {
                state.role = role;
                state.status = 'Playing';
                state.date = new Date(1848, 2, 1).getTime();
                state.stats = makeStats(role);
                state.trail = makeRandomTrail(role);
                state.events = [makeGameStartEvent()];

                const cash = makeCash(Math.max(INITIAL_MIN_CASH_TOTAL, state.players.length * (INITIAL_BASE_CASH_PER_PERSON + 20 * state.stats.wealth.value)));
                const clothes = makeClothes(state.players.length);
                state.inventory = { ...state.inventory, cash, clothes };
            }
        },

        enableTraveling: (state, action: Action) => {
            state.traveling = true;
        },

        disableTraveling: (state, action: Action) => {
            state.traveling = false;
        },
        
        endGame: (state, action: Action) => {
            const { status, date, players, oxen, pace, ration, inventory, questInventory, wagons, trail, stats, travelOptions, limits, weather, hunting, animalAttack, banditAttack, openingChestAttempt, eastereggs, achievements, messages, events, amountChangeIndications, hangmanGame } = initialState;
            state.status = status;
            state.date = date;
            state.traveling = false;
            state.players = players;
            state.oxen = oxen;
            state.pace = pace;
            state.ration = ration;
            state.inventory = inventory;
            state.questInventory = questInventory;
            state.wagons = wagons;
            state.trail = trail;
            state.stats = stats;
            state.travelOptions = travelOptions;
            state.limits = limits;
            state.weather = weather;
            state.hunting = hunting;
            state.animalAttack = animalAttack;
            state.banditAttack = banditAttack;
            state.openingChestAttempt = openingChestAttempt;
            state.eastereggs = eastereggs;
            state.achievements = achievements;
            state.messages = messages;
            state.events = events;
            state.amountChangeIndications = amountChangeIndications;
            state.hangmanGame = hangmanGame;
        },

        advanceGame: (state, action: PayloadAction<string | undefined>) => {
            const jump = action.payload === 'jump';
            const previousInventory = state.inventory;
            const resting = !!state.travelOptions.rest;
            const { status, date, traveling, players, oxen, pace, ration, inventory, questInventory, wagons, trail, stats, travelOptions, limits, weather, hunting, animalAttack, banditAttack, openingChestAttempt, eastereggs, achievements, messages, events, amountChangeIndications, hangmanGame } = jump ? jumpToNextLocation(state) : advance(state);
            state.status = status;
            state.date = date;
            state.traveling = traveling;
            state.players = players;
            state.oxen = oxen;
            state.pace = pace;
            state.ration = ration;
            state.inventory = inventory;
            state.questInventory = questInventory;
            state.wagons = wagons;
            state.trail = trail;
            state.stats = stats;
            state.travelOptions = travelOptions;
            state.limits = limits;
            state.weather = weather;
            state.hunting = hunting;
            state.animalAttack = animalAttack;
            state.banditAttack = banditAttack;
            state.openingChestAttempt = openingChestAttempt;
            state.eastereggs = eastereggs;
            state.achievements = achievements;
            state.messages = messages;
            state.events = events;
            state.amountChangeIndications = amountChangeIndications;
            state.amountChangeIndications = addInventoryChangeIndications(state.amountChangeIndications, previousInventory, inventory);
            state.hangmanGame = hangmanGame;

            if (state.traveling) {
                const stopFastTravel = state.messages
                    .filter(message => message.roundIndex === 0)
                    .filter(message => canMessageStopAutoTravel(message))
                    .length > 0;
                
                if (stopFastTravel) {
                    state.traveling = false;
                }
            }

            if (resting) {
                const result = EnvironmentSystem.findOxenRunningOff(state);
                state.oxen = result.oxen;
                state.messages = result.messages;
            }
        },

        addPlayer: (state, action: PayloadAction<Player>) => {
            if (state.status === 'Start') {
                const player = action.payload;
                const index = state.players.findIndex(p => p.name === player.name);
                if (index === -1) {
                    state.players = [...state.players, player];
                    state.messages = [...state.messages, makeTextMessage(`:blue:{${player.name}} joined the quest.`, state.date)];
                    console.log(`Added player ${player.name}`);
                }
            }
        },

        removePlayer: (state, action: PayloadAction<string>) => {
            const player = state.players.find(p => p.name.toLowerCase() === action.payload.toLowerCase());
            if (player) {
                if (state.status === 'Start') {
                    state.players = state.players.filter(p => p.name !== player.name);
                    state.messages = [...state.messages, makeTextMessage(`:blue:{${player.name}} left the team.`, state.date)];
                    console.log(`Removed player ${player.name}`);
                } else {
                    const inactivePlayer = disablePlayer(player);
                    state.players = state.players.map(p => p.name === inactivePlayer.name ? inactivePlayer : p);
                    state.messages = [...state.messages, makeTextMessage(`:blue:{${player.name}} fell asleep.`, state.date)];
                    console.log(`Deactivated player ${player.name}`);
                }
            }
        },

        activatePlayer: (state, action: PayloadAction<string>) => {
            const player = state.players.find(p => p.name.toLowerCase() === action.payload.toLowerCase());
            if (player && !player.active && player.hp > 0) {
                const activePlayer = enablePlayer(player);
                if (activePlayer.active) {
                    state.players = state.players.map(p => p.name === activePlayer.name ? activePlayer : p);
                    state.messages = [...state.messages, makeTextMessage(`:blue:{${player.name}} woke up.`, state.date)];
                    console.log(`Activated player ${player.name}`);
                }
            }
        },

        deactivatePlayer: (state, action: PayloadAction<string>) => {
            const player = state.players.find(p => p.name.toLowerCase() === action.payload.toLowerCase());
            if (player && player.active) {
                const inactivePlayer = disablePlayer(player);
                if (!inactivePlayer.active) {
                    state.players = state.players.map(p => p.name === inactivePlayer.name ? inactivePlayer : p);
                    state.messages = [...state.messages, makeTextMessage(`:blue:{${player.name}} fell asleep.`, state.date)];
                    console.log(`Deactivated player ${player.name}`);
                }
            }
        },

        addWagon: (state, action: PayloadAction<Wagon>) => {
            const wagon = action.payload;
            const wagons = state.wagons;
            const index = wagons.findIndex(w => w.id === wagon.id);
            if (index === -1) {
                state.wagons = [...wagons, wagon];
            } else {
                wagons[index] = wagon;
                state.wagons = [...wagons];
            }
        },

        removeWagon: (state, action: PayloadAction<Wagon>) => {
            const wagon = action.payload;
            state.wagons = state.wagons.filter(w => w.id !== wagon.id);
        },

        changeInventory: (state, action: PayloadAction<Inventory>) => {
            const inventory = action.payload;
            const previousInventory = state.inventory;
            state.inventory = inventory;
            state.amountChangeIndications = addInventoryChangeIndications(state.amountChangeIndications, previousInventory, inventory);

            const { stats } = InventorySystem.applyInventoryStatModifiers(state);
            state.stats = stats;
        },

        changeQuestInventory: (state, action: PayloadAction<QuestInventory>) => {
            const questInventory = action.payload;
            state.questInventory = questInventory;
        },

        changeWagon: (state, action: PayloadAction<Wagon>) => {
            state.wagons = applyWagon(state.wagons, action.payload);
        },

        changeWagons: (state, action: PayloadAction<Wagon[]>) => {
            const wagons = action.payload;
            state.wagons = wagons;
        },

        changeOxen: (state, action: PayloadAction<Ox[]>) => {
            const oxen = action.payload;
            state.oxen = oxen;
        },

        changePace: (state, action: PayloadAction<Pace>) => {
            const pace = action.payload;
            if (pace !== state.pace) {
                state.pace = pace;
                state.traveling = false;
            }
        },

        changeRation: (state, action: PayloadAction<Ration>) => {
            const ration = action.payload;
            if (ration !== state.ration) {
                state.ration = ration;
                state.traveling = false;
            }
        },

        reportHealthStats: (state, action: PayloadAction<string>) => {
            const player = state.players.find(p => p.name === action.payload);
            if (player && player.stats) {
                state.messages = [...state.messages, makePlayerHealthStatsMessage(player)];
            }
        },

        giveMedicine: (state, action: PayloadAction<string>) => {
            const previousInventory = state.inventory;
            const playerName = action.payload;
            const { players, inventory } = HealthSystem.giveMedicine(playerName, state);

            state.players = players;
            state.inventory = inventory;
            state.amountChangeIndications = addInventoryChangeIndications(state.amountChangeIndications, previousInventory, inventory);
        },

        visitDoctor: (state, action: PayloadAction<{ playerName: string, price: number }>) => {
            const previousInventory = state.inventory;
            const { playerName, price } = action.payload;
            const { players, inventory, messages } = HealthSystem.visitDoctor(playerName, price, state);

            state.players = players;
            state.inventory = inventory;
            state.messages = messages;
            state.amountChangeIndications = addInventoryChangeIndications(state.amountChangeIndications, previousInventory, inventory);
        },

        setTravelOptions: (state, action: PayloadAction<TravelOptions>) => {
            const options = action.payload;
            state.travelOptions = options;

            if (options.chosenDestinationName) {
                state.trail = { ...state.trail, direction: options.chosenDestinationName };
                state.traveling = false;
            }
        },

        startHunting: (state, action: PayloadAction<Hunting>) => {
            const { hunting, messages } = HuntingSystem.start(action.payload, state);
            state.hunting = hunting;
            state.messages = messages;
            state.traveling = false;
        },

        cancelHunting: (state, action: PayloadAction<number>) => {
            const previousInventory = state.inventory;
            const { hunting, inventory, messages, events } = HuntingSystem.cancel(action.payload, state);
            state.hunting = hunting;
            state.inventory = inventory;
            state.messages = messages;
            state.events = events;
            state.traveling = false;
            state.amountChangeIndications = addInventoryChangeIndications(state.amountChangeIndications, previousInventory, inventory);
        },

        timeoutHunting: (state, action: PayloadAction<number>) => {
            const previousInventory = state.inventory;
            const { hunting, inventory, messages, events } = HuntingSystem.timeout(action.payload, state);
            state.hunting = hunting;
            state.inventory = inventory;
            state.messages = messages;
            state.events = events;
            state.traveling = false;
            state.amountChangeIndications = addInventoryChangeIndications(state.amountChangeIndications, previousInventory, inventory);
        },

        endHunting: (state, action: PayloadAction<{ username: string, message: string, maxDuration: number }>) => {
            const previousInventory = state.inventory;
            const { username, message, maxDuration } = action.payload;
            if (state.hunting?.playerName.toLowerCase() === username.toLowerCase() && !state.hunting?.result) {
                const { hunting, inventory, messages, events } = HuntingSystem.end(message, maxDuration, state);
                state.hunting = hunting;
                state.inventory = inventory;
                state.messages = messages;
                state.events = events;
                state.traveling = false;
                state.amountChangeIndications = addInventoryChangeIndications(state.amountChangeIndications, previousInventory, inventory);

                const result = EnvironmentSystem.findOxenRunningOff(state);
                state.oxen = result.oxen;
                state.messages = result.messages;
            }
        },

        startFightingAnimals: (state, action: PayloadAction<AnimalAttack>) => {
            if (state.animalAttack === null) {
                const { animalAttack, messages } = FightingAnimalsSystem.start(action.payload, state);
                state.animalAttack = animalAttack;
                state.messages = messages;
                state.traveling = false;
            }
        },

        fightAnimalShot: (state, action: PayloadAction<{ playerName: string, word: string }>) => {
            const previousInventory = state.inventory;
            const { playerName, word } = action.payload;
            if (state.animalAttack !== null && state.animalAttack.result === undefined) {
                const { animalAttack, inventory, messages, players } = FightingAnimalsSystem.registerShot(playerName, word, state);
                state.animalAttack = animalAttack;
                state.inventory = inventory;
                state.players = players;
                state.messages = messages;
                state.traveling = false;
                state.amountChangeIndications = addInventoryChangeIndications(state.amountChangeIndications, previousInventory, inventory);
            }
        },

        stopFightingAnimals: (state, action: Action) => {
            const previousInventory = state.inventory;
            if (state.animalAttack !== null && state.animalAttack.result === undefined) {
                const { animalAttack, inventory, messages, players } = FightingAnimalsSystem.stop(state);
                state.animalAttack = animalAttack;
                state.inventory = inventory;
                state.players = players;
                state.messages = messages;
                state.traveling = false;
                state.amountChangeIndications = addInventoryChangeIndications(state.amountChangeIndications, previousInventory, inventory);
            }
        },

        fleeFromWildAnimals: (state, action: Action) => {
            const { players, limits, wagons, messages } = NpcSystem.fleeFromWildAnimals(state);
            state.players = players;
            state.limits = limits;
            state.wagons = wagons;
            state.messages = messages;
            state.traveling = false;
        },

        startFightingBandits: (state, action: PayloadAction<BanditAttack>) => {
            if (state.banditAttack === null) {
                const { banditAttack, messages } = FightingBanditsSystem.start(action.payload, state);
                state.banditAttack = banditAttack;
                state.messages = messages;
                state.traveling = false;
            }
        },

        fightBanditShot: (state, action: PayloadAction<{ playerName: string, word: string }>) => {
            const previousInventory = state.inventory;
            const { playerName, word } = action.payload;
            if (state.banditAttack !== null && state.banditAttack.result === undefined) {
                const { banditAttack, inventory, messages, players } = FightingBanditsSystem.registerShot(playerName, word, state);
                state.banditAttack = banditAttack;
                state.inventory = inventory;
                state.players = players;
                state.messages = messages;
                state.traveling = false;
                state.amountChangeIndications = addInventoryChangeIndications(state.amountChangeIndications, previousInventory, inventory);
            }
        },

        stopFightingBandits: (state, action: Action) => {
            const previousInventory = state.inventory;
            if (state.banditAttack !== null && state.banditAttack.result === undefined) {
                const { banditAttack, inventory, messages, players } = FightingBanditsSystem.stop(state);
                state.banditAttack = banditAttack;
                state.inventory = inventory;
                state.players = players;
                state.messages = messages;
                state.traveling = false;
                state.amountChangeIndications = addInventoryChangeIndications(state.amountChangeIndications, previousInventory, inventory);
            }
        },

        fleeFromRiders: (state, action: Action) => {
            const previousInventory = state.inventory;
            const { banditAttack, inventory, messages, players } = NpcSystem.fleeFromRiders(state);
            state.banditAttack = banditAttack;
            state.inventory = inventory;
            state.players = players;
            state.messages = messages;
            state.traveling = false;
            state.amountChangeIndications = addInventoryChangeIndications(state.amountChangeIndications, previousInventory, inventory);
        },

        clearMessages: (state, action: Action) => {
            state.messages = [];
        },

        appendMessage: (state, action: PayloadAction<Message>) => {
            const message = action.payload;
            state.messages = [...state.messages, message];
        },

        removeEvent: (state, action: PayloadAction<number>) => {
            const id = action.payload;
            state.events = state.events.filter(event => id !== event.id);
        },

        displayedAmountChangeIndications: (state, action: PayloadAction<AmountChangeIndication>) => {
            state.amountChangeIndications = displayed(state.amountChangeIndications, action.payload);
        },

        foundEasteregg: (state, action: PayloadAction<EastereggName>) => {
            const { players, messages, eastereggs } = EastereggSystem.found(action.payload, state);
            state.players = players;
            state.messages = messages;
            state.traveling = false;
            state.eastereggs = eastereggs;
        },

        unlockedAchievement: (state, action: PayloadAction<Achievement>) => {
            const achievement = action.payload;
            if (achievement.id) {
                const exists = state.achievements.findIndex(a => a.name === achievement.name) !== -1;
                if (!exists) {
                    state.achievements = [...state.achievements, achievement];
                    state.messages = [...state.messages, makeUnlockedAchievementMessage(achievement, state.date)];
                    state.events = [...state.events, makeUnlockedAchievementEvent(achievement)];
                } else {
                    console.log(`Ignoring achievement ${achievement.name}, because it already exists.`);
                }
            } else {
                console.warn(`Ignoring achievement ${achievement.name}, you have to store it into the DB first!`);
            }
        },

        cheat: (state, action: PayloadAction<string>) => {
            const input = action.payload;
            const previousInventory = state.inventory;

            const { trail, achievements, messages, events, players, stats, inventory, wagons, oxen } = CheatSystem.cheat(input, state);

            state.trail = trail;
            state.achievements = achievements;
            state.messages = messages;
            state.events = events;
            state.players = players;
            state.stats = stats;
            state.inventory = inventory;
            state.wagons = wagons;
            state.oxen = oxen;
            state.amountChangeIndications = addInventoryChangeIndications(state.amountChangeIndications, previousInventory, inventory);
        },

        fillWaterBottles: (state, action: PayloadAction<{ source: 'Well' | 'River', fixedAmount?: number }>) => {
            const previousInventory = state.inventory;
            const { source, fixedAmount } = action.payload;
            const { inventory } = EnvironmentSystem.fillWaterBottles(state, source, fixedAmount);
            state.inventory = inventory;
            state.amountChangeIndications = addInventoryChangeIndications(state.amountChangeIndications, previousInventory, inventory);
        },

        openChest: (state, action: Action) => {
            if (state.questInventory.chest?.lock?.result === 'locked') {
                const { messages, openingChestAttempt } = ChestLockSystem.startUnlocking(state);
                state.messages = messages;
                state.openingChestAttempt = openingChestAttempt;
            }
        },

        unlockChest: (state, action: PayloadAction<{ playerName: string, message: string }>) => {
            if (state.openingChestAttempt) {
                const { playerName, message } = action.payload;
                const { openingChestAttempt, messages, questInventory } = ChestLockSystem.unlock(playerName, message, state);
                state.openingChestAttempt = openingChestAttempt;
                state.messages = messages;
                state.questInventory = questInventory;
            }
        },

        closeChest: (state, action: Action) => {
            if (state.questInventory.chest?.lock) {
                const { openingChestAttempt, questInventory } = ChestLockSystem.stopUnlocking(state);
                state.openingChestAttempt = openingChestAttempt;
                state.questInventory = questInventory;
            }
        },

        startHangman: (state, action: PayloadAction<HangmanGame>) => {
            state.hangmanGame = action.payload;
        },

        makeHangmanGuess: (state, action: PayloadAction<{ playerName: string, message: string }>) => {
            const { playerName, message } = action.payload;
            const game = state.hangmanGame;
            const player = state.players.find(p => p.name.toLowerCase() === playerName.toLowerCase());

            if (game !== null && game.status !== 'won' && game.status !== 'lost' && player !== undefined && /^[a-zA-Z]$/.test(message)) {
                const updatedGame = updateHangmanGame(message, player.name, game);
                const guess = updatedGame.tries[updatedGame.tries.length - 1];
                const messages = [...state.messages];
                if (guess.playerName === player.name && guess.guessedCharacter === message) {
                    messages.push(makeHangmanGuessMessage(guess, state.date));
                    if (updatedGame.status === 'won' || updatedGame.status === 'lost') {
                        messages.push(makeHangmanEndedMessage(game, state.date));
                    }
                }
                state.hangmanGame = updatedGame;
                state.messages = messages;
            }
        },

        endHangman: (state, action: Action) => {
            state.hangmanGame = null;
        },

        eatAtInn: (state, action: Action) => {
            const previousInventory = state.inventory;
            const { inventory, players, messages } = TownSystem.eatAtInn(state);
            state.inventory = inventory;
            state.players = players;
            state.messages = messages;
            state.amountChangeIndications = addInventoryChangeIndications(state.amountChangeIndications, previousInventory, inventory);
        },

        restAtInn: (state, action: Action) => {
            const previousInventory = state.inventory;
            const { players, inventory } = TownSystem.restAtInn(state);
            state.inventory = inventory;
            state.players = players;
            state.amountChangeIndications = addInventoryChangeIndications(state.amountChangeIndications, previousInventory, inventory);
        },

        boughtSomething: (state, action: PayloadAction<{price: number, item: string}>) => {
            const { price, item } = action.payload;
            const { stats } = InventorySystem.applyShoppingBoostForTrader(state, price, item);
            state.stats = stats;
        },
    }
});

export const {
    startGame, endGame, enableTraveling, disableTraveling, advanceGame,
    addPlayer, removePlayer, activatePlayer, deactivatePlayer, addWagon, removeWagon,
    changeInventory, changeQuestInventory, changeWagon, changeWagons, changeOxen, changePace, changeRation,
    visitDoctor, giveMedicine, reportHealthStats, setTravelOptions,
    startHunting, cancelHunting, timeoutHunting, endHunting,
    startFightingAnimals, fightAnimalShot, stopFightingAnimals, fleeFromWildAnimals,
    startFightingBandits, fightBanditShot, stopFightingBandits, fleeFromRiders,
    clearMessages, appendMessage, removeEvent, displayedAmountChangeIndications,
    foundEasteregg, unlockedAchievement, cheat,
    openChest, unlockChest, closeChest, startHangman, makeHangmanGuess, endHangman,
    eatAtInn, restAtInn, boughtSomething,
} = gameSlice.actions;

export const selectGame = (state: RootState) => state.game;

export default gameSlice.reducer;