import { Ration } from "../inventory/Ration";
import { Player } from "../Player";
import { Pace } from "../travel/Pace";

const HUNGER_HP = 30;      // HP per person per day, meager ration, steady pace
const FOOD_PER_PERSON = 2; // kg of food per person per day, meager ration, steady pace

function hungerFactorForPace(pace: Pace | null): number {
    if (pace === null) {
        return 0.5;
    } else {
        switch (pace) {
            case 'Steady':    return 1.0;
            case 'Strenuous': return 2.0;
            case 'Grueling':  return 3.0;
        }
    }
}

function hungerFactorForRation(ration: Ration): number {
    switch (ration) {
        case 'Bare Bones': return 0.7;
        case 'Meager':     return 1.0;
        case 'Filling':    return 1.3;
    }
}

export function hungerMalus(pace: Pace | null): number {
    const factor = hungerFactorForPace(pace);
    return HUNGER_HP * factor;
}

export function hungerBonus(pace: Pace | null, ration: Ration): number {
    const factor = hungerFactorForPace(pace) * hungerFactorForRation(ration);
    return HUNGER_HP * factor;
}

function determineIdealRationPerPerson(pace: Pace | null, ration: Ration): number {
    const factor = hungerFactorForPace(pace) * hungerFactorForRation(ration);
    return FOOD_PER_PERSON * factor;
}

interface RationedFood {
    food: number;
    fraction: number;
}

function calculateRationedFoodPerPerson(pace: Pace | null, ration: Ration, foodAvailable: number): RationedFood {
    if (foodAvailable === 0) {
        return { food: 0, fraction: 0 };
    }

    const idealRation = determineIdealRationPerPerson(pace, ration);

    if (!isFinite(foodAvailable) || idealRation <= foodAvailable) {
        return { food: idealRation, fraction: 1 };
    } else {
        const fraction = foodAvailable / idealRation;
        return { food: foodAvailable, fraction };
    }
}

export interface FoodRationPerPerson {
    food: number; // weight of food
    fraction: number; // of ideal ration
    hpBonus: number; // from feeding
    hpMalus: number; // from hunger
}

export function calculateFoodRationPerPerson(pace: Pace | null, ration: Ration, foodAvailable: number = Infinity): FoodRationPerPerson {
    const rationedFood = calculateRationedFoodPerPerson(pace, ration, foodAvailable);
    const hpBonus = Math.floor(hungerBonus(pace, ration) * rationedFood.fraction);
    const hpMalus = hungerMalus(pace);

    return { ...rationedFood, hpBonus, hpMalus };
}

export function calculateFoodRations(pace: Pace | null, ration: Ration, peopleCount: number, totalFoodAvailable: number): FoodRationPerPerson {
    if (peopleCount === 0) {
        return { food: 0, fraction: 0, hpBonus: 0, hpMalus: 0 };
    } else if (peopleCount === 1) {
        return calculateFoodRationPerPerson(pace, ration, totalFoodAvailable);
    } else {
        const foodAvailable = totalFoodAvailable / peopleCount;
        return calculateFoodRationPerPerson(pace, ration, foodAvailable);
    }
}

export function isHungry(player: Player): boolean {
    return player.hungry === true;
}

export function enableHunger(player: Player): Player {
    return { ...player, hungry: true };
}

export function disableHunger(player: Player): Player {
    return { ...player, hungry: false };
}
