import { formatCash } from "../../../../../types/inventory/Cash";
import { formatFood } from "../../../../../types/inventory/Food";
import { calculateFoodCapacity } from "../../../../../types/inventory/Inventory";
import { buyFood, determineSellingPrice, sellFood, StoreItemName } from "../../../../../types/Store";
import { POUND_PER_KILOGRAM } from "../../../../../types/Utilities";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { boughtSomething, changeInventory, selectGame } from "../../../gameSlice";

import ProductAmount from "./ProductAmount";
import ProductName from "./ProductName";
import ProductPrice from "./ProductPrice";
import StoreItem from "./StoreItem";
import TradingButtons from "./TradingButtons";

interface FoodStoreItemProps {
    price: number;
    selection: [selection: StoreItemName | null, setSelection: (name: StoreItemName | null) => void];
}

export default function FoodStoreItem({ price, selection }: FoodStoreItemProps) {
    const dispatch = useAppDispatch();
    const { inventory, stats, wagons, players } = useAppSelector(selectGame);
    const { cash, food } = inventory;

    const [selectedItem, setSelectedItem] = selection;
    const active = selectedItem === 'Water';
    const foodPerBox = 20 / POUND_PER_KILOGRAM;
    const capacity = calculateFoodCapacity(inventory, wagons, players);

    let buy: ((amount: number) => void) | undefined = undefined;
    if (cash.amount >= price && capacity >= foodPerBox) {
        buy = (amount) => {
            const a = Math.min(amount, capacity);
            dispatch(changeInventory(buyFood(inventory, a, price)));
            dispatch(boughtSomething({ price: price * a, item: 'food' }));
            setSelectedItem(null);
        };
    }

    let sell: ((amount: number) => void) | undefined = undefined;
    if (food.amount > 0) {
        sell = (amount) => {
            dispatch(changeInventory(sellFood(inventory, amount, determineSellingPrice(price, stats.trading))));
            setSelectedItem(null);
        };
    }

    const productName = <ProductName>Food</ProductName>;

    const productPrice = (
        <ProductPrice active={active}>
            <span>buy for {formatCash(price * foodPerBox)}, sell for {formatCash(determineSellingPrice(price, stats.trading) * foodPerBox)} per box, with {formatFood(foodPerBox)} per box.</span>
        </ProductPrice>
    );

    const productAmount = <ProductAmount>{formatFood(food)}</ProductAmount>;

    const money = (amount: number, mode: 'buy' | 'sell') => {
        const factor = mode === 'buy' ? 1 : 0.5;
        return price * factor * amount;
    };

    const tradingButtons = (
        <TradingButtons
            amountInStock={food.amount}
            amountPerUnit={foodPerBox}
            canAfford={(amount) => amount * price <= cash.amount}
            money={money}
            formatUnits={(units) => formatFood(units * foodPerBox)}
            minAmount={0}
            maxAmount={capacity}
            buy={buy}
            sell={sell}
        />
    );

    return (
        <StoreItem
            gameIcon="meat"
            productName={productName}
            productPrice={productPrice}
            productAmount={productAmount}
            tradingButtons={tradingButtons}
        />
    );
}