import { db } from "../app/db";

export interface Word {
    id?: number;
    word: string;
}

export function makeWord(word: string): Word {
    return { word };
}

export async function fetchWords(): Promise<string[]> {
    const storedWords = await db.getWords();
    if (storedWords.length > 0) {
        return storedWords;
    }

    let url: URL;
    if (process.env.NODE_ENV === 'production') {
        url = new URL('https://valleylodge.quest/wordlist.txt');
    } else {
        const { protocol, hostname, port } = window.location;
        url = new URL(`${protocol}//${hostname}:${port}/wordlist.txt`);
    }

    const response = await fetch(url);
    if (200 === response.status) {
        const text = await response.text();
        const words = text
            .split('\n')
            .map(line => line.trim())
            .filter(line => line.length > 0);
        
        if (words.length === 0) {
            console.warn('Wordlist is empty.');
            return [];
        }

        await db.importWords(words);
        console.info(`Imported ${words.length} words.`);

        return words;
    } else {
        throw new Error(`Error loading word list, HTTP status ${response.status}`);
    }
}