import { Form } from "react-bootstrap";
import { calculateFoodRationPerPerson } from "../../../../types/health/Hunger";
import { ALL_RATIONS, Ration } from "../../../../types/inventory/Ration";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { selectSettings } from "../../../settings/settingsSlice";
import { changeRation, selectGame } from "../../gameSlice";

export default function RationControl() {
    const dispatch = useAppDispatch();
    const { ration, pace } = useAppSelector(selectGame);
    const { cheatMode } = useAppSelector(selectSettings);

    const setRation = (value: string) => {
        const ration = value as Ration;
        dispatch(changeRation(ration));
    };

    const options = ALL_RATIONS.map(ration => {
        if (cheatMode === 'on') {
            return <option key={ration} value={ration}>{ration} [{calculateFoodRationPerPerson(pace, ration).hpBonus} HP]</option>;
        } else {
            return <option key={ration} value={ration}>{ration}</option>;
        }
    });

    return <Form.Select value={ration} onChange={({target}) => setRation(target.value)}>{options}</Form.Select>;
}