import { formatCash } from "../../../../../types/inventory/Cash";
import { calculateMedicineCapacity } from "../../../../../types/inventory/Inventory";
import { formatMedicine } from "../../../../../types/inventory/Medicine";
import { buyMedicine, determineSellingPrice, sellMedicine, StoreItemName } from "../../../../../types/Store";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { boughtSomething, changeInventory, selectGame } from "../../../gameSlice";

import ProductAmount from "./ProductAmount";
import ProductName from "./ProductName";
import ProductPrice from "./ProductPrice";
import StoreItem from "./StoreItem";
import TradingButtons from "./TradingButtons";

interface MedicineStoreItemProps {
    price: number;
    selection: [selection: StoreItemName | null, setSelection: (name: StoreItemName | null) => void];
}

export default function MedicineStoreItem({ price, selection }: MedicineStoreItemProps) {
    const dispatch = useAppDispatch();
    const { inventory, stats, wagons, players } = useAppSelector(selectGame);
    const { cash, medicine } = inventory;

    const [selectedItem, setSelectedItem] = selection;
    const active = selectedItem === 'Medicine';
    const capacity = calculateMedicineCapacity(inventory, wagons, players);

    let buy: ((amount: number) => void) | undefined = undefined;
    if (cash.amount >= price && capacity > 0) {
        buy = (amount) => {
            const a = Math.min(amount, capacity);
            dispatch(changeInventory(buyMedicine(inventory, a, price)));
            dispatch(boughtSomething({ price: price * a, item: 'medicine' }));
            setSelectedItem(null);
        };
    }

    let sell: ((amount: number) => void) | undefined = undefined;
    if (medicine.amount > 0) {
        sell = (amount) => {
            dispatch(changeInventory(sellMedicine(inventory, amount, determineSellingPrice(price, stats.trading))));
            setSelectedItem(null);
        };
    }

    const productName = <ProductName>Medicine</ProductName>;

    const productPrice = (
        <ProductPrice active={active}>
            <span>buy for {formatCash(price)}, sell for {formatCash(determineSellingPrice(price, stats.trading))} per pack.</span>
        </ProductPrice>
    );

    const productAmount = <ProductAmount>{formatMedicine(medicine, true)}</ProductAmount>;

    const money = (amount: number, mode: 'buy' | 'sell') => {
        const factor = mode === 'buy' ? 1 : 0.5;
        return price * factor * amount;
    };

    const tradingButtons = (
        <TradingButtons
            amountInStock={medicine.amount}
            amountPerUnit={1}
            canAfford={(amount) => amount * price <= cash.amount}
            money={money}
            formatUnits={(units) => formatMedicine(units, false)}
            minAmount={0}
            maxAmount={capacity}
            buy={buy}
            sell={sell}
        />
    );

    return (
        <StoreItem
            gameIcon="medicines"
            productName={productName}
            productPrice={productPrice}
            productAmount={productAmount}
            tradingButtons={tradingButtons}
        />
    );
}