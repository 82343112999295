import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Col, ListGroup, Modal, Row } from "react-bootstrap";
import { calculateResolvedHangmanWord, HangmanImagePart, HangmanTry } from "../../../../../types/Hangman";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { selectSettings } from "../../../../settings/settingsSlice";
import { makeHangmanGuess, selectGame } from "../../../gameSlice";
import { useKeyboardInput } from "../../../../system/KeyboardInputManager";
import { Player } from "../../../../../types/Player";

import AvatarImage from "../../../passenger/AvatarImage";

interface HangmanGameModalProps {
    open: boolean;
    close: () => void;
}

export default function HangmanGameModal({ open, close }: HangmanGameModalProps) {
    const dispatch = useAppDispatch();
    const { hangmanGame, players } = useAppSelector(selectGame);
    const { cheatMode, twitchCommands } = useAppSelector(selectSettings);
    const keyboardInput = useKeyboardInput();

    const word = hangmanGame ? calculateResolvedHangmanWord(hangmanGame) : '';

    const guesses = (hangmanGame ? hangmanGame.tries : [])
        .map((guess, i) => {
            const player = players.find(p => p.name.toLowerCase() === guess.playerName.toLowerCase());
            return <ListGroup.Item key={i}><GuessView guess={guess} player={player} /></ListGroup.Item>;
        });
    
    const parts = hangmanGame?.parts ?? [];
    const won = hangmanGame?.status === 'won';
    const lost = hangmanGame?.status === 'lost';
    const remaining = (won || lost) ? 0 : hangmanGame?.status ?? 0;
    const usedCharacters = new Set((hangmanGame?.tries ?? []).map(guess => guess.guessedCharacter));

    const [isAnswerVisible, setAnswerVisible] = useState(false);
    // const [parts, setParts] = useState<HangmanImagePart[]>([]);
    // const addPart = () => setParts(Array.from([...HANGMAN_IMAGE_PARTS].slice(0, parts.length + 1)))};

    const onKeyPressed = useCallback((event: KeyboardEvent, top: boolean) => {
        if (top) {
            const { key } = event;
            const player = players.find(p => !!p.channelOwner);

            if (/^[a-zA-Z]$/.test(key) && !!player) {
                dispatch(makeHangmanGuess({ playerName: player.name, message: key }));
            }
        }
        return false;
    }, [players, dispatch]);
    
    const onTitleClicked = () => {
        if (cheatMode === 'on') {
            setAnswerVisible(!isAnswerVisible);
        }
    };

    useEffect(() => {
        const name = 'HangmanGameModal';
        keyboardInput.push(name, onKeyPressed);
        return () => keyboardInput.pop(name);
    }, [keyboardInput, onKeyPressed]);

    return (
        <Modal show={open} backdrop="static" size="xl">
            <Modal.Header>
                <Modal.Title onClick={onTitleClicked}>
                    { isAnswerVisible && hangmanGame !== null && <span>{hangmanGame.word}</span> }
                    { !isAnswerVisible && <span>A Game of Hangman</span> }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="fs-5 text-center text-muted mb-4">
                    Guess the word. Enter <span className="text-warning">!{twitchCommands.guess}</span> followed by a letter in chat.
                </div>
                <Row style={{ minHeight: '30rem' }}>
                    <Col sm={3}>
                        <ListGroup>{guesses}</ListGroup>
                    </Col>
                    <Col>
                        <WordView word={word} />
                        <div className="fs-4 text-center mt-3 mb-5">
                            { !!remaining && <span>Remaining guesses: {remaining}</span> }
                            { won && <span className="text-success">Yay, we won!</span> }
                            { lost && <span className="text-danger">Oh no, we lost! The word was: {hangmanGame?.word ?? ''}.</span> }
                        </div>
                        <KeyboardView usedCharacters={usedCharacters} />
                    </Col>
                    <Col sm={3}>
                        <HangmanImage parts={parts} />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={close}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

function WordView({ word }: { word: string }) {
    const characters = word.split('')
        .map((c, i) => <CharacterView key={i} character={c} />);
    
    return <div className="d-flex w-100 justify-content-center align-items-center">{characters}</div>;
}

function CharacterView({ character }: { character: string }) {
    return <div className="fs-1 ms-2">{character.toUpperCase()}</div>;
}

function GuessView({ guess, player }: { guess: HangmanTry, player?: Player }) {
    return (
        <div className="d-flex justify-content-start align-items-center">
            { !!player && <AvatarImage player={player} size={32} /> }
            <div className="flex-grow-1 text-truncate ms-2">{guess.playerName}</div>
            { guess.correct && <div className="ms-2 fs-4 text-center text-success" style={{ width: '1rem' }}>{guess.guessedCharacter.toUpperCase()}</div> }
            { !guess.correct && <div className="ms-2 fs-4 text-center text-danger" style={{ width: '1rem' }}>{guess.guessedCharacter.toUpperCase()}</div> }
        </div>
    );
}

function KeyboardView({ usedCharacters }: { usedCharacters: Set<string> }) {
    const layout = ['QWERTYUIOP', 'ASDFGHJKL', 'ZXCVBNM'].map(line => line.split(''));

    const lineViews = layout.map((line, lineIndex) => {
        const keyViews = line.map((key, keyIndex) => {
            const id = `key_${lineIndex}_${keyIndex}`;
            const style = { width: '1.5rem', aspectRatio: '1' };
            if (usedCharacters.has(key.toLowerCase()) || usedCharacters.has(key.toUpperCase())) {
                return <div key={id} style={style} className="text-center text-muted m-1">{key}</div>;
            } else {
                return <div key={id} style={style} className="text-center m-1"><strong>{key}</strong></div>;
            }
        });

        return <div className="d-flex justify-content-center align-items-center">{keyViews}</div>;
    });

    return (
        <div>
            <div>{ lineViews[0] }</div>
            <div>{ lineViews[1] }</div>
            <div>{ lineViews[2] }</div>
        </div>
    );
}

function HangmanImage({ parts }: { parts: HangmanImagePart[] }) {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);

    useEffect(() => {
        const head     = parts.includes('Head');
        const torso    = parts.includes('Torso');
        const leftArm  = parts.includes('Left Arm');
        const rightArm = parts.includes('Right Arm');
        const leftLeg  = parts.includes('Left Leg');
        const rightLeg = parts.includes('Right Leg');
        
        const pole             = parts.includes('Pole');
        const poleSupportBeam  = parts.includes('Pole Support Beam');
        const plank            = parts.includes('Plank');
        const plankSupportBeam = parts.includes('Plank Support Beam');
        const rope             = parts.includes('Rope');

        const canvas = canvasRef.current;
        const context = canvas?.getContext('2d');
        if (canvas && context) {
            const w = canvas.width;
            const h = canvas.height;
            const π = Math.PI;

            const headCenter = [w * 0.3, rope ? h * 0.4 : h * 0.5];
            const headRadius = 50;
            const leftEye = [headCenter[0] - headRadius * 0.3, headCenter[1] - headRadius * 0.2];
            const rightEye = [headCenter[0] + headRadius * 0.3, headCenter[1] - headRadius * 0.2];
            const torsoLength = h * 0.25;
            const armLength = w * 0.2;
            const armOffset = torsoLength * 0.2;
            const legLength = w * 0.2;
            const baseY = h * 0.9;
            const baseLength = w * 0.9;
            const poleX = w * 0.75;
            const poleLength = h * 0.8;
            const supportBeamOffset = w * 0.15;
            const plankLength = poleX - headCenter[0];

            context.strokeStyle = 'red';
            context.lineWidth = 6;
            context.fillStyle = 'black';
            context.setLineDash([]);
            context.clearRect(0, 0, w, h);

            context.beginPath();
            context.moveTo(w/2 - baseLength * 0.5, baseY);
            context.lineTo(w/2 + baseLength * 0.5, baseY);
            context.stroke();

            if (head) {
                context.beginPath();
                context.arc(headCenter[0], headCenter[1], headRadius, 0, 2 * π);
                context.stroke();

                // left eye
                context.beginPath();
                if (rope) {
                    context.moveTo(leftEye[0] - headRadius * .15, leftEye[1] - headRadius * .15);
                    context.lineTo(leftEye[0] + headRadius * .15, leftEye[1] + headRadius * .15);
                    context.moveTo(leftEye[0] - headRadius * .15, leftEye[1] + headRadius * .15);
                    context.lineTo(leftEye[0] + headRadius * .15, leftEye[1] - headRadius * .15);
                } else {
                    context.arc(leftEye[0], leftEye[1], headRadius * 0.1, 0, 2 * π);
                }
                context.stroke();

                // right eye
                context.beginPath();
                if (rope) {
                    context.moveTo(rightEye[0] - headRadius * .15, rightEye[1] - headRadius * .15);
                    context.lineTo(rightEye[0] + headRadius * .15, rightEye[1] + headRadius * .15);
                    context.moveTo(rightEye[0] - headRadius * .15, rightEye[1] + headRadius * .15);
                    context.lineTo(rightEye[0] + headRadius * .15, rightEye[1] - headRadius * .15);
                } else {
                    context.arc(rightEye[0], rightEye[1], headRadius * 0.1, 0, 2 * π);
                }
                context.stroke();
            }

            if (torso) {
                context.beginPath();
                context.moveTo(headCenter[0], headCenter[1] + headRadius);
                context.lineTo(headCenter[0], headCenter[1] + headRadius + torsoLength);
                context.stroke();
            }

            if (leftArm) {
                context.beginPath();
                context.moveTo(headCenter[0], headCenter[1] + headRadius + armOffset);
                if (rope) {
                    context.lineTo(headCenter[0] - armLength, headCenter[1] + headRadius + armOffset - armLength / 2);
                } else {
                    context.lineTo(headCenter[0] - armLength, headCenter[1] + headRadius + armOffset + armLength / 2);
                }
                context.stroke();
            }

            if (rightArm) {
                context.beginPath();
                context.moveTo(headCenter[0], headCenter[1] + headRadius + armOffset);
                if (rope) {
                    context.lineTo(headCenter[0] + armLength, headCenter[1] + headRadius + armOffset - armLength / 2);
                } else {
                    context.lineTo(headCenter[0] + armLength, headCenter[1] + headRadius + armOffset + armLength / 2);
                }
                context.stroke();
            }

            if (leftLeg) {
                context.beginPath();
                context.moveTo(headCenter[0], headCenter[1] + headRadius + torsoLength);
                context.lineTo(headCenter[0] - legLength, headCenter[1] + headRadius + torsoLength + legLength / 2);
                context.stroke();
            }

            if (rightLeg) {
                context.beginPath();
                context.moveTo(headCenter[0], headCenter[1] + headRadius + torsoLength);
                context.lineTo(headCenter[0] + legLength, headCenter[1] + headRadius + torsoLength + legLength / 2);
                context.stroke();
            }

            if (pole) {
                context.beginPath();
                context.moveTo(poleX, baseY);
                context.lineTo(poleX, baseY - poleLength);
                context.stroke();
            }

            if (poleSupportBeam) {
                context.beginPath();
                context.moveTo(poleX - supportBeamOffset, baseY);
                context.lineTo(poleX, baseY - supportBeamOffset);
                context.lineTo(poleX + supportBeamOffset, baseY);
                context.stroke();
            }

            if (plank) {
                context.beginPath();
                context.moveTo(poleX, baseY - poleLength);
                context.lineTo(poleX - plankLength, baseY - poleLength);
                context.stroke();
            }

            if (plankSupportBeam) {
                context.beginPath();
                context.moveTo(poleX - supportBeamOffset, baseY - poleLength);
                context.lineTo(poleX, baseY - poleLength + supportBeamOffset);
                context.stroke();
            }

            if (rope) {
                context.beginPath();
                context.setLineDash([8, 3]);
                context.moveTo(headCenter[0], baseY - poleLength);
                context.lineTo(headCenter[0], headCenter[1] - headRadius);
                context.stroke();
            }
        }
    }, [parts, canvasRef]);

    return <canvas width={600} height={800} ref={canvasRef} className="rounded bg-darker-5" style={{ width: '100%', aspectRatio: '600/800' }}></canvas>;
}