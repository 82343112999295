import { useEffect } from "react";
import { Alert } from "react-bootstrap";
import { UnlockedAchievementEvent } from "../../../types/GameEvent";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { removeEvent, selectGame } from "../gameSlice";

import AchievementView from "../achievement/AchievementView";

const timers = new Map<number, any>();

export default function EventBannerView() {
    const dispatch = useAppDispatch();
    const { events } = useAppSelector(selectGame);

    const event = events.length === 0 ? null : events[0];

    useEffect(() => {
        if (event !== null && !timers.has(event.id)) {
            const autoRemove = event.type !== 'Game Start' && event.type !== 'Arrived In Dickshooter';
            if (autoRemove) {
                const timer = setTimeout(() => dispatch(removeEvent(event.id)), 5000);
                timers.set(event.id, timer);
            }
        }
    }, [event, dispatch]);

    if (event === null) {
        return null;
    } else {
        let view: JSX.Element | null = null;
        switch (event.type) {
            case 'Unlocked Achievement':
                view = <AchievementUnlockedEventView event={event as UnlockedAchievementEvent} />;
                break;
        }

        if (view === null) {
            return null;
        } else {
            return <div>{view}</div>;
        }
    }
}

function AchievementUnlockedEventView({ event }: { event: UnlockedAchievementEvent }) {
    const dispatch = useAppDispatch();

    const close = () => dispatch(removeEvent(event.id));
    
    return (
        <Alert variant="dark" onClose={close} dismissible>
            <AchievementView achievement={event.achievement} size="lg" prependTitle="Achievement unlocked: " />
        </Alert>
    );
}