import { Card } from "react-bootstrap";
import { routingCurrentLocation } from "../../../../types/location/Routing";
import { useAppSelector } from "../../../hooks";
import { selectGame } from "../../gameSlice";

import RationControl from "../navigation/RationControl";
import AnimalAttackButtons from "./animalAttack/AnimalAttackButtons";
import AnimalAttackModal from "./animalAttack/AnimalAttackModal";
import BanditAttackButtons from "./banditAttack/BanditAttackButtons";
import BanditAttackModal from "./banditAttack/BanditAttackModal";
import HuntButton from "./hunting/HuntButton";
import HuntingModal from "./hunting/HuntingModal";
import RestButton from "./RestButton";

export default function RoadInteractions() {
    const { trail } = useAppSelector(selectGame);
    const location = routingCurrentLocation(trail.routing);

    if (location?.type === 'Town' || location?.type === 'RiverCrossing') {
        return null;
    }

    return (
        <>
        <HuntingModal />
        <AnimalAttackModal />
        <BanditAttackModal />
        <Card>
            <Card.Header>Road</Card.Header>
            <Card.Body className="d-flex flex-column justify-content-start align-items-stretch">
                <HuntButton />
                <RestButton />
                <AnimalAttackButtons />
                <BanditAttackButtons />
            </Card.Body>
            <Card.Footer>
                <RationControl />
            </Card.Footer>
        </Card>
        </>
    );
}
