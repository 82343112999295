import { POUND_PER_KILOGRAM } from "../Utilities";
import { InventoryItem } from "./InventoryItem";

export interface Medicine extends InventoryItem {
    name: 'Medicine';
}

export function makeMedicine(amount: number = 0): Medicine {
    return { amount, name: 'Medicine' };
}

export function formatMedicine(medicine: Medicine | number, extendedUnit: boolean = false): string {
    const singularUnit = extendedUnit ? 'pack of medicine' : 'pack';
    const pluralUnit = extendedUnit ? 'packs of medicine' : 'packs';
    if (typeof medicine === 'number') {
        if (medicine === 1) {
            return `1 ${singularUnit}`;
        } else {
            return `${medicine} ${pluralUnit}`;
        }
    } else if (medicine.amount === 1) {
        return `1 ${singularUnit}`;
    } else {
        return `${medicine.amount} ${pluralUnit}`;
    }
}

export function determineMedicineWeight(medicine: Medicine): number {
    return medicine.amount / POUND_PER_KILOGRAM;
}

export function determineMedicineForWeight(weight: number): number {
    return Math.floor(weight * POUND_PER_KILOGRAM);
}
