import { useCallback, useEffect, useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faAnglesRight, faAnglesLeft } from "@fortawesome/free-solid-svg-icons";
import { useAppDispatch, useAppSelector } from "../hooks";
import { selectSettings } from "../settings/settingsSlice";
import { useTwitchManager } from "../twitch/TwitchManager";
import { cheat, foundEasteregg, selectGame, startGame } from "./gameSlice";
import { useEastereggManager } from "../system/EastereggManager";
import { EastereggName } from "../../types/Easteregg";
import { determineInventoryWeight } from "../../types/inventory/Inventory";
import { determineWagonWeightCapacity } from "../../types/Wagon";
import { formatWeight } from "../../types/Utilities";

import EventBannerView from "./event/EventBannerView";
import StartPlayerList from "./passenger/StartPlayerList";
import InventoryView from "./inventory/InventoryView";
import QuestInventoryView from "./inventory/QuestInventoryView";
import WagonView from "./wagon/WagonView";
import PlayingPlayerList from "./passenger/PlayingPlayerList";
import TrailView from "./trail/TrailView";
import MessagesView from "./message/MessagesView";
import OxList from "./passenger/OxList";
import RiverInteractions from "./interactions/river/RiverInteractions";
import RepairsInteractions from "./interactions/repairs/RepairsInteractions";
import StatsView from "./stats/StatsView";
import TownInteractions from "./interactions/town/TownInteractions";
import ExtrasInteractions from "./interactions/extras/ExtrasInteractions";
import NavigationInteractions from "./interactions/navigation/NavigationInteractions";
import RoadInteractions from "./interactions/road/RoadInteractions";

export default function GameView() {
    const game = useAppSelector(selectGame);

    return (
        <>
        <EventBannerView />
        { game.status === 'Start' && <StartGameView /> }
        { game.status === 'Playing' && <PlayingView /> }
        { game.status === 'Game Over' && <PlayingView /> }
        </>
    );
}

function StartGameView() {
    const dispatch = useAppDispatch();
    const settings = useAppSelector(selectSettings);
    const twitchManager = useTwitchManager();
    const { players, messages } = useAppSelector(selectGame);

    type ConnectionState = 'disconnected' | 'connecting' | 'connected';

    const [connectionState, setConnectionState] = useState<ConnectionState>(twitchManager.connected ? 'connected' : twitchManager.token ? 'connecting' : 'disconnected');

    const onConnected = useCallback(() => setConnectionState('connected'), [setConnectionState]);
    const onDisconnected = useCallback(() => setConnectionState('disconnected'), [setConnectionState]);

    const onStartButtonClicked = () => dispatch(startGame('Heir'));

    useEffect(() => {
        const name = 'start game view';
        twitchManager.addEventListener(name, 'connect', onConnected);
        twitchManager.addEventListener(name, 'disconnect', onDisconnected);

        return () => {
            twitchManager.removeEventListener(name, 'disconnect');
            twitchManager.removeEventListener(name, 'connect');
        };
    }, [onConnected, onDisconnected, twitchManager]);

    return (
        <>
            { connectionState === 'disconnected' &&
            <div className="text-warning fs-4 text-center p-5">
                This game is <em>not</em> connected to a Twitch channel right now.
                <div className="fs-6">
                    Go to the settings <FontAwesomeIcon icon={faGear} /> and authorize this app to 
                    connect with your bot account.
                </div>
            </div>
            }
            { connectionState === 'connecting' &&
            <div className="text-muted fs-4 text-center p-5">
                Connecting &hellip;
            </div>
            }
            { connectionState === 'connected' &&
            <div className="text-white fs-4 text-center p-5">
                Enter <strong className="text-success">!{settings.twitchCommands.join}</strong> in chat to join the team.
            </div>
            }
            <StartPlayerList />

            <div className="text-center mt-5">
                <Button onClick={onStartButtonClicked} disabled={players.length === 0}>Start Game</Button>
            </div>

            <div className="fixed-bottom m-4 d-flex justify-content-center bottom-messages-view">
                <div className="w-50 p-4 bg-black rounded">
                    <MessagesView messages={messages} count={4} bg="dark" />
                </div>
            </div>
        </>
    );
}

type LayoutFocus = 'focus-left' | 'focus-right' | null;

function PlayingView() {
    const dispatch = useAppDispatch();
    const { status, oxen, events, inventory, wagons } = useAppSelector(selectGame);
    const { cheatMode } = useAppSelector(selectSettings);
    const eastereggManager = useEastereggManager();
    const [layoutFocus, setLayoutFocus] = useState<LayoutFocus>(null);

    const onEastereggFound = useCallback((name: EastereggName) => dispatch(foundEasteregg(name)), [dispatch]);

    const hasTravellingWith = oxen.length > 0 || (cheatMode === 'on' && layoutFocus === 'focus-right');

    const inventoryWeight = determineInventoryWeight(inventory);
    const wagonCapacity = wagons.map(determineWagonWeightCapacity).reduce((prev, curr) => prev + curr, 0);
    
    const inventoryWeightIndicator = inventoryWeight ? <div className="text-lowercase d-inline ms-4" style={{ fontWeight: 'lighter' }}>{formatWeight(inventoryWeight)}</div> : undefined;
    const wagonCapacityIndicator = wagonCapacity ? <div className="text-lowercase d-inline ms-4" style={{ fontWeight: 'lighter' }}>{formatWeight(wagonCapacity)}</div> : undefined;
    const cheatingIndicator = cheatMode === 'on' ? <div className="text-lowercase d-inline ms-4 text-info" style={{ fontWeight: 'lighter' }}>Cheat Mode active</div> : undefined;

    useEffect(() => {
        const name = 'playing view';
        eastereggManager.push(name, onEastereggFound);
        return () => eastereggManager.pop(name);
    }, [eastereggManager, onEastereggFound]);

    useEffect(() => {
        (window as any)['cheat'] = (input: string) => {
            if (cheatMode === 'off') {
                console.warn('No cheating!');
            } else {
                dispatch(cheat(input));
            }
        };
    }, [dispatch, cheatMode]);

    return (
        <div className="text-white pb-4">
            { status !== 'Start' && <div style={{ marginBottom: '-2rem' }}><StatsView /></div> }
            <div className={["d-flex", "flex-row", "justify-content-between", "align-items-top", layoutFocus ?? ""].join(' ')}>
                <div className="game-layout-start ms-1 me-1">
                    <div className={events.length === 0 ? "mt-4" : "mt-4 opacity-25"}>
                        <PanelHeader title="Inventory" side="left" layoutFocusProp={[layoutFocus, setLayoutFocus]}>{inventoryWeightIndicator}</PanelHeader>
                        <InventoryView expanded={layoutFocus === 'focus-left'} />
                    </div>
                    <div className={events.length === 0 ? "mt-4" : "mt-4 opacity-25"}>
                        <PanelHeader title="Quest Inventory" side="left" layoutFocusProp={[layoutFocus, setLayoutFocus]} />
                        <QuestInventoryView expanded={layoutFocus === 'focus-left'} />
                    </div>
                    <div className={events.length === 0 ? "mt-4" : "mt-4 opacity-25"}>
                        <PanelHeader title="Wagon" side="left" layoutFocusProp={[layoutFocus, setLayoutFocus]}>{wagonCapacityIndicator}</PanelHeader>
                        <WagonView expanded={layoutFocus === 'focus-left'} />
                    </div>
                </div>
                <div className="game-layout-center ms-1 me-1 flex-grow-1">
                    <div className="mt-4">
                        <PanelHeader title="Quest" side="center" layoutFocusProp={[layoutFocus, setLayoutFocus]}>{cheatingIndicator}</PanelHeader>
                        <TrailView />
                    </div>
                    { status === 'Playing' && events.length === 0 &&
                    <div className="mt-4 d-flex justify-content-start align-items-top">
                        <RiverInteractions />
                        <RepairsInteractions />
                        <TownInteractions />
                        <RoadInteractions />
                        <NavigationInteractions />
                        <ExtrasInteractions />
                    </div>
                    }
                </div>
                <div className="game-layout-end ms-1 me-1">
                    <div className={events.length === 0 ? "mt-4" : "mt-4 opacity-25"}>
                        <PanelHeader title="Players" side="right" layoutFocusProp={[layoutFocus, setLayoutFocus]} />
                        <PlayingPlayerList expanded={layoutFocus === 'focus-right'} />
                    </div>
                    { hasTravellingWith &&
                    <div className={events.length === 0 ? "mt-4" : "mt-4 opacity-25"}>
                        <PanelHeader title="Traveling With" side="right" layoutFocusProp={[layoutFocus, setLayoutFocus]} />
                        <OxList expanded={layoutFocus === 'focus-right'} />
                    </div>
                    }
                </div>
            </div>
        </div>
    );
}

function PanelHeader({ title, side, layoutFocusProp, children }: { title: string, side: 'left' | 'right' | 'center', layoutFocusProp: [LayoutFocus, (layoutFocus: LayoutFocus) => void], children?: JSX.Element }) {
    const [layoutFocus, setLayoutFocus] = layoutFocusProp;

    const focused = (() => {
        switch (side) {
            case 'left':   return layoutFocus === 'focus-left';
            case 'right':  return layoutFocus === 'focus-right';
            case 'center': return false;
        }
    })();

    const icon = (() => {
        switch (side) {
            case 'left':   return layoutFocus === 'focus-left' ? faAnglesLeft : faAnglesRight;
            case 'right':  return layoutFocus === 'focus-right' ? faAnglesRight : faAnglesLeft;
            case 'center': return null;
        }
    })();

    const toggleLayoutFocus = () => {
        if (side === 'left') {
            setLayoutFocus(layoutFocus === 'focus-left' ? null : 'focus-left');
        } else if (side === 'right') {
            setLayoutFocus(layoutFocus === 'focus-right' ? null : 'focus-right');
        }
    };

    const renderTooltip = (props: any) => (
        <Tooltip id="expand-collapse-tooltip" {...props}>
            { focused ? 'click to collapse' : 'click to expand' }
        </Tooltip>
    );

    const className = ['d-flex', 'align-items-center', 'text-uppercase', 'text-muted'];
    if (side === 'left') {
        className.push('justify-content-between');
    } else {
        className.push('justify-content-start');
    }

    return (
        <h6 className={className.join(' ')}>
            { side !== 'right' && <div>{title} {children}</div> }
            { side !== 'center' && icon !== null &&
            <OverlayTrigger placement={side} overlay={renderTooltip} delay={{ show: 250, hide: 400 }}>
                <span className="ms-2 me-2" onClick={toggleLayoutFocus} style={{ cursor: 'pointer' }}>
                    <FontAwesomeIcon icon={icon} />
                </span>
            </OverlayTrigger>
            }
            { side === 'right' && <div>{title} {children}</div> }
        </h6>
    );
}
