import { Button, Card, Modal } from "react-bootstrap";
import { formatCash } from "../../../../../types/inventory/Cash";
import { canAffordEatingAtInn, canAffordRestingAtInn, determineRoomCount } from "../../../../../types/location/Inn";
import { routingCurrentLocation } from "../../../../../types/location/Routing";
import { Town } from "../../../../../types/location/Town";
import { isDead } from "../../../../../types/Player";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { advanceGame, eatAtInn, restAtInn, selectGame, setTravelOptions } from "../../../gameSlice";

export default function InnModal({ open, close }: { open: boolean, close: () => void }) {
    const dispatch = useAppDispatch();
    const { trail, travelOptions, players, inventory, messages } = useAppSelector(selectGame);
    const location = routingCurrentLocation(trail.routing);
    const town = location?.type === 'Town' ? location as Town : null;
    const inn = town?.inn ?? null;
    const peopleCount = players.filter(player => !isDead(player)).length;
    const foodPrice = inn?.foodPrice ?? 0;
    const roomCount = determineRoomCount(peopleCount);
    const roomPrice = inn?.roomPrice ?? 0;
    const eatingAvailable = foodPrice > 0 && town !== null && canAffordEatingAtInn(town, peopleCount, inventory.cash.amount);
    const alreadyAteHereToday = messages.find(message => message.roundIndex === 0 && message.type === 'Ate At Inn') !== undefined;
    const restingAvailable = roomPrice > 0 && town !== null && canAffordRestingAtInn(town, peopleCount, inventory.cash.amount);
    const totalFoodPrice = foodPrice * peopleCount;
    const totalRoomPrice = roomCount * roomPrice;

    const eatFood = () => dispatch(eatAtInn());

    const rest = () => {
        dispatch(restAtInn());
        dispatch(setTravelOptions({ ...travelOptions, rest: true }));
        dispatch(advanceGame());
        close();
    };

    if (inn === null) {
        return null;
    }

    return (
        <Modal show={open} onHide={close}>
            <Modal.Header>
                <Modal.Title>{inn.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { !!foodPrice && !!roomPrice &&
                <Card.Text>
                    "Welcome travelers, this is the <em>{inn.name}</em>", the innkeeper greets us. 
                    "Do you need some accomodations? We have rooms available for <span className="text-warning">{formatCash(roomPrice)} </span> 
                    each. You look hungry, we serve the best food you can find in this area."
                    <br /><br />
                    The menu looks sparse, everything costs <span className="text-warning">{formatCash(foodPrice)}</span> per person.
                </Card.Text>
                }
                { !!foodPrice && !roomPrice &&
                <Card.Text>
                    "Welcome to <em>{inn.name}</em>", the innkeeper greets us. "We don't have any 
                    rooms availabe right now. But our kitchen serves the best food you can find in 
                    this area."
                    <br /><br />
                    The menu looks sparse, everything costs <span className="text-warning">{formatCash(foodPrice)}</span> per person.
                </Card.Text>
                }
                { !foodPrice && !!roomPrice &&
                <Card.Text>
                    The inn seems awkwardly dark and empty. From the kitchen we see an old woman walking 
                    towards us. "The kitchen is closed. But we still have rooms available, if you want to rest. 
                    It's <span className="text-warning">{formatCash(roomPrice)}</span> per room."
                </Card.Text>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={close}>Close</Button>
                { roomPrice > 0 && <Button variant="primary" onClick={rest} disabled={!restingAvailable}>Rest for {formatCash(totalRoomPrice)}</Button> }
                { foodPrice > 0 && <Button variant="primary" onClick={eatFood} disabled={!eatingAvailable || alreadyAteHereToday}>Eat for {formatCash(totalFoodPrice)}</Button> }
            </Modal.Footer>
        </Modal>
    );
}