import { Card, ListGroup } from "react-bootstrap";
import { useAppSelector } from "../../hooks";
import { selectGame } from "../gameSlice";
import { isDead, Player } from "../../../types/Player";

import PlayerAvatar from "./PlayerAvatar";
import Healthbar from "./Healthbar";

export default function PlayingPlayerList({ expanded }: { expanded?: boolean }) {
    const { players } = useAppSelector(selectGame);

    const alivePlayers = players.filter(player => !isDead(player));
    const deadPlayers = players.filter(player => isDead(player));
    const sortedPlayers = [...alivePlayers, ...deadPlayers];

    if (expanded) {
        const cells = sortedPlayers.map(player => (
            <div className="me-2 mb-2" key={player.name}>
                <ExpandedPlayerView player={player} />
            </div>
        ));
        return <div className="d-flex w-100 justify-content-start align-items-top">{cells}</div>;
    } else {
        const listItems = sortedPlayers.map(player => (
            <ListGroup.Item key={player.name}>
                <CollapsedPlayerView player={player} />
            </ListGroup.Item>
        ));
        return <ListGroup>{listItems}</ListGroup>;
    }
}

function CollapsedPlayerView({ player }: { player: Player }) {
    return (
        <>
            <PlayerAvatar player={player} clothesIndicator inflictionIndicator />
            <Healthbar passenger={player} type="Player" />
        </>
    );
}

function ExpandedPlayerView({ player }: { player: Player }) {
    return (
        <Card>
            <Card.Body>
                <PlayerAvatar player={player} large />
            </Card.Body>
        </Card>
    );
}