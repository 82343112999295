import { calculateBulletsCapacity, calculateClothesCapacity, Inventory } from "./inventory/Inventory";
import { isDead, Player } from "./Player";
import { randomElement, randomEvent, randomInt } from "./Random";
import { Wagon } from "./Wagon";

const FIGHTING_WORDS = ['shoot', 'pew', 'boom', 'bang', 'bazinga', 'vaffanculo'];

type BanditStrength = 'weak' | 'medium' | 'strong';
const BANDIT_STRENGTHS: BanditStrength[] = ['weak', 'medium', 'strong'];

function getBanditStrengthSuccessRateThreshold(strength: BanditStrength): number {
    switch (strength) {
        case 'weak': return 0.2;
        case 'medium': return 0.4;
        case 'strong': return 0.6;
    }
}

function randomFightingWord(): string {
    return randomElement(FIGHTING_WORDS)!;
}

export interface FightingResult {
    killedPlayerName: string | null;
    injuredPlayerName: string | null;
    bulletsUsed: number;
    bullets: number;
    clothes: number;
    cash: number;
    waterBottles: number;
}

export interface BanditAttack {
    start: number;
    word: string;
    strength: BanditStrength;
    attackingPlayers: string[];
    hits: string[];
    result?: FightingResult;
}

export function makeBanditAttack(attackingPlayers: string[]): BanditAttack {
    const start = new Date().getTime();
    const word = randomFightingWord();
    const hits: string[] = [];
    const strength = randomElement(BANDIT_STRENGTHS)!;

    return { start, word, strength, attackingPlayers, hits };
}

export function registerShot(banditAttack: BanditAttack, playerName: string, word: string): BanditAttack {
    const attacker = banditAttack.attackingPlayers.includes(playerName);
    const attacked = banditAttack.hits.includes(playerName);
    if (word === banditAttack.word && attacker && !attacked) {
        const hits = [...banditAttack.hits, playerName];
        return { ...banditAttack, hits };
    }
    return banditAttack;
}

export function makeFightingResult(attack: BanditAttack, players: Player[], inventory: Inventory, wagons: Wagon[]): FightingResult {
    const hits = attack.hits;
    const bulletsUsed = Math.max(hits.length, attack.attackingPlayers.length * 3);
    const successRate = attack.attackingPlayers.length === 0 ? 0 : hits.length / attack.attackingPlayers.length;
    const threshold = getBanditStrengthSuccessRateThreshold(attack.strength);
    
    let clothes = 0;
    let bullets = 0;
    let cash = 0;
    let waterBottles = 0;

    // Do we lose stuff, or actually win?
    if (successRate < threshold) {
        clothes = -randomInt(0, inventory.clothes.amount);
        bullets = -randomInt(0, inventory.bullets.amount);
        cash = -randomInt(0, inventory.cash.amount);
    } else {
        clothes = Math.min(randomInt(2, 5), calculateClothesCapacity(inventory, wagons, players));
        bullets = Math.min(randomInt(10, 50), calculateBulletsCapacity(inventory, wagons, players));
        cash = randomInt(5, 120);
        waterBottles = randomEvent(0.2) ? 1 : 0;
    }
    
    const probabilityOfKilledPlayer = successRate < 0.3 ? 0.05 : 0.01;
    let killedPlayerName: string | null = null;
    if (randomEvent(probabilityOfKilledPlayer)) {
        const player = randomElement(players.filter(player => !isDead(player)));
        if (player) {
            killedPlayerName = player.name;
        }
    }

    const probabilityOfInjuredPlayer = successRate < 0.6 ? 0.20 : 0.05;
    let injuredPlayerName: string | null = null;
    if (randomEvent(probabilityOfInjuredPlayer)) {
        const player = randomElement(players.filter(player => !isDead(player) && player.name !== killedPlayerName));
        if (player) {
            injuredPlayerName = player.name;
        }
    }

    return { bulletsUsed, bullets, clothes, cash, waterBottles, killedPlayerName, injuredPlayerName };
}