import { Button, Image } from "react-bootstrap";
import { useAppDispatch } from "../../hooks";
import { removeEvent } from "../gameSlice";
import { PlayerDiedEvent } from "../../../types/GameEvent";
import { randomElement } from "../../../types/Random";

export default function PlayerDiedEventView({ event }: { event: PlayerDiedEvent }) {
    const dispatch = useAppDispatch();

    const imageName = randomElement(['/rip.png', '/skull.png'])!;

    const close = () => dispatch(removeEvent(event.id));

    return (
        <div className="d-flex w-100 h-100 justify-content-start align-items-center">
            <div className="text-start p-4">
                <h3>RIP {event.player.name}</h3>
                <div>Cause of death: {event.player.causeOfDeath}</div>
                <Button variant="primary" onClick={close}>Close</Button>
            </div>
            <Image src={imageName} height={240} rounded />
        </div>
    );
}