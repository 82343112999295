import { Button } from "react-bootstrap";

interface MapButtonProps {
    openModal: [boolean, (open: boolean) => void];
}

export default function MapButton({ openModal }: MapButtonProps) {
    const [isModalOpen, setModalOpen] = openModal;

    const onButtonClicked = () => setModalOpen(true);

    return <Button variant="secondary" size="sm" onClick={onButtonClicked} disabled={isModalOpen}>open</Button>;
}