import { Form } from "react-bootstrap";
import { ALL_PACES, getTravelHoursPerDay, Pace } from "../../../../types/travel/Pace";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { selectSettings } from "../../../settings/settingsSlice";
import { changePace, selectGame } from "../../gameSlice";

export default function PaceControl() {
    const dispatch = useAppDispatch();
    const { pace } = useAppSelector(selectGame);
    const { cheatMode } = useAppSelector(selectSettings);

    const setPace = (value: string) => {
        const pace = value as Pace;
        dispatch(changePace(pace));
    };
    
    const options = ALL_PACES.map(pace => {
        if (cheatMode === 'on') {
            return <option key={pace} value={pace}>{pace} [{getTravelHoursPerDay(pace)} hr]</option>;
        } else {
            return <option key={pace} value={pace}>{pace}</option>;
        }
    });

    return <Form.Select value={pace} onChange={({target}) => setPace(target.value)}>{options}</Form.Select>;
}