import { getClimate } from "../../types/Climate";
import { makeArrivedAtHiddenLocationEvent, makeArrivedInDickshooterEvent, makeFoundAbandonedWagonEvent, makePlayerDiedEvent } from "../../types/GameEvent";
import { enableInjury, makeRandomInjury } from "../../types/health/Injury";
import { enableFindingAbandonedWagonCooldown, enableTravelCooldown } from "../../types/Limits";
import { Location } from "../../types/location/Location";
import { makeOxDiedMessage, makePayedAtTollStationMessage, makePlayerDiedMessage, makePlayerInjuredMessage, makeCrossedRiverMessage, makeArrivedMessage, makeWeatherHappenedMessage, makeFoundWildFruitMessage, makeFoundAbandonedWagonMessage, makeFoundWaterMessage, ArrivedMessage, makeFoundLegendaryTownMessage, makeTextMessage, makeOxInjuredMessage, makeFoundOxAgainMessage } from "../../types/Message";
import { addOxen, disableOxRanOff, enableOxDeath, enableOxInjury, isOxDead, isOxInjured, isOxRanOff, Ox } from "../../types/Ox";
import { getTravelHoursPerDay } from "../../types/travel/Pace";
import { enableDeath, enableWet, isDead } from "../../types/Player";
import { FloatRiverCrossingOption, FordRiverCrossingOption } from "../../types/location/RiverCrossingOption";
import { routingCurrentLocation, routingCurrentMapRegionNames, routingForwardAndReverseDirections, routingLastLocation } from "../../types/location/Routing";
import { advanceTrail, Trail, TravelOptions } from "../../types/travel/Trail";
import { canTravel } from "../../types/travel/Travel";
import { randomEvent, randomIndex, randomInt } from "../../types/Random";
import { countBrokenAxles, countBrokenWheels, hasBrokenTongue } from "../../types/Wagon";
import { advanceWeather } from "../../types/Weather";
import { GameState } from "../game/gameSlice";
import { TollStation } from "../../types/location/TollStation";
import { RiverCrossing } from "../../types/location/RiverCrossing";
import { decreaseInventoryItem, increaseInventoryItem } from "../../types/inventory/InventoryItem";
import { getMapItem } from "../../types/inventory/QuestInventory";
import { addMap, getMapLODValue, MapItem } from "../../types/inventory/Maps";
import { Water, WATER_PER_BOTTLE } from "../../types/inventory/Water";
import { enableFreezing } from "../../types/health/Freezing";
import { isLegendaryTown } from "../../types/Role";
import { addStatModifier, applyStat, makeStatModifier, Stat } from "../../types/Stat";
import { addRifle } from "../../types/inventory/Rifles";
import { calculateBulletsCapacity, calculateClothesCapacity, calculateFoodCapacity, calculateMedicineCapacity, calculateRiflesCapacity, calculateWagonAxlesCapacity, calculateWagonTonguesCapacity, calculateWagonWheelsCapacity } from "../../types/inventory/Inventory";

function getCurrentLocation(trail: Trail): Location | null {
    return routingCurrentLocation(trail.routing);
}

export default class EnvironmentSystem {

    static payAtTollStation(game: GameState): GameState {
        const messages = [...game.messages];
        const location = getCurrentLocation(game.trail);
        let inventory = game.inventory;

        if (location?.type === 'TollStation') {
            const tollStation = location as TollStation;
            const cash = decreaseInventoryItem(inventory.cash, tollStation.price);
            inventory = { ...inventory, cash };
            messages.push(makePayedAtTollStationMessage(tollStation, game.date));
        }

        return { ...game, inventory, messages };
    }

    static crossRiver(game: GameState): GameState {
        const location = getCurrentLocation(game.trail);
        const option = game.travelOptions.chosenRiverCrossingOption;

        if (location?.type === 'RiverCrossing') {
            const riverCrossing = location as RiverCrossing;
            if (option?.name === 'Ford') {
                const fordRiver = option as FordRiverCrossingOption;
                return this.fordRiver(game, riverCrossing, fordRiver);
            } else if (option?.name === 'Float') {
                const floatRiver = option as FloatRiverCrossingOption;
                return this.floatRiver(game, riverCrossing, floatRiver);
            }
        }

        return game;
    }

    private static fordRiver(game: GameState, riverCrossing: RiverCrossing, fordRiver: FordRiverCrossingOption): GameState {
        const { date } = game;
        const messages = [...game.messages];
        const events = [...game.events];
        let players = [...game.players];
        let oxen = [...game.oxen];
        let inventory = game.inventory;
        let badThingsHappened = 0;

        // Is somebody drowning?
        if (fordRiver.drownedPlayerName !== null) {
            const alivePlayers = players.filter(player => !isDead(player));
            const drowningPlayer = alivePlayers.find(player => player.name === fordRiver.drownedPlayerName);
            if (drowningPlayer) {
                const drownedPlayer = enableDeath(drowningPlayer, 'drowned');
                messages.push(makePlayerDiedMessage(drownedPlayer, date));
                events.push(makePlayerDiedEvent(drownedPlayer));
                players = players.map(p => p.name === drownedPlayer.name ? drownedPlayer : p);
                ++badThingsHappened;
            }
        }
        
        // Is somebody getting hurt?
        if (fordRiver.injuredPlayerName !== null) {
            const alivePlayers = players.filter(player => !isDead(player));
            const hurtingPlayer = alivePlayers.find(player => player.name === fordRiver.injuredPlayerName);
            if (hurtingPlayer) {
                const injury = makeRandomInjury(game.stats.health);
                const injuredPlayer = enableInjury(hurtingPlayer, injury);
                messages.push(makePlayerInjuredMessage(injuredPlayer, injury, date));
                players = players.map(p => p.name === injuredPlayer.name ? injuredPlayer : p);
                ++badThingsHappened;
            }
        }

        // Is an ox getting stuck?
        if (fordRiver.oxenGotStuck) {
            const index = randomIndex(oxen);
            if (index !== -1) {
                const ox = oxen[index];
                if (!isOxDead(ox) && !isOxRanOff(ox)) {
                    if (isOxInjured(ox)) {
                        const deadOx = enableOxDeath(ox, 'drowned');
                        oxen[index] = deadOx;
                        messages.push(makeOxDiedMessage(deadOx, date));
                    } else {
                        const injuredOx = enableOxInjury(ox);
                        oxen[index] = injuredOx;
                        messages.push(makeOxInjuredMessage(ox, date));
                    }
                    ++badThingsHappened;
                }
            }
        }

        // Did we lose inventory?
        if (fordRiver.lostFoodCount > 0 || fordRiver.lostClothesCount > 0 || fordRiver.lostBulletsCount > 0) {
            messages.push(makeCrossedRiverMessage(riverCrossing, false, date, {
                food: fordRiver.lostFoodCount,
                clothes: fordRiver.lostClothesCount,
                bullets: fordRiver.lostBulletsCount,
            }));
            const food = decreaseInventoryItem(inventory.food, fordRiver.lostFoodCount);
            const clothes = decreaseInventoryItem(inventory.clothes, fordRiver.lostClothesCount);
            const bullets = decreaseInventoryItem(inventory.bullets, fordRiver.lostBulletsCount);
            inventory = { ...inventory, food, clothes, bullets };
            badThingsHappened += fordRiver.lostFoodCount;
            badThingsHappened += fordRiver.lostClothesCount;
            badThingsHappened += fordRiver.lostBulletsCount;
        }

        // Or did everything went well?
        if (badThingsHappened === 0) {
            messages.push(makeCrossedRiverMessage(riverCrossing, false, date));
        }

        return { ...game, messages, events, players, oxen, inventory };
    }

    private static floatRiver(game: GameState, riverCrossing: RiverCrossing, floatRiver: FloatRiverCrossingOption): GameState {
        const { date } = game;
        const messages = [...game.messages];
        const events = [...game.events];
        let players = [...game.players];
        let inventory = game.inventory;
        let badThingsHappened = 0;

        // Is the wagon tipped over?
        if (floatRiver.tippedOver) {
            players = players
                .map(enableWet)
                .map(enableFreezing);
            
            ++badThingsHappened;
        }

        // Is somebody drowning?
        if (floatRiver.drownedPlayerName !== null) {
            const alivePlayers = players.filter(player => !isDead(player));
            const drowningPlayer = alivePlayers.find(player => player.name === floatRiver.drownedPlayerName);
            if (drowningPlayer) {
                const drownedPlayer = enableDeath(drowningPlayer, 'drowned');
                messages.push(makePlayerDiedMessage(drownedPlayer, date));
                events.push(makePlayerDiedEvent(drownedPlayer));
                players = players.map(p => p.name === drownedPlayer.name ? drownedPlayer : p);
                ++badThingsHappened;
            }
        }
        
        // Is somebody getting hurt?
        if (floatRiver.injuredPlayerName !== null) {
            const alivePlayers = players.filter(player => !isDead(player));
            const hurtingPlayer = alivePlayers.find(player => player.name === floatRiver.injuredPlayerName);
            if (hurtingPlayer) {
                const injury = makeRandomInjury(game.stats.health);
                const injuredPlayer = enableInjury(hurtingPlayer, injury);
                messages.push(makePlayerInjuredMessage(injuredPlayer, injury, date));
                players = players.map(p => p.name === injuredPlayer.name ? injuredPlayer : p);
                ++badThingsHappened;
            }
        }

        // Did we lose inventory?
        if (floatRiver.lostFoodCount > 0 || floatRiver.lostClothesCount > 0 || floatRiver.lostBulletsCount > 0) {
            const food = decreaseInventoryItem(inventory.food, floatRiver.lostFoodCount);
            const clothes = decreaseInventoryItem(inventory.clothes, floatRiver.lostClothesCount);
            const bullets = decreaseInventoryItem(inventory.bullets, floatRiver.lostBulletsCount);
            inventory = { ...inventory, food, clothes, bullets };
            badThingsHappened += floatRiver.lostFoodCount;
            badThingsHappened += floatRiver.lostClothesCount;
            badThingsHappened += floatRiver.lostBulletsCount;
        }

        // Or did everything went well?
        if (badThingsHappened === 0) {
            messages.push(makeCrossedRiverMessage(riverCrossing, false, date));
        } else if (floatRiver.lostFoodCount > 0 || floatRiver.lostClothesCount > 0 || floatRiver.lostBulletsCount > 0) {
            messages.push(makeCrossedRiverMessage(riverCrossing, floatRiver.tippedOver, date, {
                food: floatRiver.lostFoodCount,
                clothes: floatRiver.lostClothesCount,
                bullets: floatRiver.lostBulletsCount,
            }));
        } else {
            messages.push(makeCrossedRiverMessage(riverCrossing, floatRiver.tippedOver, date));
        }

        return { ...game, messages, events, players, inventory };
    }

    static advanceTrail(game: GameState, fixedDistance?: number): GameState {
        const { oxen, date } = game;
        const messages = [...game.messages];
        const events = [...game.events];
        let { chosenDestinationName } = game.travelOptions;
        let trail: Trail = { ...game.trail };
        let inventory = game.inventory;

        const traveling = canTravel(game) && !game.travelOptions.rest;
        let autoFillWater = false;

        if (traveling) {
            const productiveOxenCount = oxen.filter(ox => !isOxDead(ox) && !isOxRanOff(ox)).length;
            const pullingOxenCount = Math.min(2, Math.max(0, productiveOxenCount));
            const travelDistancePerHourAndOx = 0.75;
            const travelHoursPerDay = getTravelHoursPerDay(game.pace);
            const travelDistance = fixedDistance ?? travelDistancePerHourAndOx * travelHoursPerDay * pullingOxenCount;
            
            if (travelDistance > 0) {
                trail = advanceTrail(trail, travelDistance, chosenDestinationName ?? null);
                chosenDestinationName = undefined;

                let location = getCurrentLocation(trail);
                if (location) {
                    messages.push(makeArrivedMessage(location, date));
                    if (location.type === 'RiverCrossing' || location.type === 'Town') {
                        autoFillWater = true;
                    }
                    if (location.visibility === 'hidden') {
                        location = { ...location, visibility: 'revealed' };
                        events.push(makeArrivedAtHiddenLocationEvent(location));
                    }
                    const directions = routingForwardAndReverseDirections(trail.routing);
                    if (directions) {
                        const { forward } = directions;
                        chosenDestinationName = forward;
                        trail = { ...trail, direction: forward };
                    }
                }
            }
        }

        const travelOptions: TravelOptions = chosenDestinationName ? { chosenDestinationName } : {};

        if (autoFillWater) {
            const maximum = inventory.water.containers
                .map(container => container.capacity)
                .reduce((prev, curr) => prev + curr, 0);
            
            if (inventory.water.amount < maximum) {
                const water: Water = { ...inventory.water, amount: maximum };
                inventory = { ...inventory, water };
            }
        }

        return { ...game, trail, travelOptions, messages, events, inventory };
    }

    static jumpToNextLocation(game: GameState): GameState {
        const location = routingCurrentLocation(game.trail.routing) ?? routingLastLocation(game.trail.routing);
        let currentLocation = location;
        while (currentLocation === null || currentLocation?.name === location?.name) {
            game = EnvironmentSystem.advanceTrail(game, 10);
            currentLocation = routingCurrentLocation(game.trail.routing);
        }
        return game;
    }

    static advanceWeather(game: GameState): GameState {
        const { trail } = game;
        const lastVisitedLocation = routingLastLocation(trail.routing)!;
        const currentLocation = getCurrentLocation(trail);
        const date = new Date(game.date);
        const climate = getClimate(lastVisitedLocation.climate, date);
        const weather = advanceWeather(game.weather, climate);
        const messages = [...game.messages];
        let limits = game.limits;

        if (limits.travelCooldownDays === 0 && currentLocation?.type !== 'RiverCrossing') {
            if (weather.weatherEvent.name === 'Hail Storm') {
                limits = enableTravelCooldown(limits, 1);
                messages.push(makeWeatherHappenedMessage('Hail Storm', 1, game.date));
            }

            else if (weather.weatherEvent.name === 'Heavy Fog') {
                limits = enableTravelCooldown(limits, 3);
                messages.push(makeWeatherHappenedMessage('Heavy Fog', 3, game.date));
            }

            else if (weather.weatherEvent.name === 'Severe Weather') {
                limits = enableTravelCooldown(limits, 2);
                messages.push(makeWeatherHappenedMessage('Severe Weather', 2, game.date));
            }
        }

        return { ...game, weather, limits, messages };
    }

    static findFruit(game: GameState): GameState {
        const { trail, weather, date, wagons, players } = game;
        const messages = [...game.messages];
        let inventory = game.inventory;

        const niceWeather = weather.condition === 'Clear'
            || weather.condition === 'Cloudy'
            || weather.condition === 'Mostly Sunny'
            || weather.condition === 'Partly Sunny'
            || weather.condition === 'Overcast'
            || weather.condition === 'Sunny';
        
        const productiveClimate = weather.climateName === 'Continental'
            || weather.climateName === 'Moderate'
            || weather.climateName === 'Tropical';
        
        const currentLocation = getCurrentLocation(trail);

        if (currentLocation === null && niceWeather && productiveClimate) {
            const probabilityOfFindingFruit = 0.05;
            if (randomEvent(probabilityOfFindingFruit)) {
                const amount = Math.min(randomInt(1, 10), calculateFoodCapacity(inventory, wagons, players));
                const food = increaseInventoryItem(inventory.food, amount);
                inventory = { ...inventory, food };
                messages.push(makeFoundWildFruitMessage(amount, date));
            }
        }

        return { ...game, inventory, messages };
    }

    static findAbandonedWagon(game: GameState): GameState {
        const { trail, wagons, players, date } = game;
        const messages = [...game.messages];
        const events = [...game.events];
        let oxen = [...game.oxen];
        let { inventory, questInventory, limits } = game;

        const currentLocation = getCurrentLocation(trail);
        const regions = routingCurrentMapRegionNames(trail.routing);
        const mapItems = regions
            .map(region => getMapItem(questInventory, region))
            .filter(item => item !== null)
            .map(item => item!);

        if (limits.findingAbandonedWagonCooldownDays === 0 && currentLocation === null) {
            const probabilityOfFindingWagon = 0.02;
            if (randomEvent(probabilityOfFindingWagon)) {
                limits = enableFindingAbandonedWagonCooldown(limits, 31);

                let { wagonTongues, wagonWheels, wagonAxles, clothes, bullets, medicine, rifles } = inventory;
                let { maps } = questInventory;

                const needsOxen = oxen.filter(ox => !isOxDead(ox) && !isOxRanOff(ox)).length === 0;
                const needsTongue = wagons.filter(hasBrokenTongue).length > 0 && wagonTongues.amount === 0;
                const needsWheels = Math.max(0, wagons.reduce((prev, curr) => prev + countBrokenWheels(curr), 0) - wagonWheels.amount);
                const needsAxles = Math.max(0, wagons.reduce((prev, curr) => prev + countBrokenAxles(curr), 0) - wagonAxles.amount);
                const needsClothes = clothes.amount < players.length;
                const needsBullets = bullets.amount < 10;
                const needsRifles = rifles.amount < 1;
                const needsMedicine = medicine.amount === 0;
                const needsMap = mapItems.length === 0;

                const probabilityOfFindingOxen = needsOxen ? 1 : 0.01;
                const probabilityOfFindingClothes = needsClothes ? 0.50 : 0.10;
                const probabilityOfFindingBullets = needsBullets ? 0.80 : 0.10;
                const probabilityOfFindingRifles = needsRifles ? 0.50 : 0.01;
                const probabilityOfFindingMedicine = needsMedicine ? 0.50 : 0.10;
                const probabilityOfFindingMap = needsMap ? 0.30 : 0;

                let foundOxen = 0;
                let foundTongues = 0;
                let foundWheels = 0;
                let foundAxles = 0;
                let foundClothes = 0;
                let foundBullets = 0;
                let foundRifles = 0;
                let foundMedicine = 0;
                let foundMap: MapItem | null = null;

                if (randomEvent(probabilityOfFindingOxen)) {
                    foundOxen = 1;
                    oxen = addOxen(oxen);
                }

                if (needsTongue) {
                    foundTongues = Math.min(1, calculateWagonTonguesCapacity(inventory, wagons, players));
                    wagonTongues = increaseInventoryItem(wagonTongues, foundTongues);
                }
                if (needsWheels) {
                    foundWheels = Math.min(needsWheels, calculateWagonWheelsCapacity(inventory, wagons, players));
                    wagonWheels = increaseInventoryItem(wagonWheels, foundWheels);
                }
                if (needsAxles) {
                    foundAxles = Math.min(needsAxles, calculateWagonAxlesCapacity(inventory, wagons, players));
                    wagonAxles = increaseInventoryItem(wagonAxles, foundAxles);
                }

                if (randomEvent(probabilityOfFindingClothes)) {
                    foundClothes = Math.min(randomInt(1, 5), calculateClothesCapacity(inventory, wagons, players));
                    clothes = increaseInventoryItem(clothes, foundClothes);
                }

                if (randomEvent(probabilityOfFindingBullets)) {
                    foundBullets = Math.min(randomInt(10, 100), calculateBulletsCapacity(inventory, wagons, players));
                    bullets = increaseInventoryItem(bullets, foundBullets);
                }

                if (randomEvent(probabilityOfFindingRifles)) {
                    foundRifles = Math.min(1, calculateRiflesCapacity("Grandpa's Rifle", inventory, wagons, players));
                    if (!!foundRifles) {
                        rifles = addRifle("Grandpa's Rifle", rifles);
                    }
                }

                if (randomEvent(probabilityOfFindingMedicine)) {
                    foundMedicine = Math.min(randomInt(1, 10), calculateMedicineCapacity(inventory, wagons, players));
                    medicine = increaseInventoryItem(medicine, foundMedicine);
                }

                if (randomEvent(probabilityOfFindingMap)) {
                    const region = regions.length === 0 ? '' : regions[0];
                    maps = addMap(maps, region, 'Names');
                    const matchingItems = maps.items
                        .filter(item => item.regionName === region)
                        .sort((a, b) => getMapLODValue(a.levelOfDetail) - getMapLODValue(b.levelOfDetail));
                    foundMap = matchingItems.length === 0 ? null : matchingItems[matchingItems.length - 1];
                }

                if (foundOxen || foundClothes || foundBullets || foundMedicine || foundMap) {
                    messages.push(makeFoundAbandonedWagonMessage({
                        oxen: foundOxen,
                        clothes: foundClothes,
                        bullets: foundBullets,
                        rifles: foundRifles,
                        medicine: foundMedicine,
                        map: foundMap,
                    }, date));
                    events.push(makeFoundAbandonedWagonEvent({
                        oxen: foundOxen,
                        wagonTongues: foundTongues,
                        wagonWheels: foundWheels,
                        wagonAxles: foundAxles,
                        clothes: foundClothes,
                        bullets: foundBullets,
                        rifles: foundRifles,
                        medicine: foundMedicine,
                        map: !!foundMap,
                    }));
                }

                inventory = { ...inventory, wagonTongues, wagonWheels, wagonAxles, clothes, bullets, rifles, medicine };
                questInventory = { ...questInventory, maps };
            }
        }

        return { ...game, inventory, questInventory, limits, messages, events, oxen };
    }

    static fillWaterBottles(game: GameState, source: 'Well' | 'River' | 'Rain' | 'Environment', fixedAmount?: number): GameState {
        let { inventory, weather, trail, date } = game;
        let { water } = inventory;
        const messages = [...game.messages];
        
        const maximum = water.containers
            .map(container => container.capacity)
            .reduce((prev, curr) => prev + curr, 0);

        if (source === 'Rain') {
            let rain: 'little' | 'much' | 'none';

            const chanceOfRain = weather.condition === 'Chance of Rain' || weather.condition === 'Chance of Thunderstorm';
            const littleRain = weather.condition === 'Flurries' || weather.condition === 'Freezing Drizzle' || weather.condition === 'Light Snow' || weather.condition === 'Scattered Showers' || weather.condition === 'Scattered Thunderstorm' || weather.condition === 'Sleet';
            const muchRain = weather.condition === 'Rain' || weather.condition === 'Snow' || weather.condition === 'Snow Showers' || weather.condition === 'Thunderstorm';

            if (chanceOfRain) {
                const event = randomInt(0, 2);
                if (event === 0) {
                    rain = 'none';
                } else if (event === 1) {
                    rain = 'little';
                } else {
                    rain = 'much';
                }
            } else if (littleRain) {
                rain = 'little';
            } else if (muchRain) {
                rain = 'much';
            } else {
                rain = 'none';
            }

            if (rain === 'little') {
                const increment = WATER_PER_BOTTLE;
                const amount = Math.min(maximum, water.amount + increment);

                water = { ...water, amount };
                inventory = { ...inventory, water };
            } else if (rain === 'much') {
                water = { ...water, amount: maximum };
                inventory = { ...inventory, water };
            }
        }

        else if (source === 'Environment') {
            const currentLocation = routingCurrentLocation(trail.routing);
            let probabilityOfFindingWater = 0;
            if (currentLocation === null && water.amount < maximum) {
                switch (weather.climateName) {
                    case 'Continental':
                        probabilityOfFindingWater = 0.10;
                        break;
                    case 'Moderate':
                        probabilityOfFindingWater = 0.30;
                        break;
                    case 'Tropical':
                        probabilityOfFindingWater = 0.40;
                        break;
                    case 'Dry':
                        probabilityOfFindingWater = 0.00;
                        break;
                    case 'Polar':
                        probabilityOfFindingWater = 0.20;
                        break;
                }
            }

            if (randomEvent(probabilityOfFindingWater)) {
                water = { ...water, amount: maximum };
                inventory = { ...inventory, water };
                messages.push(makeFoundWaterMessage(maximum, date));
            }
        }

        else { // all other sources for water
            if (fixedAmount === undefined) {
                water = { ...water, amount: maximum };
                inventory = { ...inventory, water };
            } else {
                const amount = Math.min(maximum, water.amount + fixedAmount);
                water = { ...water, amount };
                inventory = { ...inventory, water };
            }
        }

        return { ...game, inventory, messages };
    }

    static arrivedAtLocation(game: GameState): GameState {
        const messages = [...game.messages];

        const message = messages.find(m => m.type === 'Arrived' && m.roundIndex === 0);
        if (message === undefined) {
            return game;
        }

        const location = (message as ArrivedMessage).location;
        if (isLegendaryTown(location, game.role)) {
            const firstTime = messages.find(m => m.type === 'Found Legendary Town') === undefined;
            if (firstTime) {
                messages.push(makeFoundLegendaryTownMessage(location, game.date));
                if (location.name === 'Dickshooter') {
                    return EnvironmentSystem.arrivedAtLegendaryDickshooter({ ...game, messages });
                }
            }
        }

        return { ...game, messages };
    }

    private static arrivedAtLegendaryDickshooter(game: GameState): GameState {
        const name = 'Dickshooter Boost';
        const value = game.role === 'Wayfarer' ? 4 : 2;

        game = EnvironmentSystem.boostStatForOneMonth(game, game.stats.makeDo, name, value);
        game = EnvironmentSystem.boostStatForOneMonth(game, game.stats.dexterity, name, value);
        game = EnvironmentSystem.boostStatForOneMonth(game, game.stats.luck, name, value);
        game = EnvironmentSystem.boostStatForOneMonth(game, game.stats.strength, name, value);
        game = EnvironmentSystem.boostStatForOneMonth(game, game.stats.trading, name, value);
        game = EnvironmentSystem.boostStatForOneMonth(game, game.stats.wealth, name, value);
        game = EnvironmentSystem.boostStatForOneMonth(game, game.stats.health, name, value);
        game = EnvironmentSystem.boostStatForOneMonth(game, game.stats.morale, name, value);
        game = EnvironmentSystem.boostStatForOneMonth(game, game.stats.stamina, name, value);
        game = EnvironmentSystem.boostStatForOneMonth(game, game.stats.pathfinding, name, value);
        game = EnvironmentSystem.boostStatForOneMonth(game, game.stats.hunting, name, value);

        const events = [...game.events, makeArrivedInDickshooterEvent()];

        return { ...game, events };
    }

    private static boostStatForOneMonth<S extends Stat>(game: GameState, stat: S, name: string, value: number): GameState {
        const message = makeTextMessage(`Boosting :blue:{${stat.name}} for a month.`, game.date);
        const messages = [...game.messages, message];
        const boost = makeStatModifier(name, value, 30);
        const stats = applyStat(game.stats, addStatModifier(boost, stat));

        return { ...game, messages, stats };
    }

    static findOxenRunningOff(game: GameState): GameState {
        const probabilityOfFindingOx = .10;

        const apply = (onFoundOx: (ox: Ox) => void) => (ox: Ox) => {
            if (!isOxDead(ox)) {
                const ranOff = ox.ranOff;
                const distance = Math.abs((ranOff?.atMileage ?? 0) - game.trail.mileage);
                if (ranOff !== undefined && distance < 50) {
                    if (randomEvent(probabilityOfFindingOx)) {
                        const returnedOx = disableOxRanOff(ox);
                        onFoundOx(returnedOx);
                        return returnedOx;
                    }
                }
            }
            return ox;
        };

        const messages = [...game.messages];
        const oxen = [...game.oxen].map(apply(ox => messages.push(makeFoundOxAgainMessage(ox, game.date))));

        return { ...game, oxen, messages };
    }

}