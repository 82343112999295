import { Location, LocationName, LocationStatus, LocationType, LocationVisibility, RegionName } from "./Location";
import { loadTowns, Town } from "./Town";
import { getDay } from "date-fns";
import { Routing, routingLocation } from "./Routing";
import { isDead, Player } from "../Player";
import { randomElement, rollDice } from "../Random";
import { ResourceAbundances } from "./Resource";
import { TradingStat } from "../Stat";

import * as riverCrossingsJson from "./river_crossings.json"

export type RiverCrossingPaymentType = 'Money' | 'Clothes' | 'Food';
export type RiverCrossingPaymentProvider = 'Ferryman' | 'Guide';

export interface RiverCrossingPrice {
    type: RiverCrossingPaymentType;
    provider: RiverCrossingPaymentProvider;
    amount: number;
}

export interface RiverCrossing extends Location {
    width: number;
    depth: number;
    price: RiverCrossingPrice | null;
    side1: LocationName[];
    side2: LocationName[];
}

interface RiverCrossingJsonData {
    id: string;
    name: string;
    width: number;
    depth: number;
    sideA: string;
    sideB: string;
    distanceAtoB: number;
}

function resolveRiverCrossing(json: RiverCrossingJsonData, mappedTowns: Map<string, Town>): RiverCrossing | null {
    const type: LocationType = 'RiverCrossing';
    const regions: RegionName[] = ['all'];
    const status: LocationStatus = 'Pending';
    const visibility: LocationVisibility = 'visible';
    const { width, depth } = json;

    let id = json.id;
    if (id.endsWith('A')) {
        id = id.substring(0, id.length - 1);
    }

    const a = mappedTowns.get(json.sideA);
    if (a === undefined) {
        console.warn(`[RiverCrossing] Could not find location ${json.sideA} for river ${id}.`);
        return null;
    }

    const b = mappedTowns.get(json.sideB);
    if (b === undefined) {
        console.warn(`[RiverCrossing] Could not find location ${json.sideB} for river ${id}.`);
        return null;
    }

    const climate = a.climate;
    const terrain = a.terrain;
    const { latitude, longitude } = interpolateCoordinates(a, b, json.distanceAtoB);
    const resources: ResourceAbundances = {
        food: 'average',
        water: 'rich',
        other: 'poor',
    };
    const name = `${a.name} ${json.name} Crossing`;
    const side1 = [a.name];
    const side2 = [b.name];

    return {
        type, id, name, regions, status, visibility, climate, terrain,
        latitude, longitude, resources, width, depth, side1, side2,
        price: null,
    };
}

export function loadRiverCrossings(): RiverCrossing[] {
    const towns = loadTowns();
    const mappedTowns = new Map<string, Town>();
    towns.forEach(town => mappedTowns.set(town.id, town));

    const riverCrossingsData = Array.from(riverCrossingsJson);
    const riverCrossings: RiverCrossing[] = riverCrossingsData
        .map(json => resolveRiverCrossing(json, mappedTowns))
        .filter(optional => optional !== null)
        .map(optional => optional!);
    
    return riverCrossings;
}

export function formatRiverWidth(width: RiverCrossing | number): string {
    // TODO: express in meters and convert to feet
    if (typeof width === 'number') {
        return `${width.toFixed(0)} ft`;
    } else {
        return formatRiverWidth(width.width);
    }
}

export function formatRiverDepth(depth: RiverCrossing | number): string {
    // TODO: express in meters and convert to feet
    if (typeof depth === 'number') {
        return `${depth.toFixed(0)} ft`;
    } else {
        return formatRiverDepth(depth.depth);
    }
}

function interpolateCoordinates(a: Town, b: Town, factor: number): { latitude: number, longitude: number } {
    const alat = a.latitude;
    const alon = a.longitude;
    const blat = b.latitude;
    const blon = b.longitude;
    const dlat = blat - alat;
    const dlon = blon - alon;

    const latitude = alat + dlat * factor;
    const longitude = alon + dlon * factor;

    return { latitude, longitude };
}

function calculateRiverWidthNumber(riverCrossing: RiverCrossing): number {
    const w_300 = Math.ceil(riverCrossing.width / 300);
    return Math.min(5, w_300);
}

function calculateRiverDepthNumber(riverCrossing: RiverCrossing): number {
    const d_6 = Math.ceil(riverCrossing.depth / 6);
    return Math.min(10, 2 * d_6);
}

function determineFerrymanPrice(riverCrossing: RiverCrossing, routing: Routing, players: Player[], date: number): RiverCrossingPrice | null {
    const towns = [...riverCrossing.side1, ...riverCrossing.side2]
        .map(name => routingLocation(routing, name))
        .filter(location => location?.type === 'Town')
        .map(location => location as Town);

    // Calculate average town number.
    const townNumbers = towns.map(town => town.townNumber);
    const avgTownNumber = townNumbers.length === 0 ? 0 : townNumbers.reduce((prev, curr) => prev + curr, 0) / townNumbers.length;
    if (avgTownNumber < 5) {
        return null; // No ferryman available.
    }

    // Determine on what day of the week the ferryman is available.
    const townIDs = towns.map(town => +town.id.substring(3)).sort((a,b) => b - a);
    const dow = townIDs[0] % 7; // 0=Sunday, ..., 6=Saturday
    const today = (getDay(date) + 1) % 7; // add one day because this is calculated before the next day starts
    if (dow !== today) {
        return null; // Not available today.
    }

    // Calculate price.
    const alivePlayerCount = players.filter(player => !isDead(player)).length;
    const price = 15 * alivePlayerCount;

    return { provider: 'Ferryman', type: 'Money', amount: price };
}

function determineGuidePrice(riverCrossing: RiverCrossing, routing: Routing, players: Player[], tradingStat: TradingStat): RiverCrossingPrice | null {
    const towns = [...riverCrossing.side1, ...riverCrossing.side2]
        .map(name => routingLocation(routing, name))
        .filter(location => location?.type === 'Town')
        .map(location => location as Town);

    // Calculate average town number.
    const townNumbers = towns.map(town => town.townNumber);
    const avgTownNumber = townNumbers.length === 0 ? 0 : townNumbers.reduce((prev, curr) => prev + curr, 0) / townNumbers.length;
    if (avgTownNumber <= 0) {
        return null; // No guide available.
    }
    
    // Check if a guide is available.
    if (rollDice(20) >= Math.floor(avgTownNumber * 2)) {
        return null; // No guide available.
    }

    // Calculate possible prices.
    const w = calculateRiverWidthNumber(riverCrossing);
    const d = calculateRiverDepthNumber(riverCrossing);
    const wxd = w * d;
    const alivePlayerCount = players.filter(player => !isDead(player)).length;
    const cash = (wxd * 0.3 - tradingStat.value) * alivePlayerCount;
    const clothes = Math.floor(cash * 0.1);
    const food = Math.floor(cash * 0.1);

    const prices: RiverCrossingPrice[] = [
        { provider: 'Guide', type: 'Money', amount: cash },
        { provider: 'Guide', type: 'Clothes', amount: clothes },
        { provider: 'Guide', type: 'Food', amount: food },
    ];

    const availablePrices = prices.filter(price => price.amount > 0);

    return randomElement(availablePrices) ?? null;
}

export function determineRiverCrossingPrice(riverCrossing: RiverCrossing, routing: Routing, players: Player[], tradingStat: TradingStat, date: number): RiverCrossingPrice | null {
    return determineFerrymanPrice(riverCrossing, routing, players, date)
        ?? determineGuidePrice(riverCrossing, routing, players, tradingStat)
        ?? null;
}

export function determineProbabilityOfTippingOver(riverCrossing: RiverCrossing): number {
    // Base probability of 5% at 4ft, 10% at 5ft, 15% at 6ft etc ... maximum 80%.
    // Then scale that up based on 700ft average width, so 1000ft would be 143% of the base probability.
    return Math.min(0.80, riverCrossing.width / 700 * Math.min(0.80, Math.max(0, riverCrossing.depth - 3) * 0.05));
}