import { Location } from "./location/Location";

export type Role = 'Wayfarer'
    | 'Carpenter'
    | 'Miner'
    | 'Runaway'
    | 'Trader'
    | 'Heir'
    | 'Doctor'
    | 'Missionary'
    | 'Pioneer'
    | 'Surveyor'
    | 'Hunter'
    ;

export const ALL_ROLES: Role[] = ['Wayfarer', 'Carpenter', 'Miner', 'Runaway', 'Trader', 'Heir', 'Doctor', 'Missionary', 'Pioneer', 'Surveyor', 'Hunter'];

export function isLegendaryTown(location: Location, role: Role): boolean {
    return location.name === 'Dickshooter' && role === 'Heir';
}