import { Button, Image } from "react-bootstrap";
import { useAppDispatch } from "../../hooks";
import { removeEvent } from "../gameSlice";
import { FoundAbandonedWagonEvent } from "../../../types/GameEvent";
import { formatOxen } from "../../../types/Ox";
import { formatAnd } from "../../../types/Utilities";
import { formatWagonTongues, formatWagonWheels, formatWagonAxles } from "../../../types/inventory/SpareParts";
import { formatClothes } from "../../../types/inventory/Clothes";
import { formatBullets } from "../../../types/inventory/Bullets";
import { formatMedicine } from "../../../types/inventory/Medicine";

export default function FoundAbandonedWagonEventView({ event }: { event: FoundAbandonedWagonEvent }) {
    const dispatch = useAppDispatch();

    const close = () => dispatch(removeEvent(event.id));

    return (
        <div className="d-flex w-100 h-100 justify-content-start align-items-center">
            <div className="text-start p-4">
                <h3>We've found an abandoned wagon.</h3>
                <div>After searching it, we found {formatFoundThings(event)}.</div>
                <Button variant="primary" onClick={close}>Close</Button>
            </div>
            <Image src="/wagon.png" height={240} rounded />
        </div>
    );
}

function formatFoundThings({ oxen, wagonTongues, wagonWheels, wagonAxles, clothes, bullets, medicine, map }: FoundAbandonedWagonEvent): string {
    const things: string[] = [];

    if (oxen) {
        things.push(formatOxen(oxen));
    }
    if (wagonTongues) {
        things.push(formatWagonTongues(wagonTongues));
    }
    if (wagonWheels) {
        things.push(formatWagonWheels(wagonWheels));
    }
    if (wagonAxles) {
        things.push(formatWagonAxles(wagonAxles));
    }
    if (clothes) {
        things.push(formatClothes(clothes, true));
    }
    if (bullets) {
        things.push(formatBullets(bullets));
    }
    if (medicine) {
        things.push(formatMedicine(medicine, true));
    }
    if (map) {
        things.push('a map');
    }

    return formatAnd(things);
}