import { Player } from "../Player";

const EXHAUSTION_HP = 5; // HP per person per day

export interface Exhaustion {
    active: boolean;
    damageTaken: number;
}

export function makeExhaustion(): Exhaustion {
    return {
        active: false,
        damageTaken: 0,
    };
}

export function exhaustionMalus(): number {
    return EXHAUSTION_HP;
}

export function isExhausted(player: Player): boolean {
    return player.exhaustion.active === true;
}

export function enableExhaustion(player: Player): Player {
    const exhaustion: Exhaustion = { ...player.exhaustion, active: true };
    return { ...player, exhaustion };
}

export function disableExhaustion(player: Player): Player {
    const exhaustion: Exhaustion = { ...player.exhaustion, active: false };
    return { ...player, exhaustion };
}

export function increaseExhaustionDamageTaken(player: Player, damage: number): Player {
    const damageTaken = player.exhaustion.damageTaken + damage;
    const exhaustion: Exhaustion = { ...player.exhaustion, damageTaken };
    return { ...player, exhaustion };
}

export function resetExhaustionDamageTaken(player: Player): Player {
    const exhaustion: Exhaustion = { ...player.exhaustion, damageTaken: 0 };
    return { ...player, exhaustion };
}
