import { ProgressBar } from "react-bootstrap";
import { HealthVariants } from "../../../types/health/Health";
import { isOxDead, isOxRanOff, Ox } from "../../../types/Ox";
import { isDead, Player } from "../../../types/Player";
import { useAppDispatch } from "../../hooks";
import { reportHealthStats } from "../gameSlice";

export default function Healthbar({ passenger, type }: { passenger: Player | Ox, type: 'Player' | 'Ox' }) {
    const dispatch = useAppDispatch();

    const player = type === 'Player' ? passenger as Player : null;
    const ox = type === 'Ox' ? passenger as Ox : null;
    const hp = (player ?? ox)?.hp ?? 0;
    const health = HealthVariants.ofValue(hp);
    const dead = (player && isDead(player)) || (ox && isOxDead(ox)) || (ox && isOxRanOff(ox));
    const className = `healthbar ${health.status.replaceAll(' ', '')}`;
    const max = HealthVariants.good.maximum;

    const onClicked = () => {
        if (player) {
            dispatch(reportHealthStats(player.name));
        }
    };

    if (dead) {
        return null;
    } else {
        return (
            <div className="d-flex flex-row justify-content-start align-items-center w-100 h-100" onClick={onClicked}>
                <ProgressBar min={0} max={max} now={hp} className={className} />
            </div>
        );
    }
}