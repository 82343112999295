import { Image, Modal, Spinner } from "react-bootstrap";
import { randomElement } from "../../../../../types/Random";
import { useAppSelector } from "../../../../hooks";
import { selectGame } from "../../../gameSlice";

import AvatarImage from "../../../passenger/AvatarImage";

export default function HuntingModal() {
    const { hunting, players } = useAppSelector(selectGame);
    const hunter = hunting === null ? null : players.find(player => player.name === hunting.playerName) ?? null;
    const imageName = randomElement(['/hunting1.png', '/hunting2.png'])!;

    return (
        <Modal show={!!hunting && !hunting.result} backdrop="static">
            <Modal.Body className="text-center fs-2 pt-5 pb-5">
                { !!hunter &&
                <div>
                    <AvatarImage player={hunter} size={120} />
                    <div>{hunter.name} is hunting</div>
                    <div className="text-warning">&quot;{hunting?.word}&quot;</div>
                </div>
                }
                <Spinner animation="grow" variant="primary" />
                <div>
                    <Image src={imageName} width={420} rounded />
                </div>
            </Modal.Body>
        </Modal>
    );
}