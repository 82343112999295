import { Card } from "react-bootstrap";
import { useState } from "react";
import { Location } from "../../../../types/location/Location";
import { RiverCrossingOption } from "../../../../types/location/RiverCrossingOption";
import { routingCurrentLocation } from "../../../../types/location/Routing";
import { Trail } from "../../../../types/travel/Trail";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { selectGame, setTravelOptions } from "../../gameSlice";
import { RiverCrossing } from "../../../../types/location/RiverCrossing";

import ChooseRiverCrossingOptionButton from "./ChooseRiverCrossingOptionButton";
import ChooseRiverCrossingOptionModal from "./ChooseRiverCrossingOptionModal";
import RationControl from "../navigation/RationControl";

export default function RiverInteractions() {
    const dispatch = useAppDispatch();
    const { trail, hunting, travelOptions } = useAppSelector(selectGame);
    
    const location = getCurrentLocation(trail);
    const riverCrossing = asRiverCrossing(location);
    const chosenOption = travelOptions.chosenRiverCrossingOption;

    const [isModalOpen, setModalOpen] = useState(false);

    const chooseOption = (chosenRiverCrossingOption: RiverCrossingOption) => {
        const chosenDestinationName = chosenRiverCrossingOption.destination;
        dispatch(setTravelOptions({ chosenRiverCrossingOption, chosenDestinationName }));
    };

    if (!riverCrossing || !!hunting) {
        return null;
    }

    return (
        <>
        <ChooseRiverCrossingOptionModal riverCrossing={riverCrossing} open={isModalOpen} choose={chooseOption} close={() => setModalOpen(false)} />
        <Card>
            <Card.Header>River Crossing</Card.Header>
            <Card.Body className="d-flex flex-column justify-content-start align-items-stretch">
                { chosenOption !== undefined && <div className="text-center text-info mb-2">{chosenOption.name}</div> }
                <ChooseRiverCrossingOptionButton chooseOption={() => setModalOpen(true)} />
                <ReadyInfo chosenOption={chosenOption} />
            </Card.Body>
            <Card.Footer>
                <RationControl />
            </Card.Footer>
        </Card>
        </>
    );
}

function getCurrentLocation(trail: Trail): Location | null {
    return routingCurrentLocation(trail.routing);
}

function asRiverCrossing(location: Location | null): RiverCrossing | null {
    if (location?.type === 'RiverCrossing') {
        return location as RiverCrossing;
    } else {
        return null;
    }
}

function ReadyInfo({ chosenOption }: { chosenOption: RiverCrossingOption | undefined }) {
    if (chosenOption === null) {
        return null;
    } else {
        return <div className="text-muted mb-2">Click on "Travel" to <br /> cross the river now.</div>;
    }
}