import { useCallback, useEffect } from "react";
import { ListGroup, Modal } from "react-bootstrap";
import { LocationName } from "../../../../../types/location/Location";
import { TollStation } from "../../../../../types/location/TollStation";
import { routingNextVisibleLocations, routingPreviousLocation } from "../../../../../types/location/Routing";
import { lookupLocation, Trail } from "../../../../../types/travel/Trail";
import { useAppSelector } from "../../../../hooks";
import { useKeyboardInput } from "../../../../system/KeyboardInputManager";
import { selectGame } from "../../../gameSlice";
import { Cash, formatCash } from "../../../../../types/inventory/Cash";

export default function ChooseDirectionModal({ open, cancel, choose }: { open: boolean, cancel: () => void, choose: (name: LocationName) => void }) {
    const keyboardInput = useKeyboardInput();
    const { inventory, trail } = useAppSelector(selectGame);
    const { cash } = inventory;

    const directions = routingNextVisibleLocations(trail.routing).map(location => location.name);
    const accessibleDirections = directions.filter(isValidDirection(cash, trail));
    const inaccessibleDirections = directions.filter(not(isValidDirection(cash, trail)));

    const onKeyPressed = useCallback((event: KeyboardEvent, top: boolean) => {
        for (let index = 0; index < accessibleDirections.length; ++index) {
            if (event.key === `${index + 1}` && open && top) {
                const name = accessibleDirections[index];
                choose(name);
                return true;
            }
        }
        return false;
    }, [accessibleDirections, open, choose]);

    const isReverseDirection = (name: LocationName) => {
        return routingPreviousLocation(trail.routing)?.name === name;
    };

    useEffect(() => {
        const name = 'choose direction modal';
        if (open) {
            keyboardInput.push(name, onKeyPressed);
        } else {
            keyboardInput.pop(name);
        }
        return () => keyboardInput.pop(name);
    }, [keyboardInput, open, onKeyPressed]);

    const accessibleListItems = accessibleDirections.map((name, index) => (
        <ListGroup.Item key={name} action onClick={() => choose(name)}>
            <kbd>{index + 1}</kbd> {isReverseDirection(name) ? <span className="text-warning">back to </span> : ""} {formatLocation(name, trail)}
        </ListGroup.Item>
    ));

    const inaccessibleListItems = inaccessibleDirections.map(name => (
        <ListGroup.Item key={name}>
            {formatLocation(name, trail)}
        </ListGroup.Item>
    ));

    const listItems = [...accessibleListItems, ...inaccessibleListItems];

    return (
        <Modal show={open} onHide={cancel}>
            <Modal.Header>
                <Modal.Title>Which way do we go?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ListGroup>{listItems}</ListGroup>
            </Modal.Body>
        </Modal>
    );
}

type LocationPredicate = (name: LocationName) => boolean;

function isValidDirection(cash: Cash, trail: Trail): LocationPredicate {
    return (name) => {
        const location = lookupLocation(trail, name);
        if (location?.type === 'TollStation') {
            const tollStation = location as TollStation;
            return tollStation.price <= cash.amount;
        }
        return location !== null;
    };
}

const not: (predicate: LocationPredicate) => LocationPredicate = (predicate) => {
    return (name) => !predicate(name);
};

function formatLocation(name: LocationName, trail: Trail): string {
    const location = lookupLocation(trail, name);
    if (location?.type === 'TollStation') {
        const tollStation = location as TollStation;
        const cost = formatCash(tollStation.price);
        return `${name} (${cost})`;
    }
    return name;
}