import { interpolatedValueBetweenLocations, Location, LocationName } from "../location/Location";
import { generateMap, RouteNode } from "../location/RouteNode";
import { RiverCrossingOption } from "../location/RiverCrossingOption";
import { Routing, routingCurrentLocation, routingDistanceToNextLocation, routingLastLocation, routingLocation, routingNextLocation } from "../location/Routing";
import { Role } from "../Role";

export interface Trail {
    routing: Routing;
    mileage: number;
    distanceSinceLastLocation: number;
    direction: LocationName | null;
}

export interface TravelOptions {
    rest?: boolean;
    chosenDestinationName?: LocationName;
    chosenRiverCrossingOption?: RiverCrossingOption;
};

export function makeEmptyTrail(): Trail {
    const root: RouteNode = {
        location: 'Independence',
        next: [],
    };

    const routing: Routing = {
        locations: [],
        root,
        destinationName: '',
        nodes: [root],
        pathTaken: ['Independence'],
    };

    return {
        routing,
        mileage: 0,
        distanceSinceLastLocation: 0,
        direction: null,
    };
}

export function makeRandomTrail(role: Role): Trail {
    return {
        routing: generateMap(role),
        mileage: 0,
        distanceSinceLastLocation: 0,
        direction: null,
    };
}

export function lookupLocation(trail: Trail, name: LocationName): Location | null {
    return routingLocation(trail.routing, name);
}

export function getDistanceToNextLocation(trail: Trail, chosenDestinationName: LocationName | null): number {
    const distance = routingDistanceToNextLocation(trail.routing, chosenDestinationName);
    if (isNaN(distance) || distance === 0) {
        return 0;
    }

    return Math.max(0, distance - trail.distanceSinceLastLocation);
}

function markAsDeparted(location: Location): Location {
    if (location.status === 'Arrived') {
        return { ...location, status: 'Departed' };
    }
    return location;
}

function markAsArrived(location: Location, nextLocationName: LocationName): Location {
    if (location.name === nextLocationName) {
        return { ...location, status: 'Arrived' };
    }
    return location;
}

function replaceLocationInArray(location: Location, array: Location[]): Location[] {
    return array.map(l => l.name === location.name ? location : l);
}

export function advanceTrail(trail: Trail, distance: number, chosenDestinationName: LocationName | null): Trail {

    // Abort if we're already at the chosen destination.
    const currentLocation = routingCurrentLocation(trail.routing);
    if (currentLocation !== null && currentLocation.name === chosenDestinationName) {
        console.log(`We're already at ${chosenDestinationName}`);
        return trail;
    }

    // Abort if we're already at the next location.
    const nextLocation = routingNextLocation(trail.routing, chosenDestinationName ?? trail.direction);
    if (nextLocation !== null && nextLocation.name === currentLocation?.name) {
        console.log(`We're already at ${nextLocation.name}`);
        return trail;
    }

    // Abort if the next location is the last location somehow.
    const lastLocation = routingLastLocation(trail.routing);
    if (lastLocation === null || nextLocation === null || lastLocation.name === nextLocation.name) {
        if (lastLocation === null) {
            console.log(`Could not determine last location.`);
        } else if (nextLocation === null) {
            console.log(`Could not determine next location.`);
        } else {
            console.log(`Last and next locations ${nextLocation.name} are the same.`);
        }
        return trail;
    }

    // Calculate the distance to the next location and check if we've arrived.
    const distanceToNextLocation = routingDistanceToNextLocation(trail.routing, chosenDestinationName ?? trail.direction);
    const distanceTraveled = trail.distanceSinceLastLocation + distance;
    const arrived = distanceTraveled >= distanceToNextLocation;

    if (arrived) {
        const currentLocation = markAsArrived(nextLocation, nextLocation.name);
        const locations = replaceLocationInArray(currentLocation, trail.routing.locations.map(markAsDeparted));
        const pathTaken = [...trail.routing.pathTaken, currentLocation.name];
        const mileage = trail.mileage + distance;
        const distanceSinceLastLocation = 0;
        const routing: Routing = { ...trail.routing, locations, pathTaken };
        return { ...trail, routing, mileage, distanceSinceLastLocation, direction: null };
    }
    
    else { // not arrived
        const locations = trail.routing.locations.map(markAsDeparted);
        const mileage = trail.mileage + distance;
        const distanceSinceLastLocation = trail.distanceSinceLastLocation + distance;
        const routing: Routing = { ...trail.routing, locations };
        return { ...trail, routing, mileage, distanceSinceLastLocation };
    }
}

export function trailInterpolatedValueBetweenLocations(trail: Trail, value: (location: Location) => number): number {
    const distanceToNextLocation = routingDistanceToNextLocation(trail.routing, trail.direction);
    const distanceSinceLastLocation = trail.distanceSinceLastLocation;
    const lastLocation = routingCurrentLocation(trail.routing) ?? routingLastLocation(trail.routing);
    const nextLocation = routingNextLocation(trail.routing, trail.direction);
    
    if (lastLocation === null) {
        return 0;
    } else if (nextLocation === null) {
        return value(lastLocation);
    }

    const lhs = value(lastLocation);
    const rhs = value(nextLocation);
    
    return interpolatedValueBetweenLocations(lhs, rhs, distanceSinceLastLocation, distanceToNextLocation);
}
