import { useCallback, useEffect, useState } from "react";
import { Button, Form, ListGroup, Modal } from "react-bootstrap";
import { makeFerryRiverCrossingOption, makeFloatRiverCrossingOption, makeFordRiverCrossingOption, makeGuideRiverCrossingOption, RiverCrossingOption, RiverCrossingOptionName } from "../../../../types/location/RiverCrossingOption";
import { routingRiverCrossingDirections } from "../../../../types/location/Routing";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useKeyboardInput } from "../../../system/KeyboardInputManager";
import { changeInventory, selectGame } from "../../gameSlice";
import { formatRiverDepth, formatRiverWidth, RiverCrossing } from "../../../../types/location/RiverCrossing";
import { LocationName } from "../../../../types/location/Location";
import { decreaseInventoryItem } from "../../../../types/inventory/InventoryItem";
import { formatClothes } from "../../../../types/inventory/Clothes";
import { formatCash } from "../../../../types/inventory/Cash";
import { formatFood } from "../../../../types/inventory/Food";

export default function ChooseRiverCrossingOptionModal({ riverCrossing, open, choose, close }: { riverCrossing: RiverCrossing, open: boolean, close: () => void, choose: (option: RiverCrossingOption) => void }) {
    const { players, inventory, trail, stats } = useAppSelector(selectGame);

    const guideAvailable = riverCrossing.price?.provider === 'Guide';
    const ferryAvailable = riverCrossing.price?.provider === 'Ferryman';
    const directionsAvailable = routingRiverCrossingDirections(trail.routing);

    const [option, setOption] = useState<RiverCrossingOptionName | null>(null);
    const [destinationName, setDestinationName] = useState<LocationName | null>(null);
    const [isGuidePayed, setGuidePayed] = useState(false);
    const [isFerryPayed, setFerryPayed] = useState(false);

    const chooseGuide = () => {
        if (guideAvailable) {
            setOption('Guide');
        }
    };

    const chooseFerry = () => {
        if (ferryAvailable) {
            setOption('Ferry');
        }
    };

    const chooseFording = () => {
        if (destinationName === null) {
            setOption('Ford');
        } else {
            choose(makeFordRiverCrossingOption(riverCrossing, players, inventory, destinationName, stats.stamina));
            setOption(null);
            close();
        }
    };

    const chooseFloating = () => {
        if (destinationName === null) {
            setOption('Float');
        } else {
            choose(makeFloatRiverCrossingOption(riverCrossing, players, inventory, destinationName, stats.stamina));
            setOption(null);
            close();
        }
    };

    const onGuideModalClosed = (payed: boolean) => {
        setGuidePayed(payed);
        if (payed && destinationName !== null) {
            choose(makeGuideRiverCrossingOption(true, destinationName));
        }
        setOption(null);
        close();
    };

    const onFerryModalClosed = (payed: boolean) => {
        setFerryPayed(payed);
        if (payed && destinationName !== null) {
            choose(makeFerryRiverCrossingOption(true, destinationName));
        }
        setOption(null);
        close();
    };

    const onDestinationNameChosen = (name: LocationName) => {
        setDestinationName(name);

        if (option === 'Ferry' && isFerryPayed) {
            choose(makeFerryRiverCrossingOption(true, name));
            setOption(null);
            close();
        }

        else if (option === 'Guide' && isGuidePayed) {
            choose(makeGuideRiverCrossingOption(true, name));
            setOption(null);
            close();
        }

        else if (option === 'Float') {
            choose(makeFloatRiverCrossingOption(riverCrossing, players, inventory, name, stats.stamina));
            setOption(null);
            close();
        }

        else if (option === 'Ford') {
            choose(makeFordRiverCrossingOption(riverCrossing, players, inventory, name, stats.stamina));
            setOption(null);
            close();
        }
    };

    useEffect(() => {
        if (directionsAvailable.length > 0 && destinationName === null) {
            setDestinationName(directionsAvailable[0]);
        }
    }, [directionsAvailable, destinationName, setDestinationName]);

    return (
        <Modal show={open} onHide={close}>
            <Modal.Header>
                <Modal.Title>{riverCrossing.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mb-4">
                    This river is {formatRiverWidth(riverCrossing)} wide and {formatRiverDepth(riverCrossing)} deep.
                    How do you want to cross this river?
                </div>
                <ListGroup>
                    <ListGroup.Item action onClick={chooseFording}>
                        <div><u>F</u>ording</div>
                        <small className="text-muted">
                            "Fording" a river means trying to pull your wagon through a shallow part of
                            the river, with the oxen still attached.
                        </small>
                    </ListGroup.Item>
                    <ListGroup.Item action onClick={chooseFloating}>
                        <div><u>C</u>ock the Wagon™</div>
                        <small className="text-muted">
                            Cock the wagon, cover the bottom with pitch or tar to make it watertight.
                            Then you can pile your supplies on top and try to float them across a river.
                        </small>
                    </ListGroup.Item>
                    {guideAvailable &&
                        <ListGroup.Item action onClick={chooseGuide}>
                            <div>Hire a <u>G</u>uide</div>
                            <small className="text-muted">
                                Hire a native guide to help you cross the river. They might want something
                                in return.
                            </small>
                        </ListGroup.Item>
                    }
                    {ferryAvailable &&
                        <ListGroup.Item action onClick={chooseFerry}>
                            <div><u>U</u>se a Ferry</div>
                            <small className="text-muted">
                                Pay for a ferry to cross the river safely.
                            </small>
                        </ListGroup.Item>
                    }
                </ListGroup>

                <Form.Label className="mt-2">Which way do we go?</Form.Label>
                { directionsAvailable.length > 0 &&
                <Form.Select onChange={({target}) => onDestinationNameChosen(target.value as LocationName)}>
                    { directionsAvailable.map(direction => (<option key={direction} value={direction}>{direction}</option>)) }
                </Form.Select>
                }
                { directionsAvailable.length === 0 &&
                <div className="text-danger">Oh no, there is nothing on the other side of the river! Jabu, this is a bug!!</div>
                }

                <GuideModal riverCrossing={riverCrossing} open={option === 'Guide'} onClose={onGuideModalClosed} />
                <FerryModal riverCrossing={riverCrossing} open={option === 'Ferry'} onClose={onFerryModalClosed} />
            </Modal.Body>
        </Modal>
    );
}

interface GuideModalProps {
    riverCrossing: RiverCrossing;
    open: boolean;
    onClose: (payed: boolean) => void;
}

function GuideModal({ riverCrossing, open, onClose }: GuideModalProps) {
    const dispatch = useAppDispatch();
    const keyboardInput = useKeyboardInput();
    const { inventory } = useAppSelector(selectGame);

    const payWithMoneyEnabled = riverCrossing.price?.type === 'Money';
    const payWithClothesEnabled = riverCrossing.price?.type === 'Clothes';
    const payWithFoodEnabled = riverCrossing.price?.type === 'Food';
    const price = riverCrossing.price?.amount ?? 0;
    const acceptButtonDisabled = !(payWithClothesEnabled || payWithMoneyEnabled || payWithFoodEnabled)
        || (payWithClothesEnabled && inventory.clothes.amount < price)
        || (payWithMoneyEnabled && inventory.cash.amount < price)
        || (payWithFoodEnabled && inventory.food.amount < price);

    const payGuide = useCallback(() => {
        if (payWithMoneyEnabled && price > 0 && inventory.cash.amount >= price) {
            dispatch(changeInventory({ ...inventory, cash: decreaseInventoryItem(inventory.cash, price) }));
            onClose(true);
        } else if (payWithClothesEnabled && price > 0 && inventory.clothes.amount >= price) {
            dispatch(changeInventory({ ...inventory, clothes: decreaseInventoryItem(inventory.clothes, price) }));
            onClose(true);
        } else if (payWithFoodEnabled && price > 0 && inventory.food.amount >= price) {
            dispatch(changeInventory({ ...inventory, food: decreaseInventoryItem(inventory.food, price) }));
            onClose(true);
        }
    }, [payWithMoneyEnabled, payWithClothesEnabled, payWithFoodEnabled, price, inventory, dispatch, onClose]);

    const onAcceptButtonClicked = () => payGuide();
    const onCancelButtonClicked = () => onClose(false);

    const onKeyPressed = useCallback((event: KeyboardEvent, top: boolean) => {
        if (open && event.key === 'Enter') {
            payGuide();
            return true;
        }
        return false;
    }, [open, payGuide]);

    useEffect(() => {
        const name = 'guide modal';
        if (open) {
            keyboardInput.push(name, onKeyPressed);
        } else {
            keyboardInput.pop(name);
        }
        return () => keyboardInput.pop(name);
    }, [keyboardInput, open, onKeyPressed]);

    return (
        <Modal show={open} onHide={() => onClose(false)} backdrop="static">
            <Modal.Header>
                <Modal.Title>{riverCrossing.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mb-4">
                    This river is {formatRiverWidth(riverCrossing)} wide and {formatRiverDepth(riverCrossing)} deep.
                </div>
                <div className="mb-4">
                    The guide can take you over the river safely.
                    { payWithMoneyEnabled &&
                    <span className="ms-1 me-1">He only demands {formatCash(price)} for payment.</span>
                    }
                    { payWithClothesEnabled &&
                    <span className="ms-1 me-1">He only demands {formatClothes(price)} of clothes for payment.</span>
                    }
                    { payWithFoodEnabled &&
                    <span className="ms-1 me-1">He only demands {formatFood(price)} of food for payment.</span>
                    }
                    Do you pay him?
                </div>
                {payWithClothesEnabled && price > 0 && inventory.clothes.amount < price &&
                    <div className="text-danger">
                        Since you don't have any clothes to spare, somebody (we're not saying who) is starting to take off
                        their clothes. Unfortunately this scared away the guide.
                    </div>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onCancelButtonClicked}>Decline</Button>
                <Button variant="primary" onClick={onAcceptButtonClicked} disabled={acceptButtonDisabled}>Accept</Button>
            </Modal.Footer>
        </Modal>
    );
}

interface FerryModalProps {
    riverCrossing: RiverCrossing;
    open: boolean;
    onClose: (payed: boolean) => void;
}

function FerryModal({ riverCrossing, open, onClose }: FerryModalProps) {
    const dispatch = useAppDispatch();
    const keyboardInput = useKeyboardInput();
    const { inventory } = useAppSelector(selectGame);
    const canPayWithMoney = riverCrossing.price?.type === 'Money';
    const price = riverCrossing.price?.amount ?? 0;
    const acceptButtonDisabled = !canPayWithMoney || inventory.cash.amount < price;

    const payFerry = useCallback(() => {
        if (canPayWithMoney && inventory.cash.amount >= price) {
            dispatch(changeInventory({ ...inventory, cash: decreaseInventoryItem(inventory.cash, price) }));
            onClose(true);
        }
    }, [canPayWithMoney, price, inventory, dispatch, onClose]);

    const onAcceptButtonClicked = () => payFerry();
    const onCancelButtonClicked = () => onClose(false);

    const onKeyPressed = useCallback((event: KeyboardEvent, top: boolean) => {
        if (open && event.key === 'Enter') {
            payFerry();
            return true;
        }
        return false;
    }, [open, payFerry]);

    useEffect(() => {
        const name = 'ferry modal';
        if (open) {
            keyboardInput.push(name, onKeyPressed);
        } else {
            keyboardInput.pop(name);
        }
        return () => keyboardInput.pop(name);
    }, [keyboardInput, open, onKeyPressed]);

    return (
        <Modal show={open} onHide={() => onClose(false)} backdrop="static">
            <Modal.Header>
                <Modal.Title>{riverCrossing.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mb-4">
                    This river is {formatRiverWidth(riverCrossing)} wide and {formatRiverDepth(riverCrossing)} deep.
                </div>
                <div className="mb-4">
                    The ferry will bring you over to the other side safely. It will
                    cost <span className="text-info">{formatCash(price)} </span>
                    to use the ferry. Do you pay them?
                </div>
                {canPayWithMoney && price > 0 && inventory.cash.amount < price &&
                    <div className="text-danger">
                        Unfortunately you are broke. You look at the ferryman embarrassed and decline the offer.
                    </div>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onCancelButtonClicked}>Decline</Button>
                <Button variant="primary" onClick={onAcceptButtonClicked} disabled={acceptButtonDisabled}>Accept</Button>
            </Modal.Footer>
        </Modal>
    );
}