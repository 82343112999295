import { addDays } from "date-fns";
import { GameState } from "../game/gameSlice";
import { isDead } from "../../types/Player";
import { advanceLimits } from "../../types/Limits";
import { incrementRound } from "../../types/Message";
import { canTravel } from "../../types/travel/Travel";
import { determineRiverCrossingPrice, RiverCrossing } from "../../types/location/RiverCrossing";
import { Routing } from "../../types/location/Routing";
import { Trail } from "../../types/travel/Trail";
import { advanceStats } from "../../types/Stat";
import { advanceOxAge } from "../../types/Ox";

import HealthSystem from "./HealthSystem";
import HuntingSystem from "./HuntingSystem";
import InventorySystem from "./InventorySystem";
import EnvironmentSystem from "./EnvironmentSystem";
import WagonSystem from "./WagonSystem";
import NpcSystem from "./NpcSystem";
import FightingAnimalsSystem from "./FightingAnimalsSystem";
import FightingBanditsSystem from "./FightingBanditsSystem";
import EastereggSystem from "./EastereggSystem";
import ChestLockSystem from "./ChestLockSystem";
import CheatSystem from "./CheatSystem";
import TownSystem from "./TownSystem";
import AchievementSystem from "./AchievementSystem";

export { HealthSystem, HuntingSystem, InventorySystem, EnvironmentSystem, WagonSystem, NpcSystem, FightingAnimalsSystem, FightingBanditsSystem, EastereggSystem, ChestLockSystem, CheatSystem, TownSystem, AchievementSystem };

export function jumpToNextLocation(game: GameState): GameState {
    game = EnvironmentSystem.jumpToNextLocation(game);
    return updateStatus(game);
}

export function advance(game: GameState): GameState {
    game = advanceDate(game);

    // Advance player/oxen state.
    game = HealthSystem.resetHealthStats(game);
    game = HealthSystem.feedPlayers(game);
    game = HealthSystem.giveWater(game);
    game = HealthSystem.makeRandomPlayerInjuredOrSick(game);
    game = HealthSystem.makeRandomPlayerExhausted(game);
    game = HealthSystem.makeRandomOxenDeadOrInjuredOrRunningOff(game);
    game = HealthSystem.makeRandomOxenExhausted(game);
    game = HealthSystem.damagePlayersFromDiseases(game);
    game = HealthSystem.damagePlayersFromInjuries(game);
    game = HealthSystem.damageOxenFromInjuries(game);
    game = HealthSystem.healPlayerExhaustion(game, 'resting');
    game = HealthSystem.damagePlayersFromExhaustion(game);
    game = HealthSystem.healOxenExhaustion(game, 'resting');
    game = HealthSystem.damageOxenFromExhaustion(game);
    game = HealthSystem.damagePlayersFromFreezing(game);

    // Advance trail.
    game = EnvironmentSystem.payAtTollStation(game);
    game = EnvironmentSystem.crossRiver(game);
    game = EnvironmentSystem.advanceTrail(game);
    game = EnvironmentSystem.advanceWeather(game);
    game = HealthSystem.applyFreezing(game);
    game = HuntingSystem.reset(game);
    game = FightingAnimalsSystem.reset(game);
    game = EnvironmentSystem.arrivedAtLocation(game);

    // Advance wagon state.
    game = InventorySystem.distributeAvailableClothes(game);
    game = EnvironmentSystem.fillWaterBottles(game, 'Rain');
    game = EnvironmentSystem.findFruit(game);
    game = EnvironmentSystem.fillWaterBottles(game, 'Environment');
    game = EnvironmentSystem.findAbandonedWagon(game);
    game = WagonSystem.killOxen(game);
    game = WagonSystem.repairWagon(game);
    game = WagonSystem.breakWagon(game);

    // NPC events.
    game = NpcSystem.gettingRobbedByThief(game);
    game = NpcSystem.gettingAttackedByWildAnimals(game);
    game = NpcSystem.ridersApproaching(game);

    game = HealthSystem.capHP(game);
    game = InventorySystem.applyInventoryStatModifiers(game);

    return updateStatus(game);
}

function advanceDate(game: GameState): GameState {
    const date = addDays(game.date, 1).getTime();
    const limits = advanceLimits(game.limits);
    const messages = game.messages.map(incrementRound);

    // Update the price for each river crossing every day.
    const locations = game.trail.routing.locations.map(location => {
        if (location.type === 'RiverCrossing') {
            const riverCrossing = location as RiverCrossing;
            const price = determineRiverCrossingPrice(riverCrossing, game.trail.routing, game.players, game.stats.trading, game.date);
            return { ...riverCrossing, price };
        }
        return location;
    });
    const routing: Routing = { ...game.trail.routing, locations };
    const trail: Trail = { ...game.trail, routing };
    const stats = advanceStats(game.stats);

    // Advance oxen age.
    const oxen = game.oxen.map(advanceOxAge);

    return { ...game, date, limits, messages, trail, stats, oxen };
}

function updateStatus(game: GameState): GameState {
    const { players, status, traveling } = game;

    const allDead = players.length === players.filter(isDead).length;
    if (status === 'Playing' && allDead) {
        return { ...game, status: 'Game Over' };
    }
    
    if (traveling && !canTravel(game)) {
        game = { ...game, traveling: false };
    }

    return game;
}