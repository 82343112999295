import { useAppSelector } from "../../hooks";
import { selectGame } from "../gameSlice";
import { GameStartEvent, ArrivedAtHiddenLocationEvent, FoundAbandonedWagonEvent, HuntingFailureEvent, HuntingSucceededEvent, PlayerDiedEvent, ArrivedInDickshooterEvent } from "../../../types/GameEvent";

import HuntingFailureEventView from "./HuntingFailureEventView";
import HuntingSucceededEventView from "./HuntingSucceededEventView";
import FoundAbandonedWagonEventView from "./FoundAbandonedWagonEventView";
import GameStartEventView from "./GameStartEventView";
import ArrivedAtHiddenLocationEventView from "./ArrivedAtHiddenLocationEventView";
import PlayerDiedEventView from "./PlayerDiedEventView";
import FoundDickshooterView from "./FoundDickshooterView";

export default function EventView() {
    const { events } = useAppSelector(selectGame);
    
    const event = events.length === 0 ? null : events[0];

    if (event === null) {
        return null;
    } else {
        let view: JSX.Element | null = null;
        switch (event.type) {
            case 'Game Start':
                view = <GameStartEventView event={event as GameStartEvent} />;
                break;
            case 'Arrived At Hidden Location':
                view = <ArrivedAtHiddenLocationEventView event={event as ArrivedAtHiddenLocationEvent} />;
                break;
            case 'Arrived In Dickshooter':
                view = <FoundDickshooterView event={event as ArrivedInDickshooterEvent} />;
                break;
            case 'Found Abandoned Wagon':
                view = <FoundAbandonedWagonEventView event={event as FoundAbandonedWagonEvent} />;
                break;
            case 'Hunting Failed':
                view = <HuntingFailureEventView event={event as HuntingFailureEvent} />;
                break;
            case 'Hunting Succeeded':
                view = <HuntingSucceededEventView event={event as HuntingSucceededEvent} />;
                break;
            case 'Player Died':
                view = <PlayerDiedEventView event={event as PlayerDiedEvent} />;
                break;
        }

        if (view === null) {
            return null;
        } else {
            return <div className="event-message text-center rounded">{view}</div>;
        }
    }
}