export type ResourceAbundance = 'poor' | 'average' | 'rich';

export interface ResourceAbundances {
    food: ResourceAbundance;
    water: ResourceAbundance;
    other: ResourceAbundance;
}

export function resourceAbundanceNumericValue(abundance: ResourceAbundance): number {
    switch (abundance) {
        case 'poor':    return 0;
        case 'average': return 1;
        case 'rich':    return 2;
    }
}

export function resourceAbundancesNumericValue(abundances: ResourceAbundances): number {
    const food  = resourceAbundanceNumericValue(abundances.food);
    const water = resourceAbundanceNumericValue(abundances.water);
    const other = resourceAbundanceNumericValue(abundances.other);

    return food + water + other;
}