export type InventoryItemName = 'Cash' | 'Food' | 'Water' | 'Bullets' | 'Clothes' | 'Medicine' | 'Wagon Tongues' | 'Wagon Wheels' | 'Wagon Axles' | 'Rifles';

export interface InventoryItem {
    name: InventoryItemName;
    amount: number;
    maximum?: number;
}

export function increaseInventoryItem<Item extends InventoryItem>(item: Item, amount: number): Item {
    if (amount < 0) {
        return decreaseInventoryItem(item, -amount);
    }
    return { ...item, amount: item.amount + amount };
}

export function decreaseInventoryItem<Item extends InventoryItem>(item: Item, amount: number): Item {
    return { ...item, amount: Math.max(0, item.amount - amount) };
}