import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Button, Card, ListGroup } from "react-bootstrap";
import { addOxen, isOxDead, isOxRanOff, Ox } from "../../../types/Ox";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { selectSettings } from "../../settings/settingsSlice";
import { changeOxen, selectGame } from "../gameSlice";
import Healthbar from "./Healthbar";

// import Healthbar from "./Healthbar";
import OxAvatar from "./OxAvatar";

export default function OxList({ expanded }: { expanded?: boolean }) {
    const dispatch = useAppDispatch();
    const { oxen } = useAppSelector(selectGame);
    const { cheatMode } = useAppSelector(selectSettings);

    const aliveOxen = oxen.filter(ox => !isOxDead(ox) && !isOxRanOff(ox));
    const deadOxen = oxen.filter(ox => isOxDead(ox));
    const ranOffOxen = oxen.filter(ox => isOxRanOff(ox));
    const sortedOxen = [...aliveOxen, ...deadOxen, ...ranOffOxen];

    const increaseOxen = () => dispatch(changeOxen(addOxen(oxen)));

    if (expanded) {
        const cells = sortedOxen.map(ox => (
            <div className="me-2 mb-2" key={ox.name}>
                <ExpandedOxView ox={ox} />
            </div>
        ));
        return (
            <>
            { cheatMode === 'on' && <Button variant="secondary" size="sm" onClick={increaseOxen}><FontAwesomeIcon icon={faPlus} /></Button> }
            <div className="d-flex w-100 justify-content-start align-items-top">{cells}</div>
            </>
        );
    }

    else {
        const listItems = sortedOxen.map(ox => (
            <ListGroup.Item key={ox.name}>
                <CollapsedOxView ox={ox} />
            </ListGroup.Item>
        ));
        return <ListGroup>{listItems}</ListGroup>;
    }
}

function CollapsedOxView({ ox }: { ox: Ox }) {
    return (
        <>
            <OxAvatar ox={ox} />
            <Healthbar passenger={ox} type="Ox" />
        </>
    );
}

function ExpandedOxView({ ox }: { ox: Ox }) {
    return (
        <Card>
            <Card.Body>
                <OxAvatar ox={ox} large />
            </Card.Body>
        </Card>
    );
}