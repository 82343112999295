import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { Card } from "react-bootstrap";
import { Location, LocationName } from "../../../../types/location/Location";
import { routingNextLocation } from "../../../../types/location/Routing";
import { TravelAvailabilityPrevention, travelPreventions } from "../../../../types/travel/Travel";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { disableTraveling, selectGame, setTravelOptions } from "../../gameSlice";

import ChooseDirectionButton from "./direction/ChooseDirectionButton";
import ChooseDirectionModal from "./direction/ChooseDirectionModal";
import TravelButton from "./TravelButton";
import PaceControl from "./PaceControl";
import AutoTravelSwitch from "./AutoTravelSwitch";

export default function NavigationInteractions() {
    const dispatch = useAppDispatch();
    const game = useAppSelector(selectGame);
    const { trail, travelOptions, limits } = game;

    const nextLocation = routingNextLocation(trail.routing, trail.direction ?? travelOptions.chosenDestinationName ?? null);
    const preventions = travelPreventions(game);
    
    const [isChoosingDirection, setChoosingDirection] = useState(false);
    const [isAutoTraveling, setAutoTraveling] = useState(false);
    
    const warnings = preventions
        .filter(isTravelPreventionWarning)
        .map((prevention, index) => <div key={index} className="text-warning"><FontAwesomeIcon icon={faExclamationTriangle} /> {prevention}</div>);
    
    const autoTravelToggleVisible = !preventions.includes('cooldown') && warnings.length === 0;
    const paceDropdownVisible = !preventions.includes('cooldown') && warnings.length === 0;

    const chooseDirection = (chosenDestinationName: LocationName) => {
        dispatch(setTravelOptions({ ...travelOptions, chosenDestinationName }));
        setChoosingDirection(false);
    };

    const toggleAutoTravel = () => {
        dispatch(disableTraveling());
        setAutoTraveling(!isAutoTraveling);
    };

    if (preventions.includes('under attack')) {
        return null;
    }

    return (
        <>
        <ChooseDirectionModal open={isChoosingDirection} choose={chooseDirection} cancel={() => setChoosingDirection(false)} />
        <Card>
            <Card.Header>
                { nextLocation === null && <span className="text-warning">Which way do we go?</span> }
                { nextLocation !== null && <span>Navigation</span> }
            </Card.Header>
            <Card.Body className="d-flex flex-column justify-content-start align-items-stretch">
                <DestinationInfo destination={nextLocation} />
                { warnings }
                <TravelCooldownInfo days={limits.travelCooldownDays} />
                <ChooseDirectionButton chooseDirection={() => setChoosingDirection(true)} />
                <TravelButton autoTravel={isAutoTraveling} />
            </Card.Body>
            { (autoTravelToggleVisible || paceDropdownVisible) &&
            <Card.Footer>
                { autoTravelToggleVisible && <AutoTravelSwitch checked={isAutoTraveling} toggle={toggleAutoTravel} /> }
                { paceDropdownVisible && <PaceControl /> }
            </Card.Footer>
            }
        </Card>
        </>
    );
}

function isTravelPreventionWarning(prevention: TravelAvailabilityPrevention): boolean {
    return prevention === 'no oxen'
        || prevention === 'no wagon'
        || prevention === 'broken wagon';
}

function formatDays(count: number): string {
    if (count === 1) {
        return '1 day';
    } else {
        return `${count} days`;
    }
}

function DestinationInfo({ destination }: { destination: Location | null }) {
    if (destination === null) {
        return null;
    } else {
        return <div className="text-info mb-2"><strong>to:</strong> {destination.name}</div>;
    }
}

function TravelCooldownInfo({ days }: { days: number }) {
    if (days === 0) {
        return null;
    } else {
        return (
            <div className="text-muted mb-2">
                We're stuck here for {formatDays(days)}.<br />
                Better get some rest.
            </div>
        );
    }
}