import { useState } from "react";
import { Stat } from "../../../types/Stat";
import { useAppSelector } from "../../hooks";
import { selectGame } from "../gameSlice";
import StatsModal from "./StatsModal";

export default function StatsView() {
    const { stats } = useAppSelector(selectGame);
    const [isModalOpen, setModalOpen] = useState(false);

    const openModal = () => setModalOpen(true);

    return (
        <>
        <div className="d-flex w-100 justify-content-center align-items-center" onClick={openModal} style={{ cursor: 'pointer' }}>
            <StatView stat={stats.makeDo} />
            <StatView stat={stats.dexterity} />
            <StatView stat={stats.luck} />
            <StatView stat={stats.strength} />
            <StatView stat={stats.trading} />
            <StatView stat={stats.wealth} />
            <StatView stat={stats.health} />
            <StatView stat={stats.morale} />
            <StatView stat={stats.stamina} />
            <StatView stat={stats.pathfinding} />
            <StatView stat={stats.hunting} />
            <StatView stat={stats.experience} />
        </div>
        <StatsModal open={[isModalOpen, setModalOpen]} />
        </>
    );
}

function StatView({ stat }: { stat: Stat }) {
    const rounded = Math.floor(stat.value);

    return (
        <div title={stat.name}>
            <div className="text-center text-rotate-neg45 text-muted"><small>{stat.code}</small></div>
            { rounded < 0 && <div className="text-center text-danger">{rounded}</div> }
            { rounded === 0 && <div className="text-center text-muted">{rounded}</div> }
            { rounded > 0 && <div className="text-center text-success">{rounded}</div> }
        </div>
    );
}