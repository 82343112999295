export type Pace = 'Steady' | 'Strenuous' | 'Grueling';
export const ALL_PACES: Pace[] = ['Steady', 'Strenuous', 'Grueling'];

export function getTravelHoursPerDay(pace: Pace): number {
    switch (pace) {
        case 'Steady': return 8;
        case 'Strenuous': return 12;
        case 'Grueling': return 16;
    }
}
