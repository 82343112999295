import { useCallback, useEffect } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { makeAnimalAttack } from "../../../../../types/AnimalAttack";
import { isLastMessageMatching } from "../../../../../types/Message";
import { isDead } from "../../../../../types/Player";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { selectSettings } from "../../../../settings/settingsSlice";
import { useTwitchManager } from "../../../../twitch/TwitchManager";
import { fleeFromWildAnimals, selectGame, startFightingAnimals, stopFightingAnimals } from "../../../gameSlice";

let __animal_attack_timer: any | undefined = undefined;

export default function AnimalAttackButtons() {
    const dispatch = useAppDispatch();
    const twitch = useTwitchManager();
    const { status, animalAttack, players, inventory, messages } = useAppSelector(selectGame);
    const { huntingDuration } = useAppSelector(selectSettings);

    const gettingAttacked = status === 'Playing' && isLastMessageMatching(messages, message => message.type === 'Wild Animals Attacking');
    const fighters = players.filter(player => !isDead(player) && player.active);

    const fightEnabled = (animalAttack === null || animalAttack.result !== undefined)
        && fighters.length > 0
        && inventory.rifles.amount > 0
        && inventory.bullets.amount > 0;

    const fleeEnabled = (animalAttack === null || animalAttack.result !== undefined);
    
    const startAttack = useCallback(() => {
        const attack = makeAnimalAttack(fighters.map(player => player.name));
        twitch.sendMessage(`@everybody Type the following word as quickly as possible: ${attack.word}`);
        dispatch(startFightingAnimals(attack));
    }, [dispatch, twitch, fighters]);

    const stopAttack = useCallback(() => {
        if (animalAttack !== null && !animalAttack.result) {
            dispatch(stopFightingAnimals());
        }
    }, [animalAttack, dispatch]);

    const flee = () => dispatch(fleeFromWildAnimals());

    useEffect(() => {
        if (!!__animal_attack_timer && (!animalAttack || !!animalAttack.result)) {
            clearTimeout(__animal_attack_timer);
            __animal_attack_timer = undefined;
        } else if (!__animal_attack_timer && !!animalAttack && !animalAttack.result) {
            const now = new Date().getTime();
            const dt = now - animalAttack.start;
            const time = (huntingDuration * 1000) - dt;
            if (time > 0) {
                __animal_attack_timer = setTimeout(() => stopAttack(), time);
            } else {
                stopAttack();
            }
        }
    }, [animalAttack, huntingDuration, stopAttack]);

    if (gettingAttacked) {
        return (
            <ButtonGroup className="mb-1" vertical>
                <Button variant="primary" className="text-truncate" onClick={startAttack} disabled={!fightEnabled}>Fight Wild Animals</Button>
                <Button variant="primary" className="text-truncate" onClick={flee} disabled={!fleeEnabled}>Flee from Wild Animals</Button>
            </ButtonGroup>
        );
    } else {
        return null;
    }
}