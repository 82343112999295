import { Player } from "../Player";
import { rollDice } from "../Random";
import { HealthStat } from "../Stat";

export type DiseaseName = 'Plague' | 'Typhoid' | 'Cholera' | 'Measles' | 'Dysentery' | 'Fever' | 'Cough' | 'Cold' | 'Back Ache';

export interface Disease {
    name: DiseaseName;
    remainingDays: number;
    damagePerDay: number;
    healingPerDay: number;
    damageTaken: number;
}

function makePlague(): Disease {
    return {
        name: 'Plague',
        remainingDays: 60,
        damagePerDay: 60,
        healingPerDay: 60,
        damageTaken: 0,
    };
}

function makeTyphoid(): Disease {
    return {
        name: 'Typhoid',
        remainingDays: 10,
        damagePerDay: 40,
        healingPerDay: 10,
        damageTaken: 0,
    };
}

function makeCholera(): Disease {
    return {
        name: 'Cholera',
        remainingDays: 15,
        damagePerDay: 30,
        healingPerDay: 30,
        damageTaken: 0,
    };
}

function makeMeasles(): Disease {
    return {
        name: 'Measles',
        remainingDays: 10,
        damagePerDay: 30,
        healingPerDay: 30,
        damageTaken: 0,
    };
}

function makeDysentery(): Disease {
    return {
        name: 'Dysentery',
        remainingDays: 10,
        damagePerDay: 25,
        healingPerDay: 25,
        damageTaken: 0,
    };
}

function makeFever(): Disease {
    return {
        name: 'Fever',
        remainingDays: 5,
        damagePerDay: 30,
        healingPerDay: 30,
        damageTaken: 0,
    };
}

function makeCough(): Disease {
    return {
        name: 'Cough',
        remainingDays: 5,
        damagePerDay: 20,
        healingPerDay: 20,
        damageTaken: 0,
    };
}

function makeCold(): Disease {
    return {
        name: 'Cold',
        remainingDays: 7,
        damagePerDay: 20,
        healingPerDay: 20,
        damageTaken: 0,
    };
}

function makeBackAche(): Disease {
    return {
        name: 'Back Ache',
        remainingDays: 3,
        damagePerDay: 40,
        healingPerDay: 40,
        damageTaken: 0,
    };
}

export function makeDisease(name: DiseaseName): Disease {
    switch (name) {
        case 'Plague':
            return makePlague();
        case 'Typhoid':
            return makeTyphoid();
        case 'Cholera':
            return makeCholera();
        case 'Measles':
            return makeMeasles();
        case 'Dysentery':
            return makeDysentery();
        case 'Fever':
            return makeFever();
        case 'Cough':
            return makeCough();
        case 'Cold':
            return makeCold();
        case 'Back Ache':
            return makeBackAche();
    }
}

export function makeRandomDisease(healthStat: HealthStat): Disease {
    const d = rollDice(80) + healthStat.value;
    if (d <= 1) {
        return makePlague();
    } else if (d < 4) {
        return makeTyphoid();
    } else if (d < 6) {
        return makeCholera();
    } else if (d < 10) {
        return makeMeasles();
    } else if (d < 20) {
        return makeDysentery();
    } else if (d < 30) {
        return makeFever();
    } else if (d < 45) {
        return makeCough();
    } else if (d < 60) {
        return makeCold();
    } else  {
        return makeBackAche();
    }
}

export function isDiseaseDamaging(disease: Disease): boolean {
    return disease.remainingDays > 0;
}

export function isDiseaseHealing(disease: Disease): boolean {
    return disease.remainingDays === 0 && disease.damageTaken > 0;
}

export function decreaseDiseaseRemainingDays(disease: Disease, amount: number = 1): Disease {
    const remainingDays = Math.max(0, disease.remainingDays - amount);
    return { ...disease, remainingDays };
}

export function increaseDiseaseDamageTaken(disease: Disease, damage: number): Disease {
    const damageTaken = disease.damageTaken + damage;
    return { ...disease, damageTaken };
}

export function decreaseDiseaseDamageTaken(disease: Disease, damage: number): Disease {
    const damageTaken = Math.max(0, disease.damageTaken - damage);
    return { ...disease, damageTaken };
}

export function isSick(player: Player): boolean {
    return player.diseases
        .filter(isDiseaseDamaging)
        .length > 0;
}

export function enableDisease(player: Player, disease: Disease): Player {
    const index = player.diseases.findIndex(d => d.name === disease.name);
    if (index === -1) {
        const diseases: Disease[] = [...player.diseases, disease];
        return { ...player, diseases };
    } else {
        const diseases: Disease[] = [...player.diseases];
        diseases[index] = disease;
        return { ...player, diseases };
    }
}

export function disableDisease(player: Player, disease: Disease): Player {
    const diseases: Disease[] = player.diseases.filter(i => i.name !== disease.name);
    return { ...player, diseases };
}
