import { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useTwitchManager } from "./TwitchManager";

interface TwitchButtonsProps {
    channelName: string;
    onPendingAuthorization?: () => void;
}

export default function TwitchButtons({ channelName, onPendingAuthorization }: TwitchButtonsProps) {
    const twitchManager = useTwitchManager();
    const noClientID = !process.env.REACT_APP_TWITCH_CLIENT_ID?.length;

    const [isConnected, setConnected] = useState(twitchManager.connected);

    const onConnected = useCallback(() => setConnected(true), [setConnected]);
    const onDisconnected = useCallback(() => setConnected(false), [setConnected]);

    const onConnectButtonClicked = () => {
        twitchManager.enabled = true;
        twitchManager.connect();
    };

    const onDisconnectButtonClicked = () => {
        twitchManager.disconnect();
        twitchManager.enabled = false;
    };
    
    const onAuthorizeButtonClicked = () => {
        if (onPendingAuthorization) {
            onPendingAuthorization();
        }
        twitchManager.requestAuthorization(channelName);
    };

    const onDeauthorizeButtonClicked = () => twitchManager.deauthorize();

    const onPrintLogButtonClicked = () => twitchManager.printLog();

    useEffect(() => {
        const name = 'twitch settings';
        twitchManager.addEventListener(name, 'connect', onConnected);
        twitchManager.addEventListener(name, 'disconnect', onDisconnected);
        setConnected(twitchManager.connected);

        return () => {
            twitchManager.removeEventListener(name, 'connect');
            twitchManager.removeEventListener(name, 'disconnect');
        };
    }, [onConnected, onDisconnected, setConnected, twitchManager]);

    return (
        <div className="text-center">
            { noClientID && <div className="text-danger">Something is wrong. There is no Twitch Client ID.</div> }

            { !isConnected && <div className="text-muted">Not connected.</div> }
            { !isConnected && twitchManager.token !== null && <Button variant="primary" className="me-1" onClick={onConnectButtonClicked}>Connect</Button> }
            { !isConnected && twitchManager.token === null && <Button variant="primary" className="me-1" onClick={onAuthorizeButtonClicked} disabled={channelName.length === 0}>Authorize</Button> }
            
            { isConnected && <div className="text-success">Connected to {channelName}.</div> }
            { isConnected && <Button variant="primary" className="me-1" onClick={onDisconnectButtonClicked}>Disconnect</Button> }
            { isConnected && <Button variant="danger" className="me-1" onClick={onDeauthorizeButtonClicked}>Deauthorize</Button> }

            <Button variant="secondary" className="me-1" onClick={onPrintLogButtonClicked}>Print Log</Button>
        </div>
    );
}