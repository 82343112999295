import { Inventory } from "../../types/inventory/Inventory";
import { Water, WaterContainer } from "../../types/inventory/Water";
import { addRifle, has1836Colt } from "../../types/inventory/Rifles";
import { LocationName } from "../../types/location/Location";
import { Routing, routingNodeForLocation } from "../../types/location/Routing";
import { ValleyLodge } from "../../types/location/ValleyLodge";
import { makeArrivedMessage, makeFound1836ColtMessage, makeTextMessage } from "../../types/Message";
import { Trail } from "../../types/travel/Trail";
import { GameState } from "../game/gameSlice";

import EnvironmentSystem from "./EnvironmentSystem";
import InventorySystem from "./InventorySystem";
import AchievementSystem from "./AchievementSystem";
import { disableDisease, enableDisease, isSick, makeRandomDisease } from "../../types/health/Disease";
import { disableInjury, enableInjury, isInjured, makeRandomInjury } from "../../types/health/Injury";
import { disableDeath, disableNaked, disableWet, enableDeath, enableNaked, enableWet, isDead, isNaked, isWet } from "../../types/Player";
import { HealthVariants } from "../../types/health/Health";
import { increaseInventoryItem } from "../../types/inventory/InventoryItem";
import { WATER_PER_CANISTER } from "../../types/inventory/Water";
import { repeat } from "../../types/Utilities";
import { makeWagon } from "../../types/Wagon";
import { makeOx } from "../../types/Ox";

export default class CheatSystem {

    static cheat(input: string, game: GameState): GameState {
        if (input.toLowerCase() === 'set me up') {
            return this.setMeUp(game);
        } else if (input.toLowerCase() === 'reveal valley lodge') {
            return this.revealValleyLodge(game);
        } else if (input.toLowerCase().startsWith('jump to ')) {
            const name = input.substring(8).trim();
            return this.jumpToLocation(name, game);
        } else if (input.toLowerCase().startsWith('achieved ')) {
            const name = input.substring(9).trim();
            return this.generateAchievement(name, game);
        } else if (input.toLowerCase().startsWith('unlock ')) {
            const name = input.substring(7).trim();
            return this.unlock(name, game);
        } else if (input.toLowerCase().startsWith('make player ')) {
            const what = input.substring(12).trim();
            return this.makePlayerState(what, game);
        } else {
            console.log(`cheat input ignored: ${input}`);
            return game;
        }
    }

    static setMeUp(game: GameState): GameState {
        let { inventory, wagons, oxen } = game;

        const bullets = increaseInventoryItem(inventory.bullets, 100);
        const rifles = addRifle("Grandpa's Rifle", inventory.rifles);
        const food = increaseInventoryItem(inventory.food, 1000);
        const containers: WaterContainer[] = repeat({ type: 'Canister', capacity: WATER_PER_CANISTER }, 5);
        const water: Water = increaseInventoryItem({ ...inventory.water, containers }, 5 * WATER_PER_CANISTER);
        inventory = { ...inventory, bullets, rifles, food, water };

        wagons = [...wagons, makeWagon("Pilgrim's Wagon")];

        oxen = [...oxen, makeOx('The Rudd I'), makeOx('The Rudd II')];

        return { ...game, inventory, wagons, oxen };
    }

    static revealValleyLodge(game: GameState): GameState {
        const messages = [...game.messages];
        const locations = [...game.trail.routing.locations];
        const index = locations.findIndex(location => location.type === 'ValleyLodge');
        if (index === -1) {
            return game;
        }

        const valleyLodge = locations[index] as ValleyLodge;
        game.trail.routing.nodes
            .filter(node => node.location === valleyLodge.name)
            .forEach(node => console.log(`Found valley lodge`, JSON.parse(JSON.stringify(node))));
        
        if (valleyLodge.visibility !== 'hidden') {
            return game;
        }
        
        locations[index] = { ...valleyLodge, visibility: 'revealed' };
        const routing: Routing = { ...game.trail.routing, locations };
        const trail: Trail = { ...game.trail, routing };
        messages.push(makeTextMessage(`:red:{Used a cheat to reveal the location of Valley Lodge.}`, game.date));
        
        const node = routingNodeForLocation(routing, valleyLodge.name);
        console.log(`Revealed valley lodge`, JSON.parse(JSON.stringify(node)));

        return { ...game, trail, messages };
    }

    static jumpToLocation(name: string, game: GameState): GameState {
        const messages = [...game.messages];
        let locations = [...game.trail.routing.locations];
        const index = locations.findIndex(location => name.toLowerCase() === location.name.toLowerCase());
        if (index === -1 || locations[index].status === 'Arrived') {
            return game;
        }

        const locationName: LocationName = locations[index].name;
        locations = locations.map(location => {
            if (location.name === locationName) {
                return { ...location, status: 'Arrived' };
            } else if (location.status === 'Arrived') {
                return { ...location, status: 'Departed' };
            } else {
                return location;
            }
        });
        let location = locations[index];

        const pathTaken = [...game.trail.routing.pathTaken, locationName];
        const routing: Routing = { ...game.trail.routing, locations, pathTaken };
        const trail: Trail = { ...game.trail, routing };
        messages.push(makeTextMessage(`:red:{Used a cheat to jump to ${locationName}.}`, game.date));
        messages.push(makeArrivedMessage(location, game.date));
        if (location.visibility === 'hidden') {
            location = { ...location, visibility: 'revealed' };
            locations[index] = location;
        }

        const node = routingNodeForLocation(routing, locationName);
        console.log(`Jumped to ${locationName}.`, JSON.parse(JSON.stringify(node)));

        return EnvironmentSystem.arrivedAtLocation({ ...game, trail, messages });
    }

    static generateAchievement(name: string, game: GameState): GameState {
        return AchievementSystem.unlockDemoAchievement(name, game);
    }

    static unlock(name: string, game: GameState): GameState {
        switch (name.toLowerCase()) {
        case '1836 colt':
        case 'colt':
            return this.unlock1836Colt(game);
        default:
            return game;
        }
    }

    private static unlock1836Colt(game: GameState): GameState {
        if (has1836Colt(game.inventory)) {
            return game;
        }

        const rifles = addRifle('1836 Colt', game.inventory.rifles);
        const inventory: Inventory = { ...game.inventory, rifles };
        
        const { stats } = InventorySystem.applyInventoryStatModifiers({ ...game, inventory });
        const { achievements, messages, events } = AchievementSystem.unlock1836Colt({ ...game, inventory, stats });
        messages.push(makeFound1836ColtMessage(game.date));

        return { ...game, inventory, stats, achievements, messages, events };
    }

    static makePlayerState(what: string, game: GameState): GameState {
        const [playerName, condition] = what.split(' ');
        const index = game.players.findIndex(player => player.name.toLowerCase() === playerName.toLowerCase());
        if (index === -1) {
            return game;
        }

        let player = game.players[index];

        if (condition === 'sick') {
            if (isSick(player)) {
                const disease = player.diseases[0];
                player = disableDisease(player, disease);
            } else {
                const disease = makeRandomDisease(game.stats.health);
                player = enableDisease(player, disease);
            }
        }

        else if (condition === 'injured') {
            if (isInjured(player)) {
                const injury = player.injuries[0];
                player = disableInjury(player, injury);
            } else {
                const injury = makeRandomInjury(game.stats.health);
                player = enableInjury(player, injury);
            }
        }

        else if (condition === 'naked') {
            if (isNaked(player)) {
                player = disableNaked(player);
            } else {
                player = enableNaked(player);
            }
        }

        else if (condition === 'wet') {
            if (isWet(player)) {
                player = disableWet(player);
            } else {
                player = enableWet(player);
            }
        }

        else if (condition === 'dead') {
            if (isDead(player)) {
                player = disableDeath(player, HealthVariants.good.maximum, 'cheat');
            } else {
                player = enableDeath(player, 'cheat');
            }
        }

        const players = [...game.players];
        players[index] = player;

        return { ...game, players };
    }

}