import { Button } from "react-bootstrap";
import { useAppDispatch } from "../../hooks";
import { removeEvent } from "../gameSlice";
import { ArrivedAtHiddenLocationEvent } from "../../../types/GameEvent";

export default function ArrivedAtHiddenLocationEventView({ event }: { event: ArrivedAtHiddenLocationEvent }) {
    const dispatch = useAppDispatch();

    const close = () => dispatch(removeEvent(event.id));

    return (
        <div className="d-flex w-100 h-100 justify-content-between align-items-top">
            <div className="text-start p-4">
                <h3>Found {event.location.name}!</h3>
                <div>
                    We stumbled upon a hidden place.
                </div>
                <Button variant="primary" onClick={close}>Close</Button>
            </div>
        </div>
    );
}