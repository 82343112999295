import { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { makeHunting } from "../../../../../types/Hunting";
import { routingCurrentLocation } from "../../../../../types/location/Routing";
import { isDead } from "../../../../../types/Player";
import { randomElement } from "../../../../../types/Random";
import { travelPreventions } from "../../../../../types/travel/Travel";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { selectSettings } from "../../../../settings/settingsSlice";
import { useTwitchManager } from "../../../../twitch/TwitchManager";
import { timeoutHunting, selectGame, startHunting } from "../../../gameSlice";

export default function HuntButton() {
    const dispatch = useAppDispatch();
    const twitch = useTwitchManager();
    const game = useAppSelector(selectGame);
    const { players, inventory, hunting, status, traveling, trail } = game;
    const { huntingDuration } = useAppSelector(selectSettings);
    const hunters = players.filter(player => !isDead(player) && player.active);
    const currentLocation = routingCurrentLocation(trail.routing);
    const preventions = travelPreventions(game);

    const [timer, setTimer] = useState<any | undefined>(undefined);

    const huntingDisabled = (!!hunting && !hunting.result) // already hunting
        || hunters.length === 0                            // nobody can hunt
        || inventory.bullets.amount < 1                    // no bullets
        || inventory.rifles.amount < 1                     // no rifles
        || status !== 'Playing'                            // game over
        || traveling                                       // traveling mode
        || currentLocation?.type === 'Town';               // in a town
    
    const hunt = useCallback(() => {
        const hunter = randomElement(hunters);
        if (hunter && !huntingDisabled) {
            const hunting = makeHunting(hunter.name);
            twitch.sendMessage(`@${hunter.name} you're hunting. Type the following word as quickly as possible: ${hunting.word}`);
            dispatch(startHunting(hunting));
        }
    }, [hunters, twitch, huntingDisabled, dispatch]);

    const timeout = useCallback(() => {
        if (hunting && !hunting.result) {
            dispatch(timeoutHunting(huntingDuration));
        }
    }, [hunting, huntingDuration, dispatch]);

    const onButtonClicked = () => hunt();

    useEffect(() => {
        if (!!timer && (!hunting || !!hunting.result)) {
            clearTimeout(timer);
            setTimer(undefined);
        } else if (!timer && !!hunting && !hunting.result) {
            const dt = new Date().getTime() - hunting.start;
            const time = (huntingDuration * 1000) - dt;
            if (time > 0) {
                setTimer(setTimeout(() => timeout(), time));
            } else {
                timeout();
            }
        }
    }, [timer, setTimer, hunting, timeout, huntingDuration]);

    if (preventions.includes('under attack')) {
        return null;
    } else {
        return <Button variant="primary" className="mb-1 ps-2 pe-2 text-truncate" disabled={huntingDisabled} onClick={onButtonClicked} style={{ minWidth: '6rem' }}>Hunt</Button>;
    }
}