import { Button, Image } from "react-bootstrap";
import { useAppDispatch } from "../../hooks";
import { removeEvent } from "../gameSlice";
import { HuntingFailureEvent } from "../../../types/GameEvent";
import { formatBullets } from "../../../types/inventory/Bullets";

export default function HuntingFailureEventView({ event }: { event: HuntingFailureEvent }) {
    const dispatch = useAppDispatch();

    const close = () => dispatch(removeEvent(event.id));

    return (
        <div className="d-flex w-100 h-100 justify-content-start align-items-center">
            <div className="text-start p-4">
                <h3>{event.miss.playerName} missed.</h3>
                { event.miss.bullets > 0 && <div>We've lost {formatBullets(event.miss.bullets)}.</div> }
                <Button variant="primary" onClick={close}>Close</Button>
            </div>
            <Image src="/gun.png" height={240} rounded />
        </div>
    );
}