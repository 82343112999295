import { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { routingCurrentLocation } from "../../../../types/location/Routing";
import { Town } from "../../../../types/location/Town";
import { travelPreventions } from "../../../../types/travel/Travel";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { selectSettings } from "../../../settings/settingsSlice";
import { advanceGame, disableTraveling, enableTraveling, selectGame } from "../../gameSlice";

let __auto_travel_timer: any | undefined = undefined;

export default function TravelButton({ autoTravel }: { autoTravel: boolean }) {
    const dispatch = useAppDispatch();
    const game = useAppSelector(selectGame);
    const { cheatMode } = useAppSelector(selectSettings);
    const [isJumping, setJumping] = useState(false);

    const location = routingCurrentLocation(game.trail.routing);
    const town = location?.type === 'Town' ? location as Town : null;
    const preventions = travelPreventions(game);
    const enabled = preventions.length === 0;
    const traveling = game.traveling;

    const travel = useCallback(() => {
        dispatch(disableTraveling());
        if (isJumping) {
            dispatch(advanceGame('jump'));
        } else {
            dispatch(advanceGame());
        }
    }, [dispatch, isJumping]);

    const startTravel = () => {
        dispatch(enableTraveling());
        dispatch(advanceGame());
    };

    const stopTravel = () => dispatch(disableTraveling());

    const installTimer = useCallback(() => {
        if (__auto_travel_timer === undefined) {
            __auto_travel_timer = setInterval(() => {
                if (traveling && enabled) {
                    dispatch(advanceGame());
                }
            }, 1000);
        }
    }, [dispatch, traveling, enabled]);

    const uninstallTimer = useCallback(() => {
        if (__auto_travel_timer !== undefined) {
            clearInterval(__auto_travel_timer);
            __auto_travel_timer = undefined;
        }
    }, []);

    const onKeyDown = useCallback((event: KeyboardEvent) => {
        if (event.key === 'Shift' && cheatMode === 'on') {
            setJumping(true);
        }
    }, [cheatMode, setJumping]);

    const onKeyUp = useCallback((event: KeyboardEvent) => {
        if (event.key === 'Shift' && cheatMode === 'on') {
            setJumping(false);
        }
    }, [cheatMode, setJumping]);

    useEffect(() => {
        window.addEventListener('keydown', onKeyDown);
        window.addEventListener('keyup', onKeyUp);
        return () => {
            window.removeEventListener('keyup', onKeyUp);
            window.removeEventListener('keydown', onKeyDown);
        };
    }, [onKeyDown, onKeyUp]);

    useEffect(() => {
        if (traveling && enabled) {
            installTimer();
        } else if (!traveling || !enabled) {
            uninstallTimer();
        }
        return () => uninstallTimer();
    }, [traveling, enabled, installTimer, uninstallTimer]);

    useEffect(() => {
        if (traveling && (!enabled || location !== null)) {
            dispatch(disableTraveling());
        }
    }, [traveling, enabled, location, dispatch, uninstallTimer]);

    if (preventions.includes('cooldown') || preventions.includes('no oxen') || preventions.includes('no wagon')) {
        return null;
    } else if (autoTravel && traveling) {
        return <Button variant="warning" className="mb-1 ps-2 pe-2 text-truncate" onClick={stopTravel}>Stop</Button>;
    } else if (autoTravel) {
        if (town !== null) {
            return <Button variant="primary" className="mb-1 ps-2 pe-2 text-truncate" onClick={startTravel} disabled={!enabled}>Leave Town</Button>;
        } else {
            return <Button variant="primary" className="mb-1 ps-2 pe-2 text-truncate" onClick={startTravel} disabled={!enabled}>Travel</Button>;
        }
    } else {
        if (isJumping) {
            return <Button variant="primary" className="mb-1 ps-2 pe-2 text-truncate" onClick={travel} disabled={!enabled}>Jump</Button>;
        } else if (town !== null) {
            return <Button variant="primary" className="mb-1 ps-2 pe-2 text-truncate" onClick={travel} disabled={!enabled}>Leave Town</Button>;
        } else {
            return <Button variant="primary" className="mb-1 ps-2 pe-2 text-truncate" onClick={travel} disabled={!enabled}>Travel</Button>;
        }
    }
}