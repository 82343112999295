import { formatAnd, GALLON_PER_LITER, OUNCE_PER_GALLON } from "../Utilities";
import { InventoryItem } from "./InventoryItem";

export const WATER_PER_BOTTLE = 1.5;
export const WATER_PER_CANISTER = 10;

export type WaterContainerType = 'Bottle' | 'Canister';

export interface WaterContainer {
    type: WaterContainerType;
    capacity: number;
}

export interface Water extends InventoryItem {
    name: 'Water';
    containers: WaterContainer[];
}

export function makeWater(amount: number = 0, bottleCount: number = 1): Water {
    const containers: WaterContainer[] = [];
    for (let index = 0; index < bottleCount; ++index) {
        containers.push({ type: 'Bottle', capacity: WATER_PER_BOTTLE });
    }
    return { amount, containers, name: 'Water' };
}

export function formatWater(water: Water | number, unit: 'liter' | 'gallon' | 'percent' = 'gallon'): string {
    if (typeof water === 'number') {
        if (unit === 'liter') {
            return water.toFixed(3) + ' l';
        } else if (unit === 'gallon' || unit === 'percent') {
            const gallons = water * GALLON_PER_LITER;
            if (gallons >= 1) {
                return gallons.toFixed(2) + ' gal';
            }
            const ounces = gallons * OUNCE_PER_GALLON;
            return ounces.toFixed(2) + ' fl oz';
        }
    } else {
        if (unit === 'liter') {
            return water.amount.toFixed(3) + ' l';
        } else if (unit === 'gallon') {
            const gallons = water.amount * GALLON_PER_LITER;
            if (gallons >= 1) {
                return gallons.toFixed(2) + ' gal';
            }
            const ounces = gallons * OUNCE_PER_GALLON;
            return ounces.toFixed(2) + ' fl oz';
        } else if (unit === 'percent') {
            const maximum = water.containers
                .map(container => container.capacity)
                .reduce((prev, curr) => prev + curr, 0);

            if (maximum === 0) {
                return '0 %';
            } else {
                return (100 * (water.amount / maximum)).toFixed(0) + ' %';
            }
        }
    }
    return 'invalid unit';
}

export function formatWaterContainers(containers: WaterContainer[] | { count: number, type: WaterContainerType }, fillingLevel?: Water) {
    const items: string[] = [];
    const containerTypes: WaterContainerType[] = [];

    if ('length' in containers && containers.length) {
        const bottles = containers.filter(container => container.type === 'Bottle').length;
        const canisters = containers.filter(container => container.type === 'Canister').length;
        if (bottles > 0) {
            items.push(bottles === 1 ? '1 bottle' : `${bottles} bottles`);
            containerTypes.push('Bottle');
        }
        if (canisters > 0) {
            items.push(canisters === 1 ? '1 canister' : `${canisters} canisters`);
            containerTypes.push('Canister');
        }
    }

    else if ('count' in containers && containers.count && 'type' in containers) {
        const { count, type } = containers;
        if (type === 'Bottle') {
            if (count > 0) {
                items.push(count === 1 ? '1 bottle' : `${count} bottles`);
                containerTypes.push(type);
            }
        } else if (type === 'Canister') {
            if (count > 0) {
                items.push(count === 1 ? '1 canister' : `${count} canisters`);
                containerTypes.push(type);
            }
        }
    }

    const amounts = formatAnd(items, 'no water');
    if (!fillingLevel || amounts === 'no water') {
        return amounts;
    }

    const maximum = fillingLevel.containers
        .filter(container => containerTypes.includes(container.type))
        .map(container => container.capacity)
        .reduce((prev, curr) => prev + curr, 0);
    
    const totalAmount = fillingLevel.amount;
    if (maximum === 0 || totalAmount === 0) {
        return `${amounts}, empty`;
    } else if (totalAmount === maximum) {
        return `${amounts}, full`;
    } else {
        const percentage = (100 * (totalAmount / maximum)).toFixed(0);
        return `${amounts}, ${percentage} %`;
    }
}

export function determineWaterWeight(water: Water): number {
    return water.amount;
}

export function determineWaterForWeight(weight: number): number {
    return weight;
}