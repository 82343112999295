import { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitch } from "@fortawesome/free-brands-svg-icons";
import { useAppDispatch, useAppSelector } from "../hooks";
import { activatePlayer, addPlayer, deactivatePlayer, endHunting, fightAnimalShot, fightBanditShot, makeHangmanGuess, removePlayer, selectGame, unlockChest } from "../game/gameSlice";
import { HuntingHit, HuntingResult } from "../../types/Hunting";
import { makePlayer } from "../../types/Player";
import { selectSettings } from "../settings/settingsSlice";
import { TwitchConnectEvent, TwitchMessageEvent, useTwitchManager } from "./TwitchManager";
import { formatFood } from "../../types/inventory/Food";

interface HuntingCallback {
    username: string;
    notify: (result: HuntingResult) => void;
}

export default function TwitchBot() {
    const dispatch = useAppDispatch();
    const twitchManager = useTwitchManager();
    const settings = useAppSelector(selectSettings);
    const { status, players, hunting, animalAttack, banditAttack, openingChestAttempt, hangmanGame } = useAppSelector(selectGame);

    const [isConnected, setConnected] = useState(twitchManager.connected);
    const [huntingCallback, setHuntingCallback] = useState<HuntingCallback | null>(null);

    const onConnectedToTwitch = useCallback((event: TwitchConnectEvent) => {
        setConnected(true);

        const exists = players
            .map(player => player.name.toLowerCase() === event.channel.toLowerCase())
            .length > 0;
        
        if (!exists && status === 'Start') {
            twitchManager.getUser(event.channel).then(user => {
                const name = user.name;
                const avatarURL = user.avatarURL;
                const channelOwner = true;
                dispatch(addPlayer({ ...makePlayer(name, true), avatarURL, channelOwner }));
            });
        }
    }, [twitchManager, players, status, dispatch, setConnected]);

    const onDisconnectedFromTwitch = useCallback(() => setConnected(false), [setConnected]);

    const onJoinCommand = useCallback((event: TwitchMessageEvent) => {
        const username = getUsername(event);
        const exists = players
            .filter(player => player.name.toLowerCase() === username)
            .length > 0;
        
        if (!exists && status === 'Start') {
            twitchManager.getUser(username)
                .then(user => {
                    const name = user.name;
                    const avatarURL = user.avatarURL;
                    const channelOwner = username.toLowerCase() === event.channel.toLowerCase();
                    dispatch(addPlayer({ ...makePlayer(name, true), avatarURL, channelOwner }));
                    event.client.say(event.channel, `@${username} has joined the team.`);
                })
                .catch(error => console.warn(error));
        }
    }, [twitchManager, players, status, dispatch]);

    const onLeaveCommand = useCallback((event: TwitchMessageEvent) => {
        const username = getUsername(event);
        const player = players.find(p => p.name.toLowerCase() === username.toLowerCase());
        if (player && player.active) {
            dispatch(removePlayer(username));
            if (player.hp > 0) {
                event.client.say(event.channel, `@${username} fell into a deep slumber.`);
            }
        }
    }, [players, dispatch]);

    const onLurkCommand = useCallback((event: TwitchMessageEvent) => {
        const username = getUsername(event);
        const player = players.find(p => p.name.toLowerCase() === username.toLowerCase());
        if (player && player.active) {
            dispatch(deactivatePlayer(username));
            if (player.hp > 0) {
                event.client.say(event.channel, `@${username} fell into a deep slumber.`);
            }
        }
    }, [players, dispatch]);

    const onUnlurkCommand = useCallback((event: TwitchMessageEvent) => {
        const username = getUsername(event);
        const player = players.find(p => p.name.toLowerCase() === username.toLowerCase());
        if (player && !player.active) {
            dispatch(activatePlayer(username));
            if (player.hp > 0) {
                event.client.say(event.channel, `@${username} woke up, wondering what kind of flowers these are.`);
            }
        }
    }, [players, dispatch]);

    const onHuntingResponse = useCallback((event: TwitchMessageEvent) => {
        const username = getUsername(event);
        const notify = (result: HuntingResult) => {
            if (result.type === 'Hit') {
                const hit = result as HuntingHit;
                const seconds = hit.duration.toFixed(3) + ' s';
                const food = formatFood(hit.food) + ' of food';
                event.client.say(event.channel, `@${username} Good job, you took ${seconds} and brought back ${food}.`);
            } else if (result.type === 'Miss') {
                event.client.say(event.channel, `@${username} You missed. Booooo!`);
            }
        };
        setHuntingCallback({ username, notify });
        dispatch(endHunting({ username, message: event.message, maxDuration: settings.huntingDuration }));
    }, [dispatch, setHuntingCallback, settings]);

    const onAnimalAttackResponse = useCallback((event: TwitchMessageEvent) => {
        const username = getUsername(event);
        if (animalAttack && !animalAttack.result) {
            const playerName = animalAttack.attackingPlayers.find(name => name.toLowerCase() === username.toLowerCase());
            const word = event.message.toLowerCase().trim();
            if (playerName !== undefined && word.length > 0) {
                dispatch(fightAnimalShot({ playerName, word }));
            }
        }
    }, [animalAttack, dispatch]);

    const onBanditAttackResponse = useCallback((event: TwitchMessageEvent) => {
        const username = getUsername(event);
        if (banditAttack && !banditAttack.result) {
            const playerName = banditAttack.attackingPlayers.find(name => name.toLowerCase() === username.toLowerCase());
            const word = event.message.toLowerCase().trim();
            if (playerName !== undefined && word.length > 0) {
                dispatch(fightBanditShot({ playerName, word }));
            }
        }
    }, [banditAttack, dispatch]);

    const onChestUnlockResponse = useCallback((event: TwitchMessageEvent) => {
        if (openingChestAttempt) {
            const username = getUsername(event);
            const message = event.message.substring(settings.twitchCommands.guess.length + 1).trim();
            dispatch(unlockChest({ playerName: username, message }));
        }
    }, [dispatch, settings, openingChestAttempt]);

    const onHangmanGuess = useCallback((event: TwitchMessageEvent) => {
        if (hangmanGame) {
            const username = getUsername(event);
            const message = event.message.substring(settings.twitchCommands.guess.length + 1).trim();
            dispatch(makeHangmanGuess({ playerName: username, message }));
        }
    }, [dispatch, hangmanGame, settings]);

    const onMessage = useCallback((event: TwitchMessageEvent) => {
        const username = getUsername(event);
        if (username.length) {
            const { join, leave, lurk, unlurk, guess } = settings.twitchCommands;
            const commandsWithArguments = event.message.startsWith('!') && event.message.length > 1 ? event.message.substring(1) : '';
            // eslint-disable-next-line
            let [command, ...args] = commandsWithArguments.split(' ');
            command = command.toLowerCase();

            if (command === join) {
                onJoinCommand(event);
            } else if (command === leave) {
                onLeaveCommand(event);
            } else if (command === lurk) {
                onLurkCommand(event);
            } else if (command === unlurk) {
                onUnlurkCommand(event);
            } else if (command === '' && hunting !== null && username.toLowerCase() === hunting.playerName.toLowerCase()) {
                onHuntingResponse(event);
            } else if (command === '' && animalAttack !== null && animalAttack.result === undefined) {
                onAnimalAttackResponse(event);
            } else if (command === '' && banditAttack !== null && banditAttack.result === undefined) {
                onBanditAttackResponse(event);
            } else if (command === guess && openingChestAttempt) {
                onChestUnlockResponse(event);
            } else if (command === guess && hangmanGame) {
                onHangmanGuess(event);
            }
        }
    }, [settings, hunting, animalAttack, banditAttack, openingChestAttempt, hangmanGame, onJoinCommand, onLeaveCommand, onLurkCommand, onUnlurkCommand, onHuntingResponse, onAnimalAttackResponse, onBanditAttackResponse, onChestUnlockResponse, onHangmanGuess]);

    useEffect(() => {
        const name = 'twitch bot';
        twitchManager.addEventListener(name, 'connect', onConnectedToTwitch);
        twitchManager.addEventListener(name, 'disconnect', onDisconnectedFromTwitch);
        twitchManager.addEventListener(name, 'message', onMessage);
        twitchManager.connect();

        return () => {
            twitchManager.removeEventListener(name, 'connect');
            twitchManager.removeEventListener(name, 'disconnect');
            twitchManager.removeEventListener(name, 'message');
        };
    }, [twitchManager, onConnectedToTwitch, onDisconnectedFromTwitch, onMessage]);

    useEffect(() => {
        if (huntingCallback !== null && !!hunting?.result && hunting.result.playerName.toLowerCase() === huntingCallback.username.toLowerCase()) {
            huntingCallback.notify(hunting.result);
            setHuntingCallback(null);
        }
    }, [hunting, huntingCallback, setHuntingCallback]);

    return <FontAwesomeIcon icon={faTwitch} className={isConnected ? "twitch on" : "twitch off"} />;
}

function getUsername(event: TwitchMessageEvent): string {
    return event.userstate['display-name'] ?? event.userstate.username ?? '';
}