import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { formatCash } from "../../../../../types/inventory/Cash";
import { buyWagons, determineSellingPrice, sellWagons, StoreItemName } from "../../../../../types/Store";
import { Wagon, WagonName } from "../../../../../types/Wagon";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { boughtSomething, changeInventory, changeWagons, selectGame } from "../../../gameSlice";

import ProductAmount from "./ProductAmount";
import ProductName from "./ProductName";
import ProductPrice from "./ProductPrice";
import StoreItem from "./StoreItem";
import TradingButtons from "./TradingButtons";

interface WagonStoreItemProps {
    pilgrimsWagonPrice: number;
    baseWagonPrice: number;
    largeWagonPrice: number;
    doubleBaseWagonPrice: number;
    doubleLargeWagonPrice: number;
    selection: [selection: StoreItemName | null, setSelection: (name: StoreItemName | null) => void];
}

export default function WagonStoreItem({ pilgrimsWagonPrice, baseWagonPrice, largeWagonPrice, doubleBaseWagonPrice, doubleLargeWagonPrice, selection }: WagonStoreItemProps) {
    const dispatch = useAppDispatch();
    const { inventory, wagons, stats } = useAppSelector(selectGame);
    const { cash } = inventory;
    
    const [selectedItem, setSelectedItem] = selection;
    const [wagonName, setWagonName] = useState<WagonName | null>(null);
    const active = selectedItem === 'Pilgrims Wagon';

    const pilgrimsWagon    = !!pilgrimsWagonPrice;
    const baseWagon        = !!baseWagonPrice;
    const largeWagon       = !!largeWagonPrice;
    const doubleBaseWagon  = !!doubleBaseWagonPrice;
    const doubleLargeWagon = !!doubleLargeWagonPrice;
    const count = [pilgrimsWagon, baseWagon, largeWagon, doubleBaseWagon, doubleLargeWagon].filter(e => e === true).length;

    let price: number = 0;
    if (wagonName === "Pilgrim's Wagon") {
        price = pilgrimsWagonPrice;
    } else if (wagonName === 'Base Wagon') {
        price = baseWagonPrice;
    } else if (wagonName === 'Large Wagon') {
        price = largeWagonPrice;
    } else if (wagonName === 'Double Base Wagon') {
        price = doubleBaseWagonPrice;
    } else if (wagonName === 'Double Large Wagon') {
        price = doubleLargeWagonPrice;
    }

    let buy: ((amount: number) => void) | undefined = undefined;
    if (wagonName !== null && price > 0 && cash.amount >= price) {
        buy = (amount) => {
            const [changedInventory, changedWagons] = buyWagons(inventory, wagons, wagonName, amount, price);
            dispatch(changeInventory(changedInventory));
            dispatch(changeWagons(changedWagons));
            dispatch(boughtSomething({ price: price * amount, item: 'wagons' }));
            setSelectedItem(null);
        };
    }

    let sell: ((amount: number) => void) | undefined = undefined;
    if (wagonName !== null && price > 0 && wagons.length > 0) {
        sell = (amount) => {
            const [changedInventory, changedWagons] = sellWagons(inventory, wagons, wagonName, amount, determineSellingPrice(price, stats.trading));
            dispatch(changeInventory(changedInventory));
            dispatch(changeWagons(changedWagons));
            setSelectedItem(null);
        };
    }

    useEffect(() => {
        const pilgrimsWagon    = !!pilgrimsWagonPrice;
        const baseWagon        = !!baseWagonPrice;
        const largeWagon       = !!largeWagonPrice;
        const doubleBaseWagon  = !!doubleBaseWagonPrice;
        const doubleLargeWagon = !!doubleLargeWagonPrice;
        const count = [pilgrimsWagon, baseWagon, largeWagon, doubleBaseWagon, doubleLargeWagon].filter(e => e === true).length;

        if (pilgrimsWagon && !baseWagon && !largeWagon && !doubleBaseWagon && !doubleLargeWagon && wagonName !== "Pilgrim's Wagon") {
            setWagonName("Pilgrim's Wagon");
        } else if (!pilgrimsWagon && baseWagon && !largeWagon && !doubleBaseWagon && !doubleLargeWagon && wagonName !== 'Base Wagon') {
            setWagonName('Base Wagon');
        } else if (!pilgrimsWagon && !baseWagon && largeWagon && !doubleBaseWagon && !doubleLargeWagon && wagonName !== 'Large Wagon') {
            setWagonName('Large Wagon');
        } else if (!pilgrimsWagon && !baseWagon && !largeWagon && doubleBaseWagon && !doubleLargeWagon && wagonName !== 'Double Base Wagon') {
            setWagonName('Double Base Wagon');
        } else if (!pilgrimsWagon && !baseWagon && !largeWagon && !doubleBaseWagon && doubleLargeWagon && wagonName !== 'Double Large Wagon') {
            setWagonName('Double Large Wagon');
        } else if (count > 1 && wagonName === null) {
            if (pilgrimsWagon) {
                setWagonName("Pilgrim's Wagon");
            } else if (baseWagon) {
                setWagonName('Base Wagon');
            } else if (largeWagon) {
                setWagonName('Large Wagon');
            } else if (doubleBaseWagon) {
                setWagonName('Double Base Wagon');
            } else if (doubleLargeWagon) {
                setWagonName('Double Large Wagon');
            }
        } else if (count === 0 && wagonName !== null) {
            setWagonName(null);
        }
    }, [pilgrimsWagonPrice, baseWagonPrice, largeWagonPrice, doubleBaseWagonPrice, doubleLargeWagonPrice, wagonName, setWagonName]);

    const productName = (
        <ProductName>
            <div className="d-flex justify-content-start align-items-center">
                <div className="me-2">Wagons</div>
                { pilgrimsWagon && !baseWagon && !largeWagon && !doubleBaseWagon && !doubleLargeWagon && <div>Pilgrim's Wagon</div> }
                { !pilgrimsWagon && baseWagon && !largeWagon && !doubleBaseWagon && !doubleLargeWagon && <div>Base Wagon</div> }
                { !pilgrimsWagon && !baseWagon && largeWagon && !doubleBaseWagon && !doubleLargeWagon && <div>Large Wagon</div> }
                { !pilgrimsWagon && !baseWagon && !largeWagon && doubleBaseWagon && !doubleLargeWagon && <div>Double Base Wagon</div> }
                { !pilgrimsWagon && !baseWagon && !largeWagon && !doubleBaseWagon && doubleLargeWagon && <div>Double Large Wagon</div> }
                { count > 1 &&
                <Form.Select value={wagonName ?? ''} onChange={({target}) => setWagonName(target.value as WagonName)}>
                    { pilgrimsWagon    && <option value="Pilgrim's Wagon">Pilgrim's Wagons</option> }
                    { baseWagon        && <option value="Base Wagon">Base Wagons</option> }
                    { largeWagon       && <option value="Large Wagon">Large Wagons</option> }
                    { doubleBaseWagon  && <option value="Double Base Wagon">Double Base Wagons</option> }
                    { doubleLargeWagon && <option value="Double Large Wagon">Double Large Wagons</option> }
                </Form.Select>
                }
            </div>
        </ProductName>
    );

    const productPrice = (
        <ProductPrice active={active}>
            <>
            { wagonName === "Pilgrim's Wagon"    && <span>buy for {formatCash(pilgrimsWagonPrice)}, sell for {formatCash(determineSellingPrice(pilgrimsWagonPrice, stats.trading))} per wagon.</span> }
            { wagonName === 'Base Wagon'         && <span>buy for {formatCash(baseWagonPrice)}, sell for {formatCash(determineSellingPrice(baseWagonPrice, stats.trading))} per wagon.</span> }
            { wagonName === 'Large Wagon'        && <span>buy for {formatCash(largeWagonPrice)}, sell for {formatCash(determineSellingPrice(largeWagonPrice, stats.trading))} per wagon.</span> }
            { wagonName === 'Double Base Wagon'  && <span>buy for {formatCash(doubleBaseWagonPrice)}, sell for {formatCash(determineSellingPrice(doubleBaseWagonPrice, stats.trading))} per wagon.</span> }
            { wagonName === 'Double Large Wagon' && <span>buy for {formatCash(doubleLargeWagonPrice)}, sell for {formatCash(determineSellingPrice(doubleLargeWagonPrice, stats.trading))} per wagon.</span> }
            </>
        </ProductPrice>
    );

    const productAmount = <ProductAmount>{formatWagons(wagons)}</ProductAmount>;

    const money = (amount: number, mode: 'buy' | 'sell') => {
        const factor = mode === 'buy' ? 1 : .5;
        if (wagonName === "Pilgrim's Wagon") {
            return pilgrimsWagonPrice * factor * amount;
        } else if (wagonName === 'Base Wagon') {
            return baseWagonPrice * factor * amount;
        } else if (wagonName === 'Large Wagon') {
            return largeWagonPrice * factor * amount;
        } else if (wagonName === 'Double Base Wagon') {
            return doubleBaseWagonPrice * factor * amount;
        } else if (wagonName === 'Double Large Wagon') {
            return doubleLargeWagonPrice * factor * amount;
        } else {
            return 0;
        }
    };

    const canAfford = (amount: number) => {
        if (wagonName === "Pilgrim's Wagon") {
            return amount * pilgrimsWagonPrice <= cash.amount;
        } else if (wagonName === 'Base Wagon') {
            return amount * baseWagonPrice <= cash.amount;
        } else if (wagonName === 'Large Wagon') {
            return amount * largeWagonPrice <= cash.amount;
        } else if (wagonName === 'Double Base Wagon') {
            return amount * doubleBaseWagonPrice <= cash.amount;
        } else if (wagonName === 'Double Large Wagon') {
            return amount * doubleLargeWagonPrice <= cash.amount;
        } else {
            return false;
        }
    };

    const tradingButtons = (
        <TradingButtons
            amountInStock={wagons.filter(wagon => wagon.name === wagonName).length}
            amountPerUnit={1}
            canAfford={canAfford}
            money={money}
            formatUnits={formatWagons}
            minAmount={0}
            maxAmount={Infinity}
            buy={buy}
            sell={sell}
        />
    );

    return (
        <StoreItem
            gameIcon="old-wagon"
            productName={productName}
            productPrice={productPrice}
            productAmount={productAmount}
            tradingButtons={tradingButtons}
        />
    );
}

function formatWagons(wagons: Wagon[] | number): string {
    if (typeof wagons === 'number') {
        if (wagons === 1) {
            return '1 wagon';
        } else {
            return `${wagons} wagons`;
        }
    } else if (wagons.length === 1) {
        return '1 wagon';
    } else {
        return `${wagons.length} wagons`;
    }
}