import { RegionName } from "../location/Location";
import { Chest } from "./Chest";
import { Diary, makeDiary } from "./Diary";
import { makeMaps, MapItem, Maps } from "./Maps";

export function getMapItem(inventory: QuestInventory, regionName: RegionName): MapItem | null {
    return inventory.maps.items.find(item => item.regionName === regionName) ?? null;
}

export interface QuestInventory {
    maps: Maps;
    diary: Diary;
    chest?: Chest;
}

export function makeQuestInventory(): QuestInventory {
    const maps = makeMaps();
    const diary = makeDiary();

    return { maps, diary };
}