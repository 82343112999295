import { format } from "date-fns";
import { Card, Col, Row } from "react-bootstrap";
import { faCloud, faCloudBolt, faCloudRain, faCloudShowersHeavy, faCloudSun, faSmog, faSnowflake, faSun, faTemperatureArrowDown, faWind, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { useAppSelector } from "../../hooks";
import { selectGame } from "../gameSlice";
import { getDistanceToNextLocation, Trail, TravelOptions } from "../../../types/travel/Trail";
import { formatTemperature, WeatherCondition } from "../../../types/Weather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { routingCurrentLocation, routingNextVisibleLocation } from "../../../types/location/Routing";
import { Location } from "../../../types/location/Location";
import { Town } from "../../../types/location/Town";

import MessagesView from "../message/MessagesView";
import EventView from "../event/EventView";
import RoadView from "./RoadView";

export default function TrailView() {
    const { date, trail, travelOptions, weather, messages } = useAppSelector(selectGame);
    
    const weatherIcon = getWeatherIcon(weather.condition);
    const currentLocation = routingCurrentLocation(trail.routing);
    const nextLocation = routingNextVisibleLocation(trail.routing, travelOptions.chosenDestinationName ?? trail.direction);
    const newMessages = messages.filter(message => message.roundIndex === 0);

    return (
        <Card className="trail-view">
            <Card.Header>
                <Row className="align-items-center">
                    <Col sm={3} className="text-start">
                        {format(date, 'EE, MMM d, yyyy')}
                    </Col>
                    <Col className="text-center">
                        { !!weatherIcon && <FontAwesomeIcon icon={weatherIcon} fixedWidth className="me-1" /> } {weather.condition}, {formatTemperature(weather)}
                    </Col>
                    <Col sm={3} className="text-end">
                        {formatDistance(trail.mileage)}
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                { !!nextLocation && !currentLocation &&
                    <NextLocationView
                        nextLocation={nextLocation}
                        trail={trail}
                        travelOptions={travelOptions}
                    />
                }
                { !!currentLocation &&
                    <CurrentLocationView
                        currentLocation={currentLocation}
                    />
                }
                <EventView />
            </Card.Body>
            { newMessages.length > 0 &&
            <Card.Footer>
                <MessagesView messages={newMessages} />
            </Card.Footer>
            }
        </Card>
    );
}

function formatDistance(distance: number): string {
    return distance.toFixed(0) + ' miles';
}

function getWeatherIcon(condition: WeatherCondition): IconDefinition | null {
    switch (condition) {
        case 'Sunny':
        case 'Mostly Sunny':
        case 'Clear':
            return faSun;
        case 'Partly Sunny':
        case 'Overcast':
            return faCloudSun;
        case 'Cloudy':
            return faCloud;
        case 'Storm':
        case 'Flurries':
            return faWind;
        case 'Chance of Rain':
        case 'Freezing Drizzle':
        case 'Scattered Showers':
            return faCloudRain;
        case 'Rain':
        case 'Hail':
            return faCloudShowersHeavy;
        case 'Thunderstorm':
        case 'Scattered Thunderstorm':
        case 'Chance of Thunderstorm':
            return faCloudBolt;
        case 'Haze':
        case 'Fog':
            return faSmog;
        case 'Snow':
        case 'Snow Showers':
        case 'Light Snow':
        case 'Sleet':
            return faSnowflake;
        case 'Icy':
            return faTemperatureArrowDown;
        default:
            return null;
    }
}

interface NextLocationViewProps {
    nextLocation: Location;
    trail: Trail;
    travelOptions: TravelOptions;
}

function NextLocationView({ nextLocation, trail, travelOptions }: NextLocationViewProps) {
    const traveledDistance = trail.distanceSinceLastLocation;
    const remainingDistance = getDistanceToNextLocation(trail, travelOptions?.chosenDestinationName ?? trail.direction);
    const fraction = remainingDistance === 0 ? 1 : traveledDistance / (traveledDistance + remainingDistance);

    return (
        <div className="mb-4">
            <div>We're on our way to <strong>{nextLocation.name}</strong>, {formatDistance(remainingDistance)} to go.</div>
            <RoadView fraction={fraction} />
        </div>
    );
}

interface CurrentLocationViewProps {
    currentLocation: Location;
}

function CurrentLocationView({ currentLocation }: CurrentLocationViewProps) {
    const town = currentLocation.type === 'Town' ? currentLocation as Town : null;

    return (
        <div>
            <h3 className="text-center">·:· Welcome to <strong>{currentLocation.name}</strong> ·:·</h3>
            { town !== null && <h6 className="text-center">Population: {formatPopulation(town.population)}</h6> }
            <div style={{ maxWidth: '60rem' }}>
                There is probably something very interesting to say about this place. Maybe even something relevant to this story. 
                But I haven't come up with anything yet. Well, let's hope that Miguel has some great ideas what to put at this place. 
                ~Jabu
            </div>
        </div>
    );
}

function formatPopulation(population: number): string {
    return population.toLocaleString('en');
}